<template>
    <div class="tab_con">
        <div class="clear"></div>
        <div class="q_box">
            <div class="zs_sf">招生省份：{{ provinceObj.ProvinceName }}</div>
            <div class="plan_select year" v-if="APQParametersData.length!=0">
                <el-select v-model="APQParameters.Year" @change="APQParametersChangeYear()">
                    <el-option v-for="year in APQParametersData" :key="year.Value" :label="year.Name" :value="year.Value"></el-option>
                </el-select>
            </div>
            <div class="plan_select year" v-if="LevelNumber==4&&SubjectData.length!=0">
                <el-select v-model="APQParameters.SubjectType" @change="APQParametersChangeSubject()" :disabled="(UserData&&UserData.UserType!=1&&UserSubjectType!=null)">
                    <el-option v-for="subject in SubjectData" :key="subject.Value" :label="subject.Name" :value="subject.Value"></el-option>
                </el-select>
            </div>
            <div class="plan_select batch" v-if="BatchData.length!=0">
                <el-select v-model="APQParameters.BatchId" @change="APQParametersChangeBatch()">
                    <el-option v-for="batch in BatchData" :key="batch.Value" :label="batch.Name" :value="batch.Value"></el-option>
                </el-select>
            </div>
            <div class="plan_select plan" v-if="PlanCollegeData.length!=0">
                <el-select v-model="APQParameters.PlanCollegeId" @change="APQParametersChangePlan()">
                    <el-option v-for="plancollege in PlanCollegeData" :key="plancollege.Value" :label="plancollege.Name" :value="plancollege.Value"></el-option>
                </el-select>
            </div>
        </div>
        <table class="planhead" cellspacing="0" v-if="PlanMajorData&&APQParametersData.length!=0">
            <tr>
                <td>院校名称</td>
                <td>专业代码</td>
                <td>专业名称</td>
                <td>计划人数</td>
                <td>学费</td>
                <td>学制（年）</td>
                <td>批次</td>
            </tr>
        </table>
        <table class="plantable" cellspacing="0" v-if="PlanMajorData.length!=0&&APQParametersData.length!=0">
            <tr v-for="(plan,index) in PlanMajorData.PlanMajorList" :key="index">
                <td>{{PlanMajorData.CollegeName}}</td>
                <td>{{plan.MajorCode}}</td>
                <td>{{plan.MajorName}}<div style="font-size:12px;">{{plan.MajorRemark}}</div></td>
                <td>{{plan.MajorPlanPersons}}</td>
                <td>{{plan.LearningCost}}</td>
                <td>{{plan.LearningYears}}</td>
                <td>{{PlanMajorData.BatchName}}</td>
            </tr>
        </table>
        <div v-if="APQParametersData.length==0||PlanMajorData.length==0" class="nodata">
            <i class="iconfont">&#xe67c;</i>
            <span>未查询到数据！</span>
        </div>
        <div class="nologinorvip" v-if="!UserData">
            <i class="el-icon-warning"></i>
            <span class="txt">您还未登录，请先登录！</span>
            <div class="btn" @click.prevent="$router.push('/login')">前往登录</div>
        </div>
        <div class="nologinorvip" v-if="UserData&&!HaveVip">
            <i class="el-icon-warning"></i>
            <span class="txt">你还不是会员，无法查看！</span>
            <div class="btn" @click.prevent="$router.push('/openvip')">开通会员<i class="iconfont">&#xe76c;</i></div>
        </div>
    </div>
</template>
<script>
import { getStore,setStore } from "@/utils/utils";
import API from "@/api/config";
export default {
    data() {
        return{
            provinceObj: {},
            APQParametersData:[],
            APQParameters:{
                ProvinceId: null,
                CollegeId: null,
                Year: null,
                SubjectType: null,
                BatchId: null,
                PlanCollegeId: null
            },
            LevelNumber:0,
            SubjectData:[],
            BatchData:[],
            PlanCollegeData:[],
            PlanMajorData:{
                CollegeName	:'',
                BatchName:'',
                PlanMajorList:[]
            },
            UserData:null,
            HaveVip:false,
            UserSubjectType:null
        }
    },
    mounted(){
        let user = getStore("LoginData");
        if(user){
            this.UserData = JSON.parse(user);
            if(this.UserData.RightsList.length>0){
                for(let i= 0;i<this.UserData.RightsList.length;i++){
                    if(new Date(this.UserData.RightsList[i].ExpireAt)>new Date()){
                        this.HaveVip = true;
                        break;
                    }
                }
                if(this.HaveVip){
                    let temp = getStore('OrgProvince')
                    if (temp) {
                        this.provinceObj = JSON.parse(temp);
                        this.APQParameters.ProvinceId = this.provinceObj.ProvinceId;
                        this.APQParameters.CollegeId = this.$route.query.id;
                        this.GetAdmissionPlanQueryParameters(this.provinceObj.ProvinceId);// 获取招生计划查询参数
                    }
                    // 获取个人信息
                    this.$store.dispatch('user/getStudentInfo').then(userInfo => {
                        if(userInfo.UserScore){
                            this.UserSubjectType = userInfo.UserScore.SubjectType;
                        }
                    });
                }
            }
        }
    },
    methods:{
        // 获取招生计划查询参数
        GetAdmissionPlanQueryParameters(provinceId){
            API.Query.GetAdmissionPlanQueryParameters({ProvinceId:provinceId,CollegeId:this.$route.query.id}).then(res => {
                if(res.Code==0){
                    this.APQParametersData=res.Data;
                    if(this.APQParametersData.length>0){
                        this.APQParameters.Year = this.APQParametersData[0].Value;
                        this.LevelNumber+=1;
                        this.GetLevelNumber(this.APQParametersData[0].SubList);// 递归参数的层级数
                        if(this.LevelNumber==3){
                            let obj1 = this.APQParametersData.find(f=>f.Value==this.APQParameters.Year);
                            if(obj1){
                                this.BatchData = obj1.SubList;
                                if(this.BatchData){
                                    this.APQParameters.BatchId = this.BatchData[0].Value;
                                    let obj2 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                                    if(obj2){
                                        this.PlanCollegeData = obj2.SubList;
                                        if(this.PlanCollegeData){
                                            this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                                        }
                                    }
                                }
                            }
                        }
                        if(this.LevelNumber==4){
                            let obj1 = this.APQParametersData.find(f=>f.Value==this.APQParameters.Year);
                            if(obj1){
                                this.SubjectData = obj1.SubList;
                                if(this.SubjectData){
                                    this.SubjectData.forEach(fe=>{
                                        fe.Value = parseInt(fe.Value);
                                    });
                                    this.APQParameters.SubjectType = this.SubjectData[0].Value;
                                    let obj2 = this.SubjectData.find(f=>f.Value==this.APQParameters.SubjectType);
                                    if(obj2){
                                        this.BatchData = obj2.SubList;
                                        if(this.BatchData){
                                            let t = this.BatchData.find(f => {
                                                if (f.SubList && f.SubList.length>0) {
                                                    return true
                                                }
                                            })
                                            if (!t) {
                                                this.PlanMajorData = []
                                                this.PlanCollegeData = []
                                                return
                                            }
                                            this.APQParameters.BatchId = t.Value;
                                            let obj3 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                                            if(obj3){
                                                this.PlanCollegeData = obj3.SubList;
                                                if(this.PlanCollegeData){
                                                    this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.GetAdmissionPlan();//获取招生计划
                    }
                }
            });
        },
        // 递归参数的层级数
        GetLevelNumber(obj){
            if(obj&&obj.length!=0){
                this.LevelNumber+=1;
                let tempIt = obj.find(it => {
                    if (it.SubList && it.SubList.length > 0) {
                        return true
                    }
                })
                if (!tempIt) {
                    tempIt = obj[0]
                }
                this.GetLevelNumber(tempIt.SubList);
            }
        },
        //获取招生计划
        GetAdmissionPlan(){
            API.Query.GetAdmissionPlan(this.APQParameters).then(plan => {
                this.PlanMajorData=[];
                if(plan.Code==0){
                    if(plan.Data){
                        this.PlanMajorData = plan.Data;
                    }
                }
            });
        },
        // 参数切换 年份
        APQParametersChangeYear(){
            if(this.LevelNumber==3){
                let obj1 = this.APQParametersData.find(f=>f.Value==this.APQParameters.Year);
                if(obj1){
                    this.BatchData = obj1.SubList;
                    if(this.BatchData){
                        this.APQParameters.BatchId = this.BatchData[0].Value;
                        let obj2 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                        if(obj2){
                            this.PlanCollegeData = obj2.SubList;
                            if(this.PlanCollegeData.length>0){
                                this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                            }
                        }
                    }
                    else{
                        this.APQParameters.BatchId = null;
                    }
                }
            }
            if(this.LevelNumber==4){
                let obj1 = this.APQParametersData.find(f=>f.Value==this.APQParameters.Year);
                if(obj1){
                    this.SubjectData = obj1.SubList;
                    if(this.SubjectData){
                        this.SubjectData.forEach(fe=>{
                            fe.Value = parseInt(fe.Value);
                        });
                        this.APQParameters.SubjectType = this.SubjectData[0].Value;
                        let obj2 = this.SubjectData.find(f=>f.Value==this.APQParameters.SubjectType);
                        if(obj2){
                            this.BatchData = obj2.SubList;
                            if(this.BatchData){
                                let t = this.BatchData.find(f => {
                                    if (f.SubList && f.SubList.length>0) {
                                        return true
                                    }
                                })
                                if (!t) {
                                    this.PlanMajorData = []
                                    this.PlanCollegeData = []
                                    return
                                }
                                this.APQParameters.BatchId = t.Value;
                                let obj3 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                                if(obj3){
                                    this.PlanCollegeData = obj3.SubList;
                                    if(this.PlanCollegeData.length>0){
                                        this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                                    }
                                }
                            }
                            else{
                                this.APQParameters.BatchId = null;
                            }
                        }
                    }
                    else{
                        this.APQParameters.SubjectType = null;
                    }
                }
            }
            this.GetAdmissionPlan();//获取招生计划
        },
        // 参数切换 科类
        APQParametersChangeSubject(){
            this.SubjectData.forEach(fe=>{
                fe.Value = parseInt(fe.Value);
            });
            let obj2 = this.SubjectData.find(f=>f.Value==this.APQParameters.SubjectType);
            if(obj2){
                this.BatchData = obj2.SubList;
                if(this.BatchData){
                    let t = this.BatchData.find(f => {
                        if (f.SubList && f.SubList.length>0) {
                            return true
                        }
                    })
                    if (!t) {
                        this.PlanMajorData = []
                        this.PlanCollegeData = []
                        return
                    }
                    this.APQParameters.BatchId = t.Value;
                    let obj3 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                    if(obj3){
                        this.PlanCollegeData = obj3.SubList;
                        if(this.PlanCollegeData.length>0){
                            this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                        }
                    }
                }
                else{
                    this.APQParameters.BatchId =null;
                }
            }
            this.GetAdmissionPlan();//获取招生计划
        },
        // 参数切换 批次
        APQParametersChangeBatch(){
            let obj2 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
            if(obj2){
                this.PlanCollegeData = obj2.SubList;
                if(this.PlanCollegeData.length>0){
                    this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                }
            }
            this.GetAdmissionPlan();//获取招生计划
        },
        // 参数切换 计划
        APQParametersChangePlan(){
            this.GetAdmissionPlan();//获取招生计划
        },
    }
}
</script>
<style scoped lang="less">
.tab_con{
    min-height: 500px;
    border: 1px solid #d1d1d1;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    .q_box{
        margin: 10px 20px 0;
        height: 60px;
        user-select: none;
        .zs_sf{
            color: #666;
            font-size: 14px;
            line-height: 60px;
            float: left;
            margin-right: 20px;
        }
        .plan_select{
            float: left;
            margin-left: 10px;
            margin-top: 13px;
            user-select: none;
        }
        .plan_select.year{
            width: 80px;
        }
        .plan_select.batch{
            width: 100px;
        }
        .plan_select.plan{
            width: 180px;
        }
    }
    .planhead{
        margin: 0px 20px 0px;
        border-radius: 5px;
        background-color: #fff;
        display: block;
        table-layout: fixed;
        border: 1px solid #ddd;
        border-bottom-left-radius:0;
        border-bottom-right-radius:0;
        overflow: hidden;
        background-color: rgba(var(--themecolor),0.1);
        tr{
            display:block;
            td{
                color: #555;
                font-size: 16px;
                padding: 15px 0px;
                text-align: center;
                border-left: 1px solid #ddd;
                display: inline-block;
            }
            td:nth-child(1){
                border-left: none;
                width: 170px;
            }
            td:nth-child(2),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7){
                width: 120px;
            }
            td:nth-child(3){
                width: calc(100% - 776px);
            }
        }
    }
    .plantable{
        table-layout: fixed !important;
        margin: 0px 20px 30px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-top: none;
        border-top-left-radius:0;
        border-top-right-radius:0;
        overflow: hidden;
        width: calc(100% - 40px);
        tr{
            td{
                border-left: 1px solid #ddd;
                color: #777;
                text-align: center;
                border-bottom: 1px solid #ddd;
                font-size: 14px;
                line-height: 22px;
                padding: 20px 10px;
            }
            td:first-child{
                border-left:none;
                width: 150px;
            }
            td:nth-child(2),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7){
                width: 100px;
            }
        }
        tr:last-child td{
            border-bottom:none;
        }
        tr:nth-child(2n+1){
            background-color: #fdfdfd;
        }
    }
    .nodata{
        color:#666;
        font-size:14px;
        text-align:center;
        i{
            display: block;
            margin: auto;
            padding-top: 50px;
            font-size: 60px;
            color:#acabab;
        }
    }
    .nologinorvip{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        background-color: rgba(255,255,255,1);
        width: 100%;
        height: 100%;
        text-align: center;
        i.el-icon-warning{
            color: rgba(var(--themecolor),0.8);
            font-size: 60px;
            margin-top: 100px;
        }
        .txt{
            color: #888;
            font-size: 28px;
            margin-top: 20px;
            display: block;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            background-color: rgba(var(--themecolor),0.8);
            border-radius: 5px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            margin: 50px auto 0;
            position: relative;
            font-weight: bold;
            letter-spacing: 2px;
            i{
                font-size: 34px;
                position: absolute;
                right: 15px;
                top: 2px;
                color: #ecae03;
                font-weight: normal;
            }
        }
        .btn:hover{
            background-color: rgba(var(--themecolor),1);
        }
    }
}
</style>