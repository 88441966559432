<template>
    <div class="tab_con">
        <div class="con_box">
            <div class="title"><span :class="(ScoreLineBox==1?'on':'no')" @click.prevent="ScoreLineBtn(1)">院校分数线</span><span :class="(ScoreLineBox==2?'on':'no')" @click.prevent="ScoreLineBtn(2)">专业分数线</span></div>
            <!-- 院校分数线 -->
            <!-- 综合 -->
            <div class="content" v-if="ScoreLineBox==1&&AdmissionCollegeDataShow0&&AdmissionCollegeDataShow0.length!=0">
                <div class="q_box">
                    <div class="zs_sf">招生省份：{{ provinceObj.ProvinceName }}</div>
                    <div class="plan_select year" v-if="false">
                        <el-select v-model="AdmissionCollegeSubjectValue0">
                            <el-option v-for="item0 in AdmissionCollegeSubjectData0" :key="item0.value" :label="item0.name" :value="item0.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <table class="collegelinehead" cellspacing="0">
                    <tr>
                        <td>院校名称</td>
                        <td>年份</td>
                        <td>最低分</td>
                        <td>最低位次</td>
                        <td>平均分</td>
                        <td>线差</td>
                        <td>录取人数</td>
                        <td>批次</td>
                    </tr>
                </table>
                <table class="collegelinetable" cellspacing="0">
                    <tr v-for="(item,index) in AdmissionCollegeDataShow0" :key="index">
                        <td>{{item.CollegeName}}<div style="font-size:12px;">{{item.CollegeRemark}}</div></td>
                        <td>{{item.Year}}</td>
                        <td>{{item.ScoreLow}}</td>
                        <td>{{item.RankLow}}</td>
                        <td></td>
                        <td></td>
                        <td>{{item.Persons}}</td>
                        <td>{{item.BatchName}}</td>
                    </tr>
                </table>
                <div v-if="!AdmissionCollegeDataShow0||AdmissionCollegeDataShow0.length==0" class="nodata">
                    <i class="iconfont">&#xe67c;</i>
                    <span>未查询到院校分数线数据！</span>
                </div>
            </div>
            <!-- 物理/历史 -->
            <div class="content" v-if="ScoreLineBox==1&&AdmissionCollegeDataShow12&&AdmissionCollegeDataShow12.length!=0">
                <div class="q_box">
                    <div class="zs_sf">招生省份：{{ provinceObj.ProvinceName }}</div>
                    <div class="plan_select year">
                        <el-select v-model="AdmissionCollegeSubjectValue12" @change="AdmissionCollegeSubjectChange(AdmissionCollegeSubjectValue12)" :disabled="UserData&&UserData.UserType!=1&&UserSubjectType!=null">
                            <el-option v-for="item1 in AdmissionCollegeSubjectData1" :key="item1.value" :label="item1.name" :value="item1.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <table class="collegelinehead" cellspacing="0">
                    <tr>
                        <td>院校名称</td>
                        <td>年份</td>
                        <td>最低分</td>
                        <td>最低位次</td>
                        <td>平均分</td>
                        <td>线差</td>
                        <td>录取人数</td>
                        <td>批次</td>
                    </tr>
                </table>
                <table class="collegelinetable" cellspacing="0">
                    <tr v-for="(item,index) in AdmissionCollegeDataShow12" :key="index">
                        <td>{{item.CollegeName}}<div style="font-size:12px;">{{item.CollegeRemark}}</div></td>
                        <td>{{item.Year}}</td>
                        <td>{{item.ScoreLow}}</td>
                        <td>{{item.RankLow}}</td>
                        <td></td>
                        <td></td>
                        <td>{{item.Persons}}</td>
                        <td>{{item.BatchName}}</td>
                    </tr>
                </table>
                <div v-if="!AdmissionCollegeDataShow12||AdmissionCollegeDataShow12.length==0" class="nodata">
                    <i class="iconfont">&#xe67c;</i>
                    <span>未查询到院校分数线数据！</span>
                </div>
            </div>
            <!-- 理科/文科 -->
            <div class="content" v-if="ScoreLineBox==1&&AdmissionCollegeDataShow34&&AdmissionCollegeDataShow34.length!=0">
                <div class="q_box">
                    <div class="zs_sf">招生省份：{{ provinceObj.ProvinceName }}</div>
                    <div class="plan_select year">
                        <el-select v-model="AdmissionCollegeSubjectValue34" @change="AdmissionCollegeSubjectChange(AdmissionCollegeSubjectValue34)">
                            <el-option v-for="item2 in AdmissionCollegeSubjectData2" :key="item2.value" :label="item2.name" :value="item2.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <table class="collegelinehead" cellspacing="0">
                    <tr>
                        <td>院校名称</td>
                        <td>年份</td>
                        <td>最低分</td>
                        <td>最低位次</td>
                        <td>平均分</td>
                        <td>线差</td>
                        <td>录取人数</td>
                        <td>批次</td>
                    </tr>
                </table>
                <table class="collegelinetable" cellspacing="0">
                    <tr v-for="(item,index) in AdmissionCollegeDataShow34" :key="index">
                        <td>{{item.CollegeName}}<div style="font-size:12px;">{{item.CollegeRemark}}</div></td>
                        <td>{{item.Year}}</td>
                        <td>{{item.ScoreLow}}</td>
                        <td>{{item.RankLow}}</td>
                        <td></td>
                        <td></td>
                        <td>{{item.Persons}}</td>
                        <td>{{item.BatchName}}</td>
                    </tr>
                </table>
                <div v-if="!AdmissionCollegeDataShow34||AdmissionCollegeDataShow34.length==0" class="nodata">
                    <i class="iconfont">&#xe67c;</i>
                    <span>未查询到院校分数线数据！</span>
                </div>
            </div>
            <!-- 专业分数线 -->
            <div class="content" v-if="ScoreLineBox==2">
                <div class="q_box">
                    <div class="zs_sf">招生省份：{{ provinceObj.ProvinceName }}</div>
                    <div class="plan_select year" v-if="AMDParametersData.length!=0">
                        <el-select v-model="AMDParameters.Year" @change="AMDParametersChangeYear()">
                            <el-option v-for="year in AMDParametersData" :key="year.Value" :label="year.Name" :value="year.Value"></el-option>
                        </el-select>
                    </div>
                    <div class="plan_select year" v-if="LevelNumber==4&&SubjectData.length!=0">
                        <el-select v-model="AMDParameters.SubjectType" @change="AMDParametersChangeSubject()" :disabled="UserData&&UserData.UserType!=1&&UserSubjectType!=null">
                            <el-option v-for="subject in SubjectData" :key="subject.Value" :label="subject.Name" :value="subject.Value"></el-option>
                        </el-select>
                    </div>
                    <div class="plan_select batch" v-if="BatchData.length!=0">
                        <el-select v-model="AMDParameters.BatchId" @change="AMDParametersChangeBatch()">
                            <el-option v-for="batch in BatchData" :key="batch.Value" :label="batch.Name" :value="batch.Value"></el-option>
                        </el-select>
                    </div>
                    <div class="plan_select plan" v-if="PlanCollegeData.length!=0">
                        <el-select v-model="AMDParameters.CollegeKey" @change="AMDParametersChangePlan()">
                            <el-option v-for="plancollege in PlanCollegeData" :key="plancollege.Value" :label="plancollege.Name" :value="plancollege.Value"></el-option>
                        </el-select>
                    </div>
                </div>
                <table class="majorlinehead" cellspacing="0" v-if="AdmissionMajorData.length!=0&&AMDParametersData.length!=0">
                    <tr>
                        <td>院校名称</td>
                        <td>专业名称</td>
                        <td>最低分</td>
                        <td>最低位次</td>
                        <td>平均分</td>
                        <td>最高分</td>
                        <td>录取人数</td>
                        <td>批次</td>
                    </tr>
                </table>
                <table class="majorlinetable" cellspacing="0" v-if="AdmissionMajorData.length!=0&&AMDParametersData.length!=0">
                    <tr v-for="(item,index) in AdmissionMajorData.MajorList" :key="index">
                        <td>{{AdmissionMajorData.CollegeName}}</td>
                        <td>{{item.MajorName}}<div style="font-size:12px;">{{item.MajorRemark}}</div></td>
                        <td>{{item.ScoreLow}}</td>
                        <td>{{item.RankLow}}</td>
                        <td>{{item.ScoreAvg}}</td>
                        <td>{{item.ScoreHigh}}</td>
                        <td>{{item.Persons}}</td>
                        <td>{{AdmissionMajorData.BatchName}}</td>
                    </tr>
                </table>
                <div v-if="AdmissionMajorData.length==0||AMDParametersData.length==0" class="nodata">
                    <i class="iconfont">&#xe67c;</i>
                    <span>未查询到专业分数线数据！</span>
                </div>
            </div>
        </div>
        <div class="nologinorvip" v-if="!UserData">
            <i class="el-icon-warning"></i>
            <span class="txt">您还未登录，请先登录！</span>
            <div class="btn" @click.prevent="$router.push('/login')">前往登录</div>
        </div>
        <div class="nologinorvip" v-if="UserData&&!HaveVip">
            <i class="el-icon-warning"></i>
            <span class="txt">你还不是会员，无法查看！</span>
            <div class="btn" @click.prevent="$router.push('/openvip')">开通会员<i class="iconfont">&#xe76c;</i></div>
        </div>
    </div>
</template>
<script>
import { getStore,setStore } from "@/utils/utils";
import API from "@/api/config";
export default {
    data() {
        return{
            provinceObj: {},
            ScoreLineBox:1,

            AdmissionCollegeSubjectData0:[],
            AdmissionCollegeSubjectData1:[],
            AdmissionCollegeSubjectData2:[],

            AdmissionCollegeData:[],
            AdmissionCollegeSubjectValue0:null,
            AdmissionCollegeSubjectValue12:null,
            AdmissionCollegeSubjectValue34:null,
            AdmissionCollegeDataShow0:[],
            AdmissionCollegeDataShow12:[],
            AdmissionCollegeDataShow34:[],

            AMDParametersData:[],
            AMDParameters:{
                ProvinceId: null,
                CollegeId: null,
                Year: null,
                SubjectType: null,
                BatchId: null,
                CollegeKey: null
            },
            LevelNumber:0,
            SubjectData:[],
            BatchData:[],
            PlanCollegeData:[],
            AdmissionMajorData:{
                CollegeName	:'',
                BatchName:'',
                PlanMajorList:[]
            },
            UserData:null,
            HaveVip:false,
            UserSubjectType:null
        }
    },
    mounted(){
        let user = getStore("LoginData");
        if(user){
            this.UserData = JSON.parse(user);
            if(this.UserData.RightsList.length>0){
                for(let i= 0;i<this.UserData.RightsList.length;i++){
                    if(new Date(this.UserData.RightsList[i].ExpireAt)>new Date()){
                        this.HaveVip = true;
                        break;
                    }
                }
                if(this.HaveVip){
                    let temp = getStore('OrgProvince')
                    if (temp) {
                        this.provinceObj = JSON.parse(temp);
                        this.AMDParameters.ProvinceId = this.provinceObj.ProvinceId;
                        this.AMDParameters.CollegeId = this.$route.query.id;
                        this.GetAdmissionCollege(this.provinceObj.ProvinceId);// 获取院校录取数据
                        this.GetAdmissionMajorQueryParameters(this.provinceObj.ProvinceId);// 获取专业录取数据查询参数
                    }
                }
            }
        }
    },
    methods:{
        // 院校分数线、专业分数线 切换
        ScoreLineBtn(val){
            this.ScoreLineBox=val;
        },
        // 获取院校录取数据
        GetAdmissionCollege(provinceId){
            API.Query.GetAdmissionCollege({ProvinceId:provinceId,CollegeId:this.$route.query.id}).then(res => {
                if(res.Code==0){
                    this.AdmissionCollegeData = res.Data;
                    
                    let temp = Object.keys(this.AdmissionCollegeData);
                    
                    if(temp.includes('0')){
                        this.AdmissionCollegeSubjectData0.push({name: '综合', value: '0'});
                        this.AdmissionCollegeSubjectValue0 = '0';
                        this.AdmissionCollegeDataShow0 = this.AdmissionCollegeData['0'];
                    }
					if (temp.includes('1')) {
                        this.AdmissionCollegeSubjectData1.push({name: '物理', value: '1'});
                        this.AdmissionCollegeSubjectData1.push({name: '历史', value: '2'});
						this.AdmissionCollegeSubjectValue12 = '1';
                        this.AdmissionCollegeDataShow12 = this.AdmissionCollegeData['1'];
					}
					if (temp.includes('3')) {
                        this.AdmissionCollegeSubjectData2.push({name: '理科', value: '3'});
                        this.AdmissionCollegeSubjectData2.push({name: '文科', value: '4'});
                        this.AdmissionCollegeSubjectValue34 = '3';
                        this.AdmissionCollegeDataShow34 = this.AdmissionCollegeData['3'];
					}
                    // 获取个人信息
                    this.$store.dispatch('user/getStudentInfo').then(userInfo => {
                        if(userInfo.UserScore){
                            this.UserSubjectType = userInfo.UserScore.SubjectType;
                        }
                    });
                }
            });
        },
        // 科类/选科切换
        AdmissionCollegeSubjectChange(val){
            if(val=='1'||val=='2'){
                this.AdmissionCollegeSubjectValue12 = val;
                this.AdmissionCollegeDataShow12 = this.AdmissionCollegeData[this.AdmissionCollegeSubjectValue12];
            }
            else{
                this.AdmissionCollegeSubjectValue34 = val;
                this.AdmissionCollegeDataShow34 = this.AdmissionCollegeData[this.AdmissionCollegeSubjectValue34];
            }
        },
        // 获取专业录取数据查询参数
        GetAdmissionMajorQueryParameters(provinceId){
            API.Query.GetAdmissionMajorQueryParameters({ProvinceId:provinceId,CollegeId:this.$route.query.id}).then(res => {
                if(res.Code==0){
                    this.AMDParametersData=res.Data;
                    if(this.AMDParametersData.length>0){
                        this.AMDParameters.Year = this.AMDParametersData[0].Value;
                        this.LevelNumber+=1;
                        this.GetLevelNumber(this.AMDParametersData[0].SubList);// 递归参数的层级数
                        if(this.LevelNumber==3){
                            let obj1 = this.AMDParametersData.find(f=>f.Value==this.AMDParameters.Year);
                            if(obj1){
                                this.BatchData = obj1.SubList;
                                if(this.BatchData){

                                    this.AMDParameters.BatchId = this.BatchData[0].Value;

                                    let obj2 = this.BatchData.find(f=>f.Value==this.AMDParameters.BatchId);
                                    if(obj2){
                                        this.PlanCollegeData = obj2.SubList;
                                        if(this.PlanCollegeData){
                                            this.AMDParameters.CollegeKey = this.PlanCollegeData[0].Value;
                                        }
                                    }
                                }
                            }
                        }
                        if(this.LevelNumber==4){
                            let obj1 = this.AMDParametersData.find(f=>f.Value==this.AMDParameters.Year);
                            if(obj1){
                                this.SubjectData = obj1.SubList;
                                if(this.SubjectData){
                                    this.SubjectData.forEach(fe=>{
                                        fe.Value = parseInt(fe.Value);
                                    });
                                    this.AMDParameters.SubjectType = this.SubjectData[0].Value;
                                    let obj2 = this.SubjectData.find(f=>f.Value==this.AMDParameters.SubjectType);
                                    if(obj2){
                                        this.BatchData = obj2.SubList;
                                        if(this.BatchData){
                                            let t = this.BatchData.find(f => {
                                                if (f.SubList && f.SubList.length>0) {
                                                    return true
                                                }
                                            })
                                            if (!t) {
                                                this.AdmissionMajorData = []
                                                this.PlanCollegeData = []
                                                return
                                            }
                                            this.AMDParameters.BatchId = t.Value;
                                            let obj3 = this.BatchData.find(f=>f.Value==this.AMDParameters.BatchId);
                                            if(obj3){
                                                this.PlanCollegeData = obj3.SubList;
                                                if(this.PlanCollegeData){
                                                    this.AMDParameters.CollegeKey = this.PlanCollegeData[0].Value;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.GetAdmissionMajor();//获取专业录取数据
                    }
                }
            });
        },
        // 递归参数的层级数
        GetLevelNumber(obj){
            if(obj&&obj.length!=0){
                this.LevelNumber+=1;
                let tempIt = obj.find(it => {
                    if (it.SubList && it.SubList.length > 0) {
                        return true
                    }
                })
                if (!tempIt) {
                    tempIt = obj[0]
                }
                this.GetLevelNumber(tempIt.SubList);
            }
        },
        //获取专业录取数据
        GetAdmissionMajor(){
            API.Query.GetAdmissionMajor(this.AMDParameters).then(plan => {
                this.AdmissionMajorData=[];
                if(plan.Code==0){
                    if(plan.Data){
                        this.AdmissionMajorData = plan.Data;
                    }
                }
            });
        },
        // 参数切换 年份
        AMDParametersChangeYear(){
            if(this.LevelNumber==3){
                let obj1 = this.AMDParametersData.find(f=>f.Value==this.AMDParameters.Year);
                if(obj1){
                    this.BatchData = obj1.SubList;
                    if(this.BatchData){
                        this.AMDParameters.BatchId = this.BatchData[0].Value;
                        let obj2 = this.BatchData.find(f=>f.Value==this.AMDParameters.BatchId);
                        if(obj2){
                            this.PlanCollegeData = obj2.SubList;
                            if(this.PlanCollegeData){
                                this.AMDParameters.CollegeKey = this.PlanCollegeData[0].Value;
                            }
                            else{
                                this.AMDParameters.CollegeKey = null;
                            }
                        }
                    }
                    else{
                        this.AMDParameters.BatchId = null;
                    }
                }
            }
            if(this.LevelNumber==4){
                let obj1 = this.AMDParametersData.find(f=>f.Value==this.AMDParameters.Year);
                if(obj1){
                    this.SubjectData = obj1.SubList;
                    if(this.SubjectData){
                        this.SubjectData.forEach(fe=>{
                            fe.Value = parseInt(fe.Value);
                        });
                        this.AMDParameters.SubjectType = this.SubjectData[0].Value;
                        let obj2 = this.SubjectData.find(f=>f.Value==this.AMDParameters.SubjectType);
                        if(obj2){
                            this.BatchData = obj2.SubList;
                            if(this.BatchData){
                                let t = this.BatchData.find(f => {
                                    if (f.SubList && f.SubList.length>0) {
                                        return true
                                    }
                                })
                                if (!t) {
                                    this.AdmissionMajorData = []
                                    this.PlanCollegeData = []
                                    return
                                }
                                this.AMDParameters.BatchId = t.Value;
                                let obj3 = this.BatchData.find(f=>f.Value==this.AMDParameters.BatchId);
                                if(obj3){
                                    this.PlanCollegeData = obj3.SubList;
                                    if(this.PlanCollegeData){
                                        this.AMDParameters.CollegeKey = this.PlanCollegeData[0].Value;
                                    }
                                    else{
                                        this.AMDParameters.CollegeKey = null;
                                    }
                                }
                            }
                            else{
                                this.AMDParameters.BatchId = null;
                            }
                        }
                    }
                    else{
                        this.AMDParameters.SubjectType = null;
                    }
                }
            }
            this.GetAdmissionMajor();//获取专业录取数据
        },
        // 参数切换 科类
        AMDParametersChangeSubject(){
            this.SubjectData.forEach(fe=>{
                fe.Value = parseInt(fe.Value);
            });
            let obj2 = this.SubjectData.find(f=>f.Value==this.AMDParameters.SubjectType);
            if(obj2){
                this.BatchData = obj2.SubList;
                if(this.BatchData){
                    let t = this.BatchData.find(f => {
                        if (f.SubList && f.SubList.length>0) {
                            return true
                        }
                    })
                    if (!t) {

                        this.AdmissionMajorData = []
                        this.PlanCollegeData = []
                        return
                    }
                    this.AMDParameters.BatchId = t.Value;
                    let obj3 = this.BatchData.find(f=>f.Value==this.AMDParameters.BatchId);
                    if(obj3){
                        this.PlanCollegeData = obj3.SubList;
                        if(this.PlanCollegeData.length>0){
                            this.AMDParameters.CollegeKey = this.PlanCollegeData[0].Value;
                        }
                    }
                }
                else{
                    this.AMDParameters.BatchId =null;
                }
            }
            this.GetAdmissionMajor();//获取专业录取数据
        },
        // 参数切换 批次
        AMDParametersChangeBatch(){
            let obj2 = this.BatchData.find(f=>f.Value==this.AMDParameters.BatchId);
            if(obj2){
                this.PlanCollegeData = obj2.SubList;
                if(this.PlanCollegeData.length>0){
                    this.AMDParameters.CollegeKey = this.PlanCollegeData[0].Value;
                }
            }
            this.GetAdmissionMajor();//获取专业录取数据
        },
        // 参数切换 计划
        AMDParametersChangePlan(){
            this.GetAdmissionMajor();//获取专业录取数据
        },
    }
}
</script>
<style scoped lang="less">
.tab_con{
    min-height: 500px;
    border: 1px solid #d1d1d1;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    .con_box{
        margin: 0 20px;
        padding-top: 20px;
        .title{
            height: 30px;
            background-color: #fff;
            position: relative;
            z-index: 1;
            span{
                color: #666;
                font-size: 14px;
                height: 29px;
                display: inline-block;
                line-height: 29px;
                padding: 0px 20px;
                border-top: 1px solid #ddd;
                border-right: 1px solid #ddd;
                background-color: #fff;
                border-bottom: 1px solid #ddd;
            }
            span.no:hover{
                cursor: pointer;
                color: rgba(var(--themecolor),0.9);
                background-color: rgba(var(--themecolor),0.1);
            }
            span.on{
                color: #444;
                border-bottom: 1px solid #fff;
            }
            span:first-child{
                border-top-left-radius: 5px;
                border-left: 1px solid #ddd;
            }
            span:last-child{
                border-top-right-radius: 5px;
            }
        }
        .content{
            border: 1px solid #ddd;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;
        }
    }
    .q_box{
        margin: 10px 20px 0;
        height: 60px;
        user-select: none;
        .zs_sf{
            color: #666;
            font-size: 14px;
            line-height: 60px;
            float: left;
            margin-right: 20px;
        }
        .plan_select{
            float: left;
            margin-left: 10px;
            margin-top: 13px;
            user-select: none;
        }
        .plan_select.year{
            width: 80px;
        }
        .plan_select.batch{
            width: 100px;
        }
        .plan_select.plan{
            width: 180px;
        }
    }
    .collegelinehead{
        margin: 0px 20px 0px;
        border-radius: 5px;
        background-color: #fff;
        display: block;
        table-layout: fixed;
        border: 1px solid #ddd;
        border-bottom-left-radius:0;
        border-bottom-right-radius:0;
        overflow: hidden;
        background-color: rgba(var(--themecolor),0.1);
        tr{
            display:block;
            td{
                color: #555;
                font-size: 16px;
                padding: 15px 0px;
                text-align: center;
                border-left: 1px solid #ddd;
                display: inline-block;
            }
            td:nth-child(1){
                width: 170px;
                border-left: none;
            }
            td:nth-child(2),td:nth-child(3),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8){
                width: calc((100% - 177px) / 7);
            }
        }
    }
    .collegelinetable{
        table-layout: fixed !important;
        margin: 0px 20px 30px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-top: none;
        border-top-left-radius:0;
        border-top-right-radius:0;
        overflow: hidden;
        width: calc(100% - 40px);
        tr{
            td{
                border-left: 1px solid #ddd;
                color: #777;
                text-align: center;
                border-bottom: 1px solid #ddd;
                font-size: 14px;
                line-height: 22px;
                padding: 20px 10px;
            }
            td:nth-child(1){
                border-left:none;
                width: 150px;
            }
            td:nth-child(2),td:nth-child(3),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8){
                width: calc(100% / 7);
            }
        }
        tr:last-child td{
            border-bottom:none;
        }
        tr:nth-child(2n+1){
            background-color: #fdfdfd;
        }
    }
    .majorlinehead{
        margin: 0px 20px 0px;
        border-radius: 5px;
        background-color: #fff;
        display: block;
        table-layout: fixed;
        border: 1px solid #ddd;
        border-bottom-left-radius:0;
        border-bottom-right-radius:0;
        overflow: hidden;
        background-color: rgba(var(--themecolor),0.1);
        tr{
            display:block;
            td{
                color: #555;
                font-size: 16px;
                padding: 15px 0px;
                text-align: center;
                border-left: 1px solid #ddd;
                display: inline-block;
            }
            td:nth-child(1){
                border-left: none;
            }
            td:nth-child(2){
                width: 170px;
            }
            td:nth-child(1),td:nth-child(3),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8){
                width: calc((100% - 177px) / 7);
            }
        }
    }
    .majorlinetable{
        table-layout: fixed !important;
        margin: 0px 20px 30px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-top: none;
        border-top-left-radius:0;
        border-top-right-radius:0;
        overflow: hidden;
        width: calc(100% - 40px);
        tr{
            td{
                border-left: 1px solid #ddd;
                color: #777;
                text-align: center;
                border-bottom: 1px solid #ddd;
                font-size: 14px;
                line-height: 22px;
                padding: 20px 10px;
            }
            td:first-child{
                border-left:none;
            }
            td:nth-child(2){
                width: 150px;
            }
            td:nth-child(1),td:nth-child(3),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8){
                width: calc(100% / 7);
            }
        }
        tr:last-child td{
            border-bottom:none;
        }
        tr:nth-child(2n+1){
            background-color: #fdfdfd;
        }
    }
    .nodata{
        color:#666;
        font-size:14px;
        text-align:center;
        padding-bottom: 30px;
        i{
            display: block;
            margin: auto;
            font-size: 60px;
            color:#acabab;
        }
    }
    .nologinorvip{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        background-color: rgba(255,255,255,1);
        width: 100%;
        height: 100%;
        text-align: center;
        i.el-icon-warning{
            color: rgba(var(--themecolor),0.8);
            font-size: 60px;
            margin-top: 100px;
        }
        .txt{
            color: #888;
            font-size: 28px;
            margin-top: 20px;
            display: block;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            background-color: rgba(var(--themecolor),0.8);
            border-radius: 5px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            margin: 50px auto 0;
            position: relative;
            font-weight: bold;
            letter-spacing: 2px;
            i{
                font-size: 34px;
                position: absolute;
                right: 15px;
                top: 2px;
                color: #ecae03;
                font-weight: normal;
            }
        }
        .btn:hover{
            background-color: rgba(var(--themecolor),1);
        }
    }
}
</style>