<template>
	<div class="private-info">
    <div class="info-row">
      <div class="info-item">
        <custom-input input-name="姓名" v-model="query.Name"></custom-input>
      </div>
      <div class="info-item">
        <custom-select
          v-model="query.Gender"
          picker-name="性别"
          :picker-list="localJson.genderList"
        ></custom-select>
      </div>
      <div class="info-item">
        <custom-date-picker
          v-model="query.Birthday"
          picker-name="出生年月"
        ></custom-date-picker>
      </div>
      <div class="info-item">
        <custom-select
          v-model="query.Personality"
          picker-name="性格倾向"
          :picker-list="localJson.personalityTypeList"
        ></custom-select>
      </div>
      <div class="info-item">
        <custom-select
          v-model="query.BloodType"
          picker-name="血型"
          :picker-list="localJson.bloodList"
        ></custom-select>
      </div>
    </div>
    <div class="info-row custom-row">
      <div class="info-item">
        <custom-input input-name="联系电话" v-model="query.Mobile"></custom-input>
      </div>
      <div class="info-item">
        <custom-input input-name="E-mail" v-model="query.Email" input-width="418px"></custom-input>
      </div>
    </div>
    <div class="info-row custom-row all-width">
      <div class="info-item">
        <custom-input input-name="家庭住址" v-model="query.HomeAddress" input-width="100%"></custom-input>
      </div>
    </div>
    <div class="info-row custom-row">
      <div class="info-item">
        <custom-input input-name="所在学校" v-model="query.SchoolName" input-width="636px"></custom-input>
      </div>
      <div class="info-item">
        <custom-input input-name="所在班级" v-model="query.ClassName" input-width="418px"></custom-input>
      </div>
    </div>
    <div class="bottom-item">
      <custom-table
        table-name="学习成绩记录"
        add-button-text="+ 添加"
        empty-tips="暂无成绩记录"
        :table-main="query.HighSchoolScoreList"
        :table-option="localJson.scoreOptionList"
        :table-type="localJson.scoreContentType"
        @change="handleScore"
      ></custom-table>
    </div>
    <div class="bottom-item">
      <custom-table
        table-name="参加比赛及获奖情况"
        add-button-text="添加"
        :table-main="query.CompetitionList"
        :table-option="localJson.competitionOptionList"
        :table-type="localJson.competitionContentType"
        @change="handleCompetition"
      ></custom-table>
    </div>
    <div class="bottom-item">
      <custom-table
        table-name="大学愿望"
        add-button-text="添加"
        :table-main="query.CollegeWishList"
        :table-option="localJson.collegeOption"
        :table-type="localJson.collegeContentType"
        @change="handleCollegeWish"
      ></custom-table>
    </div>
    <div class="bottom-item">
      <custom-table
        table-name="职业愿望"
        add-button-text="添加"
        :table-main="query.CareerWishList"
        :table-option="localJson.professionOption"
        :table-type="localJson.professionContentType"
        @change="handleProfessionWish"
      ></custom-table>
    </div>
<!--
		<div class="bottom-item">
			<custom-table
			table-name="学习成绩记录"
			add-button-text="添加成绩记录"
			:table-main="query.HighSchoolScoreList"
			:table-option="localJson.scoreOptionList"
			:table-type="localJson.scoreContentType"
			@change="handleScore"
			></custom-table>
		</div>
		<div class="bottom-item">
			<custom-table
			table-name="参加比赛及获奖情况"
			add-button-text="添加获奖项目"
			:table-main="query.CompetitionList"
			:table-option="localJson.competitionOptionList"
			:table-type="localJson.competitionContentType"
			@change="handleCompetition"
			></custom-table>
		</div>
		<div class="bottom-item">
			<custom-table
			table-name="大学愿望"
			add-button-text="添加大学愿望"
			:table-main="query.CollegeWishList"
			:table-option="localJson.collegeOption"
			:table-type="localJson.collegeContentType"
			@change="handleCollegeWish"
			></custom-table>
		</div>
		<div class="bottom-item">
			<custom-table
			table-name="职业愿望"
			add-button-text="添加职业愿望"
			:table-main="query.CareerWishList"
			:table-option="localJson.professionOption"
			:table-type="localJson.professionContentType"
			@change="handleProfessionWish"
			></custom-table>
		</div>-->

	</div>
</template>

<script>
	import local_json from '../my-components/private_info_json.js'
	import CustomInput from '../my-components/CustomInput.vue'
	import CustomSelect from '../my-components/CustomSelect.vue'
	import CustomDatePicker from '../my-components/CustomDatePicker.vue'
	import CustomTable from '../my-components/CustomTable.vue'
	export default {
		components: {
			CustomInput,
      CustomSelect,
      CustomDatePicker,
			CustomTable
		},
		data(){
			return {
				localJson: local_json,
				query: {
					// input parameters
					Name:'',
					PoliticalStatus:'',
					Mobile:'',
					Email:'',
					HomeAddress:'',
					SchoolName:'',
					ClassName:'',
					// select parameters
					Gender:'',
					IsMarried:'',
					Birthday:'',
					Personality:'',
					BloodType:'',
					// array parameters
					HighSchoolScoreList:[],
					CompetitionList:[],
					CollegeWishList:[],
					CareerWishList:[]
				}
			}
		},
		props:{
			mainData: {
				type: Object,
				default: () => ({})
			}
		},
		watch:{
			mainData(val){
				this.renderDefaultData(val)
			}
		},
		mounted() {},
		methods:{
			// 提交參數
			submitQuery(){
				this.$emit('getPrivateInfo', this.query)
			},

			// 性别选择
			selectGender(parameter){
				console.log(parameter)
				this.query.Gender = parameter.value
			},

			// 婚姻情况
			selectMarry(parameter){
				console.log(parameter)
				this.query.IsMarried = parameter.value
			},

			// 生日选择
			selectBirthday(parameter){
				console.log(parameter)
				this.query.Birthday = parameter
			},

			// 性格倾向
			selectCharacter(parameter){
				console.log(parameter)
				this.query.Personality = parameter.value
			},

			// 血型
			selectBlood(parameter){
				console.log(parameter)
				this.query.BloodType = parameter.value
			},

			// 成績
			handleScore(parameter){
				console.log(parameter,'score')
				this.query.HighSchoolScoreList = parameter
			},

			// 競賽獲獎
			handleCompetition(parameter){
				console.log(parameter, 'competition')
				this.query.CompetitionList = parameter
			},

			// 大學願望
			handleCollegeWish(parameter){
				console.log(parameter, 'college wish')
				this.query.CollegeWishList = parameter
			},

			// 職業願望
			handleProfessionWish(parameter){
				console.log(parameter, 'profession wish')
				this.query.CareerWishList = parameter
			},

			// 默认参数处理
			renderDefaultData(main){
				if(main.Birthday)main.Birthday = main.Birthday.substr(0, 10)
				if(main.CompetitionList&&main.CompetitionList.length!==0){
					main.CompetitionList.forEach(item => {
						item.ContestDate = item.ContestDateStr
					})
				}
				this.query = this.mainData
			}
		}
	}
</script>

<style lang="less" scoped>
.private-info{
	.info-row{
		margin-bottom: 24px;
		display: flex;
		justify-content: space-between;
		.info-item{
			margin-right: 18px;
		}
	}
	.custom-row{
		justify-content: normal;
	}
	.all-width{
	display: block;
		.info-item{
			margin-right: 0;
		}
	}
	.bottom-item{
		margin-bottom: 40px;
	}
}
</style>
