<template>
  <div class="box-container">
    <div class="new-exam">
      <img class="index-background-image" src="@/assets/test/new-exam-choose/new-exam-choose-picture1.png" />
      <div class="tips">
        <div class="text">
          新高考选科可以说是高中的第一次重大选择，是今后人生道路的分水岭，选择什么样的科目将影响大学专业选择，专业选择又与未来的职业有着紧密的联系，人生职业的方向从而关乎到人生。
          新高考选科指导系统，以生涯规划测评数据为基础，根据学生的兴趣、爱好、性格及潜能分析，对学生实施选科指导，具有科学、具体、针对性等特性，旨在解决学子在面对新高考选科中遇到的各种困扰。
        </div>
      </div>
      <div class="bottom">
        <div class="title">
          <img class="bottom-title" src="@/assets/test/new-exam-choose/new-exam-choose-picture2.png" />
        </div>
        <div class="middle">
          <div class="item" v-for="(item, index) in mainData" :key="index">
            <div class="item-content">
              <div class="name">{{ item.name }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
            <div class="bg-color"></div>
          </div>
        </div>
        <div class="bottom-text">
          近些年来，随着高等教育普及化的到来，高考考生就读大学的机会不断扩大。
          虽然就读大学的压力在减小，但另一个严肃的问题却一直困扰着家长和考生，就是如何选择一个既符合考生自身性格、特长又有良好发展空间的大学专业。
          为了能解决这个在填报高考志愿时较为复杂的问题，专业测试能帮助考生更加全面、更加清楚地了解自己。测试平台的专家团队凭借多年高考志愿指导和考生心理辅导的实践经验，在充分研究国际知名测试的基础上，结合中国学生的特点，以心理测量学为依据，倾力研发了这套旨在帮助学生全面认识自己、科学选择专业、准确定位职业发展方向的专业测评系统。
          本报告就是根据考生的真实作答，判断和分析每一个考生自己的性格和特长，结合国家教育部最新公布的国内普通高等院校专业目录，为考生定制的具有科学指导意义的个性化评估报告。
          希望测评报告能帮助考生和家长正确而科学的填报高考志愿，为考生的未来加一把力。
        </div>
      </div>
    </div>
    <div class="button-box">
      <div class="button" @click="startTest">开始测评</div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import { getStore } from '@/utils/utils'
export default {
  name: "new-exam-choose-index",
  data() {
    return {
      mainData: [
        {
          name: '选考',
          text: '与你的升学关联'
        },
        {
          name: '学考',
          text: '关乎你能否读理想大学'
        },
        {
          name: '选考科目',
          text: '关乎你如何选择大学专业'
        },
      ]
    }
  },
  methods: {
    startTest() {
      this.$store.dispatch('user/judgeLoginStatus').then(flag => {
        if (flag) {
          this.$router.push({
            // path: '/exam-test-tips',
            path: '/exam-test/choose-province',
            query: {
              testType: 4
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '请先登录！',
            showClose: true,
            center: true
          })
          this.$router.push('/login')
        }
      })

      // let temp = getStore('LoginData')
      // if (temp) {

      //   this.$router.push({
      //     path: '/exam-test-tips',
      //     query: {
      //       testType: 4
      //     }
      //   })
      // } else {
      //   this.$message({
      //     type: 'error',
      //     message: '请先登录！',
      //     showClose: true,
      //     center: true
      //   })
      //   this.$router.push('/login')
      // }
    }
  }
}
</script>

<style scoped lang="less">
.box-container {
  background-color: rgba(var(--themecolor),0.75);
}

.new-exam {
  max-width: 750px;
  margin: 0 auto;
  font-family: PingFang SC;
  padding: 1px;
  min-height: 140vh;
  background-color: rgba(var(--themecolor),0);
  position: relative;
  padding-bottom: 50px;
  line-height: 24px;
  color: #2A2B4E;
  font-size: 14px;

  .index-background-image {
    height: 440px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .tips {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    border: 5px solid rgba(var(--themecolor),0.5);
    border-radius: 20px;
    position: absolute;
    top: 460px;
    left: 0;
  }

  .bottom {
    margin-top: 720px;

    .title {
      text-align: center;

      .bottom-title {
        width: 200px;
      }
    }

    .middle {
      .item {
        position: relative;

        .bg-color {
          padding: 20px;
          margin: 20px;
          background-color: rgba(var(--themecolor),7.5);
          border-radius: 20px;

        }

        &-content {
          display: flex;
          align-items: center;
          position: absolute;
          left: 20px;
          top: 0;

          .name {
            height: 40px;
            padding: 10px 20px;
            border-radius: 20px;
            background-color: #FFF7B1;
            color: rgba(var(--themecolor),1);
            font-weight: bold;
            box-sizing: border-box;

          }

          .text {
            margin-left: 20px;
            border-radius: 20px;
            color: #ffffff;
          }
        }

      }
    }

    &-text {
      line-height: 1.5;
      padding: 20px;
      margin: 20px;
      background-color: rgba(var(--themecolor),0.75);
      color: #ffffff;
      font-size: .92rem;
      border-radius: 20px;
    }
  }
}
.button-box {
  text-align: center;
  z-index: 3;

  .button {
    width: 192px;
    margin: auto;
    border-radius: 25px;
    background-color: #fff;
    font-weight: bold;
    color: #2f2f2f;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, .3);
    cursor: pointer;
    height: 49px;
    line-height: 49px;
    text-align: center;
    font-size: 18px;
    border: 2px solid rgba(var(--themecolor),1);
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 1024px) {
  .new-exam {
    width: 100%;
    margin: auto;
  }
}
</style>
