<template>
  <div class="job-future">
    <div class="con_box" style="margin-top:30px">
        <div class="title"><span class="on">就业方向</span></div>
        <div class="content">
            <div class="info">{{specialtyInfo.Job}}</div>
        </div>
    </div>
    <div class="con_box" style="margin-top:30px">
        <div class="title"><span class="on">近10年平均薪资</span></div>
        <div class="content">
          <div class="echarts-box" v-if="specialtyInfo.SalaryList.length !== 0">
            <div class="salary-title">{{ specialtyInfo.SalaryList[0].Name+'年 平均薪资 ￥'+ specialtyInfo.SalaryList[0].Value+'元'}}</div>
            <div id="echartsLine"></div>
          </div>
          <div class="salary-box" v-if="specialtyInfo.SalaryList.length !== 0">
            <div class="salary-item" v-for="(item, index) in specialtyInfo.SalaryList" :key="index">
              <div class="year">{{ item.Name }}</div>
              <div class="money">￥{{ item.Value }}元</div>
            </div>
          </div>
          <div class="clear"></div>
          <div class="clear" style="height:20px"></div>
        </div>
    </div>
    <div class="box-distribute">
      <div class="box-item">
        <div class="collapse-title">主要职业分布</div>
        <div id="echartsPieProfession"></div>
      </div>
      <div class="box-item">
        <div class="collapse-title">主要行业分布</div>
        <div id="echartsPieIndustry"></div>
      </div>
      <div class="box-item">
        <div class="collapse-title">主要就业地区分布</div>
        <div id="echartsPieArea"></div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="box-distribute">
      <div class="box-item">
        <div class="content-item" v-for="(item, index) in specialtyInfo.DistributeProfessionList" :key="index">
          <div class="left">{{ item.Name }}</div>
          <div class="right">{{ item.Value + '%' }}</div>
        </div>
      </div>
      <div class="box-item">
        <div class="content-item" v-for="(item, index) in specialtyInfo.DistributeIndustryList" :key="index">
          <div class="left">{{ item.Name }}</div>
          <div class="right">{{ item.Value + '%' }}</div>
        </div>
      </div>
      <div class="box-item">
        <div class="content-item" v-for="(item, index) in specialtyInfo.DistributeAreaList" :key="index">
          <div class="left">{{ item.Name }}</div>
          <div class="right">{{ item.Value + '%' }}</div>
        </div>
      </div>
      <div class="clear"></div>
      <div class="clear" style="height:20px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobFuture",
  data () {
    return {
      // 为了计算百分比时使用的总数
      professionTotal: 0,
      industryTotal: 0,
      areaTotal: 0,
    }
  },
  props: {
    specialtyInfo: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    // 初始化数据
    initialize () {
      this.handleEchartsLine()
      this.distributeProfession()
      this.distributeIndustry()
      this.distributeArea()
    },
    // 薪资折线图
    handleEchartsLine () {
      if (this.specialtyInfo.SalaryList.length === 0) return
      let xArray = this.specialtyInfo.SalaryList.map(item => {
        return item.Name
      })
      let yArray = this.specialtyInfo.SalaryList.map(item => {
        return item.Value
      })
      xArray = xArray.reverse()
      yArray = yArray.reverse()
      let OEcharts = this.$echarts.init(document.getElementById('echartsLine'))
      let options = {
        title: {
          text: '平均薪资',
          textStyle: {
            color: '#333',
            fontWeight: 'normal',
            fontSize: 16
          }
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.4)',
          textStyle: {
            color: '#ffffff'
          }
        },
        xAxis: {
          type: 'category',
          data: xArray,
        },
        yAxis: {
          type: 'value'
        },
        series:[{
          type: 'line',
          data: yArray
        }]
      }
      OEcharts.setOption(options)
    },
    // 职业分布图（饼图）
    distributeProfession () {
      this.sortList(this.specialtyInfo.DistributeProfessionList, 'Proportion') // 数组排序

      let itemArray = this.specialtyInfo.DistributeProfessionList.map(item => {
        this.professionTotal += item.Value // 计算百分比的总数
        return { // echart配置使用
          value: item.Value,
          name: item.Name
        }
      })
      this.handlePieConfig('echartsPieProfession', itemArray)
    },
    // 行业分布图
    distributeIndustry () {
      this.sortList(this.specialtyInfo.DistributeIndustryList, 'Proportion') // 排序

      let itemArray = this.specialtyInfo.DistributeIndustryList.map(item => {
        this.industryTotal += item.Value
        return {
          value: item.Value,
          name: item.Name
        }
      })
      this.handlePieConfig('echartsPieIndustry', itemArray)
    },
    // 地区分布图
    distributeArea () {
      this.sortList(this.specialtyInfo.DistributeAreaList, 'Proportion') // 排序

      let itemArray = this.specialtyInfo.DistributeAreaList.map(item => {
        this.areaTotal += item.Value
        return {
          value: item.Value,
          name: item.Name
        }
      })
      this.handlePieConfig('echartsPieArea', itemArray)
    },
    // 数组公用排序处理
    sortList (array, property) {
      // array 需要排序的数组，property 元素中排序依据的属性
      for(let i = 0; i < array.length; i++) {
        for(let j = 0; j < array.length - i - 1; j++) {
          if(array[j][property] < array[j + 1][property]) {
            let temp = array[j]
            array[j] = array[j + 1]
            array[j + 1] = temp
          }
        }
      }
    },
    // 饼图配置公共部分
    handlePieConfig (domID, data) {
      let OEcharts = this.$echarts.init(document.getElementById(domID))
      let options = {
        legend: {
          type: 'plain',
          // orient: 'horizontal',
          icon: 'circle',
          left: 0
        },
        tooltip: {
          show: true,
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,.4)',
          textStyle: {
            color: '#ffffff'
          },
          formatter(parameter, ticket, callback){
            return parameter.data.name + '<br />' + '占比：' + parameter.data.value +'%'
          }
        },
        series: {
          type: 'pie',
          radius: ['15%', '35%'],
          selectedMode: 'single',
          selectedOffset: 15,
          label: {
            position: 'outer',
            alignTo: 'edgeLabel',
            bleedMargin: 0
          },
          data: data
        }
      }
      OEcharts.setOption(options)
    },
    // 百分比转换
    toPercent(num, total) {
      let temp = (num / total) * 100
      return Math.round(temp) + '%'
    }
  }
}
</script>

<style scoped lang="less">
.con_box{
  margin: 0 20px;
  .title{
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #e7e7e7;
    border-bottom-right-radius: 5px;
    span{
        color: #666;
        font-size: 14px;
        height: 29px;
        display: inline-block;
        line-height: 29px;
        padding: 0px 20px;
        border-top: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        background-color: #fff;
        border-bottom: 1px solid #e7e7e7;
    }
    span.no:hover{
        cursor: pointer;
        color: rgba(var(--themecolor),0.9);
        background-color: rgba(var(--themecolor),0.1);
    }
    span.on{
        color: #444;
        border-bottom: 1px solid #fff;
    }
    span:first-child{
        border-top-left-radius: 5px;
        border-left: 1px solid #e7e7e7;
    }
    span:last-child{
        border-top-right-radius: 5px;
    }
  }
  .content{
    border: 1px solid #e7e7e7;
    border-top: none;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-height: 60px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    .info{
      color: #666;
      font-size: 14px;
      text-indent: 28px;
      line-height: 24px;
      margin: 20px 20px 10px;
      max-height: 186px;
      overflow: hidden;
    }
    .info.nonum{
      max-height: none;
    }
    .echarts-box {
      width: 500px;
      margin-left: 20px;
      float: left;
      .salary-title {
        color: #333;
        font-size: 15px;
        text-align: center;
        margin: 20px 0 10px;
      }
      #echartsLine {
        width: 500px;
        height: 300px;
        padding: 20px;
        border: 1px solid #e7e7e7;
        border-radius: 5px;
      }
    }
    .salary-box {
      width: calc(100% - 600px);
      float: right;
      margin-top: 38px;
      margin-right: 20px;
      .salary-item {
        border-bottom: 1px solid #e6e6e6;
        font-size: 14px;
        padding: 8px 20px;
        color: #666;
        .year{
          width: 50%;
          display: inline-block;
        }
        .money {
          width: 50%;
          display: inline-block;
        }
      }
      .salary-item:last-child{
        border-bottom: none;
      }
    }
  }
}
.box-distribute {
  margin: 0px 20px;
  .box-item {
    width: calc(100% / 3);
    float: left;
    .collapse-title {
      margin: 0px 20px 15px;
      color: #555;
      font-size: 18px;
      text-align: center;
      display: inline-block;
    }
    #echartsPieProfession, #echartsPieIndustry, #echartsPieArea {
      height: 300px;
      width: 300px;
      margin: auto;
    }
    .content-item {
      margin: 0 20px;
      padding: 8px 0;
      border-bottom: 1px solid var(--color-grey);
      font-size: 14px;
      color: #666;
      font-size: 14px;
      .left{
        display: inline-block;
        width: calc(100% - 110px);
        padding-left: 20px;
      }
      .right{
        display: inline-block;
        width: 70px;
        padding-right: 20px;
        text-align: right;
      }
    }
  }
}

</style>
