<template>
  <div class="study-course-detail">
    <show-detail
      v-if="showDetail"
      :main-data="mainData"
    ></show-detail>
    <video-detail
      v-else
      :main-data="mainData"
    ></video-detail>
  </div>
</template>

<script>
import API from "@/api/config";
import ShowDetail from "@/views/careerManagement/my-components/ShowDetail";
import VideoDetail from "@/views/careerManagement/my-components/VideoDetail";

export default {
  components: {
    ShowDetail,
    VideoDetail
  },
  name: "study-course-detail",
  data () {
    return {
      showDetail: true,
      mainData: {}
    }
  },
  watch: {
    $route(to, from){
      if(to.path === '/careerManagement/study-course-detail'){
        this.initialize()
      }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    initialize(){
      API.CareerPlan.GetCoursewareDetail({Id: this.$route.query.Id}).then(res => {
        this.mainData = res.Data || {}
        res.Data.CoursewareFileUrl.indexOf('mp4')>-1?(this.showDetail = false):(this.showDetail = true)
      })
    }
  },
}
</script>

<style scoped>
  .study-course-detail{
    max-width: 1420px;
    margin: auto;
  }
</style>
