import Layout from '@/layout'
export default [
// 生涯管理
{
    path: '/careerManagement',
    component: Layout,
    redirect: '/careerManagement/careerplan',
    children: [
      {
        path: 'careerplan',
        name: 'careerPlan',
        component: () => import('@/views/careerManagement/careerPlan/index'),
        meta: {title: '生涯规划书'},
        hidden: true
      },
      {
        path: 'learningplan',
        name: 'learningplan',
        component: () => import('@/views/careerManagement/learningPlan/index'),
        meta: {title: '学习计划'},
        hidden: true
      },
      {
        path: 'entrancematters',
        name: 'entrancematters',
        component: () => import('@/views/careerManagement/entranceMatters/index'),
        meta: {title: '升学事项'},
        hidden: true
      },
      {
        path: 'targetlocation',
        name: 'targetlocation',
        component: () => import('@/views/careerManagement/targetLocation/index'),
        meta: {title: '目标定位'},
        hidden: true
      },
      {
        path: 'recomBook',
        name: 'recomBook',
        component: () => import('@/views/careerManagement/recomBook/index'),
        meta: {title: '推荐书籍'},
        hidden: true
      },
      {
        path: 'careerStory',
        name: 'careerStory',
        component: () => import('@/views/careerManagement/careerStory/index'),
        meta: {title: '生涯故事'},
        hidden: true
      },
      {
        path: 'career-story-detail',
        name: 'career-story-detail',
        component: () => import('@/views/careerManagement/careerStory/career-story-detail'),
        meta: {title: '详情'},
        hidden: true
      },
      {
        path: 'careerCase',
        name: 'careerCase',
        component: () => import('@/views/careerManagement/careerCase/index'),
        meta: {title: '生涯案例'},
        hidden: true
      },
      {
        path: 'career-case-detail',
        name: 'career-case-detail',
        component: () => import('@/views/careerManagement/careerCase/career-case-detail'),
        meta: {title: '详情'},
        hidden: true
      },
      {
        path: 'studyCourse',
        name: 'studyCourse',
        component: () => import('@/views/careerManagement/studyCourse/index'),
        meta: {title: '学能课堂'},
        hidden: true
      },
      {
        path: 'study-course-detail',
        name: 'study-course-detail',
        component: () => import('@/views/careerManagement/studyCourse/study-course-detail'),
        meta: {title: '详情'},
        hidden: true
      },
      {
        path: 'careerTeaching',
        name: 'careerTeaching',
        component: () => import('@/views/careerManagement/careerTeaching/index'),
        meta: {title: '生涯教案'},
        hidden: true
      },
      {
        path: 'career-teaching-detail',
        name: 'career-teaching-detail',
        component: () => import('@/views/careerManagement/careerTeaching/career-teaching-detail'),
        meta: {title: '详情'},
        hidden: true
      },
      {
        path: 'careerCourseware',
        name: 'careerCourseware',
        component: () => import('@/views/careerManagement/careerCourseware/index'),
        meta: {title: '生涯课件'},
        hidden: true
      },
      {
        path: 'career-courseware-detail',
        name: 'career-courseware-detail',
        component: () => import('@/views/careerManagement/careerCourseware/career-courseware-detail'),
        meta: {title: '详情'},
        hidden: true
      },
      {
        path: 'careerCourse',
        name: 'careerCourse',
        component: () => import('@/views/careerManagement/careerCourse/index'),
        meta: {title: '生涯课堂'},
        hidden: true
      },
      {
        path: 'videoCourse',
        name: 'videoCourse',
        component: () => import('@/views/careerManagement/videoCourse/index'),
        meta: {title: '网络课堂'},
        hidden: true
      },
      {
        path: 'video-list',
        name: 'video-list',
        component: () => import('@/views/careerManagement/videoCourse/video-list'),
        meta: {title: '网络课堂'},
        hidden: true
      },
      {
        path: 'video-detail',
        name: 'video-detail',
        component: () => import('@/views/careerManagement/videoCourse/video-detail'),
        meta: {title: '详情'},
        hidden: true
      },
    ]
  },
]