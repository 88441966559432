<template>
    <div class="pagebox" v-loading="loading" element-loading-text="加载中...">
        <!-- 顶部 -->
        <PHeader></PHeader>
        <!-- 院校比较 -->
        <div class="college_compare_fixed">
            <div class="compare_head_fixed" :class="TableHeaderFixed" v-show="CompareIsShow">
                <div class="compare_head_con">
                    <table cellspacing="0">
                        <tr>
                            <td style="width:136px;">
                                <div class="txt" style="margin-top:15px;">
                                    <div>共 <span style="color:rgba(var(--themecolor));font-size:18px;">{{CollegeCompareList.length}}</span> 所院校</div>
                                    <div><el-checkbox v-model="OnlyLookUnlike">只看差异</el-checkbox></div>
                                </div>
                            </td>
                            <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index">
                                <div class="txt" style="margin-top:10px;position:relative;">
                                    <div class="logo" :style="{'background':'url('+item.CollegeLogoFileUrl+') no-repeat center center / cover'}"></div>
                                    <div class="sname nowrap" :title="item.CollegeName">{{item.CollegeName}}</div>
                                    <div class="replace iconfont" title="替换" @click.prevent="ReplaceOrAddCollege(1,item.Id,item.CollegeName)">&#xe694;</div>
                                    <div class="delete iconfont" title="移除" @click.prevent="RemoveCollege(item)">&#xe683;</div>
                                </div>
                            </td>
                            <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'compare'+index">
                                <div class="txt" style="margin-top:10px;position:relative;">
                                    <div class="replace add" @click.prevent="ReplaceOrAddCollege(2)"><i class="iconfont">&#xe682;</i>添加院校</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="college_compare_list">
            <div class="compare_list_con" id="table_table" v-show="CompareIsShow">
                <table cellspacing="0">
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'CategoryName')">
                        <td style="width:136px;"><div class="txt">院校类别</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CategoryName}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'ProvinceName')">
                        <td style="width:136px;"><div class="txt">省份名称</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.ProvinceName}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'CityName')">
                        <td style="width:136px;"><div class="txt">城市名称</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CityName}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'Address')">
                        <td style="width:136px;"><div class="txt">地址</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.Address}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'EstablishDate')">
                        <td style="width:136px;"><div class="txt">成立时间</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.EstablishDate}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'Tel')">
                        <td style="width:136px;"><div class="txt">电话</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.Tel}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'ChargeDept')">
                        <td style="width:136px;"><div class="txt">主管部门</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.ChargeDept}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'NatureName')">
                        <td style="width:136px;"><div class="txt">性质</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.NatureName}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'EducationLevelStr')">
                        <td style="width:136px;"><div class="txt">办学层次</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.EducationLevelStr}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'StrengthTags')&&IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'FeatureTags')">
                        <td style="width:136px;"><div class="txt">院校标签</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.StrengthTags}}<span v-show="item.StrengthTags&&item.FeatureTags">,</span>{{item.FeatureTags}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'TeachersDescription')">
                        <td style="width:136px;"><div class="txt">师资力量</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.TeachersDescription}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'MajorAdmissionRule')">
                        <td style="width:136px;"><div class="txt">专业录取规则</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.MajorAdmissionRule}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'TotalOfTeachers')">
                        <td style="width:136px;"><div class="txt">教师总数</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.TotalOfTeachers}}<span v-show="item.TotalOfTeachers">人</span></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'PercentOfMale')">
                        <td style="width:136px;"><div class="txt">男生占比</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.PercentOfMale}}<span v-show="item.PercentOfMale">%</span></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'PercentOfFemale')">
                        <td style="width:136px;"><div class="txt">女生占比</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.PercentOfFemale}}<span v-show="item.PercentOfFemale">%</span></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'PercentOfPostgraduateRecommendation')">
                        <td style="width:136px;"><div class="txt">保研比例</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.PercentOfPostgraduateRecommendation}}<span v-show="item.PercentOfPostgraduateRecommendation">%</span></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'PercentOfPostgraduate')">
                        <td style="width:136px;"><div class="txt">读研比例</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.PercentOfPostgraduate}}<span v-show="item.PercentOfPostgraduate">%</span></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'PercentOfGoAbroad')">
                        <td style="width:136px;"><div class="txt">海外发展比例</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.PercentOfGoAbroad}}<span v-show="item.PercentOfGoAbroad">%</span></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'MasterFirstDisciplineCount')">
                        <td style="width:136px;"><div class="txt">一级学科硕士点数量</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.MasterFirstDisciplineCount}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'MasterSecondDisciplineCount')">
                        <td style="width:136px;"><div class="txt">二级学科硕士点数量</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.MasterSecondDisciplineCount}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'MasterDegreeAuthCategoryCount')">
                        <td style="width:136px;"><div class="txt">硕士专业学位授权类别数量</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.MasterDegreeAuthCategoryCount}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'DoctoralFirstDisciplineCount')">
                        <td style="width:136px;"><div class="txt">一级学科博士点数量</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.DoctoralFirstDisciplineCount}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'DoctoralSecondDisciplineCount')">
                        <td style="width:136px;"><div class="txt">二级学科博士点数量</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.DoctoralSecondDisciplineCount}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr v-if="IsOnlyLookUnlike(OnlyLookUnlike,CollegeCompareList,'DoctoralDegreeAuthCategoryCount')">
                        <td style="width:136px;"><div class="txt">博士专业学位授权类数量</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.DoctoralDegreeAuthCategoryCount}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="background-color:#eee;border-right:1px solid #eee;padding:5px 0px;">院校排名</td>
                        <td style="background-color:#eee;padding:5px 0px;" :style="{'border-right':(CollegeCompareList.length==index+1)?'':'1px solid #eee'}" v-for="(item,index) in CollegeCompareList" :key="index"></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">软科</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeRankList.find(f=>f.RankCategoryName=='软科')?item.CollegeRankList.find(f=>f.RankCategoryName=='软科').RankNum:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">ESI</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeRankList.find(f=>f.RankCategoryName=='ESI排名')?item.CollegeRankList.find(f=>f.RankCategoryName=='ESI排名').RankNum:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">QS</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeRankList.find(f=>f.RankCategoryName=='QS')?item.CollegeRankList.find(f=>f.RankCategoryName=='QS').RankNum:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">USNews</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeRankList.find(f=>f.RankCategoryName=='USNews')?item.CollegeRankList.find(f=>f.RankCategoryName=='USNews').RankNum:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">泰晤士</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeRankList.find(f=>f.RankCategoryName=='泰晤士')?item.CollegeRankList.find(f=>f.RankCategoryName=='泰晤士').RankNum:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">校友会</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeRankList.find(f=>f.RankCategoryName=='校友会')?item.CollegeRankList.find(f=>f.RankCategoryName=='校友会').RankNum:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">武书连</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeRankList.find(f=>f.RankCategoryName=='武书连')?item.CollegeRankList.find(f=>f.RankCategoryName=='武书连').RankNum:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="background-color:#eee;border-right:1px solid #eee;padding:5px 0px;">综合评级</td>
                        <td style="background-color:#eee;padding:5px 0px;" :style="{'border-right':(CollegeCompareList.length==index+1)?'':'1px solid #eee'}" v-for="(item,index) in CollegeCompareList" :key="index"></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">就业</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeIndicateList.find(f=>f.IndicatorName=='就业')?item.CollegeIndicateList.find(f=>f.IndicatorName=='就业').Score:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">地域</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeIndicateList.find(f=>f.IndicatorName=='地域')?item.CollegeIndicateList.find(f=>f.IndicatorName=='地域').Score:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">城市</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeIndicateList.find(f=>f.IndicatorName=='城市')?item.CollegeIndicateList.find(f=>f.IndicatorName=='城市').Score:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">专业实力</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeIndicateList.find(f=>f.IndicatorName=='专业实力')?item.CollegeIndicateList.find(f=>f.IndicatorName=='专业实力').Score:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">综合实力</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeIndicateList.find(f=>f.IndicatorName=='综合实力')?item.CollegeIndicateList.find(f=>f.IndicatorName=='综合实力').Score:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">属性</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt">{{item.CollegeIndicateList.find(f=>f.IndicatorName=='属性')?item.CollegeIndicateList.find(f=>f.IndicatorName=='属性').Score:''}}</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="background-color:#eee;border-right:1px solid #eee;padding:5px 0px;">教研实力</td>
                        <td style="background-color:#eee;padding:5px 0px;" :style="{'border-right':(CollegeCompareList.length==index+1)?'':'1px solid #eee'}" v-for="(item,index) in CollegeCompareList" :key="index"></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">实验室与研究机构</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''">
                            <div class="txt">
                                <div v-for="(c,index) in item.CollegeLoboratoryList" :key="index" v-show="c.LaboratoryList">{{c.CategoryName}}<span v-if="c.LaboratoryList">：{{c.LaboratoryList.length}}</span></div>
                            </div>
                        </td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="background-color:#eee;border-right:1px solid #eee;padding:5px 0px;">学科评估</td>
                        <td style="background-color:#eee;padding:5px 0px;" :style="{'border-right':(CollegeCompareList.length==index+1)?'':'1px solid #eee'}" v-for="(item,index) in CollegeCompareList" :key="index"></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">A+</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='A+')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='A+').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">A</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='A')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='A').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">A-</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='A-')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='A-').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">B+</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='B+')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='B+').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">B</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='B')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='B').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">B-</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='B-')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='B-').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">C+</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='C+')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='C+').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">C</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='C')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='C').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                    <tr>
                        <td style="width:136px;"><div class="txt">C-</div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in CollegeCompareList" :key="index" :class="index==4?'border':''"><div class="txt"><div v-for="c in (item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='C-')?item.CollegeLearnSubjectEvaluateList.find(f=>f.EvaluateLevel=='C-').LearnSubjectList:[])" :key="c">{{c}}</div></div></td>
                        <td :style="{'width':CollegeCompareTableTdWidth+'px'}" v-for="(item,index) in (5-CollegeCompareList.length)" :key="'nodata'+index" class="nodata"></td>
                    </tr>
                </table>
                <div class="mark">（ 以上信息仅供参考，详细数据以院校官方公布信息为准 ）</div>
            </div>
        </div>

        <!-- 对比 -->
        <div class="college_compare_box" :style="{'right':CompareFixed+'px'}" :class="AnimateCompareClass">
            <div class="compare">
                <div class="open" @click.prevent="OpenCompareBtn">
                    <div class="ico">
                        <i class="el-icon-d-arrow-right" v-if="CompareFixed==0"></i>
                        <i class="el-icon-d-arrow-left" v-else></i>
                    </div>
                    <div class="tit">{{CompareFixed==0?'收起院校对比':'展开院校对比'}}
                        <div class="num" v-if="CollegeCompareData.length>0">{{CollegeCompareData.length}}</div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="db" v-if="CollegeCompareData.length>0" @click.prevent="StartCompareBtn"><span>开始对比</span></div>
                <div class="list">
                    <div class="search">
                        <i class="el-icon-search"></i>
                        <el-select v-model="CompareForm.Id" clearable filterable remote reserve-keyword placeholder="请输入院校关键字" @change="CompareCollegeChange" :remote-method="RemoteMethodCollege" :loading="cloading">
                            <el-option v-for="item in CollegeSelectData" :key="item.Id" :label="item.Name" :value="item.Id" :disabled="item.Disabled" :title="(item.Disabled?'已添加：'+item.Name:'')"></el-option>
                        </el-select>
                    </div>
                    <div class="dlist">
                        <el-scrollbar wrap-class="scrollbar-wrapper" class="comparescroll">
                            <div class="dl" :class="(CollegeCompareCheckData.find(f=>f===item.Id)?'on':'')" v-for="(item,index) in CollegeCompareData" :key="index">
                                <span class="check" @click.prevent="CollegeCheck(item)"><span class="ch"><i class="el-icon-check"></i></span></span>
                                <div class="sname" @click.prevent="CollegeCheck(item)">
                                    <el-tooltip effect="light" :disabled="(item.CollegeName.length>11?false:true)" :content="item.CollegeName" placement="left">
                                        <el-button>{{item.CollegeName}}</el-button>
                                    </el-tooltip>
                                </div>
                                <i class="delete el-icon-delete" @click.prevent="CollegeAddCompare(item,2)"></i>
                            </div>
                            <div v-if="CollegeCompareData.length==0" style="text-align:center;color:#888;font-size:13px;line-height:60px;">请选择要对比的院校</div>
                        </el-scrollbar>
                        <div class="mark">每次最多可选5条记录对比</div>
                        <div class="btn" @click.prevent="StartCompareBtn">开始对比</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 替换、添加 -->
        <div class="edit_layer_box" v-if="EditLayerShow">
            <div class="elbox">
                <div class="title">{{EditLayerType==1?'替换：'+EditLayerReplaceName:'添加院校'}}<i class="el-icon-close" @click.prevent="CloseEditLayer()"></i></div>
                <div class="ellist">
                    <div class="search">
                        <i class="el-icon-search"></i>
                        <el-select v-model="CompareForm.Id" clearable filterable remote reserve-keyword placeholder="请输入院校关键字" @change="EditOrAddChange" :remote-method="RemoteMethodCollegeByReplaceOrAdd" :loading="cloading">
                            <el-option v-for="item in CollegeSelectData" :key="item.Id" :label="item.Name" :value="item.Id" :disabled="item.Disabled" :title="(item.Disabled?'已添加：'+item.Name:'')"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="back" @click.prevent="CloseEditLayer()">取消</div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import { getStore, setStore, removeStore } from "@/utils/utils";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            loading:false,
            OnlyLookUnlike:false,
            TableHeaderFixed:'',
            CollegeCompareTableTdWidth:0,
            CollegeCompareList:[],
            CompareIsShow:false,

            CompareFixed:-240,
            AnimateCompareClass:'',
            CompareForm:{
                Id:null
            },
            CollegeSelectData:[],
            cloading:false,
            CollegeCompareData:[],
            CollegeCompareCheckData:[],

            EditLayerShow:false,
            EditLayerType:1,
            EditLayerReplaceId:null,
            EditLayerReplaceName:''
        }
    },
    mounted(){
        this.GetCollegeCompare();// 获取院校对比
        //监听滚动事件
        window.addEventListener('scroll', () => {
            let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
            if (ddeht > 140) {
                this.TableHeaderFixed='fixed';
            }
            else {
                this.TableHeaderFixed='';
            }
        }, true);
        //获取对比数据
        let cc1 = getStore("CollegeCompareItem");
        if(cc1){
            this.CollegeCompareData=JSON.parse(cc1);
        }
        let cc2 = getStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
        if(cc2){
            this.CollegeCompareCheckData=JSON.parse(cc2);
        }
    },
    methods:{
        // 是否只看差异
        IsOnlyLookUnlike(bool,data,name){
            if(bool){
                let b = true;
                for(let i=0;i<data.length;i++){
                    if(data[0][name]!=data[i][name]){
                        b = true;
                    }
                    else{
                        if(data[0][name]){
                            b = false;
                        }
                        else{
                            b = true;
                        }
                    }
                }
                return b;
            }
            else{
                return true;
            }
        },
        // 获取院校对比
        GetCollegeCompare(){
            this.loading=true;
            let data=getStore("CollegeCompareCheckItem");
            if(data){
                data=JSON.parse(data);
            }
            API.Query.GetCollegeCompare({IdList:data}).then(res => {
                if(res.Code==0){
                    this.CompareIsShow=true;
                    this.loading=false;
                    for(let i in res.Data){
                        this.CollegeCompareList.push(res.Data[i]);
                    }
                    this.$nextTick(()=>{
                        let w = document.getElementById('table_table').clientWidth;
                        this.CollegeCompareTableTdWidth = ((w - 139) / 5) - 1;
                    });
                }
            });
        },
        // 替换院校、添加院校
        ReplaceOrAddCollege(type,id,name){
            this.EditLayerReplaceId=id;
            this.EditLayerReplaceName=name;
            this.EditLayerType=type;
            this.EditLayerShow=true;
        },
        // 替换、添加选中
        EditOrAddChange(id){
            // 检查是否为右侧对比例中未勾选的院校
            let obj = this.CollegeCompareData.find(f=>f.Id==id);
            //替换
            if(this.EditLayerType==1){
                if(!obj){
                    let item = this.CollegeSelectData.find(f=>f.Id==id);
                    if(item){
                        this.CollegeCompareData.push(item);
                    }
                }
                for (let index = 0; index < this.CollegeCompareCheckData.length; index++) {
                    if(this.CollegeCompareCheckData[index]==this.EditLayerReplaceId){
                        let item = this.CollegeSelectData.find(f=>f.Id==id);
                        if(item){
                            this.CollegeCompareCheckData.splice(index,1,item.Id);
                        }
                    }
                }
                setStore("CollegeCompareItem",this.CollegeCompareData);
                setStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
            }
            //添加
            else{
                if(obj){
                    this.CollegeCompareCheckData.push(id);
                    setStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
                }
                else{
                    this.CompareCollegeChange(id);// 院校对比下拉改变
                }
            }
            this.CompareForm.Id=null;
            this.CollegeSelectData=[];
            this.CollegeCompareList=[];
            this.GetCollegeCompare();// 刷新院校对比
            this.EditLayerShow=false;
        },
        // 关闭替换、添加
        CloseEditLayer(){
            this.CompareForm.Id=null;
            this.CollegeSelectData=[];
            this.EditLayerShow=false;
        },
        // 移除对比（只移除勾选）
        RemoveCollege(item){
            if(this.CollegeCompareCheckData.find(f=>f===item.Id)){
                this.CollegeCompareCheckData = this.CollegeCompareCheckData.filter(f=>f!==item.Id);
            }
            setStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
            this.CollegeCompareList=[];
            this.GetCollegeCompare();// 刷新院校对比
        },
        // 院校标签展开/收起
        CollegeTipBtn(id,type){
            let obj1 = document.getElementById('college_tip_id'+id).style;
            let obj2 = document.getElementById('college_tip_down'+id).style;
            let obj3 = document.getElementById('college_tip_up'+id).style;
            if(type==1){
                obj1.height='auto';obj1.overflow='initial';obj2.display='none';obj3.display='block';
            }
            else{
                obj1.height='24px';obj1.overflow='hidden';obj2.display='block';obj3.display='none';
            }
        },
        // 院校对比展开/收起
        OpenCompareBtn(){
            if(this.CompareFixed==0){
                this.AnimateCompareClass = 'animate_close';
                this.CompareFixed=-240;
            }
            else{
                this.AnimateCompareClass = 'animate_open';
                this.CompareFixed=0;
            }
        },
        // 院校对比添加/移除
        CollegeAddCompare(item,type){
            if(type==1){
                this.CollegeCompareData.push(item);
                if(this.CollegeCompareCheckData.length<5){
                    this.CollegeCompareCheckData.push(item.Id);
                }
            }
            else{
                this.CollegeCompareData = this.CollegeCompareData.filter(f=>f.Id!==item.Id);
                if(this.CollegeCompareCheckData.find(f=>f===item.Id)){
                    this.CollegeCompareCheckData = this.CollegeCompareCheckData.filter(f=>f!==item.Id);
                }
            }
            setStore("CollegeCompareItem",this.CollegeCompareData);
            setStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
        },
        // 勾选院校对比
        CollegeCheck(item){
            if(this.CollegeCompareCheckData.find(f=>f===item.Id)){
                this.CollegeCompareCheckData = this.CollegeCompareCheckData.filter(f=>f!==item.Id);
            }
            else{
                if(this.CollegeCompareCheckData.length<5){
                    this.CollegeCompareCheckData.push(item.Id);
                }
                else{
                    this.$message({message: '每次最多可选5条记录对比',type: 'warning'});
                }
            }
            setStore("CollegeCompareCheckItem",this.CollegeCompareCheckData);
        },
        // 院校对比下拉改变
        CompareCollegeChange(id){
            this.CompareForm.Id=null;
            let obj = this.CollegeCompareData.find(f=>f.Id==id);
            if(obj){
                this.$message({message: '已添加：'+obj.CollegeName,type: 'warning'});return false;
            }
            let item = this.CollegeSelectData.find(f=>f.Id==id);
            if(id&&item){
                this.CollegeAddCompare(item,1);// 院校对比添加
            }
            this.CollegeSelectData = [];
        },
        // 对比 搜索院校
        RemoteMethodCollege(name) {
            if (name.trim()) {
                this.cloading = true;
                let st = setTimeout(() => {
                    // 获取院校
                    API.Query.SearchCollegeByName({Name:name}).then(res => {
                        this.CollegeSelectData = res.Data;
                        this.CollegeSelectData.forEach(item=>{
                            item.CollegeName=item.Name;
                            if(this.CollegeCompareData.find(f=>f.Id==item.Id)){
                                item.Disabled = true;
                            }
                        });
                        this.cloading = false;
                        clearTimeout(st);
                    });
                }, 200);
            } else {
                this.CollegeSelectData = [];
            }
        },
        // 对比 搜索院校（替换、添加专用）
        RemoteMethodCollegeByReplaceOrAdd(name) {
            if (name.trim()) {
                this.cloading = true;
                let st = setTimeout(() => {
                    // 获取院校
                    API.Query.SearchCollegeByName({Name:name}).then(res => {
                        this.CollegeSelectData = res.Data;
                        this.CollegeSelectData.forEach(item=>{
                            item.CollegeName=item.Name;
                            if(this.CollegeCompareList.find(f=>f.Id==item.Id)){
                                item.Disabled = true;
                            }
                        });
                        this.cloading = false;
                        clearTimeout(st);
                    });
                }, 200);
            } else {
                this.CollegeSelectData = [];
            }
        },
        // 开始对比
        StartCompareBtn(){
            if(this.CollegeCompareData.length==0){
                this.$message({message: '请添加要对比的院校',type: "warning"});return false;
            }
            if(this.CollegeCompareCheckData.length==0){
                this.$message({message: '请勾选要对比的院校',type: 'warning'});return false;
            }
            if(this.CollegeCompareCheckData.length<2){
                this.$message({message: '至少要勾选2个院校',type: 'warning'});return false;
            }
            this.CollegeCompareList=[];
            this.GetCollegeCompare();// 刷新院校对比
        },
    }
}
</script>
<style scoped lang="less">
.college_compare_fixed{
    width: 100%;
    margin-top: 20px;
    height: 90px;
    .compare_head_fixed.fixed{
        position: fixed;
        top: 40px;
        left: 0px;
        z-index: 99;
    }
    .compare_head_fixed{
        width: 100%;
        height: 90px;
        .compare_head_con{
            max-width: 1420px;
            margin: auto;
            user-select: none;
            table{
                margin: 0px 20px;
                table-layout: fixed;
                background-color: #f7f7f7;
                border: 1px solid #ddd;
                border-left: none;
                border-right: none;
                width: calc(100% - 41px);
                tr{
                    td{
                        height: 88px;
                        border-right: 1px solid #ddd;
                        color: #666;
                        display: inline-block;
                        .txt{
                            margin: 0px 10px;
                            text-align: center;
                            .logo{
                                width: 40px;
                                height: 40px;
                                overflow: hidden;
                                margin: auto;
                            }
                            .sname{
                                font-size: 16px;
                                font-weight: bold;
                                letter-spacing: 1px;
                                margin-top: 5px;
                                height: 24px;
                                
                            }
                            .replace{
                                position: absolute;
                                left: -5px;
                                top: -3px;
                                z-index: 100;
                                height: 18px;
                                line-height: 18px;
                                color: #888;
                                font-size: 15px;
                                padding: 0px 7px;
                            }
                            .replace:hover{
                                cursor: pointer;
                                color: rgba(var(--themecolor),0.8);
                                i{
                                    color: rgba(var(--themecolor),0.8);
                                }
                            }
                            .add{
                                left: calc(50% - 40px);
                                top: 21px;
                                width: 80px;
                                height: 24px;
                                line-height: 24px;
                                font-size: 14px;
                                border: 1px solid #ddd;
                                border-radius: 20px;
                                background-color: #fff;
                            }
                            .add:hover{
                                border: 1px solid rgba(var(--themecolor),0.3);
                            }
                            .delete{
                                position: absolute;
                                right: -5px;
                                top: -3px;
                                z-index: 100;
                                height: 18px;
                                line-height: 18px;
                                color: #999;
                                font-size: 13px;
                                padding: 0px 7px;
                            }
                            .delete:hover{
                                cursor: pointer;
                                color: rgba(var(--themecolor),0.8);
                            }
                        }
                    }
                    td:first-child{
                        border-left: 1px solid #ddd;
                    }
                }
            }
        }
    }
}
.college_compare_list{
    max-width: 1420px;
    margin: 0px auto 50px;
    user-select: none;
    min-height: 600px;
    .compare_list_con{
        margin: 0px 20px;
        table{
            table-layout: fixed;
            border-right: 1px solid #ddd;
            background-color: #fff;
            width: calc(100% - 1px);
            tr{
                td{
                    border-bottom: 1px solid #ddd;
                    border-right: 1px solid #ddd;
                    color: #777;
                    font-size: 14px;
                    padding: 10px 0px;
                    line-height: 20px;
                    vertical-align: middle;
                    text-align: center;
                    .txt{
                        margin: 0px 10px;
                        a{
                            color: #666;
                            text-decoration: underline;
                        }
                    }
                }
            }
            tr td:first-child{
                width: 136px;
                font-weight: bold;
                vertical-align:middle;
                background-color: #f7f7f7;
                border-left: 1px solid #ddd;
            }
            .border{
                border-right: none;
            }
            .nodata{
                border-bottom: 1px solid #fff;
                background-color: #f7f7f7;
            }
            .nodata:last-child{
                border-right: none;
            }
            tr:last-child .nodata{
                border-bottom: 1px solid #ddd;
            }
        }
        .mark{
            color: #888;
            font-size: 12px;
            margin-top: 20px;
        }
    }
}
.college_compare_box{
    position: fixed;
    width: 240px;
    height: 476px;
    top: 181px;
    z-index: 21;
    .compare{
        position: relative;
        width: 100%;
        height: 100%;
        .open{
            position: absolute;
            left: -48px;
            top: 140px;
            width: 48px;
            height: 200px;
            border-right: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: rgba(var(--themecolor),1);
            cursor: pointer;
            .ico{
                width: 18px;
                height: 100%;
                line-height: 200px;
                text-align: center;
                color: #e9e8e8;
                font-size: 13px;
                float: left;
                transition: all 0.3s;
                -webkit-transition: all 0.3s;
            }
            .tit{
                width: 14px;
                height: 108px;
                color: #e9e8e8;
                font-size: 16px;
                line-height: 22px;
                margin-left: 2px;
                margin-top: 30px;
                float: left;
                position: relative;
                .num{
                    position: relative;
                    bottom: -25px;
                    left: -20px;
                    width: 46px;
                    padding: 5px 0px;
                    text-align: center;
                    border-bottom-left-radius: 5px;
                    background-color: #fff;
                    color: rgba(var(--themecolor),1);
                    font-weight: bold;
                    overflow: hidden;
                    border: 1px solid rgba(var(--themecolor),1);
                }
            }
        }
        .open:hover .ico{
            animation: animate_ico 1s ease 0s infinite;
            -webkit-animation: animate_ico 1s ease 0s infinite;
            @-webkit-keyframes animate_ico {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0.3;
                }
                100% {
                    opacity: 1;
                }
            }
            @keyframes animate_ico {
                0% {
                    opacity: 1;
                    font-size: 13px;
                }
                50% {
                    opacity: 0.3;
                }
                100% {
                    opacity: 1;
                }
            }
        }
        
        .db{
            position: absolute;
            left: -48px;
            top: 365px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            background-color: rgba(var(--themecolor),1);
            margin-top: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            text-align: center;
            span{
                letter-spacing: 2px;
                display: inline-block;
                width: 28px;
                margin-top: 7px;
                font-size: 12px;
                color: #fff;
                line-height: 16px;
            }
        }
        .list{
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #cecece;
            .search{
                width: 100%;
                height: 36px;
                position: relative;
                margin-top: 10px;
                i{
                    display: inline-block;
                    width: 30px;
                    height: 36px;
                    text-align: center;
                    line-height: 40px;
                    color: #999;
                    font-size: 14px;
                    position: absolute;
                    left: 10px;
                    top: 0px;
                    z-index: 1;
                }
                .el-select{
                    display: inline-block;
                    width: 220px;
                    margin-left: 10px;
                }
            }
            .dlist{
                margin: 10px 10px 0;
                height: 327px;
                .comparescroll{
                    height: 100%;
                    width: 222px;
                }
                .dl:last-child{
                    border-bottom: none;
                }
                .dl{
                    width: 100%;
                    height: 40px;
                    border-bottom: 1px solid #dddada;
                    position: relative;
                    .check{
                        display: block;
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        left: 0px;
                        cursor: pointer;
                        .ch{
                            width: 16px;
                            height: 16px;
                            border: 1px solid #ccc9c9;
                            border-radius: 3px;
                            overflow: hidden;
                            display: block;
                            margin: 11px 0px 0px 0px;
                            i{
                                font-size: 14px;
                                color: #fff;
                                display: none;
                            }
                        }
                    }
                    .check:hover .ch{
                        border: 1px solid rgba(var(--themecolor),0.65);
                    }
                    .sname{
                        float: left;
                        width: 188px;
                        height: 100%;
                        .el-button{
                            color: #666;
                            font-size: 14px;
                            display: block;
                            width: 100%;
                            height: 100%;
                            line-height: 38px;
                            overflow: hidden;
                            border-color: transparent;
                            background-color: transparent;
                            padding: 0;
                            overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
                            text-align: left;
                            text-indent: 28px;
                        }
                        .el-button:active{
                            border-color: transparent;
                        }
                        .el-button:focus, .el-button:hover{
                            background-color: transparent;
                        }
                    }
                    .sname:hover  .el-button{
                        color: rgba(var(--themecolor),0.75);
                    }
                    .delete{
                        color: #999;
                        font-size: 16px;
                        display: block;
                        float: left;
                        width: 32px;
                        height: 100%;
                        line-height: 40px;
                        text-align: center;
                    }
                    .delete:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor),1);
                    }
                }
                .dl.on .check .ch{
                    background-color: rgba(var(--themecolor),0.75);
                    border: 1px solid rgba(var(--themecolor),0.75);
                }
                .dl.on .check .ch:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                    border: 1px solid rgba(var(--themecolor),1);
                }
                .dl.on .check .ch i{
                    display: block;
                    line-height: 18px;
                }
                .mark{
                    text-align: center;
                    line-height: 40px;
                    color: #666;
                    font-size: 12px;
                }
                .btn{
                    width: 160px;
                    height: 38px;
                    text-align: center;
                    line-height: 38px;
                    color: #fff;
                    font-size: 14px;
                    background-color: rgba(var(--themecolor),0.85);
                    cursor: pointer;
                    letter-spacing: 1px;
                    margin: auto;
                    border-radius: 5px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }
                .btn:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
    }
}
.college_compare_box.animate_open{
    animation: animate_open 0.3s ease 0s 1;
    -webkit-animation: animate_open 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes animate_open {
        from {
            right: -240px;
        }
        to {
            right: 0px;
        }
    }
    @keyframes animate_open {
        from {
            right: -240px;
        }
        to {
            right: 0px;
        }
    }
}
.college_compare_box.animate_close{
    animation: animate_close 0.3s ease 0s 1;
    -webkit-animation: animate_close 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes animate_close {
        from {
            right: 0px;
        }
        to {
            right: -240px;
        }
    }
    @keyframes animate_close {
        from {
            right: 0px;
        }
        to {
            right: -240px;
        }
    }
}
.edit_layer_box{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.4);
    .elbox{
        width: 500px;
        height: 240px;
        background-color: #fff;
        border-radius: 5px;
        position: relative;
        left: calc(50% - 250px);
        top: calc(50% - 120px);
        z-index: 1;
        .title{
            text-align: center;
            line-height: 50px;
            height: 50px;
            color: #666;
            font-size: 16px;
            position: relative;
            border-bottom: 1px solid #d8d8d8;
            i{
                color: #888;
                font-size: 15px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
            }
            i:hover{
                color: rgba(var(--themecolor),0.85);
                cursor: pointer;
            }
        }
        .ellist{
            margin: 30px 20px 0;
            .search{
                width: 100%;
                height: 36px;
                position: relative;
                margin-top: 10px;
                i{
                    display: inline-block;
                    width: 30px;
                    height: 36px;
                    text-align: center;
                    line-height: 40px;
                    color: #999;
                    font-size: 14px;
                    position: absolute;
                    left: 10px;
                    top: 0px;
                    z-index: 1;
                }
                .el-select{
                    display: inline-block;
                    width: 100%;
                }
            }
        }
        .back{
            margin: 50px auto 0;
            width: 100px;
            height: 34px;
            line-height: 34px;
            color: #888;
            font-size: 14px;
            border: 1px solid #ddd;
            border-radius: 5px;
            text-align: center;
        }
        .back:hover{
            cursor: pointer;
            border: 1px solid #c9c9c9;
            color: #666;
        }
    }
}
</style>
<style>
.college_compare_fixed .compare_head_fixed .el-checkbox{
    margin-top: 10px;
}
.college_compare_fixed .compare_head_fixed .el-checkbox__inner{
    border: 1px solid #c2bfbf;
}
.college_compare_fixed .compare_head_fixed .el-checkbox__input.is-focus .el-checkbox__inner{
    border-color: #c2bfbf;
}
.college_compare_fixed .compare_head_fixed .el-checkbox__input.is-checked .el-checkbox__inner, .college_compare_fixed .compare_head_fixed .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: rgba(var(--themecolor),0.75);
    border-color: rgba(var(--themecolor),0.75);
}
.college_compare_fixed .compare_head_fixed .el-checkbox__label{
    padding-left: 5px;
}
.college_compare_fixed .compare_head_fixed .el-checkbox__input.is-checked + .el-checkbox__label{
    color:#666;
}
.college_compare_box .el-input .el-input__inner{
    text-indent: 28px;
    color: #666;
    height: 36px;
    padding: 0;
}
.college_compare_box .el-input__icon{
    line-height: normal;
}
.college_compare_box .el-select .el-input__inner:focus{
    border-color:rgba(var(--themecolor),0.75);
}
.college_compare_box .el-range-editor.is-active, .college_box .college_con .college_compare_box .el-range-editor.is-active:hover, .college_box .college_con .college_compare_box .el-select .el-input.is-focus .el-input__inner{
    border-color:rgba(var(--themecolor),0.75);
}
.college_compare_box .el-scrollbar__wrap{
    overflow-x: hidden;
}
.edit_layer_box .el-input .el-input__inner{
    text-indent: 40px;
    color: #666;
    height: 36px;
    padding: 0;
}
.edit_layer_box .el-select .el-input__inner:focus{
    border-color:rgba(var(--themecolor),0.75);
}
.edit_layer_box .el-range-editor.is-active, .el-range-editor.is-active:hover, .edit_layer_box .el-select .el-input.is-focus .el-input__inner{
    border-color: #ddd;
}
</style>