<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 招生计划 -->
        <div class="plan_con_box">
            <div class="con_div">
                <!-- 查询院校 -->
                <div class="college_con">
                    <div class="searchl">
                        <el-input id="College_Input_ID" placeholder="搜索院校" v-model="SearchCollegeName" @keyup.enter.native="searchCollege" @focus="ShowCollegeSearch=true" clearable @clear="clearCollegeSearch"></el-input>
                        <div v-show="ShowCollegeSearch" id="College_Show_ID" class="search-box">
                            <div class="search-li" v-for="SCItem in SearchCollegeList" :key="SCItem.Id" @click="CheckCollegeItem(SCItem.Id)">
                                <i v-show="SelectSearchList.find(i => i.Id === SCItem.Id)" class="selected-icon iconfont">&#xe68a;</i>
                                {{ SCItem.Name }}
                            </div>
                            <div class="no-data" v-if="SearchCollegeList.length <= 0">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </div>
                    <div id="Search_Button_ID" class="s_btn" @click="searchCollege"><i class="el-icon-search"></i>搜 索</div>
                    <div class="clear"></div>
                </div>
                <!-- 招生计划查询列表 -->
                <div class="tab_con" >
                    <div class="clear"></div>
                    <div class="q_box" v-if="PlanMajorData&&APQParametersData.length!=0">
                        <div class="zs_sf">招生省份：{{ provinceObj.ProvinceName }}</div>
                        <div class="plan_select year" v-if="APQParametersData.length!=0">
                            <el-select v-model="APQParameters.Year" @change="APQParametersChangeYear()">
                                <el-option v-for="year in APQParametersData" :key="year.Value" :label="year.Name" :value="year.Value"></el-option>
                            </el-select>
                        </div>
                        <div class="plan_select year" v-if="LevelNumber==4&&SubjectData.length!=0">
                            <el-select v-model="APQParameters.SubjectType" @change="APQParametersChangeSubject()" :disabled="(UserData&&UserData.UserType!=1&&UserSubjectType!=null)">
                                <el-option v-for="subject in SubjectData" :key="subject.Value" :label="subject.Name" :value="subject.Value"></el-option>
                            </el-select>
                        </div>
                        <div class="plan_select batch" v-if="BatchData.length!=0">
                            <el-select v-model="APQParameters.BatchId" @change="APQParametersChangeBatch()">
                                <el-option v-for="batch in BatchData" :key="batch.Value" :label="batch.Name" :value="batch.Value"></el-option>
                            </el-select>
                        </div>
                        <div class="plan_select plan" v-if="PlanCollegeData.length!=0">
                            <el-select v-model="APQParameters.PlanCollegeId" @change="APQParametersChangePlan()">
                                <el-option v-for="plancollege in PlanCollegeData" :key="plancollege.Value" :label="plancollege.Name" :value="plancollege.Value"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <table class="planhead" cellspacing="0" v-if="PlanMajorData&&APQParametersData.length!=0">
                        <tr>
                            <td>院校名称</td>
                            <td>专业代码</td>
                            <td>专业名称</td>
                            <td>计划人数</td>
                            <td>学费</td>
                            <td>学制（年）</td>
                            <td>批次</td>
                        </tr>
                    </table>
                    <table class="plantable" cellspacing="0" v-if="PlanMajorData.length!=0&&APQParametersData.length!=0">
                        <tr v-for="(plan,index) in PlanMajorData.PlanMajorList" :key="index">
                            <td>{{PlanMajorData.CollegeName}}</td>
                            <td>{{plan.MajorCode}}</td>
                            <td>{{plan.MajorName}}<div style="font-size:12px;">{{plan.MajorRemark}}</div></td>
                            <td>{{plan.MajorPlanPersons}}</td>
                            <td>{{plan.LearningCost}}</td>
                            <td>{{plan.LearningYears}}</td>
                            <td>{{PlanMajorData.BatchName}}</td>
                        </tr>
                    </table>
                    <div v-if="APQParametersData.length==0||PlanMajorData.length==0" class="nodata">
                        <i class="iconfont">&#xe67c;</i>
                        <span>未查询到数据！</span>
                    </div>
                    <div class="nologinorvip" v-if="!UserData">
                        <i class="el-icon-warning"></i>
                        <span class="txt">您还未登录，请先登录！</span>
                        <div class="btn" @click.prevent="$router.push('/login')">前往登录</div>
                    </div>
                    <div class="nologinorvip" v-if="UserData&&!HaveVip">
                        <i class="el-icon-warning"></i>
                        <span class="txt">你还不是会员，无法查看！</span>
                        <div class="btn" @click.prevent="$router.push('/openvip')">开通会员<i class="iconfont">&#xe76c;</i></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import { getStore,setStore,removeStore} from "@/utils/utils";
export default {
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            loading:false,
            provinceObj: {},
            APQParametersData:[],
            APQParameters:{
                ProvinceId: null,
                CollegeId: null,
                Year: null,
                SubjectType: null,
                BatchId: null,
                PlanCollegeId: null
            },
            LevelNumber:0,
            SubjectData:[],
            BatchData:[],
            PlanCollegeData:[],
            PlanMajorData:{
                CollegeName	:'',
                BatchName:'',
                PlanMajorList:[]
            },
            UserData:null,
            HaveVip:false,
            UserSubjectType:null,

            CollegeId:null,
            SearchCollegeList:[],
            ShowCollegeSearch:false,
            SearchCollegeName:'',
            SelectSearchList: []
        }
    },
    mounted(){
        let user = getStore("LoginData");
        if(user){
            this.UserData = JSON.parse(user);
            if(this.UserData.RightsList.length>0){
                for(let i= 0;i<this.UserData.RightsList.length;i++){
                    if(new Date(this.UserData.RightsList[i].ExpireAt)>new Date()){
                        this.HaveVip = true;
                        break;
                    }
                }
                if(this.HaveVip){
                    let temp = getStore('OrgProvince')
                    if (temp) {
                        this.provinceObj = JSON.parse(temp);
                        this.APQParameters.ProvinceId = this.provinceObj.ProvinceId;
                    }
                    // 获取个人信息
                    this.$store.dispatch('user/getStudentInfo').then(userInfo => {
                        if(userInfo.UserScore){
                            this.UserSubjectType = userInfo.UserScore.SubjectType;
                        }
                    });
                }
            }
            let acitem = getStore("AdmissionCollegeItem");
            if(acitem){
                acitem = JSON.parse(acitem);
                this.SearchCollegeName = acitem.CollegeNameStr;
                this.CollegeId = acitem.CollegeId;
                API.Query.SearchCollegeByName({Name:this.SearchCollegeName}).then(res => {
                    if (res.Code==0) {
                        this.SearchCollegeList = res.Data;
                        this.CheckCollegeItem(acitem.CollegeId);
                    }
                    else {
                        this.$message.error(res.Message)
                    }
                })
            }
        }
        // 收起搜索面板
        setTimeout(() => {
            let Ele1 = document.getElementById('College_Input_ID')
            let Ele2 = document.getElementById('College_Show_ID')
            let Ele3 = document.getElementById('Search_Button_ID')
            const ClickHidden = (event) => {
                
                if (event.target !== Ele1 && event.target !== Ele2 && event.target !== Ele3 && !Ele2.contains(event.target)) {
                    this.ShowCollegeSearch = false
                }
            }
            document.removeEventListener('click', ClickHidden)
            document.addEventListener('click', ClickHidden)
        }, 0)
    },
    destroyed(){
        removeStore("AdmissionCollegeItem");
    },
    methods:{
        // 筛选 搜索院校
        searchCollege() {
            if (!this.SearchCollegeName){
                this.$message.info('请输入院校');
                return;
            }
            this.Showloading();
            API.Query.SearchCollegeByName({Name:this.SearchCollegeName}).then(res => {
                if (res.Code==0) {
                    this.SearchCollegeList = res.Data;
                    this.ShowCollegeSearch = true
                    this.loading.close()
                }
                else {
                    this.$message.error(res.Message)
                     this.loading.close()
                }
            })
        },
        clearCollegeSearch() {
            this.SearchCollegeList = []
            setTimeout(() => {
                this.ShowCollegeSearch = true
            },100)
        },
        // 选中院校
        CheckCollegeItem(id){
            this.CollegeId = id;
            this.APQParameters.CollegeId = this.CollegeId;
            this.ShowCollegeSearch=false;
            this.GetAdmissionPlanQueryParameters(this.provinceObj.ProvinceId);// 获取招生计划查询参数
            setStore("AdmissionCollegeItem",{CollegeNameStr:this.SearchCollegeName,CollegeId:this.CollegeId});
        },
        // 加载
        Showloading() {
            this.loading = this.$loading({
                lock: false,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#000'
            })
        },
        // 获取招生计划查询参数
        GetAdmissionPlanQueryParameters(provinceId){
            API.Query.GetAdmissionPlanQueryParameters({ProvinceId:provinceId,CollegeId:this.CollegeId}).then(res => {
                if(res.Code==0){
                    this.APQParametersData=res.Data;
                    if(this.APQParametersData.length>0){
                        this.LevelNumber=0;
                        this.APQParameters.Year = this.APQParametersData[0].Value;
                        this.LevelNumber+=1;
                        this.GetLevelNumber(this.APQParametersData[0].SubList);// 递归参数的层级数
                        if(this.LevelNumber==3){
                            let obj1 = this.APQParametersData.find(f=>f.Value==this.APQParameters.Year);
                            if(obj1){
                                this.BatchData = obj1.SubList;
                                if(this.BatchData){
                                    this.APQParameters.BatchId = this.BatchData[0].Value;
                                    let obj2 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                                    if(obj2){
                                        this.PlanCollegeData = obj2.SubList;
                                        if(this.PlanCollegeData){
                                            this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                                        }
                                    }
                                }
                            }
                        }
                        if(this.LevelNumber==4){
                            let obj1 = this.APQParametersData.find(f=>f.Value==this.APQParameters.Year);
                            if(obj1){
                                this.SubjectData = obj1.SubList;
                                if(this.SubjectData){
                                    this.SubjectData.forEach(fe=>{
                                        fe.Value = parseInt(fe.Value);
                                    });
                                    this.APQParameters.SubjectType = this.SubjectData[0].Value;
                                    let obj2 = this.SubjectData.find(f=>f.Value==this.APQParameters.SubjectType);
                                    if(obj2){
                                        this.BatchData = obj2.SubList;
                                        if(this.BatchData){
                                            let t = this.BatchData.find(f => {
                                                if (f.SubList && f.SubList.length>0) {
                                                    return true
                                                }
                                            })
                                            if (!t) {
                                                this.PlanMajorData = []
                                                this.PlanCollegeData = []
                                                return
                                            }
                                            this.APQParameters.BatchId = t.Value;
                                            let obj3 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                                            if(obj3){
                                                this.PlanCollegeData = obj3.SubList;
                                                if(this.PlanCollegeData){
                                                    this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.GetAdmissionPlan();//获取招生计划
                    }
                }
            });
        },
        // 递归参数的层级数
        GetLevelNumber(obj){
            if(obj&&obj.length!=0){
                this.LevelNumber+=1;
                let tempIt = obj.find(it => {
                    if (it.SubList && it.SubList.length > 0) {
                        return true
                    }
                })
                if (!tempIt) {
                    tempIt = obj[0]
                }
                this.GetLevelNumber(tempIt.SubList);
            }
        },
        //获取招生计划
        GetAdmissionPlan(){
            API.Query.GetAdmissionPlan(this.APQParameters).then(plan => {
                this.PlanMajorData=[];
                if(plan.Code==0){
                    if(plan.Data){
                        this.PlanMajorData = plan.Data;
                    }
                }
            });
        },
        // 参数切换 年份
        APQParametersChangeYear(){
            if(this.LevelNumber==3){
                let obj1 = this.APQParametersData.find(f=>f.Value==this.APQParameters.Year);
                if(obj1){
                    this.BatchData = obj1.SubList;
                    if(this.BatchData){

                        this.APQParameters.BatchId = this.BatchData[0].Value;
                        
                        let obj2 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                        if(obj2){
                            this.PlanCollegeData = obj2.SubList;
                            if(this.PlanCollegeData.length>0){
                                this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                            }
                        }
                    }
                    else{
                        this.APQParameters.BatchId = null;
                    }
                }
            }
            if(this.LevelNumber==4){
                let obj1 = this.APQParametersData.find(f=>f.Value==this.APQParameters.Year);
                if(obj1){
                    this.SubjectData = obj1.SubList;
                    if(this.SubjectData){
                        this.SubjectData.forEach(fe=>{
                            fe.Value = parseInt(fe.Value);
                        });
                        this.APQParameters.SubjectType = this.SubjectData[0].Value;
                        let obj2 = this.SubjectData.find(f=>f.Value==this.APQParameters.SubjectType);
                        if(obj2){
                            this.BatchData = obj2.SubList;
                            if(this.BatchData){
                                let t = this.BatchData.find(f => {
                                    if (f.SubList && f.SubList.length>0) {
                                        return true
                                    }
                                })
                                if (!t) {
                                    this.PlanMajorData = []
                                    this.PlanCollegeData = []
                                    return
                                }
                                this.APQParameters.BatchId = t.Value;
                                let obj3 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                                if(obj3){
                                    this.PlanCollegeData = obj3.SubList;
                                    if(this.PlanCollegeData.length>0){
                                        this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                                    }
                                }
                            }
                            else{
                                this.APQParameters.BatchId = null;
                            }
                        }
                    }
                    else{
                        this.APQParameters.SubjectType = null;
                    }
                }
            }
            this.GetAdmissionPlan();//获取招生计划
        },
        // 参数切换 科类
        APQParametersChangeSubject(){
            this.SubjectData.forEach(fe=>{
                fe.Value = parseInt(fe.Value);
            });
            let obj2 = this.SubjectData.find(f=>f.Value==this.APQParameters.SubjectType);
            if(obj2){
                this.BatchData = obj2.SubList;
                if(this.BatchData){
                    let t = this.BatchData.find(f => {
                        if (f.SubList && f.SubList.length>0) {
                            return true
                        }
                    })
                    if (!t) {
                        this.PlanMajorData = []
                        this.PlanCollegeData = []
                        return
                    }
                    this.APQParameters.BatchId = t.Value;
                    let obj3 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
                    if(obj3){
                        this.PlanCollegeData = obj3.SubList;
                        if(this.PlanCollegeData.length>0){
                            this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                        }
                    }
                }
                else{
                    this.APQParameters.BatchId =null;
                }
            }
            this.GetAdmissionPlan();//获取招生计划
        },
        // 参数切换 批次
        APQParametersChangeBatch(){
            let obj2 = this.BatchData.find(f=>f.Value==this.APQParameters.BatchId);
            if(obj2){
                this.PlanCollegeData = obj2.SubList;
                if(this.PlanCollegeData.length>0){
                    this.APQParameters.PlanCollegeId = this.PlanCollegeData[0].Value;
                }
            }
            this.GetAdmissionPlan();//获取招生计划
        },
        // 参数切换 计划
        APQParametersChangePlan(){
            this.GetAdmissionPlan();//获取招生计划
        },
    }
}
</script>
<style scoped lang="less">
.plan_con_box{
    max-width: 1420px;
    margin: auto;
    .con_div{
        margin: 30px 20px;
        .college_con{
            padding: 0px 0px 30px;
            .searchl{
                position: relative;
                width: calc(100% - 210px);
                float: left;
                input{
                    display: block;
                    width: 100%;
                    height: 100%;
                    line-height: 38px;
                    border: none;
                    line-height: 1;
                    text-indent: 20px;
                    color: #666;
                    font-size: 14px;
                    background-color: #fff;
                }
                i{
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    z-index: 1;
                    width: 38px;
                    height: 100%;
                    text-align: center;
                    line-height: 38px;
                    color: #999;
                    font-size: 16px;
                }
                i:hover{
                    cursor: pointer;
                    color: #666;
                }
                .search-box {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 9;
                    width: 100%;
                    min-height: 100px;
                    max-height: 300px;
                    border: 1px solid #f0f0f0;
                    border-radius: 3px;
                    box-shadow: 0 0 10px #c3c3ed;
                    background-color: #fff;
                    font-size: 14px;
                    padding: 10px 0 20px;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #f1f1f1;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #e7e7e7;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: #d5d4d4;
                    }
                    .search-li {
                        padding: 7px 20px 7px 30px;
                        cursor: pointer;
                        color: #616060;
                        font-size: 15px;
                        border-bottom: 1px solid #f3eeee;
                        position: relative;
                        &:hover {
                            color: rgba(var(--themecolor),1);
                        }
                        .selected-icon {
                            position: absolute;
                            left: 20px;
                            color: rgba(var(--themecolor),1);
                        }
                    }
                    .no-data {
                        padding-top: 40px;
                        text-align: center;
                        color: #999;
                    }
                }
            }
            .s_btn{
                width: 200px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                font-size: 15px;
                border-radius: 3px;
                background-color: rgba(var(--themecolor),0.75);
                float: right;
                transition: all 0.2s;
                -webkit-transition: all 0.2s;
                i{
                    margin-right: 10px;
                }
            }
            .s_btn:hover{
                cursor: pointer;
                background-color: rgba(var(--themecolor),1);
            }
        }
        .tab_con{
            min-height: 500px;
            border: 1px solid #d1d1d1;
            border-radius: 5px;
            background-color: #fff;
            position: relative;
            overflow: hidden;
            .q_box{
                margin: 10px 20px 0;
                height: 60px;
                user-select: none;
                .zs_sf{
                    color: #666;
                    font-size: 14px;
                    line-height: 60px;
                    float: left;
                    margin-right: 20px;
                }
                .plan_select{
                    float: left;
                    margin-left: 10px;
                    margin-top: 13px;
                    user-select: none;
                }
                .plan_select.year{
                    width: 80px;
                }
                .plan_select.batch{
                    width: 100px;
                }
                .plan_select.plan{
                    width: 180px;
                }
            }
            .planhead{
                margin: 0px 20px 0px;
                border-radius: 5px;
                background-color: #fff;
                display: block;
                table-layout: fixed;
                border: 1px solid #ddd;
                border-bottom-left-radius:0;
                border-bottom-right-radius:0;
                overflow: hidden;
                background-color: rgba(var(--themecolor),0.1);
                tr{
                    display:block;
                    td{
                        color: #555;
                        font-size: 16px;
                        padding: 15px 0px;
                        text-align: center;
                        border-left: 1px solid #ddd;
                        display: inline-block;
                    }
                    td:nth-child(1){
                        border-left: none;
                        width: 170px;
                    }
                    td:nth-child(2),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7){
                        width: 120px;
                    }
                    td:nth-child(3){
                        width: calc(100% - 776px);
                    }
                }
            }
            .plantable{
                table-layout: fixed !important;
                margin: 0px 20px 30px;
                border-radius: 5px;
                background-color: #fff;
                border: 1px solid #ddd;
                border-top: none;
                border-top-left-radius:0;
                border-top-right-radius:0;
                overflow: hidden;
                width: calc(100% - 40px);
                tr{
                    td{
                        border-left: 1px solid #ddd;
                        color: #777;
                        text-align: center;
                        border-bottom: 1px solid #ddd;
                        font-size: 14px;
                        line-height: 22px;
                        padding: 20px 10px;
                    }
                    td:first-child{
                        border-left:none;
                        width: 150px;
                    }
                    td:nth-child(2),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7){
                        width: 100px;
                    }
                }
                tr:last-child td{
                    border-bottom:none;
                }
                tr:nth-child(2n+1){
                    background-color: #fdfdfd;
                }
            }
            .nodata{
                color:#666;
                font-size:14px;
                text-align:center;
                i{
                    display: block;
                    margin: auto;
                    padding-top: 50px;
                    font-size: 60px;
                    color:#acabab;
                }
            }
            .nologinorvip{
                position: absolute;
                left: 0px;
                top: 0px;
                z-index: 1;
                background-color: rgba(255,255,255,1);
                width: 100%;
                height: 100%;
                text-align: center;
                i.el-icon-warning{
                    color: rgba(var(--themecolor),0.8);
                    font-size: 60px;
                    margin-top: 100px;
                }
                .txt{
                    color: #888;
                    font-size: 28px;
                    margin-top: 20px;
                    display: block;
                }
                .btn{
                    width: 200px;
                    height: 50px;
                    line-height: 50px;
                    background-color: rgba(var(--themecolor),0.8);
                    border-radius: 5px;
                    text-align: center;
                    color: #fff;
                    font-size: 20px;
                    cursor: pointer;
                    margin: 50px auto 0;
                    position: relative;
                    font-weight: bold;
                    letter-spacing: 2px;
                    i{
                        font-size: 34px;
                        position: absolute;
                        right: 15px;
                        top: 2px;
                        color: #ecae03;
                        font-weight: normal;
                    }
                }
                .btn:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
    }
}
</style>
<style lang="less">
.plan_con_box .el-input.is-active .el-input__inner, .plan_con_box .el-input .el-input__inner:focus{
    border: 1px solid rgba(var(--themecolor),0.75);
}
.plan_con_box .el-range-editor.is-active, .plan_con_box .el-range-editor.is-active:hover, .plan_con_box .el-select .el-input.is-focus .el-input__inner{
    border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
    color: rgba(var(--themecolor),1);
}
</style>