export default {
	genderList: [
		{
			name: '男',
			value: true
		},
		{
			name: '女',
			value: false,
		}
	],
	marryList: [
		{
			name: '未婚',
			value: false
		},
		{
			name: '已婚',
			value: true
		}
	],
	bloodList: [
		{
			name: 'A',
			value: 'A'
		},
		{
			name: 'B',
			value: 'B'
		},
		{
			name: 'AB',
			value: 'AB'
		},
		{
			name: 'O',
			value: 'O'
		},
	],
	personalityTypeList: [
		{
			name: '外向',
			value: '外向'
		},
		{
			name: '内向',
			value: '内向'
		}
	],
	scoreOptionList: [
		{
			name: '年级',
			width: '10.1%',
			value: 'ExamType'
		},
		{
			name: '语文',
			width: '6%',
			value: 'Chinese'
		},
		{
			name: '数学',
			width: '6%',
			value: 'Math'
		},
		{
			name: '外语',
			width: '6%',
			value: 'English'
		},
		{
			name: '物理',
			width: '6%',
			value: 'Physics'
		},
		{
			name: '化学',
			width: '6%',
			value: 'Chemistry'
		},
		{
			name: '生物',
			width: '6%',
			value: 'Biology'
		},
		{
			name: '政治',
			width: '6%',
			value: 'Politics'
		},
		{
			name: '历史',
			width: '6%',
			value: 'History'
		},{
			name: '地理',
			width: '6%',
			value: 'Geology'
		},
		{
			name: '音乐',
			width: '6%',
			value: 'Music'
		},
		{
			name: '美术',
			width: '6%',
			value: 'Art'
		},
		{
			name: '体育',
			width: '6%',
			value: 'Athletics'
		},
		{
			name: '科学技术',
			width: '9.71%',
			value: 'Technology'
		},
		{
			name: '操作',
			width: '8.8%',
		},
	],
	scoreContentType: [
		{
			order: 15,
			type: 'delete'
		}
	],
	competitionOptionList: [
		{
			name: '时间',
			width: '20.1%',
			value: 'ContestDate'
		},
		{
			name: '比赛项目名称',
			width: '30.53%',
			value: 'ContestName'
		},
		{
			name: '获得证书',
			width: '23.75%',
			value: 'Certification'
		},
		{
			name: '授权单位及证明',
			width: '17.4%',
			value: 'ProofUrl'
		},
		{
			name: '操作',
			width: '8.8%'
		}
	],
	competitionContentType: [
		{
			order:1,
			type:'date',
		},
    {
      order:2,
      type:'textarea'
    },
		{
			order:4,
			type:'upload',
			api: '/api/Upload/FileUpload'
		},
		{
			order: 5,
			type: 'delete'
		}
	],
	professionOption: [
		/* {
			name: '序号',
			width: '11.77%',
			value: 'Sort'
		}, */
		{
			name: '职业愿望',
			width: '33.48%',
			value: 'CareerWishName'
		},
		{
			name: '生活城市愿望',
			width: '33.71%',
			value: 'CityWishName'
		},
		{
			name: '备注',
			width: '24.5%',
			value: 'Description'
		},
		{
			name: '操作',
			width: '8.8%',
		}
	],
	professionContentType: [
		{
			order: 4,
			type: 'delete'
		}
	],
	// 大学愿望
	collegeOption: [
		/* {
			name: '序号',
			width: '11.77%',
			value: 'Sort'
		}, */
		{
			name: '大学名称',
			width: '33.48%',
			value: 'CollegeName'
		},
		{
			name: '专业名称',
			width: '33.71%',
			value: 'SpecialtyName'
		},
		{
			name: '备注',
			width: '24.5%',
			value: 'Description'
		},
		{
			name: '操作',
			width: '8.8%',
		}
	],
	collegeContentType: [
		{
			order: 4,
			type: 'delete'
		}
	],

	PlanTypeList: [{
			name: '远期',
			value: 1
		},
		{
			name: '中期',
			value: 2
		},
		{
			name: '短期',
			value: 3
		}
	],
	// 父亲:1;母亲:2;亲戚:3;朋友:4;老师:5;同学:6;领导:7;其他:8;
	RelationshipList: [
		{
			name:'父亲',
			value:'1'
		},
		{
			name:'母亲',
			value:'2'
		},
		{
			name:'亲戚',
			value:'3'
		},
		{
			name:'朋友',
			value:'4'
		},
		{
			name:'老师',
			value:'5'
		},
		{
			name:'同学',
			value:'6'
		},
		{
			name:'领导',
			value:'7'
		},
		{
			name:'其他',
			value:'8'
		},

	],
	classTypeList:[
		{
			name:'高一',
			value:10
		},
		{
			name:'高二',
			value:11
		},
		{
			name:'高三',
			value:12
		},
	]

}
