import { render, staticRenderFns } from "./admissioncollegeandmajor.vue?vue&type=template&id=61f01296&scoped=true&"
import script from "./admissioncollegeandmajor.vue?vue&type=script&lang=js&"
export * from "./admissioncollegeandmajor.vue?vue&type=script&lang=js&"
import style0 from "./admissioncollegeandmajor.vue?vue&type=style&index=0&id=61f01296&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f01296",
  null
  
)

export default component.exports