<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 专业 -->
        <div class="major_box">
            <div class="major_con">
                <!-- 搜索专业 -->
                <div class="searchbox">
                    <div class="tab_box">
                        <div class="tabs" :class="MajorScreenSelectLevel==1?'on':''" @click.prevent="TypeCheck(1)">本科</div>
                        <div class="tabs" :class="MajorScreenSelectLevel==0?'on':''" @click.prevent="TypeCheck(0)">专科</div>
                    </div>
                    <div class="stxt">
                        <el-input id="Major_Input_ID" placeholder="搜索专业" v-model="MajorName" @keyup.enter.native="searchMajor" @focus="ShowMajorSearch=true" clearable @clear="clearMajorSearch"></el-input>
                        <div v-show="ShowMajorSearch" id="Major_Show_ID" class="search-box">
                            <div class="search-li" v-for="item in SearchMajorList" :key="item.Id" @click="SelectSearchItem(item.Id)">
                                <span class="eltxt e1" v-if="item.EducationLevel==1">本科</span><span v-else class="eltxt e2">专科</span>{{ item.Name }}
                            </div>
                            <div class="no-data" v-if="SearchMajorList.length <= 0">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </div>
                    <div id="Search_Button_ID" class="s_btn" @click="searchMajor"><i class="el-icon-search"></i>搜 索</div>
                    <div class="clear"></div>
                </div>
                <!-- 列表 -->
                <div class="major_list">
                    <div class="ttable">
                        <div class="item" v-for="(item,index) in MajorTypeData" :key="index">
                            <div class="type1">{{item.Name}}</div>
                            <div class="type2">
                                <span v-for="(it,ind) in item.SubList" :key="ind" @click.prevent="MajorTypeCheck(it.Id)">{{it.Name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="ad_con">
                        <!-- 测评 -->
                        <div class="zntb_box" @click.prevent="ToZhcp()">
                            <div class="img">
                                <img src="../../../assets/images/ad-zongheceping.png" alt="" title="点击进入专业定位综合测评"/>
                            </div>
                        </div>
                        <!-- 热门专业 -->
                        <div class="rmyx_box">
                            <div class="rtit"><i></i><span>热门专业</span><div class="clear"></div></div>
                            <div class="rlist">
                                <div class="ra" v-for="(item,index) in MajorHotData" :key="index" @click.prevent="MajorDetail(item.Id)">
                                    <div class="ico"><i class="iconfont" v-if="index<3">&#xe64e;</i><span>{{index+1}}</span></div>
                                    <div class="txt nowrap">{{ item.MajorName }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="major_order" @click.prevent="toMakorOrder()">
                            <i class="iconfont">&#xe66d;</i>
                            <div class="name">专业排行榜</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import { getStore, setStore, removeStore } from "@/utils/utils";
export default {
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            loading:false,
            MajorScreenSelectLevel:1,
            MajorTypeData: [],

            MajorName:'',
            ShowMajorSearch: false, // 搜索面板
            SearchMajorList: [],
            MajorHotData:[]
        }
    },
    mounted(){
        this.initial();
    },
    methods:{
        initial(){
            this.GetMajorTypeData()// 获取专业类别数据
            this.GetMajorHotList();// 获取热门专业列表
            // 收起搜索面板
            setTimeout(() => {
                let Ele1 = document.getElementById('Major_Input_ID')
                let Ele2 = document.getElementById('Major_Show_ID')
                let Ele3 = document.getElementById('Search_Button_ID')
                const ClickHidden = (event) => {
                    
                    if (event.target !== Ele1 && event.target !== Ele2 && event.target !== Ele3 && !Ele2.contains(event.target)) {
                        this.ShowMajorSearch = false
                    }
                }
                document.removeEventListener('click', ClickHidden)
                document.addEventListener('click', ClickHidden)
            }, 0)
        },
        // 本科 专科切换
        TypeCheck(val){
            this.MajorScreenSelectLevel = val;
            this.GetMajorTypeData()// 获取专业类别数据
        },
        clearMajorSearch() {
            this.SearchMajorList = []
            setTimeout(() => {
                this.ShowMajorSearch = true
            },100)
        },
        //选中搜索专业
        SelectSearchItem(id) {
            this.$router.push("/query/majordetail?id="+id);
        },
        // 搜索专业
        searchMajor() {
            if (!this.MajorName){
                this.$message.info('请输入专业');
                return;
            }
            this.Showloading();
            API.Query.SearchMajorByName({Name:this.MajorName}).then(res => {
                if (res.Code==0) {
                    this.SearchMajorList = res.Data;
                    this.ShowMajorSearch = true;
                    this.loading.close()
                } else {
                    this.$message.error(res.message)
                    this.loading.close()
                }
            })
        },
        // 获取专业类别数据
        GetMajorTypeData() {
            API.Web.GetMajorCategoryTreeList({EducationLevel:this.MajorScreenSelectLevel}).then(res => {
                this.MajorTypeData = res.data;
            })
        },
        // 选择专业类别
        MajorTypeCheck(id){
            this.$router.push('/query/majorlist?id='+id);
        },
        // 加载
        Showloading() {
            this.loading = this.$loading({
                lock: false,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#000'
            })
        },
        ToZhcp(){
            this.$router.push("/allround");
        },
        //获取热门专业列表
        GetMajorHotList(){
            API.Query.GetMajorHotList(10).then(res => {
                this.MajorHotData = res.Data;
            })
        },
        MajorDetail(id){
            this.$router.push("/query/majordetail?id="+id);
        },
        toMakorOrder(){
            this.$router.push("/query/majorrank");
        }
    }
}
</script>
<style scoped lang="less">
.major_box{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .major_con{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .searchbox{
            padding: 15px 10px 0;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            height: 46px;
            .tab_box{
                float: left;
                width: 260px;
                border-left: 1px solid #ddd;
                border-top-left-radius: 5px;
                .tabs{
                    height: 45px;
                    line-height: 45px;
                    display: inline-block;
                    border-right: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                    color: #666;
                    font-size: 16px;
                    padding: 0 40px;
                    background-color: rgba(var(--themecolor),0.08);
                }
                .tabs:hover{
                    cursor: pointer;
                    color: #555;
                }
                .tabs.on{
                    border-bottom: 1px solid #fff;
                    background-color:#fff;
                }
                .tabs:first-child{
                    border-top-left-radius: 5px;
                }
                .tabs:last-child{
                    border-top-right-radius: 5px;
                }
            }
            .stxt{
                position: relative;
                float: left;
                width: calc(100% - 475px);
                height: 40px;
                input{
                    display: block;
                    width: 100%;
                    height: 100%;
                    line-height: 38px;
                    border: none;
                    line-height: 1;
                    text-indent: 20px;
                    color: #666;
                    font-size: 14px;
                    background-color: #fff;
                }
                i{
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    z-index: 1;
                    width: 38px;
                    height: 100%;
                    text-align: center;
                    line-height: 38px;
                    color: #999;
                    font-size: 16px;
                }
                i:hover{
                    cursor: pointer;
                    color: #666;
                }
            }
            .search-box {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 9;
                width: 100%;
                min-height: 100px;
                max-height: 300px;
                border: 1px solid #f0f0f0;
                border-radius: 3px;
                box-shadow: 0 0 10px #c3c3ed;
                background-color: #fff;
                font-size: 14px;
                overflow: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #f1f1f1;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #e7e7e7;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background-color: #d5d4d4;
                }
                .search-li {
                    padding: 6px 20px 6px 30px;
                    cursor: pointer;
                    color: #616060;
                    font-size: 15px;
                    border-bottom: 1px solid #f3eeee;
                    position: relative;
                    &:hover {
                        color: rgba(var(--themecolor),1);
                    }
                    .eltxt{
                        margin-right: 20px;
                        border: 1px solid #ddd;
                        padding: 1px 8px;
                        font-size: 12px;
                        border-radius: 20px;
                    }
                    .e1{
                        color: #c24949;
                    }
                    .e2{
                        color: #0492d4;
                    }
                }
                .no-data {
                    padding-top: 40px;
                    text-align: center;
                    color: #999;
                }
            }
            .s_btn{
                width: 200px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                font-size: 15px;
                border-radius: 3px;
                background-color: rgba(var(--themecolor),0.75);
                float: right;
                transition: all 0.2s;
                -webkit-transition: all 0.2s;
                i{
                    margin-right: 10px;
                }
            }
            .s_btn:hover{
                cursor: pointer;
                background-color: rgba(var(--themecolor),1);
            }
        }
        .major_list{
            width: 100%;
            padding: 10px 0;
            min-height: 300px;
            background-color: #fff;
            .ttable{
                width: calc(100% - 342px);
                float:left;
                border: 1px solid #ddd;
                border-radius: 5px;
                margin: 10px 0 10px 10px;
                min-height: 600px;
                .item{
                    border-bottom: 1px solid #ddd;
                    color: #666;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    .type1{
                        width: 200px;
                        display: inline-block;
                        text-align: center;
                    }
                    .type2{
                        width: calc(100% - 201px);
                        border-left: 1px solid #ddd;
                        display: inline-block;
                        padding-bottom: 20px;
                        padding-top: 10px;
                        span{
                            padding: 2px 12px;
                            margin: 10px 0 0 10px;
                            border: 1px solid #e7e7e7;
                            border-radius: 20px;
                            background-color: #fff;
                            display: inline-block;
                        }
                        span:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor),0.8);
                            border: 1px solid rgba(var(--themecolor),0.5);
                        }
                    }
                }
                .item:last-child{
                    border-bottom: none;
                }
            }
            .ad_con{
                width: 310px;
                float:right;
                margin-right:10px;
                margin-top:10px;
                .zntb_box{
                    user-select: none;
                    width: 100%;
                    height: 132px;
                    border-radius:5px;
                    overflow: hidden;
                    cursor: pointer;
                    position: relative;
                    .img{
                        width:100%;
                        height: 132px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        background:linear-gradient(to right, rgba(var(--themecolor),0.6), rgba(var(--themecolor),0.8));
                        transition: all 0.25s;
                        -webkit-transition: all 0.25s;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
                .zntb_box:hover .img{
                    transform: scale(1.08);
                    -webkit-transform: scale(1.08);
                }
                .rmyx_box{
                    border: 1px solid #ebebeb;
                    margin-top: 20px;
                    background-color: #fff;
                    overflow: hidden;
                    border-radius: 5px;
                    padding-bottom: 20px;
                    .rtit{
                        border-bottom: 1px solid #ebebeb;
                        height: 40px;
                        line-height: 40px;
                        i{
                            float: left;
                            margin: 11px 15px 0px 20px;
                            width: 6px;
                            height: 19px;
                            background-color: rgb(var(--themecolor));
                        }
                        span{
                            font-size: 16px;
                            color: #555;
                            font-weight: bold;
                        }
                    }
                    .rlist{
                        padding-top: 10px;
                        .ra{
                            margin-left: 14px;
                            height: 44px;
                            line-height: 44px;
                            .ico{
                                float: left;
                                width: 30px;
                                height: 100%;
                                position: relative;
                                margin-right: 12px;
                                i{
                                    font-size: 30px;
                                    color: rgb(var(--themecolor));
                                }
                                span{
                                    font-size: 14px;
                                    position: absolute;
                                    top: 0px;
                                    left: 11px;
                                    color: #666;
                                }
                            }
                            .txt{
                                color: #555;
                                font-size: 16px;
                                float: left;
                                width: calc(100% - 56px);
                                transition: color 0.3s;
                                -webkit-transition: color 0.3s;
                            }
                        }
                        .ra:hover{
                            cursor: pointer;
                        }
                        .ra:hover .txt{
                            color: rgb(var(--themecolor));
                        }
                        .ra:nth-child(-n+3) .ico span{
                            color: #fff;
                        }
                        .ra:nth-child(1) .ico i{
                            color: #eb312d;
                        }
                        .ra:nth-child(2) .ico i{
                            color: #fe7002;
                        }
                        .ra:nth-child(3) .ico i{
                            color: #f7aa4c;
                        }
                    }
                }
                .major_order{
                    margin-top: 20px;
                    background-color: #fff;
                    overflow: hidden;
                    border-radius: 5px;
                    height: 70px;
                    line-height: 70px;
                    padding-left: 40px;
                    background: linear-gradient(to right, rgba(var(--themecolor),0.6), rgba(var(--themecolor),0.8));
                    i{
                        font-size: 46px;
                        display: inline-block;
                        color: #f1e6b1;
                    }
                    .name{
                        color: #f1f0f0;
                        font-size: 24px;
                        letter-spacing: 2px;
                        display: inline-block;
                        margin-left: 30px;
                    }
                }
                .major_order:hover{
                    cursor: pointer;
                    background: linear-gradient(to right, rgba(var(--themecolor),0.7), rgba(var(--themecolor),0.9));
                }
            }
        }
    }
}
.el-select-dropdown__item.selected{
    color: rgba(var(--themecolor),1);
}
.search_citybox{
    overflow: hidden;
    z-index: 2;
    padding: 10px 0 10px;
    .citem{
        display: inline-block;
        color: #666;
        font-size: 12px;
        padding: 2px 18px;
        border: 1px solid #e2e2e2;
        border-radius: 20px;
        margin: 0px 8px 10px;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
    }
    .citem:hover{
        border: 1px solid rgba(var(--themecolor),0.75);
        cursor: pointer;
        color: rgba(var(--themecolor),0.75);
    }
    .citem.on{
        border: 1px solid rgba(var(--themecolor),0.75);
        background-color: rgba(var(--themecolor),0.75);
        color: #fff;
    }
}
</style>
<style lang="less">
.major_box .major_con .searchbox .el-input.is-active .el-input__inner, .major_box .major_con .searchbox .el-input .el-input__inner:focus{
    border: 1px solid rgba(var(--themecolor),0.75);
}
.search_city_popover.el-popover{
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.3) !important;
}
</style>