<template>
    <!-- 忘记密码 -->
    <div class="layer_agreement" :class="layerclass" v-if="forgetpwdshow">
        <div class="layerbox forget" :class="layerboxclass">
            <div class="layercon">
                <div class="tit">重置密码</div>
                <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                    <div class="stepsbox">
                        <el-steps :active="stepactive" align-center>
                            <el-step title="验证手机"></el-step>
                            <el-step title="重置密码"></el-step>
                            <el-step title="成功"></el-step>
                        </el-steps>
                    </div>
                    <div class="clear"></div>
                    <div class="inputbox" v-if="stepactive==1">
                        <div class="ibox">
                            <input type="text" v-model="forgettelnumber" placeholder="请输入手机号" maxlength="11"/>
                        </div>
                        <div class="ibox">
                            <input class="code" v-model="forgetcode" placeholder="请输入验证码" maxlength="6"/>
                            <div class="codebtn" v-if="forgetisSend">{{forgettime}} s</div>
                            <div class="codebtn" v-else @click="forgetsendcode()">发送验证码</div>
                        </div>
                        <div class="ibox">
                            <div class="submitbtn" @click.prevent="forgetpwdfirst()">立即验证</div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="inputbox" v-if="stepactive==2">
                        <div class="ibox">
                            <input v-model="forgetaccountpwdnew" :type="forgetpwdtype" placeholder="请输入新的密码" maxlength="20"/>
                            <i v-if="forgetpwdstatus" class="iconfont" @click.prevent="forgetpwdbtn(false)">&#xe61b;</i>
                            <i v-else class="iconfont" @click.prevent="forgetpwdbtn(true)">&#xe61c;</i>
                        </div>
                        <div class="ibox">
                            <input v-model="forgetaccountpwdsure" :type="forgetpwdtype" placeholder="请再次确认密码" maxlength="20"/>
                            <i v-if="forgetpwdstatus" class="iconfont" @click.prevent="forgetpwdbtn(false)">&#xe61b;</i>
                            <i v-else class="iconfont" @click.prevent="forgetpwdbtn(true)">&#xe61c;</i>
                        </div>
                        <div class="ibox">
                            <div class="submitbtn" @click.prevent="forgetpwdsubmit()">立即重置</div>
                            <div class="submitbtn b" @click.prevent="forgetpwdback()">上一步</div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="inputbox" v-if="stepactive==3">
                        <i class="iconfont success">&#xe607;</i>
                        <div class="successtxt">恭喜您，密码已重置成功！</div>
                        <div class="marktxt"><span class="t">{{successtime}}</span> s 后自动关闭窗口</div>
                        <div class="clear"></div>
                    </div>
                </el-scrollbar>
                <div class="back" @click.prevent="forgetpwdhide()">返回</div>
                <div class="tipsbox" :class="forgettipsshowstyle"><span><i class="iconfont">&#xe64d;</i></span>{{ forgettipsshowtxt }}<div class="clear"></div></div>
            </div>
        </div>
        <!-- 拼图滑动验证 -->
        <PuzzleVerification ref="verificationShow"  :Mobile="forgettelnumber" :onSuccess="slideVerifySuccessForget"></PuzzleVerification>
    </div>
</template>
<script>
import PuzzleVerification from "@/components/PuzzleVerification.vue";
import API from "@/api/config";
export default{
    components: {
        PuzzleVerification
    },
    data(){
        return{
            forgetpwdshow:false,
            layerclass:'',
            layerboxclass:'',
            stepactive:1,
            successtime:5,
            successtimer:null,
            forgettelnumber:'',
            forgetcode:'',
            forgetisSend:false,
            forgettime: 60,
			forgettimer: null,
            forgettipsshow:false,
            forgettipsshowstyle:'',
            forgettipsshowtxt:'',
            forgetpwdstatus:true,
            forgetpwdtype:'password',
            forgetaccountpwdnew:'',
            forgetaccountpwdsure:'',
        }
    },
    methods:{
        //关闭窗口
        forgetpwdhide(){
            this.layerclass='layerhide';
            this.layerboxclass="layerboxhide";
            if(this.stepactive==3){
                this.stepactive=1;
                this.successtime=5;
                clearInterval(this.successtimer);
            }
            let st = setTimeout(()=>{
                this.forgetpwdshow=false;
                clearTimeout(st);
            },300);
        },
        //找回密码 发送验证码
        forgetsendcode(){
            if(!this.forgettipsshow){
                if (!this.forgettelnumber.trim()) {
                    this.forgetverifytips("请输入手机号！");return false;
                }
                else{
                    let str =  /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                    if(str.test(this.forgettelnumber.trim())==false){
                        this.forgetverifytips("手机号格式不正确！");return false;
                    }
                }
                this.$refs.verificationShow.isVerificationShow = true;//显示 拼图滑动验证组件
                this.$refs.verificationShow.refreshImg();//刷新 拼图滑动验证组件
            }
        },
        //拼图滑动验证成功的回调
        slideVerifySuccessForget(par) {
            this.$refs.verificationShow.isVerificationShow = false;//隐藏拼图滑动验证组件
            // 发送手机验证码
            par.ValidateType = 2;
            API.Web.SendValidateCode(par).then(res => {
                if (res.Code === 0) {
                    this.forgethandleTimer();//找回密码 验证码倒计时
                }
                else {
                    this.forgetverifytips(res.Message);
                }
            });
        },
        //找回密码 验证码倒计时
        forgethandleTimer(){
            this.forgetisSend = true;
			this.forgettimer = setInterval(() => {
				if (this.forgettime > 0) {
					this.forgettime--;
				}
				else {
					clearInterval(this.forgettimer);
					this.forgetisSend = false;
					this.forgettime = 60;
				}
			}, 1000);
        },
        //找回密码 验证提示
        forgetverifytips(txt){
            this.forgettipsshow=true;
            this.forgettipsshowtxt=txt;
            this.forgettipsshowstyle="ani_show2";
            let st = setTimeout(()=>{
                this.forgettipsshowtxt="";
                this.forgettipsshowstyle="";
                this.forgettipsshow=false;
                clearTimeout(st);
            },1500);
        },
        //找回密码 验证手机
        forgetpwdfirst(){
            if(!this.forgettipsshow){
                if (!this.forgettelnumber.trim()) {
                    this.forgetverifytips("请输入手机号！");return false;
                }
                else{
                    let str =  /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                    if(str.test(this.forgettelnumber.trim())==false){
                        this.forgetverifytips("手机号格式不正确！");return false;
                    }
                }
                if(!this.forgetcode.trim()){
                    this.forgetverifytips("请输入验证码！");return false;
                }
                this.stepactive=2;
            }
        },
        //找回密码 密码显示切换
        forgetpwdbtn(b){
            this.forgetpwdstatus=b;
            if(b){
                this.forgetpwdtype="password";
            }
            else{
                this.forgetpwdtype="text";
            }
        },
        //找回密码 重置密码
        forgetpwdsubmit(){
            if(!this.forgettipsshow){
                if(!this.forgetaccountpwdnew.trim()){
                    this.forgetverifytips("请输入新的密码！");return false;
                }
                if(!this.forgetaccountpwdsure.trim()){
                    this.forgetverifytips("请再次确认密码！");return false;
                }
                if(this.forgetaccountpwdnew.trim()!=this.forgetaccountpwdsure.trim()){
                    this.forgetverifytips("两次密码输入不一致！");return false;
                }
                API.Personal.ResetPassword({Mobile:this.forgettelnumber.trim(),ValidateCode:this.forgetcode.trim(),NewPassword:this.forgetaccountpwdnew.trim()}).then(res => {
                    if (res.Code === 0) {
                        this.stepactive=3;
                        this.forgettelnumber='';
                        this.forgetcode='';
                        this.forgetaccountpwdnew='';
                        this.forgetaccountpwdsure='';
                        this.successhandleTimer();
                    }
                    else {
                        this.forgetverifytips(res.Message);
                    }
                });
            }
        },
        //找回密码 返回上一步
        forgetpwdback(){
            this.stepactive=1;
        },
        //找回密码 成功关闭倒计时
		successhandleTimer(){
			this.successtimer = setInterval(() => {
				if (this.successtime > 1) {
					this.successtime--;
				}
				else {
					clearInterval(this.successtimer);
                    this.forgetpwdhide();
				}
			}, 1000);
		},
    }
}
</script>
<style lang="less" scoped>
.layer_agreement.layershow{
    animation:layershow 0.3s ease 0s 1;
    -webkit-animation:layershow 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes layershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    @keyframes layershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
}
.layer_agreement.layerhide{
    animation:layerhide 0.3s ease 0s 1;
    -webkit-animation:layerhide 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes layerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
    @keyframes layerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
}
.layer_agreement{
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    background-color: rgba(0,0,0,0.5);
    .layerbox.layerboxshow{
        animation:layerboxshow 0.3s ease 0s 1;
        -webkit-animation:layerboxshow 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes layerboxshow {
            from {top: 0px;}
            to {top: 40px;}
        }
        @keyframes layerboxshow {
            from {top: 0px;}
            to {top: 40px;}
        }
    }
    .layerbox.layerboxhide{
        animation:layerboxhide 0.3s ease 0s 1;
        -webkit-animation:layerboxhide 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes layerboxhide {
            from {top: 40px;}
            to {top: 0px;}
        }
        @keyframes layerboxhide {
            from {top: 40px;}
            to {top: 0px;}
        }
    }
    .layerbox.forget{
        height: 600px;
    }
    .layerbox{
        width: 700px;
        height: calc(100% - 80px);
        position: absolute;
        top: 0px;
        left: calc(50% - 350px);
        background-color: #fff;
        overflow: hidden;
        position: relative;
        border-radius: 5px;
        .layercon{
            width: 100%;
            height: 100%;
            position: relative;
            .tipsbox{
                position: absolute;
                width: 440px;
                height: 44px;
                line-height: 44px;
                color: #F56C6C;
                text-indent: 10px;
                font-size: 14px;
                top: 0px;
                left: 130px;
                z-index: 2;
                opacity: 0;
                background-color: #fef0f0;
                border:1px solid #fde2e2;
                border-radius: 5px;
                span{
                    width: 12px;
                    height: 12px;
                    display: block;
                    float: left;
                    margin-left: 15px;
                    margin-top: 16.5px;
                    background-color: #F56C6C;
                    line-height: 5.5px;
                    text-indent: 3.5px;
                    border-radius: 10px;
                    i{
                        font-size: 6px;
                        color: #fff;
                    }
                }
            }
            .tipsbox.ani_show2{
                animation:ani_show2 1.5s ease-out 0s 1;
                -webkit-animation:ani_show2 1.5s ease-out 0s 1;
                animation-fill-mode:forwards;
                -webkit-animation-fill-mode:forwards;
                @-webkit-keyframes ani_show2 {
                    0% {top: 0px;opacity: 0;}
                    10% {top: 160px;opacity: 1;}
                    90% {top: 160px;opacity: 1;}
                    100% {top: 0px;opacity: 0;}
                }
                @keyframes ani_show2 {
                    0% {top: 0px;opacity: 0;}
                    10% {top: 160px;opacity: 1;}
                    90% {top: 160px;opacity: 1;}
                    100% {top: 0px;opacity: 0;}
                }
            }
            .scrollbox{
                margin: 0px 17px 0 13px;
                height: calc(100% - 120px);
                overflow: hidden;
                .stepsbox{
                    width: 100%;
                    margin-top: 40px;
                    user-select: none;
                }
                .inputbox{
                    margin: 50px 50px 0;
                    i.success{
                        color: #159405;
                        font-size: 100px;
                        display: block;
                        text-align: center;
                        padding-top: 20px;
                    }
                    .successtxt{
                        color: #666;
                        font-size: 18px;
                        text-align: center;
                        margin-top: 10px;
                    }
                    .marktxt{
                        color: #888;
                        font-size: 14px;
                        margin-top: 10px;
                        text-align: center;
                    }
                    .ibox{
                        width: 100%;
                        height: 50px;
                        position: relative;
                        margin-bottom: 20px;
                        input{
                            width: 100%;
                            height: 100%;
                            line-height: 1;
                            line-height: 48px;
                            text-indent: 15px;
                            border: 1px solid #dddddd;
                            color: #666;
                            font-size: 14px;
                        }
                        input:focus{
                            border: 1px solid rgb(var(--themecolor));
                        }
                        i{
                            position: absolute;
                            width: 40px;
                            height: 48px;
                            right: 1px;
                            top: 1px;
                            line-height: 50px;
                            text-align: center;
                            color: #999;
                            font-size: 18px;
                            background-color: #fff;
                            border-radius: 5px;
                        }
                        i:hover{
                            cursor: pointer;
                            opacity: 0.6;
                            color: rgb(var(--themecolor));
                        }
                        .code{
                            width: 420px;
                        }
                        .codebtn{
                            width: 100px;
                            height: 50px;
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            line-height: 50px;
                            text-align: center;
                            color: #666;
                            font-size: 14px;
                            border: 1px solid #d8d8d8;
                            user-select: none;
                        }
                        .codebtn:hover{
                            cursor: pointer;
                            color: rgb(var(--themecolor));
                        }
                        .submitbtn{
                            margin-top: 40px;
                            color: #fff;
                            height: 50px;
                            line-height: 50px;
                            font-size: 18px;
                            font-weight: bold;
                            text-align: center;
                            background-color: rgb(var(--themecolor));
                            opacity: 0.75;
                            border-radius: 5px;
                            user-select: none;
                            transition: opacity 0.2s;
                            -webkit-transition: opacity 0.2s;
                        }
                        .submitbtn.b{
                            background-color: #ddd;
                            color: #888;
                        }
                        .submitbtn:hover{
                            opacity: 0.9;
                            cursor: pointer;
                        }
                    }
                }
            }
            .tit{
                text-align: center;
                height: 60px;
                line-height: 60px;
                color: #333;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 1px;
                border-bottom: 1px solid #e8e9e9;
            }
            .back{
                width: 100%;
                height: 44px;
                line-height: 44px;
                position: absolute;
                bottom: 0;
                left: 0px;
                z-index: 66;
                background-color: #ddd;
                color: #666;
                font-size: 14px;
                letter-spacing: 2px;
                text-align: center;
                user-select: none;
            }
            .back:hover{
                cursor: pointer;
                opacity: 0.85;
                color: rgb(var(--themecolor));
            }
        }
    }
}
</style>
<style>
.layer_agreement .layerbox.forget .layercon .el-scrollbar__wrap{
    overflow-x: hidden;
}
.layer_agreement .layerbox.forget .layercon .el-step__head.is-finish{
    color: rgba(var(--themecolor),0.8);
    border-color: rgba(var(--themecolor),0.8);
}
.layer_agreement .layerbox.forget .layercon .el-step__title.is-finish{
    color: rgba(var(--themecolor),1);
}
.layer_agreement .layerbox.forget .layercon .el-step__title.is-process{
    color: #666;
}
.layer_agreement .layerbox.forget .layercon .el-step__head.is-process{
    color: #666;
    border-color: #666;
}
</style>