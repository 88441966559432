<template>
  <div class="mainBox">
    <div class="planBody">
      <div class="head">
        <div class="headerTitle">目标定位</div>
      </div>
      <div class="planBodyMain">
        <div class="commonBox">
          <div class="titleBox">职业目标：</div>
          <div class="textBox">
            <el-input
              type="textarea"
              class="textarea"
              v-model="mainData.TargetName"
            ></el-input>
          </div>
        </div>
        <div class="commonBox">
          <div class="titleBox">目标描述：</div>
          <div class="textBox">
            <el-input
              type="textarea"
              class="textarea"
              v-model="mainData.Description"
            ></el-input>
          </div>
        </div>
        <div class="commonBox">
          <div class="titleBox">目标备注：</div>
          <div class="textBox">
            <el-input
              type="textarea"
              class="textarea"
              v-model="mainData.Remark"
            ></el-input>
          </div>
        </div>
        <div style="width:100%;height:30px;"></div>
        <div class="commonBox">
          <div class="titleBox">目标分解:</div>
          <div class="tableBox">
            <div class="table-head">
              <div class="headTextBox">
                <div class="headtext headtext1">类型</div>
                <div class="headtext headtext2">目标描述</div>
                <div class="headtext headtext2">目标备注</div>
                <div class="headtext">开始日期</div>
                <div class="headtext">结束日期</div>
                <div class="headtext headtext4">操作</div>
              </div>
              <div class="addbtn">
                <div @click="EvaluationListAdd" class="addbtnText"><i class="el-icon-plus"></i> 添加</div>
              </div>
            </div>
            <div class="table-body">
              <div
                class="bodyTr"
                @click="selectIndex = index"
                :class="{
                  bodyTrBot: mainData.TargetAchieveList.length > 1,
                }"
                v-for="(item, index) in mainData.TargetAchieveList"
                :key="index"
              >
                <div class="bodyTd bodyTd1">
                  <el-select v-model="item.TermType" placeholder="请选择">
                    <el-option
                      v-for="item in PlanTypeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="bodyTd bodyTd2">
                  <el-input
                    type="textarea"
                    class="textarea"
                    v-model="item.Description"
                    
                  ></el-input>
                </div>
                <div class="bodyTd bodyTd2">
                  <el-input
                    type="textarea"
                    class="textarea"
                    v-model="item.Remark"
                  ></el-input>
                </div>
                <div class="bodyTd">
                  <el-date-picker
                    v-model="item.DateBegin"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    class="uni-input"
                  >
                  </el-date-picker>
                </div>
                <div class="bodyTd">
                  <el-date-picker
                    v-model="item.DateEnd"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    class="uni-input"
                  >
                  </el-date-picker>
                </div>
                <div class="bodyTd bodyTd4">
                  <span class="delete" @click="handDelete(index)">移除</span>
                </div>
              </div>
              <template
                v-if="!mainData.TargetAchieveList || mainData.TargetAchieveList.length === 0"
              >
                <div class="notDataDiv">暂无数据</div>
              </template>
            </div>
          </div>
        </div>
        <div class="submitBtn" @click="submitQuery">保存表格</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/config";
import local_json from "../my-components/private_info_json.js";
import { getStore } from "@/utils/utils";
export default {
  components: {},
  data() {
    return {
      //图片地址
      imagesBaseURL: this.$store.state.imagesBaseURL,
      PlanTypeList: local_json.PlanTypeList,
      selectIndex: 0,
      mainData: {
        UserId:null,
        TargetName: "",
        Description: "",
        Remark: "",
        TargetAchieveList: []
      },
      UserId:null
    };
  },
  mounted() {
    let user = getStore("LoginData");
    if(user){
      user=JSON.parse(user);
      this.UserId = user.UserId;
      this.initialize();
    }
    else{
      this.$message({ type: 'info', message: '请先登录!' });
      this.$router.push('/login');
    }
  },
  methods: {
    submitQuery() {
      this.mainData.UserId = this.UserId;
      API.CareerPlan.SetTarget(this.mainData).then((res) => {
        if(res.Code==0){
          this.$message.success("保存成功");
        }
        else{
          this.$message.error(res.Message);
        }
      });
    },
    // 初始化获取数据
    initialize() {
      API.CareerPlan.GetTargetDetail({UserId:this.UserId}).then((res) => {
        if (res.Data) {
          this.mainData = res.Data;
        }
      });
    },
    EvaluationListAdd() {
      this.mainData.TargetAchieveList.push({
        TermType: 1,
        DateBegin: "",
        DateEnd: "",
        Description: "",
        Remark: ""
      });
    },
    handleSelect(val) {
      this.mainData.TargetAchieveList[this.selectIndex].TargetName =
        this.PlanTypeList[val.detail.value].name;
    },
    selectStartData(val) {
      this.mainData.TargetAchieveList[this.selectIndex].DateBegin = val.detail.value;
    },
    selectStartEndDate(val) {
      this.mainData.TargetAchieveList[this.selectIndex].DateEnd = val.detail.value;
    },
    handDelete(index) {
      this.mainData.TargetAchieveList.splice(index, 1);
    }
  },
};
</script>

<style lang="less" scoped>
.mainBox {
  max-width: 1420px;
  margin: auto;
}
.planBody {
  background: #eef2f6;
  border-radius: 20px;
  margin: 30px 20px;
  padding: 30px 20px;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  .headerTitle {
    font-size: 34px;
    line-height: 48px;
    color: #31415e;
  }
}

.commonBox {
  .titleBox {
    line-height: 28px;
    color: #666;
    font-weight: bold;
    margin: 8px 0px;
  }
  .textBox {
    width: 100%;
    line-height: 1.5;
    min-height: 48px;
    .textarea {
      background: #eef2f6;
      width: 100%;
    }
  }

  .tableBox {
    width: 100%;
    color: #a8c2ff;
    font-size: 14px;
    position: relative;
    z-index: 1;
    .addbtn {
      position: absolute;
      top: -57px;
      right: 0;
      width: 120px;
      color: #666;
      font-size: 16px;
      height: 44px;
      text-align: center;
      border-radius: 5px;
      font-weight: bold;
      z-index: -1;
      cursor: pointer;
      border: 1px solid #ddd;
      .addbtnText {
        line-height: 44px;
        height: 44px;
      }
    }
    .addbtn:hover{
      color: rgba(var(--themecolor),0.8);
      border: 1px solid rgba(var(--themecolor),0.8);
    }
    .table-head {
      width: 100%;
      background: rgba(var(--themecolor),0.8);
      border-radius: 8px 8px 0px 0px;
      padding: 10px 0px;

      .headTextBox {
        width: 100%;
        display: flex;
      }
    }

    .headtext {
      width: 16%;
      height: 24px;
      line-height: 24px;
      color: #eef2f6;
      text-align: center;
      border-left: #ddd 1px solid;
    }

    .headtext1 {
      width: 10%;
      border-left: none;
    }

    .headtext2 {
      width: 24%;
    }

    .headtext4 {
      width: 10%;
    }

    .table-body {
      width: 100%;
      background: rgba(var(--themecolor),0.1);
      border-radius: 0px 0px 8px 8px;

      .bodyTr {
        display: flex;

        .bodyTd {
          width: 16%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          padding: 10px 4px;
          height: auto;
          // min-height: 54px;
          border-left: #ddd 1px solid;
          .textarea {
            width: 100%;
            text-align: left;
          }
          .delete{
            display: inline-block;
            padding: 4px 15px;
            color: #666;
            font-size: 14px;
            cursor: pointer;
            border: 1px solid #d1d0d0;
            border-radius: 5px;
          }
          .delete:hover{
            color: rgba(var(--themecolor),1);
            border: 1px solid rgba(var(--themecolor),0.6);
          }
        }

        .bodyTd1 {
          width: 10%;
          border-left: none;
        }

        .bodyTd2 {
          width: 24%;
        }

        .bodyTd4 {
          width: 10%;
        }
      }

      .bodyTrBot {
        border-bottom: #ddd 1px solid;
      }
    }
  }
}

.submitBtn {
  width: 200px;
  height: 48px;
  background: rgba(var(--themecolor),0.8);
  box-shadow: 0px 10px 20px rgba(50, 113, 225, 0.4);
  opacity: 1;
  border-radius: 8px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin: 30px auto 0;
}
.submitBtn:hover{
  background: rgba(var(--themecolor),1);
}
.notDataDiv {
  height: 48px;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  color: #31415e;
  opacity: 0.4;
}

.footerText {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 22px;
  color: #ffffff;
  margin: 40px 0;
}


</style>
<style>
.planBodyMain .commonBox .tableBox .table-body .bodyTd .el-button--text{
  color: #666;
}
.el-button:focus, .el-button:hover{
  color: rgba(var(--themecolor),0.8);
  border-color: #ddd;
}
.el-button--primary:focus{
  background: rgba(var(--themecolor),0.8);
  border-color: rgba(var(--themecolor),0.8);
  color: #fff;
}
.el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
  color: #fff;
}
.el-button--primary{
  background: rgba(var(--themecolor),0.8);
  border-color: rgba(var(--themecolor),0.8);
}
.el-message-box__headerbtn:focus .el-message-box__close, .el-message-box__headerbtn:hover .el-message-box__close{
  color: rgba(var(--themecolor),1);
}
.planBodyMain .el-textarea__inner:focus,.planBodyMain .el-textarea__inner:hover{
  border: 1px solid #bdbcbc;
}
.planBodyMain .el-range-editor.is-active, .planBodyMain .el-range-editor.is-active:hover, .planBodyMain .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.8);
}
.planBodyMain .el-select .el-input__inner:focus{
  border-color: #ddd;
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),1);
}
</style>