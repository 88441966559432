<template>
  <div class="planBody">
    <div class="head">
      <div class="headerTitle">升学事项</div>
      <div>
        <el-select @change="GradeNoChange" v-model="GradeNo" placeholder="请选择">
          <el-option
            v-for="item in classTypeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="planBodyMain">
      <template v-if="mainData">
        <div
          class="planBodyItem"
          :class="{ ItemMarginright: (index + 1) % 4 === 0 }"
          v-for="(item, index) in MattersData"
          :key="item.Key"
        >
          <div class="textBox">
            {{ item.Key + "月" }}
          </div>
          <div class="BodyItem">
            <div v-for="(ite, index1) in item.Content" :key="index1">
              {{ (parseInt(index1) + 1)+'.'+ite }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import local_json from "../my-components/private_info_json.js";
export default {
  components: {},
  data() {
    return {
      GradeNo: 10,
      mainData: [],
      classTypeList: local_json.classTypeList,
      MattersData:[]
    };
  },
  mounted() {
    this.mainData=[
      {GradeNo:10,Content:[
        {Key:'1',Content:{0:'背景提升建议',1:'学年评估升学规划定位报告',2:'学业分析',3:'专业了解与定位',4:'自我认知测评与解读'}},
        {Key:'2',Content:{0:'背景提升建议',1:'课外科创活动参与',2:'专业了解与定位'}},
        {Key:'3,4',Content:{0:'背景提升建议',1:'专业了解与定位'}},
        {Key:'5',Content:{0:'背景提升建议',1:'选科/分类决策',2:'院校、升学路径定位'}},
        {Key:'6',Content:{0:'背景提升建议',1:'选科/分类决策',2:'学年评估升学规划定位报告',3:'学业分析'}},
        {Key:'9,10,11,12',Content:{0:'自我认知测评与解读'}}
      ]},
      {GradeNo:11,Content:[
        {Key:'1',Content:{0:'背景提升建议',1:'学年评估升学规划定位报告',2:'学业分析',3:'综合评价初审'}},
        {Key:'2',Content:{0:'背景提升建议',1:'课外科创活动参与',2:'综合评价初审'}},
        {Key:'3,4,5,11,12',Content:{0:'背景提升建议',1:'综合评价初审'}},
        {Key:'9',Content:{0:'背景提升建议',1:'选科/分类决策'}},
        {Key:'10',Content:{0:'背景提升建议'}}
      ]},
      {GradeNo:12,Content:[
        {Key:'1',Content:{0:'背景提升建议',1:'学年评估升学规划定位报告',2:'学业分析',3:'综合评价初审'}},
        {Key:'2',Content:{0:'课外科创活动参与',1:'综合评价初审'}},
        {Key:'3,4',Content:{0:'综合评价初审'}},
        {Key:'5,6',Content:{0:'综合评价面试'}},
        {Key:'9,10,11,12',Content:{0:'背景提升建议',1:'综合评价初审'}},
      ]}
    ];
    this.GradeNoChange();
  },
  methods: {
    GradeNoChange(){
      this.MattersData=this.mainData.find(f=>f.GradeNo==this.GradeNo).Content;
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  .headerTitle {
    font-size: 34px;
    line-height: 48px;
    color: #31415e;
  }
}
.planBody {
  padding: 40px 64px;
  background: #eef2f6;
  border-radius: 10px;
  width: 1200px;
  margin: 72px auto;
  min-height: 600px;

  .planBodyMain {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .planBodyItem {
      width: calc((100% - 96px) / 4);
      height: 238px;
      background: #fff;
      border-radius: 10px;
      margin-bottom: 32px;
      margin-right: 32px;
      font-size: 16px;
      .textBox {
        height: 48px;
        background: rgba(var(--themecolor),0.8);
        line-height: 48px;
        border-radius: 10px 10px 0px 0px;
        font-weight: bold;
        color: #eef2f6;
        text-align: center;
      }
      .BodyItem {
        padding: 16px;
        color: #31415e;
		line-height: 32px;
      }
    }
    .ItemMarginright {
      margin-right: 0;
    }
  }
}
</style>
<style lang="less">
.el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.8);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),1);
}
.el-select .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.8);
}
</style>