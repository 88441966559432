<template>
	<div class="custom-picker" :style="{width: pickerWidth}">
		<div class="picker-name">
			{{ pickerName }}：
		</div>
    <div class="select-box">
      <el-select class="picker-inner" v-model="selectValue" @change="handleSelect" placeholder="请选择">
        <el-option
          v-for="item in pickerList"
          :key="item.name"
          :label="item.name"
          :value="item.value"
          :disabled="item.disabled">
        </el-option>
      </el-select>
    </div>
<!--<picker :mode="pickerMode" :value="selectIndex" :range="pickerList" range-key="name" @change="handleSelect">
			<div class="picker-inner">
				{{ selectValue }}
			</div>
		</picker>-->
	</div>
</template>

<script>
	export default{

		data(){
			const myDate = new Date()
			const currentDate = myDate.getFullYear()+'-'+(myDate.getMonth()+2)+'-'+myDate.getDay()
			return{
				date: currentDate,
				selectValue: '',
				selectIndex: 0,

				imagesBaseURL: this.$store.state.imagesBaseURL,
			}
		},
		props:{
			pickerMode: {
				type: String,
				default: 'selector'
			},
			pickerName: {
				type: String,
				default: ''
			},
			pickerValue: '', // 默认值类型不一
      value: '',
			pickerList: {
				type: Array,
				default: () => []
			},
			pickerWidth: {
				type: String,
				default: '200px'
			}
		},
		watch:{
		  value(newValue){
        this.selectValue = newValue
      },
		},
		mounted() {
			this.initialize()
		},
		methods:{
			// 选择
			handleSelect(parameter){
			  console.log(parameter)
        this.$emit('input', parameter)
			},

			// 默认赋值
			initialize(){
        this.selectValue = this.value
			}
		}
	}
</script>

<style lang="less" scoped>
	.custom-picker{
		.picker-name{
      		color: #666;
			margin: 0 0 8px 2px;
		}
	}
</style>
