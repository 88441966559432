<template>
  <div class="show-content">
    <div class="head">
      <div class="title">{{ title }}</div>
    </div>
    <div class="main">
      <div class="main-item" :class="{'right-item': (index + 1) % 4 === 0 }" v-for="(item, index) in mainData.Data||[]" :key="index" @click="openDetail(item)">
        <img class="item-image" :src="item.CoverImageFileUrl ? item.CoverImageFileUrl : imagesURLComplete" />
        <div class="item-name">{{ item.CoursewareName }}</div>
      </div>
      <div class="nodata" v-if="!mainData">
        <i class="iconfont" style="font-size:30px;">&#xe67c;</i>
        <span>暂无数据</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowContent",
  data(){
    return {
      imagesURLComplete: 'https://jzyminiapp.obs.cn-north-4.myhuaweicloud.com/WeChatMiniApp/images/shushekaoqing.png'
    }
  },
  props: {
    title: {
      type: String,
      default: '视频课程'
    },
    mainData: {
      type: Object,
      default: () => ({})
    },
    pageConfig: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    // 选中项
    openDetail(item){
      this.$emit('changeSelect', item)
    },
    // 翻页
    turnPage(currentPage){
      this.$emit('turnPage', currentPage)
    },
  }
}
</script>

<style scoped lang="less">
.show-content{
  .head{
    margin-bottom: 24px;
    font-size: 34px;
    line-height: 48px;
    color: #31415e;
  }
  .main{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &-item{
      margin-right: 38px;
      width: calc((100% - 114px) / 4);
      background: #fff;
      border-radius: 5px;
      margin-bottom: 32px;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      .item-image {
        width: 100%;
        height: 160px;
        box-shadow: 0 4px 10px rgba(49, 65, 94, 0.1);
        vertical-align: top;
      }
      .item-name {
        padding: 0 16px;
        line-height: 40px;
        font-weight: bold;
        color: #fff;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: rgba(var(--themecolor),0.9);
      }
    }
    .right-item{
      margin-right: 0;
    }
  }
}

</style>
