<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 专业 -->
        <div class="major_box">
            <div class="major_con">
                <div class="info_box">
                    <div class="name">{{MajorDetailData.MajorName}}<span :class="MajorDetailData.EducationLevel==1?'e1':'e2'">[{{MajorDetailData.EducationLevel==1?'本科':'专科'}}]</span></div>
                    <div class="types">
                        <span>专业代码：{{MajorDetailData.NationalCode}}</span>
                        <span>专业类别：{{MajorDetailData.CategoryName}}</span>
                        <span>授予学位：{{MajorDetailData.Degree}}</span>
                        <span>男女比例：男：<label v-if="MajorDetailData.PercentOfMale">{{MajorDetailData.PercentOfMale}}%</label><label style="margin-left:20px;"></label>女：<label v-if="MajorDetailData.PercentOfFemale">{{MajorDetailData.PercentOfFemale}}%</label></span>
                    </div>
                </div>
                <div class="searchbox">
                    <div class="tab_box">
                        <div class="tabs" :class="TabCheckValue==1?'on':''" @click.prevent="TabCheck(1)">专业概况</div>
                        <div class="tabs" :class="TabCheckValue==2?'on':''" @click.prevent="TabCheck(2)">就业前景</div>
                        <div class="tabs" :class="TabCheckValue==3?'on':''" @click.prevent="TabCheck(3)">开设院校</div>
                    </div>
                    <div class="clear"></div>
                </div>
                <!-- 专业概况 -->
                <div class="major_list" v-if="TabCheckValue==1">
                    <div class="con_box" style="margin-top:30px">
                        <div class="title"><span class="on">专业介绍</span></div>
                        <div class="content">
                            <div class="info">{{MajorDetailData.MajorIntroduce}}</div>
                        </div>
                    </div>
                    <div class="con_box">
                        <div class="title"><span class="on">培养目标</span></div>
                        <div class="content">
                            <div class="info">{{MajorDetailData.DevlopTarget}}</div>
                        </div>
                    </div>
                    <div class="con_box">
                        <div class="title"><span class="on">主要课程</span></div>
                        <div class="content">
                            <div class="info">{{MajorDetailData.Course}}</div>
                        </div>
                    </div>
                    <div class="con_box">
                        <div class="title"><span class="on">具备能力</span></div>
                        <div class="content">
                            <div class="info" v-html="MajorDetailData.Ability?MajorDetailData.Ability.replace(/(\n)/g, '<br/>'):''" style="text-indent:0;"></div>
                        </div>
                    </div>
                    <div class="con_box">
                        <div class="title"><span class="on">考研方向</span></div>
                        <div class="content">
                            <div class="info" v-html="MajorDetailData.NextEducation"></div>
                        </div>
                    </div>
                    <div class="con_box" v-show="false">
                        <div class="title"><span class="on">推荐职业</span></div>
                        <div class="content">
                            <div class="tj">
                                <div class=""></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <!-- 就业前景 -->
                <div class="major_list" v-if="TabCheckValue==2">
                    <JobFuture v-if="Object.keys(MajorDetailData).length > 0" :specialty-info="MajorDetailData"></JobFuture>
                </div>
                <!-- 开设院校 -->
                <div class="major_list" v-if="TabCheckValue==3">
                    <College v-if="Object.keys(MajorDetailData).length > 0" :specialty-info="MajorDetailData"></College>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import JobFuture from "@/views/query/major/jobfuture";
import College from "@/views/query/major/college";
export default {
    components:{
        PHeader,
        PFooter,
        JobFuture,
        College
    },
    data(){
        return {
            loading:false,
            TabCheckValue:1,
            MajorDetailData:{}
        }
    },
    mounted(){
        this.initial();
    },
    methods:{
        initial(){
            this.GetMajorDetail()// 获取专业详情
        },
        // 选项卡切换
        TabCheck(val){
            this.TabCheckValue = val;
        },
        // 获取专业详情
        GetMajorDetail() {
            API.Query.GetMajorDetail({Id:this.$route.query.id}).then(res => {
                this.MajorDetailData = res.Data;
            })
        },
        // 加载
        Showloading() {
            this.loading = this.$loading({
                lock: false,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#000'
            })
        },
    }
}
</script>
<style scoped lang="less">
.major_box{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .major_con{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .info_box{
            margin: 20px 20px 0px;
            border: 1px solid #ddd;
            background-color: #fff;
            padding: 20px;
            border-radius: 5px;
            .name{
                color: #666;
                font-size: 26px;
                letter-spacing: 2px;
                .e1{
                    color: #c24949;
                    margin-left: 10px;
                    font-size: 13px;
                }
                .e2{
                    color: #0492d4;
                    margin-left: 10px;
                    font-size: 13px;
                }
            }
            .types{
                margin: 10px 0 0;
                span{
                    margin-right: 40px;
                    color: #666;
                    font-size: 14px;
                }
            }
        }
        .searchbox{
            height: 37px;
            margin: 20px 20px 0;
            border-bottom: 1px solid #ddd;
            border-bottom-right-radius: 5px;
            .tab_box{
                border-left: 1px solid #ddd;
                border-top-left-radius: 5px;
                .tabs{
                    height: 36px;
                    line-height: 36px;
                    display: inline-block;
                    border-right: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                    color: #666;
                    font-size: 16px;
                    padding: 0 40px;
                    background-color: rgba(var(--themecolor),0.05);
                }
                .tabs:hover{
                    cursor: pointer;
                    color: #555;
                }
                .tabs.on{
                    border-bottom: 1px solid #fff;
                    background-color:#fff;
                }
                .tabs:first-child{
                    border-top-left-radius: 5px;
                }
                .tabs:last-child{
                    border-top-right-radius: 5px;
                }
            }
        }
        .major_list{
            padding: 10px 0;
            min-height: 300px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-top: none;
            margin: 0 20px 20px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .con_box{
                margin: 0 20px;
                .title{
                    width: 100%;
                    height: 30px;
                    border-bottom: 1px solid #e7e7e7;
                    border-bottom-right-radius: 5px;
                    span{
                        color: #666;
                        font-size: 14px;
                        height: 29px;
                        display: inline-block;
                        line-height: 29px;
                        padding: 0px 20px;
                        border-top: 1px solid #e7e7e7;
                        border-right: 1px solid #e7e7e7;
                        background-color: #fff;
                        border-bottom: 1px solid #e7e7e7;
                    }
                    span.no:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor),0.9);
                        background-color: rgba(var(--themecolor),0.1);
                    }
                    span.on{
                        color: #444;
                        border-bottom: 1px solid #fff;
                    }
                    span:first-child{
                        border-top-left-radius: 5px;
                        border-left: 1px solid #e7e7e7;
                    }
                    span:last-child{
                        border-top-right-radius: 5px;
                    }
                }
                .content{
                    border: 1px solid #e7e7e7;
                    border-top: none;
                    border-top-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    min-height: 60px;
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 30px;
                    .info{
                        color: #666;
                        font-size: 14px;
                        text-indent: 28px;
                        line-height: 24px;
                        margin: 20px 20px 10px;
                        max-height: 186px;
                        overflow: hidden;
                    }
                    .info.nonum{
                        max-height: none;
                    }
                }
            }
        }
    }
}
</style>
<style lang="less">

</style>