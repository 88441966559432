<template>
  <div class="custom-popup">
    <el-dialog
      :visible.sync="visible"
      width="55.83vw"
      :show-close="false"
      @close="closePopup"
    >
      <div class="title" slot="title">
        {{ title }}
        <i class="el-icon-close" @click.prevent="closePopup"></i>
      </div>
      <div class="dialog-main">
        <el-input
          v-model="inputText"
          :autosize="rowConfig"
          type="textarea"
          resize="none"
          placeholder="请输入..."
        ></el-input>
        <div v-if="levelShow" class="level">
          <div class="level-item" :class="levelStatus(index)" @click="handleLevel(index)"
               v-for="(item, index) in levelList" :key="index">
            <div class="level-item-name">{{ item.name }}</div>
            <img class="level-item-image" :src="item.level"/>
          </div>
        </div>
      </div>
      <div class="footer" slot="footer">
        <el-button type="primary" @click="submit">完成</el-button>
        <el-button @click="closePopup">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CustomPopup",
  data () {
    return {
      visible: false,
      inputText: '',

      selectedLevelIndex: '',
      levelList: [
        {
          name: '优秀',
          level: require('@/assets/careerPlan/learning-level1.png')
        },
        {
          name: '良好',
          level: require('@/assets/careerPlan/learning-level2.png')
        },
        {
          name: '一般',
          level: require('@/assets/careerPlan/learning-level3.png')
        },
      ]
    }
  },
  props: {
    title: {
      type: String,
      default: '编辑'
    },
    value: {
      type: Boolean,
      default: false
    },
    contentText: {
      type: String,
      default: ''
    },
    progressItem: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    levelShow () {
      return this.type === 'comment'
    },
    rowConfig () {
      return this.type === 'comment' ? {
        minRows: 12,
        maxRows: 13
      } : {
        minRows: 15,
        maxRows: 16
      }
    }
  },
  watch: {
    value (newValue) {
      this.visible = newValue
    },
    contentText (newValue) {
      this.inputText = newValue
    },
    progressItem (newValue) {
      this.selectedLevelIndex = newValue.ScoreLevel || ''
    },
  },
  mounted () {
    this.inputText = this.contentText
  },
  methods: {

    // 提交
    submit () {
      if(this.type=='add'||this.type=='edit'){
        if (!this.inputText.trim()) {
          this.$messageTips('info', '请输入内容！')
          return
        }
      }
      let temp
      if (this.type === 'comment') {
        temp = {
          Comment: this.inputText,
          ScoreLevel: this.selectedLevelIndex
        }
      }
      else {
        temp = this.inputText
      }
      this.$emit('change', temp, _ => {
        this.closePopup()
      })
    },
    // 点击评论表情
    handleLevel (index) {
      this.selectedLevelIndex = index + 1
    },

    // 关闭弹窗
    closePopup () {
      this.inputText = ''
      this.visible = false
      this.$emit('input', false)
    },

    // level选中状态
    levelStatus (index) {
      return this.selectedLevelIndex === (index + 1) ? 'activate-level' : ''
    }
  }
}
</script>

<style scoped lang="less">
.custom-popup {
  .title {
    text-align: center;
    font-weight: 700;
    position: relative;
    i.el-icon-close{
      color: #F56C6C;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .dialog-main {
    height: calc(58.61vh - 70px);
    min-height: 350px;

    .level {
      width: 80%;
      margin: 20px auto 0;
      display: flex;
      justify-content: space-between;

      &-item {
        text-align: center;
        cursor: pointer;
        opacity: .6;

        &-name {
          margin: 5px 0;
          font-size: 20px;
        }

        &-image {
          width: 60px;
        }
      }

      .activate-level {
        opacity: 1;
      }
    }


    ::v-deep .el-textarea__inner {
      background-color: #f8f8f8;
    }
  }

  .footer {
    text-align: center;
  }
}

</style>
<style>
.custom-popup .dialog-main .el-textarea__inner:focus,.custom-popup .dialog-main .el-textarea__inner:hover{
  border: 1px solid #bdbcbc;
}
</style>