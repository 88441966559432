<template>
  <div class="app-container course">
    <div v-if="VideoCategoryListData.length > 0">
      <el-card
        class="box-card"
        v-for="(item, index) in VideoCategoryListData"
        :key="index"
      >
        <div slot="header" class="clearfix">
          <span>{{ item.Name }}</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="toCourseDetail(item.Id, item.Name)"
          >查看更多<i class="el-icon-d-arrow-right"></i></el-button
          >
        </div>
        <template v-if="item.videoList">
          <ul class="main-list">
            <li
              class="main-item"
              v-for="video in item.videoList"
              :key="video.Id"
              @click="playCourse(video)"
            >
              <h4>{{ item.CourseName }}</h4>
              <img
                class="main-item-coverImage"
                :src="video.CoverImageFileUrl ? video.CoverImageFileUrl : defaultImg"
              />
              <i class="home-lesson-playIcon"
              ><img src="../../../assets/images/home-lesson-playIcon.png"
              /></i>
              <p>{{ video.VideoName }}</p>
            </li>
          </ul>
        </template>
      </el-card>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
export default {
  name: "video-index",
  data() {
    return {
      VideoCategoryListData: [],
      defaultImg: require("../../../assets/careerPlan/course.png"),
    };
  },

  created() {
    this.getVideoCategory();
    document.documentElement.scrollTop = 0;
  },

  methods: {
    toCourseDetail(id, name) {
      this.$router.push({
        name: "video-list",
        query: {
          CategoryName: name,
          CategoryId: id,
        },
      });
    },

    getVideoCategory() {
      API.CareerPlan.GetVideoCategoryList().then((res) => {
        this.VideoCategoryListData = res.Data.map((item) => {
          item.videoList = [];
          return item;
        });
        this.GetVideoList();
      });
    },
    GetVideoList() {
      this.VideoCategoryListData.forEach((item) => {
        API.CareerPlan.GetVideoList(1,4,{CategoryId: item.Id}).then((res) => {
          item.videoList = res.Data.Data;
        });
      });
    },
    // 播放
    playCourse (params) {
      this.$router.push({
        name: 'video-detail',
        query: {
          CoverImageUrl: params.CoverImageFileUrl,
          Url: params.VideoFileUrl,
          CoursewareName: params.VideoName,
          Description: params.VideoDesc
        }
      })
    }
  },
};
</script>

<style scoped lang="less">
.course {
  width: 100%;
  min-height: 90vh;
  padding: 2rem 0;
  background-size: cover;
  .box-card{
    margin-bottom: 20px;
  }

  .course-title {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    color: #f2f2f2;
  }

  .main-list {
    display: flex;
    flex-wrap: wrap;

    .main-item {
      width: 16rem;
      height: 14rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      padding: 0.8rem;
      margin: 0 1.3rem 0.4rem;
      color: #666;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      text-align: center;
      position: relative;

      h4 {
        margin: 0 0 0.5rem;
        text-align: center;
      }

      .main-item-coverImage {
        width: 14rem;
        height: 8rem;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        margin: 0.8rem 0 0;
        font-size: 15px;
      }

      &:hover {
        box-shadow: 0 2px 4px rgba(126, 125, 125, 0.62);
        cursor: pointer;
      }

      .home-lesson-playIcon {
        position: absolute;
        top: 40%;
        left: 50%;
        margin-left: -1.25rem;

        img {
          width: 2.5rem;
          height: auto;
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
</style>
<style lang='less'>
.el-button--text{
  color: #666;
  i{
    color: #888;
    margin-left: 3px;
  }
}
.el-button--text:focus, .el-button--text:hover{
  color: rgba(var(--themecolor),0.8);
}
</style>