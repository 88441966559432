<template>
  <div class="main-box" :class="backgroundStyle">
    <div class="test-tips">
      <custom-frame title="新高考选科测评" :test-type="testType"></custom-frame>
      <div class="main">
        <div class="name">
          共{{ totalQuestion }}道选择题 约{{ totalTime }}分钟做完
        </div>
        <p class="text">
          问卷选项没有“对、错”
        </p>
        <p class="text">
          选择怎样做的 不是选择哪样更好
        </p>
        <p class="text">
          测试目的是反映最真实的自己
        </p>
        <p class="text">
          请最大程度放松下来
        </p>
        <p class="text">
          选择更接近你的感受或行为那项
        </p>
        <div class="button" @click="go">
          快开始吧！
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CustomFrame from '@/views/test/custom-components/CustomFrame'
  import API from '@/api/config'
  export default {
    components: {
      CustomFrame
    },
    name: "test-tips",
    data () {
      return {
        totalQuestion: '',
        totalTime: ''
      }
    },
    computed:{
      backgroundStyle(){
        let className
        this.testType == 4&&(className = 'background-4')
        this.testType == 5&&(className = 'background-5')
        return className
      },
      testType(){
        const {testType} = this.$route.query
        return testType*1
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      //开始测评
      go () {
        let {testType} = this.$route.query
        if (testType * 1 === 4) this.$router.push({
          // path: '/exam-test/choose-province',
          path: '/exam-test/new-test-content',
          query: {
            testType: 4
          }
        })
        if (testType * 1 === 5) this.$router.push({
          path: '/exam-test/enter-content',
          query: {
            testType: 5
          }
        })
      },

      initialize () {
        let {testType, ID} = this.$route.query

        if (testType == 4) {

          API.Career.SelSubjectQuestionList({
            ProvinceId: ID
          }).then(listData => {
            this.totalQuestion = listData.data.length
            this.totalTime = 15
          })
        }
        if (testType == 5) {
          this.getEnterPathQuestionList()
          this.totalTime = 5
        }
      },
      async getEnterPathQuestionList () {
        this.totalQuestion = (await API.Career.UpgradePathQuestionList()).data.length
      },
    }
  }
</script>

<style scoped lang="less">
  .main-box{
    width: 100%;
  }
  .background-4{
    background: linear-gradient(to bottom, rgba(var(--themecolor),0.75), rgba(var(--themecolor),1));
  }
  .background-5{
    background: linear-gradient(to bottom, #F4D25E, rgba(var(--themecolor),0.75));
  }
  .test-tips {
    position: relative;
    max-width: 750px;
    margin: 0 auto;
    border: 1px solid rgba(var(--themecolor),0);
    .main {
      box-sizing: border-box;
      width: 100%;
      padding: 40px;
      position: absolute;
      top: 150px;
      left: 0;
      text-align: center;
      .name {
        padding: 20px;
      }
      .text {
        margin: 40px 0;
      }
      .button {
        width: 180px;
        margin: auto;
        padding: 15px;
        border-radius: 30px;
        background-color: rgba(var(--themecolor),0.75);
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
</style>
