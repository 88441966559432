<template>
  <div class="mainBox">
    <div class="tabBox">
      <div
        class="tabBoxBtn"
        @click="tabType = item.type"
        v-for="item in tabBoxBtnList"
        :key="item.type"
        :class="{
          tabBoxBtnActive: tabType === item.type,
        }"
      >
        <div
          :class="{
            BoxBtnAfter: item.type !== 1,
          }"
        ></div>
        {{ item.text }}
      </div>
    </div>
    <div class="planBody">
      <div class="head">
        <div class="headerTitle">{{ tabBoxBtnList[tabType - 1].text }}</div>
        <div class="headerRight">
          <div class="submitBtn" @click="submitQuery">保存表格</div>
          <div class="dowloadBtn" @click="printPageFn">打印表格</div>
        </div>
      </div>
      <div class="planBodyMain" ref="print">
        <div class="printHead" v-if="printShow">
          {{ studentName }}学业规划书
        </div>
        <private-info
          v-show="tabType === 1 || printShow"
          ref="privateInfo"
          :main-data="mainData"
        ></private-info>
        <!-- 自我分析 -->
        <self-analysis
          v-show="tabType === 2 || printShow"
          ref="selfAnalysis"
          :detail-data="mainData"
        ></self-analysis>
        <!-- 环境分析 -->
        <environment-analysis
          v-show="tabType === 3 || printShow"
          ref="environmentAnalysis"
          :detail-data="mainData"
        ></environment-analysis>
        <!-- 目标确立 -->
        <set-goals
          v-show="tabType === 4 || printShow"
          ref="setGoals"
          :detail-data="mainData"
        ></set-goals>
        <!-- 行动计划 -->
        <action-plan
          v-show="tabType === 5 || printShow"
          ref="actionPlan"
          :detail-data="mainData"
        ></action-plan>
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   SelSelfAnalysisInfo,
//   AddOrUpdateSelfAnalysis,
// } from "@/api/career/career_plan.js";
import selfAnalysis from "./selfAnalysis.vue";
import PrivateInfo from "./PrivateInfo.vue";
import environmentAnalysis from "./environmentAnalysis.vue";
import setGoals from "./setGoals.vue";
import actionPlan from "./actionPlan.vue";

export default {
  components: {
    selfAnalysis,
    PrivateInfo,
    environmentAnalysis,
    setGoals,
    actionPlan,
  },
  data() {
    return {
      //图片地址
      imagesBaseURL: this.$store.state.imagesBaseURL,
      tabBoxBtnList: [
        {
          text: "个人概况",
          type: 1,
        },
        {
          text: "自我分析",
          type: 2,
        },
        {
          text: "环境分析",
          type: 3,
        },
        {
          text: "目标确立",
          type: 4,
        },
        {
          text: "行动计划",
          type: 5,
        },
      ],
      tabType: 1,
      mainData: {},
      flag: false,
      printShow: false,
      studentName: "",
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    // 個人概況提交參數
    submitQuery() {
      const query_1 = this.$refs.privateInfo.query;
      const query_2 = this.$refs.selfAnalysis.pageData;
      const query_3 = this.$refs.environmentAnalysis.pageData;
      const query_4 = { TargetPlanList: this.$refs.setGoals.TargetPlanList };
      const query_5 = this.$refs.actionPlan.pageData;

      let query_1_Flag = query_1.CompetitionList.some((item) => {
        if (!item.ContestDate) {
          this.$message.warning(
            "个人概况-参加比赛及获奖情况-“时间”为必填项，请完善后再保存！"
          );
          return true;
        }
      });
      if (query_1_Flag) return;

      const finalParameters = Object.assign(
        query_1,
        query_2,
        query_3,
        query_4,
        query_5
      );

      this.mainData.ID && (finalParameters.ID = this.mainData.ID);
      AddOrUpdateSelfAnalysis(finalParameters).then((res) => {
        // this.mainData = this.mainData || {}
        this.mainData.ID = res.data;
        this.$message.success("保存成功");
      });
    },

    // 初始化获取数据
    initialize() {
      SelSelfAnalysisInfo().then((res) => {
        console.log(res, "result");
        // this.mainData = res.data
        res.data && (this.mainData = res.data);
        console.log(this.mainData, "maindata");
        // this.flag = true
      });
    },
    printPageFn() {
      this.printShow = true;
      this.$store.dispatch("user/getInfo").then((res) => {
        this.studentName = res.data.Name;
        setTimeout(() => {
          this.$print(this.$refs.print);
          this.$nextTick(() => {
            let arr = document.getElementsByClassName("isNeedRemove");
            if (arr.length) arr[0].remove();
          });
          this.printShow = false;
        }, 300);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mainBox {
  padding: 40px 0;
  margin: 0 20px;
}
.tabBox {
  display: flex;
  align-items: center;
  .tabBoxBtn {
    text-align: center;
    width: 160px;
    height: 54px;
    background: rgba(var(--themecolor),0.15);
    opacity: 1;
    border-radius: 15px 15px 0px 0px;
    font-size: 16px;
    font-weight: bold;
    line-height: 54px;
    color: #555;
    margin-right: 8px;
    cursor: pointer;
  }
  .tabBoxBtnActive {
    background: rgba(var(--themecolor),0.8);
    color: #fff;
  }
}
.printHead {
  font-size: 34px;
  line-height: 48px;
  color: #31415e;
  text-align: center;
  margin: 30;
}
.planBody {
  padding: 40px 64px 64px;
  background: rgba(var(--themecolor),0.08);
  border-radius: 0px 20px 20px 20px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerTitle {
      font-size: 34px;
      line-height: 48px;
      color: #31415e;
    }
    .headerRight {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .submitBtn {
      width: 112px;
      height: 48px;
      background: rgba(var(--themecolor),0.8);
      box-shadow: 4px 4px 10px rgba(var(--themecolor), 0.4);
      border-radius: 5px;
      line-height: 48px;
      font-size: 16px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      margin-right: 40px;
    }
    .dowloadBtn {
      width: 112px;
      height: 46px;
      border: 1px solid rgba(var(--themecolor), 0.4);;
      box-shadow: 4px 4px 10px rgba(var(--themecolor), 0.2);
      border-radius: 5px;
      line-height: 46px;
      font-size: 16px;
      text-align: center;
      color: #666;
      cursor: pointer;
    }
  }
  .planBodyMain {
    width: 100%;
    min-height: 500px;
    padding-top: 30px;
    .titleTagBox {
      //   position: absolute;
      top: -2px;
      left: -2px;
      z-index: 100;

      .titleTagdiv {
        width: 180px;
        height: 48px;
        position: relative;

        .titleTag {
          width: 100%;
          height: 100%;
        }

        .titleTagText {
          display: block;
          position: absolute;
          top: 0;
          width: 100%;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          line-height: 48px;
          color: #ffffff;
        }
      }
    }
  }
}

.footerText {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 22px;
  color: #ffffff;
  margin: 40px 0;
}
</style>
