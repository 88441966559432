<template>
  <div class="planBody">
    <div class="planBodyMain">
      <div class="head">
        <div class="headerTitle">推荐书籍</div>
      </div>
      <div class="titleLeft">
        <el-scrollbar wrap-class="scrollbar-wrapper" class="leftscrollbook">
          <div class="titleBox" :class="{ titleBoxActive: titleBox === index }" @click="handClick(index)" v-for="(item, index) in mainData" :key="item.ID">《{{item.BookName}}》</div>
          <div class="clear"></div>
          <div style="width:100%;height:30px;"></div>
        </el-scrollbar>
      </div>
      <div class="bodyRight" v-if="mainData.length > 0">
        <div class="imgbox">
          <div class="img" :style="{'background':'url('+mainData[titleBox].CoverImageFileUrl+') no-repeat center center / cover'}"></div>
        </div>
        <div class="textBox">
          <div class="info">
            <div class="title">
              《{{ mainData[titleBox].BookName }}》
            </div>
            <div class="body">
              {{ mainData[titleBox].Description || "暂无书籍简介" }}
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import API from "@/api/config";
export default {
  components: {},
  data() {
    return {
      //图片地址
      imagesBaseURL: this.$store.state.imagesBaseURL,
      mainData: [],
      titleBox: 0,
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    // 初始化获取数据
    initialize() {
      API.CareerPlan.GetRecommendBookList().then((res) => {
        res.Data && (this.mainData = res.Data);
      });
    },
    handClick(index) {
      this.titleBox = index;
    },
  },
};
</script>

<style lang="less" scoped>

.planBody {
  max-width: 1420px;
  margin: auto;
  .planBodyMain {
    margin: 30px 20px 30px;
    background: #eef2f6;
    border-radius: 20px;
    padding-bottom: 30px;
    .head {
      padding: 20px 0;
      .headerTitle {
        font-size: 26px;
        color: #31415e;
        margin-left: 20px;
      }
    }
    .titleLeft {
      width: 300px;
      height: 600px;
      float: left;
      .leftscrollbook{
        height: 100%;
        .titleBox {
          margin: 20px 20px 0px;
          height: 60px;
          line-height: 60px;
          background: #eef2f6;
          box-shadow: 0px 4px 10px rgba(49, 65, 94, 0.1);
          border-radius: 10px;
          font-size: 20px;
          color: #666;
          cursor: pointer;
          text-align: center;
        }
        .titleBoxActive {
          background: rgba(var(--themecolor),0.7);
          color: #eef2f6;
          font-weight: bold;
        }
      }
    }
    .bodyRight {
      width: calc(100% - 340px);
      height: 600px;
      background: #fff;
      border-radius: 10px;
      float: right;
      overflow: hidden;
      margin-right: 20px;
      .imgbox {
        width: 350px;
        height: 470px;
        overflow: hidden;
        float: left;
        background-color: #fff;
        .img{
          width: 310px;
          height: 430px;
          margin: 20px;
          border-radius: 5px;
          overflow: hidden;
        }
      }
      .textBox {
        width: calc(100% - 350px);
        color: #666;
        font-size: 16px;
        line-height: 28px;
        text-indent: 32px;
        float: right;
        height: 100%;
        overflow: hidden;
        .info{
          padding: 0 20px;
          .title {
            width: 100%;
            text-align: center;
            line-height: 60px;
            font-size: 22px;
          }
          .body {
            font-size: 16px;
            line-height: 32px;
          }
        }
      }
    }
  }
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 4px;
}

/* 滚动槽 */
.titleLeft::-webkit-scrollbar-track {
  background: #eef2f6;
}

/* 滚动条滑块 */
.titleLeft::-webkit-scrollbar-thumb {
  background: rgba(var(--themecolor),0.8);
}
.textBox::-webkit-scrollbar-track {
  background: rgba(var(--themecolor),0.8);
}

/* 滚动条滑块 */
.textBox::-webkit-scrollbar-thumb {
  background: rgba(var(--themecolor),0.8);
}
</style>
<style lang="less">
.planBodyMain .titleLeft .leftscrollbook .el-scrollbar__wrap{
    overflow-x: hidden;
}
</style>