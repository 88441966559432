<template>
  <div class="career-courseware">
    <div class="show-content">
      <div class="head">
        <div class="title">生涯课件</div>
      </div>
      <div class="main">
        <div class="main-item" :class="{'right-item': (index + 1) % 4 === 0 }" v-for="(item, index) in mainData||[]" :key="index" @click="selectItem(item)">
          <img class="item-image" :src="item.CoverImageFileUrl ? item.CoverImageFileUrl : imagesURLComplete" />
          <div class="item-name">{{ item.CoursewareName }}</div>
        </div>
        <div class="pages" @click.prevent="pagesbtn()">
            <i class="iconfont" v-if="mainData.length==0&&pageIndex==1">&#xe67c;</i>
            <span>{{pagetxt}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/api/config";
import ShowContent from "@/views/careerManagement/my-components/ShowContent";

export default {
  components: {
    ShowContent
  },
  name: "careerCourseware",
  data(){
    return {
      pagetxt:'',
      mainData: [],
      pageIndex:1,
      pageSize:12,
      imagesURLComplete: 'https://jzyminiapp.obs.cn-north-4.myhuaweicloud.com/WeChatMiniApp/images/shushekaoqing.png'
    }
  },
  mounted () {
    this.getDataList();// 获取数据列表
  },
  methods:{
    // 选中项
    selectItem(item){
      this.$router.push({
        path: '/careerManagement/career-courseware-detail',
        query: {
          Id: item.Id
        }
      })
    },
    // 翻页
    pagesbtn(){
      if(this.HasNext){
        if(this.pagetxt=='点击加载更多'){
          this.pageIndex+=1;
          this.getDataList();// 获取数据列表
        }
      }else{
        if(this.mainData.length!=0){
          this.pagetxt='已经到底啦！';
        }
      }
    },
    // 获取数据列表
    getDataList(){
      API.CareerPlan.GetCoursewareList(this.pageIndex,this.pageSize,{CoursewareType:6}).then(res => {
        if(res.Data.Data.length>0){
          res.Data.Data.forEach(f => {
            this.mainData.push(f);
          });
          this.HasNext=res.Data.HasNext;
          if(this.HasNext){
            this.pagetxt='点击加载更多';
          }
          else{
            this.pagetxt='已经到底啦！';
          }
        }
        else{
          this.pagetxt='未找到信息！';
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.career-courseware{
  max-width: 1420px;
  margin: auto;
  .show-content{
    margin: 30px 20px;
    border-radius: 20px;
    background: #eef2f6;
    padding: 0px 20px;
    .head{
      line-height: 30px;
      color: #31415e;
      font-size: 28px;
      padding: 30px 0;
    }
    .main{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &-item{
        margin-right: 38px;
        width: calc((100% - 114px) / 4);
        background: #fff;
        border-radius: 5px;
        margin-bottom: 32px;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        .item-image {
          width: 100%;
          height: 160px;
          box-shadow: 0 4px 10px rgba(49, 65, 94, 0.1);
          vertical-align: top;
        }
        .item-name {
          padding: 0 16px;
          line-height: 40px;
          font-weight: bold;
          color: #fff;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          background: rgba(var(--themecolor),0.9);
        }
      }
      .right-item{
        margin-right: 0;
      }
      .pages{
        width: 100%;
        min-height: 50px;
        line-height: 50px;
        color: #666;
        font-size: 16px;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 10px;
        user-select: none;
        .iconfont{
          font-size: 50px;
          display: block;
          margin-top: 40px;
          color: #9b9b9b;
        }
      }
      .pages:hover{
        color: rgb(var(--themecolor));
        cursor: pointer;
      }
    }
  }
}
</style>
