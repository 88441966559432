<template>
  <div v-if="mainData.length !== 0" class="career-course">
    <div class="item-div">
      <div class="item-box">
        <div class="title">
          <img class="title-image" src="@/assets/careerPlan/career-course-rssy.png"/>
        </div>
        <div class="main">
          <download-box
            :text-list="[showText.text1]"
            :download-url="mainData[0].CoursewareFileUrl"
            :border="false"
          >
            <template v-slot:title>
              <div class="image-box">
                <img class="career-course-image1" src="@/assets/careerPlan/career-course-image1.png"/>
              </div>
            </template>
          </download-box>
        </div>
      </div>
      <div class="item-box">
        <div class="title">
          <img class="title-image" src="@/assets/careerPlan/career-course-rszw.png"/>
        </div>
        <div class="main">
          <div class="top">
            <download-box
              width="60%"
              title="一般能力（成就事件）"
              :text-list="[showText.text2_1, showText.text2_2]"
              :downloadButton="false"
              :border="false"
            ></download-box>
            <download-box
              width="35%"
              title="特殊能力"
              :text-list="[showText.text3_1, showText.text3_2]"
              :downloadButton="false"
              :border="false"
            ></download-box>
          </div>
          <download-box
            :border="false"
            :download-url="mainData[1].CoursewareFileUrl"
          ></download-box>
          <div class="center">
            <div class="image-box">
              <img class="career-course-image2" src="@/assets/careerPlan/career-course-image2.png" />
            </div>
            <div class="center-text">
              <download-box
                width="48%"
                title="个人特质"
                :text-list="[showText.text4_1, showText.text4_2]"
                :download-url="mainData[2].CoursewareFileUrl"
              ></download-box>
              <download-box
                width="48%"
                title="我的兴趣"
                :text-list="[showText.text5_1, showText.text5_2]"
                :download-url="mainData[3].CoursewareFileUrl"
              ></download-box>
            </div>
            <download-box
              title="我的价值观"
              :text-list="[showText.text6_1, showText.text6_2]"
              :download-url="mainData[4].CoursewareFileUrl"
            ></download-box>
          </div>
        </div>
      </div>
      <div class="item-box">
        <div class="title">
          <img class="title-image" src="@/assets/careerPlan/career-course-zyts.png"/>
        </div>
        <div class="main">
          <div class="top">
            <download-box
              width="48%"
              title="职业万花筒"
              :text-list="[showText.text7]"
              :download-url="mainData[5].CoursewareFileUrl"
            ></download-box>
            <download-box
              width="48%"
              title="职业资讯搜集"
              :text-list="[showText.text8]"
              :download-url="mainData[6].CoursewareFileUrl"
            ></download-box>
          </div>
          <div class="bottom">
            <download-box
              width="48%"
              title="工作条件"
              :text-list="[showText.text9]"
              :download-url="mainData[7].CoursewareFileUrl"
            ></download-box>
            <div class="image-box">
              <img class="career-course-image3" src="@/assets/careerPlan/career-course-image3.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="item-box">
        <div class="title">
          <img class="title-image" src="@/assets/careerPlan/career-course-xyts.png"/>
        </div>
        <div class="main">
          <div class="top">
            <download-box
              width="48%"
              title="大学探索"
              :text-list="[showText.text10]"
              :download-url="mainData[8].CoursewareFileUrl"
            ></download-box>
            <download-box
              width="48%"
              title="高中选择"
              :text-list="[showText.text11]"
              :download-url="mainData[9].CoursewareFileUrl"
            ></download-box>
          </div>
          <div class="bottom">
            <download-box
              width="48%"
              title="学业管理"
              :text-list="[showText.text12]"
              :download-url="mainData[10].CoursewareFileUrl"
            ></download-box>
            <div class="image-box">
              <img class="career-course-image4" src="@/assets/careerPlan/career-course-image4.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="item-box">
        <div class="title">
          <img class="title-image" src="@/assets/careerPlan/career-course-syjc.png"/>
        </div>
        <div class="main">
          <download-box
            :text-list="[showText.text13]"
            :download-url="mainData[11].CoursewareFileUrl"
            :border="false"
          >
            <template v-slot:title>
              <div class="image-box">
                <img class="career-course-image5" src="@/assets/careerPlan/career-course-image5.png"/>
              </div>
            </template>
          </download-box>
        </div>
      </div>
      <div class="item-box">
        <div class="title">
          <img class="title-image" src="@/assets/careerPlan/career-course-syxd.png"/>
        </div>
        <div class="main">
          <download-box
            :text-list="[showText.text14]"
            :download-url="mainData[12].CoursewareFileUrl"
            :border="false"
          ></download-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/config";
import local_json from "@/views/careerManagement/careerCourse/text_json";
import DownloadBox from "@/views/careerManagement/my-components/DownloadBox";

export default {
  components: {
    DownloadBox
  },
  name: "caerreCourse",
  data () {
    return {
      showText: local_json,
      downloadUrl: '',
      mainData: []
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    initialize () {
      API.CareerPlan.GetCoursewareList(1,1000,{CoursewareType:3}).then(res => {
        this.mainData = res.Data.Data
      })
    }
  }
}
</script>

<style scoped lang="less">
.career-course {
  max-width: 1420px;
  margin: auto;
  .item-div{
    margin: 30px 20px;

  }
  .item-box {
    margin-bottom: 40px;

    .title {
      margin-bottom: 24px;

      &-image {
        width: 160px;
      }
    }

    .main {
      padding: 40px 20px;
      background-color: rgb(238, 242, 246);
      border-radius: 20px;
      box-shadow: -2px -2px 1px 1px #f5f6f8;

      .image-box{
        min-width: 48%;
        text-align: center;
        .career-course-image1 {
          width: 560px;

        }
        .career-course-image2{
          width: 240px;
        }
        .career-course-image3, .career-course-image4{
          width: 208.71px;
        }
        .career-course-image5{
          width: 320px;
        }
      }
      .top {
        display: flex;
        justify-content: space-between;
      }

      .center {
        &-text{
          margin-bottom: 24px;
          display: flex;
          justify-content: space-between;
        }
      }

      .bottom {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

</style>
