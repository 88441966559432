<template>
    <div class="hotschool">
        <div class="hotschoolbox">
            <div class="hotschoolcon">
                <div class="titleico">
                    <div class="ico"><i></i><span>{{ ModuleName }}</span>
                        <div class="clear"></div>
                    </div>
                    <div class="switch">
                        <!-- <i class="iconfont">&#xe627;</i>
                        <span>换一批</span> -->
                    </div>
                    <div class="order" @click="collegeRank">
                        <i class="iconfont">&#xe66d;</i>
                        <span>院校排行榜</span>
                    </div>
                    <div class="more" @click="schmorebtn()">更多<i class="iconfont">&#xe600;</i></div>
                    <div class="clear"></div>
                </div>
                <div class="hotschoollist">
                    <div class="hsbox" v-for="(item, index) in schooldata" :key="index">
                        <div class="box" @click="openSchoolDetail(item)">
                            <div class="sico" :style="{ background: 'url(' + item.CollegeLogoFileUrl + ') no-repeat center center / 80%' }">
                            </div>
                            <div class="schname">{{ item.CollegeName }}</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/api/config'
export default {
    props: {
        ModuleName: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            schooldata: []
        }
    },
    mounted() {
        this.GetCollegeList()
    },
    methods: {
        // 详情
        openSchoolDetail(item) {
            let routerUrl = this.$router.resolve({
                path: "/query/collegedetail",
                query: {
                    id: item.Id,
                },
            });
            window.open(routerUrl.href);
        },
        schmorebtn() {
            this.$router.push('/query/college')
        },

        collegeRank() {
            this.$router.push('/query/collegerank')
        },
        GetCollegeList() {
            API.Query.GetCollegeList(1,7,{}).then(res => {
                if(res.Data.Data.length>0){
                    this.schooldata = res.Data.Data;
                }
            })
        }

    }
}
</script>
<style lang="less" scoped>
.hotschool {
    width: 100%;
    height: 240px;
    margin-top: 15px;
    user-select: none;

    .hotschoolbox {
        max-width: 1420px;
        margin: auto;

        .hotschoolcon {
            margin: 0px 20px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 3px 1px #ebeaea;

            .titleico {
                height: 50px;
                background-color: #f2f6fa;
                overflow: hidden;
                border-bottom: 1px solid #ebebeb;

                .ico {
                    float: left;

                    i {
                        float: left;
                        margin: 15px 15px 0px 20px;
                        width: 6px;
                        height: 19px;
                        background-color: rgb(var(--themecolor));
                    }

                    span {
                        font-size: 16px;
                        color: #555;
                        font-weight: bold;
                        line-height: 48px;
                    }
                }

                .switch {
                    float: left;
                    margin-left: 30px;
                    padding: 0px 10px;
                    line-height: 50px;

                    i {
                        float: left;
                        font-size: 14px;
                        color: #666;
                        margin-right: 6px;
                        margin-top: 2px;
                        opacity: 0.5;
                        transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                    }

                    span {
                        font-size: 13px;
                        color: #666;
                    }
                }

                .switch:hover {
                    cursor: pointer;
                }

                .switch:hover i {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                }

                .switch:hover span {
                    color: rgb(var(--themecolor));
                }

                .order {
                    float: left;
                    margin-left: 30px;
                    padding: 0px 10px;
                    cursor: pointer;

                    i {
                        font-size: 18px;
                        color: #f00707;
                        margin-right: 6px;
                        float: left;
                        margin-top: 2px;
                        opacity: 0.5;
                        line-height: 48px;
                    }

                    span {
                        font-size: 15px;
                        color: #666;
                        line-height: 52px;
                        font-weight: bold;
                    }
                }

                .order:hover span {
                    color: rgb(var(--themecolor));
                }

                .more {
                    float: right;
                    width: 54px;
                    height: 100%;
                    line-height: 50px;
                    color: #666;
                    font-size: 12px;
                    text-align: center;
                    margin-right: 17px;

                    i {
                        display: block;
                        transform: rotate(90deg);
                        -webkit-transform: rotate(270deg);
                        float: right;
                        color: #888;
                        font-size: 14px;
                    }
                }

                .more:hover {
                    cursor: pointer;
                    color: rgb(var(--themecolor));
                }
            }

            .hotschoollist {
                background-color: #fff;

                .hsbox {
                    width: calc(100% / 7);
                    height: 180px;
                    float: left;

                    .box {
                        margin: auto;
                        width: 140px;
                        // background-color: #e7e7e7;
                        text-align: center;
                        position: relative;
                        overflow: hidden;

                        .sico {
                            width: 120px;
                            height: 120px;
                            margin: auto;
                            margin-top: 15px;
                            transition: margin-top 0.3s;
                            -webkit-transition: margin-top 0.3s;
                        }

                        .schname {
                            color: #666;
                            font-size: 14px;
                            text-align: center;
                        }
                    }

                    .box:hover {
                        cursor: pointer;
                    }

                    .box:hover .sico {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}</style>