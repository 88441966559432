<template>
  <div class="custom-calendar">
    <div class="custom-calendar-head">
      <div class="date-box">
        <i class="arrow to-left el-icon-caret-left" @click="reduceDate"></i>
        <div class="year">{{ year }}.</div>
        <div class="month">{{ month }}</div>
        <i class="arrow to-right el-icon-caret-right" @click="increaseDate"></i>
      </div>
      <div class="today" @click="today">今天</div>
    </div>
    <div class="week">
      <div class="week-item" v-for="item in weekList" :key="item">{{ item }}</div>
    </div>
    <div class="calendar">
      <div class="calendar-item" v-for="(item, index) in MonthTotalDays" :key="index">
        <div v-if="item.date !== 'empty'" class="border-item">
          <div class="background-item" :class="selectedStyle(item)" @click="changeDate(item)">
            {{ item.date }}
            <span v-if="planNumber(item)" class="icon-item">{{planNumber(item)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCalendar",
  data () {
    return {
      year: 2021,
      month: 2,
      weekList: ['一', '二', '三', '四', '五', '六', '日',],

      publicDate: {}, // 公共时间对象
      MonthTotalDays: [],

      selectedDate: '',
    }
  },
  props: {
    calendarData: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    // 选择日期
    changeDate (item) {
      let temp = item === 'default' ? this.publicDate.getDate() : item.date // default 为发送默认的当前日期
      temp = String(temp).length < 2 ? '0' + temp : temp // 将日期格式化成两位字符串
      let tempMonth = String(this.month).length < 2 ? '0' + this.month : this.month // 将月份格式化成两位字符串

      this.selectedDate = `${this.year}-${tempMonth}-${temp}`

      this.getMonthParameter().then(res => {
        this.$emit('selectDate', this.selectedDate, res)
      })
    },

    // 今天
    today () {
      this.publicDate = new Date()
      this.year = this.publicDate.getFullYear()
      this.month = this.publicDate.getMonth() + 1
      this.countDate().then(_ => {
        this.changeDate('default')
      })
    },

    // 增加月份
    increaseDate () {
      let tempMonth = this.publicDate.getMonth()
      this.publicDate.setMonth(tempMonth + 1)
      this.getLastDate()
    },
    // 减少月份
    reduceDate () {
      let tempMonth = this.publicDate.getMonth()
      this.publicDate.setMonth(tempMonth - 1)
      this.getLastDate()
    },

    // 数据初始化
    initialize () {
      this.formatDate()
      this.changeDate('default')
    },
    // 时间格式化
    formatDate () {
      this.publicDate = new Date()
      this.getLastDate('format')
    },
    // 获取日期
    getLastDate (first) {
      this.year = this.publicDate.getFullYear()
      this.month = this.publicDate.getMonth() + 1

      this.countDate(first)
    },
    // 计算一个月的天数和星期
    countDate (first) {
      let tempDate1 = new Date(this.year, this.month, 0) // 用于计算月份总天数
      let tempDate2 = new Date(this.year, this.month - 1, 1) // 用于计算该月1号的星期

      this.MonthTotalDays = [] // 置空，防重复添加
      let temp = tempDate2.getDay() === 0 ? 7 : tempDate2.getDay() // 如果是周末就会返回0，改为7

      for (let i = 1; i < temp; i++) { // 不要=号，因为=号那天为第一天
        this.MonthTotalDays.push({date: 'empty'})
      }
      for (let i = 1; i <= tempDate1.getDate(); i++) {
        this.MonthTotalDays.push({date: i})
      }
      return new Promise(resolve => {
        this.getMonthParameter().then(res => {
          first !== 'format' && this.$emit('changeMonth', res, _ => {
            resolve()
          })
        })
      })
    },
    // 获取当前月份的第一天和最后一天参数
    getMonthParameter () {
      return new Promise(resolve => {
        let tempDate = new Date(this.year, this.month, 0)
        resolve({
          firstDate: `${this.year}-${this.month}-1`,
          endDate: `${this.year}-${this.month}-${tempDate.getDate()}`
        })
      })
    },
    // 处理选中样式
    selectedStyle (item) {
      let temp = String(item.date).length < 2 ? '0' + String(item.date) : String(item.date)
      let tempMonth = String(this.month).length < 2 ? '0' + this.month : this.month // 将月份格式化成两位字符串
      return this.selectedDate === `${this.year}-${tempMonth}-${temp}` ? 'selected-background-item' : ''
    },
    // 完成计划的数量
    planNumber (item) {
      let num = null;
      let tempItem = String(item.date);
      tempItem.length < 2 && (tempItem = '0' + tempItem);
      this.calendarData.forEach(obj=>{
        if(obj.TaskRecordList){
          obj.TaskRecordList.forEach(item=>{
            let temp = item.FinishDate.split('-');
            temp = temp[temp.length - 1];
            temp.length < 2 && (temp = '0' + temp);
            if (temp.substring(0,2) === tempItem) {
              num+=1;
            }
          });
        }
      });
      return num;
    }
  }
}
</script>

<style scoped lang="less">
.custom-calendar {
  width: 528px;
  height: 520px;
  padding: 40px;

  background: #EEF2F6;
  opacity: 1;
  border-radius: 20px;

  &-head {
    display: flex;
    justify-content: space-between;

    .date-box {
      display: flex;
      align-items: flex-end;

      .arrow {
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 30px;
        color: #afafaf;
      }

      .year {
        font-size: 26px;
        font-weight: 700;
      }

      .month {
        width: 48px;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        position: relative;
        top: 5px;
      }
    }

    .today {
      width: 80px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      background: rgba(var(--themecolor),0.8);
      box-shadow: 0 2px 4px rgba(var(--themecolor),0.2);
      border-radius: 5px;
      color: #ffffff;
    }
  }

  .week {
    display: flex;
    padding-bottom: 10px;
    margin: 20px 0 10px;
    border-bottom: 1px solid #d0d0d0;

    &-item {
      width: calc(100% / 7);
      text-align: center;
      color: #c0c0c0;
    }
  }

  .calendar {
    display: flex;
    flex-wrap: wrap;

    &-item {
      width: calc(100% / 7);
      height: 60px;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;

      .border-item {
        width: 48px;
        height: 50px;
        line-height: 2.5;
        margin: auto;
        display: flex;
        align-items: center;

        .background-item {
          width: 33px;
          height: 33px;
          line-height: 2;
          margin: auto;
          cursor: pointer;
          border-radius: 50%;
          position: relative;
          .icon-item {
            width: 16px;
            height: 16px;
            background: #fff;
            border-radius: 50%;
            border: 1px solid rgba(var(--themecolor), 0.7);
            opacity: 1;
            position: absolute;
            top: -10px;
            right: -10px;
            font-size: 10px;
            text-align: center;
            line-height: 16px;
            color: #666;
            overflow: hidden;
          }
        }
        .selected-background-item {
          color: #ffffff;
          background-color: rgba(var(--themecolor), 0.7);

        }
      }
    }
  }
}

</style>
