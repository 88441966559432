<template>
  <div class="show-detail">
    <div class="top">
      <div class="title">{{ mainData.CoursewareName }}</div>
    </div>
    <div class="main">
      <div class="image-box">
        <img class="image" :src="mainData.CoverImageFileUrl" alt="">
      </div>
      <div class="text">
        <p v-if="mainData.Description">{{ mainData.Description }}</p>
        <p v-if="mainData.Remark">{{ mainData.Remark }}</p>
        <p v-if="mainData.Summary">{{ mainData.Summary }}</p>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-item left">
        <div class="tips">附件：</div>
        <div style="float:left;">{{getUrlName()}}</div>
        <div class="clear"></div>
      </div>
      <div class="bottom-item right" @click="openFile">{{ buttonText }}</div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowDetail",
  data(){
    return{

    }
  },
  props:{
    mainData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    buttonText(){
      let str='';
      if(this.mainData){
        if(this.mainData.CoursewareFileUrl){
          str = this.mainData.CoursewareFileUrl.indexOf('.pdf')>-1?'预览附件': '下载附件';
        }
      }
      return str;
    }
  },
  methods: {
    openFile(){
      if(this.mainData){
        if(this.mainData.CoursewareFileUrl){
          (this.mainData.CoursewareFileUrl.indexOf('.pdf')>-1)?(window.open(PDFPreviewUrl+'?file='+encodeURIComponent(this.mainData.CoursewareFileUrl))):(window.open(this.mainData.CoursewareFileUrl));
        }
      }
    },
    getUrlName(){
      let str = '';
      if(this.mainData){
        if(this.mainData.CoursewareFileUrl){
          let arr = this.mainData.CoursewareFileUrl.split('.');
          str = this.mainData.CoursewareName+(this.mainData.CoursewareFileUrl.indexOf('.pdf')>-1?'.pdf':'.'+arr[arr.length-1]);
        }
      }
      return str;
    }
  }
}
</script>
<style scoped lang="less">
.show-detail{
  margin: 30px 20px;
  min-height: 400px;
  border-radius: 20px;
  background: #eef2f6;
  .top{
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
    .title{
      font-size: 26px;
      color: rgba(49, 65, 94, 1);
      margin: 0 20px;
      line-height: 34px;
    }
  }
  .main{
    padding-top: 16px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    .image-box{
      width: 400px;
      margin-left: 20px;
      .image{
        width: 100%;
      }
    }

    .text{
      width: calc(100% - 440px);
      line-height: 1.8;
      color: rgba(49, 65, 94, 1);
      font-size: 20px;
    }
  }
  .bottom{
    width: 100%;
    padding-bottom: 30px;
    &-item{
      width: 64px;
      height: 64px;
      line-height: 64px;
      opacity: 1;
      border-radius: 8px;
    }
    .left{
      width: calc(100% - 250px);
      margin-left: 20px;
      background: #fff;
      box-shadow: 0 4px 10px rgba(49, 65, 94, 0.1);
      float: left;
      cursor: default;
      text-indent: 20px;
      .tips{
        font-size: 16px;
        color: rgba(var(--themecolor),0.8);
        float: left;
      }
    }
    .right{
      width: 200px;
      background: rgba(var(--themecolor),0.8);
      text-align: center;
      color: #ffffff;
      box-shadow: 0 4px 10px rgba(50, 113, 225, 0.4);
      cursor: pointer;
      float: right;
      margin-right: 20px;
    }
  }
}

</style>
