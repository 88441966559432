<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 专业 -->
        <div class="major_box">
            <div class="major_con">
                <!-- 搜索专业、已选条件 -->
                <div class="searchbox">
                    <div class="i_box">
                        <div class="num">当前专业类： <span class="n">{{MajorData.CategoryName}}</span></div>
                        <div class="qbtn" @click.prevent="MajorSearchBtn(1)">类别切换<i class="el-icon-d-arrow-right"></i></div>
                        <div class="stxt">
                            <el-input id="Major_Input_ID" placeholder="搜索专业" v-model="MajorName" @keyup.enter.native="searchMajor" @focus="ShowMajorSearch=true" clearable @clear="clearMajorSearch"></el-input>
                            <div v-show="ShowMajorSearch" id="Major_Show_ID" class="search-box">
                                <div class="search-li" v-for="item in SearchMajorList" :key="item.Id" @click="SelectSearchItem(item.Id)">
                                    <span class="eltxt e1" v-if="item.EducationLevel==1">本科</span><span v-else class="eltxt e2">专科</span>{{ item.Name }}
                                </div>
                                <div class="no-data" v-if="SearchMajorList.length <= 0">
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        </div>
                        <div id="Search_Button_ID" class="s_btn" @click="searchMajor"><i class="el-icon-search"></i>搜 索</div>
                        <div class="clear"></div>
                    </div>
                </div>
                <!-- 筛选弹层 -->
                <div class="query_layer_box" id="query_layer_box" @click.prevent="MajorSearchBtn(2)">
                    <div class="m_box" @click.prevent.stop="">
                        <div class="m_list" id="major_list_box">
                            <el-scrollbar wrap-class="scrollbar-wrapper" class="sx_scrollbar">
                                <div class="s_box">
                                    <div class="tit">学历层次：</div>
                                    <div class="box">
                                        <span class="citem" :class="(MajorScreenSelectLevel ===1?'on':'')" @click.prevent="selectMajorLevel(1)">本科</span>
                                        <span class="citem" :class="(MajorScreenSelectLevel ===0?'on':'')" @click.prevent="selectMajorLevel(0)">专科</span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="s_box" v-for="major in MajorTypeData" :key="major.Id">
                                    <div class="tit">{{major.Name}}：</div>
                                    <div class="box">
                                        <div class="citem" :class="MajorData.Id==item1.Id?'on':''" v-for="item1 in major.SubList" :key="item1.Id" @click.prevent="MajorTypeCheck(item1.Id)">{{item1.Name}}</div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear" style="height:65px"></div>
                                <div class="btnbox">
                                    <div class="s_back" @click.prevent="MajorSearchBtn(2)"><i class="el-icon-arrow-left" style="color:#999"></i> 返 回</div>
                                </div>
                            </el-scrollbar>
                        </div>
                    </div>
                </div>
                <!-- 列表 -->
                <div class="major_list">
                    <div class="table_box">
                        <div class="major_item" v-for="(item,index) in (MajorData.MajorList?MajorData.MajorList:[])" :key="index" @click.prevent="MajorDetail(item.Id)">
                            <span class="name">{{item.MajorName}}</span>
                            <div class="info nowrap">{{item.MajorIntroduce}}</div>
                        </div>
                        <div class="pages" v-if="(MajorData.MajorList?MajorData.MajorList:[]).length==0">
                            <i class="iconfont">&#xe67c;</i>
                            <span>未找到专业信息！</span>
                        </div>
                    </div>
                    <div class="ad_con">
                        <!-- 测评 -->
                        <div class="zntb_box" @click.prevent="ToZhcp()">
                            <div class="img">
                                <img src="../../../assets/images/ad-zongheceping.png" alt="" title="点击进入专业定位综合测评"/>
                            </div>
                        </div>
                        <!-- 热门专业 -->
                        <div class="rmyx_box">
                            <div class="rtit"><i></i><span>热门专业</span><div class="clear"></div></div>
                            <div class="rlist">
                                <div class="ra" v-for="(item,index) in MajorHotData" :key="index" @click.prevent="MajorDetail(item.Id)">
                                    <div class="ico"><i class="iconfont" v-if="index<3">&#xe64e;</i><span>{{index+1}}</span></div>
                                    <div class="txt nowrap">{{ item.MajorName }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="major_order" @click.prevent="toMajorOrder()">
                            <i class="iconfont">&#xe66d;</i>
                            <div class="name">专业排行榜</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
export default {
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            loading:false,
            MajorScreenSelectLevel:1,
            MajorTypeData: [],

            MajorName:'',
            ShowMajorSearch: false, // 搜索面板
            SearchMajorList: [],

            MajorData:[],
            MajorHotData:[]
        }
    },
    mounted(){
        this.initial();
    },
    methods:{
        initial(){
            this.GetMajorTypeData()// 获取专业类别数据
            this.GetMajorCategoryDetail(this.$route.query.id);//获取专业类详情
            this.GetMajorHotList();// 获取热门专业列表
            // 收起搜索面板
            setTimeout(() => {
                let Ele1 = document.getElementById('Major_Input_ID')
                let Ele2 = document.getElementById('Major_Show_ID')
                let Ele3 = document.getElementById('Search_Button_ID')
                const ClickHidden = (event) => {
                    
                    if (event.target !== Ele1 && event.target !== Ele2 && event.target !== Ele3 && !Ele2.contains(event.target)) {
                        this.ShowMajorSearch = false
                    }
                }
                document.removeEventListener('click', ClickHidden)
                document.addEventListener('click', ClickHidden)
            }, 0)
        },
        // 筛选数据
        selectMajorLevel(type) {
            this.MajorScreenSelectLevel = type
            this.GetMajorTypeData();// 获取专业类别数据
        },
        // 专业筛选条件 显示/隐藏
        MajorSearchBtn(type){
            if(type==1){
                document.getElementById('query_layer_box').style.display='block';
                document.getElementById('query_layer_box').classList.add("layerbackshow");
                document.getElementById('query_layer_box').classList.remove("layerbackhide");
                let obj = document.getElementById("major_list_box");
                obj.style.display='block';
                obj.classList.remove("layerboxhide");
                obj.classList.add("layerboxshow");
            }
            else{
                document.getElementById('query_layer_box').classList.add("layerbackhide");
                document.getElementById('query_layer_box').classList.remove("layerbackshow");
                let obj = document.getElementById("major_list_box");
                obj.classList.remove("layerboxshow");
                obj.classList.add("layerboxhide");
                let st = setTimeout(()=>{
                    document.getElementById('query_layer_box').style.display='none';
                    obj.style.display='none';
                    clearTimeout(st);
                },300);
            }
        },
        clearMajorSearch() {
            this.SearchMajorList = []
            setTimeout(() => {
                this.ShowMajorSearch = true
            },100)
        },
        //选中搜索专业
        SelectSearchItem(id) {
            this.$router.push("/query/majordetail?id="+id);
        },
        // 搜索专业
        searchMajor() {
            if (!this.MajorName){
                this.$message.info('请输入专业');
                return;
            }
            this.Showloading();
            API.Query.SearchMajorByName({Name:this.MajorName}).then(res => {
                if (res.Code==0) {
                    this.SearchMajorList = res.Data;
                    this.ShowMajorSearch = true;
                    this.loading.close()
                } else {
                    this.$message.error(res.message)
                    this.loading.close()
                }
            })
        },
        // 获取专业类别数据
        GetMajorTypeData() {
            API.Web.GetMajorCategoryTreeList({EducationLevel:this.MajorScreenSelectLevel}).then(res => {
                this.MajorTypeData = res.data;
            })
        },
        // 专业类别选择
        MajorTypeCheck(id){
            this.GetMajorCategoryDetail(id);//获取专业类详情
        },
        //获取专业类详情
        GetMajorCategoryDetail(id){
            API.Query.GetMajorCategoryDetail({Id:id}).then(res => {
                if(res.Code==0){
                    this.MajorData = res.Data;
                }
                this.MajorSearchBtn(2);
            })
        },
        // 加载
        Showloading() {
            this.loading = this.$loading({
                lock: false,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#000'
            })
        },
        // 详情
        MajorDetail(id){
            this.$router.push("/query/majordetail?id="+id);
        },
        // 专业介绍展开/收起
        MajorTipBtn(id,type){
            let obj2 = document.getElementById('major_tip_down'+id).style;
            let obj3 = document.getElementById('major_tip_up'+id).style;
            if(type==1){
                obj1.height='auto';obj1.overflow='initial';obj2.display='none';obj3.display='block';
            }
            else{
                obj1.height='24px';obj1.overflow='hidden';obj2.display='block';obj3.display='none';
            }
        },
        ToZhcp(){
            this.$router.push("/allround");
        },
        //获取热门专业列表
        GetMajorHotList(){
            API.Query.GetMajorHotList(10).then(res => {
                this.MajorHotData = res.Data;
            })
        },
        MajorDetail(id){
            this.$router.push("/query/majordetail?id="+id);
        },
        toMajorOrder(){
            this.$router.push("/query/majorrank");
        }
    }
}
</script>
<style scoped lang="less">
.major_box{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .major_con{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .searchbox{
            padding: 15px 20px;
            background-color: #fff;
            border-bottom: 1px solid #ebe9e9;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .i_box{
                .qbtn{
                    width: 100px;
                    float: left;
                    color: #666;
                    font-size: 15px;
                    line-height: 38px;
                    border: 1px solid #ddd;
                    border-radius:5px;
                    text-align:center;
                    margin-right: 20px;
                    i{
                        margin-left: 2px;
                        color: rgba(var(--themecolor), 0.8);
                    }
                }
                .qbtn:hover{
                    cursor: pointer;
                    color: rgba(var(--themecolor), 1);
                    border: 1px solid rgba(var(--themecolor), 0.5);
                    i{
                        color: rgba(var(--themecolor), 1);
                    }
                }
                .num{
                    float: left;
                    width: 280px;
                    line-height: 40px;
                    color: #888;
                    font-size: 16px;
                    padding-right: 10px;
                    .n{
                        color: rgba(var(--themecolor),1);
                    }
                }
                .stxt{
                    position: relative;
                    float: left;
                    width: calc(100% - 625px);
                    height: 40px;
                    input{
                        display: block;
                        width: 100%;
                        height: 100%;
                        line-height: 38px;
                        border: none;
                        line-height: 1;
                        text-indent: 20px;
                        color: #666;
                        font-size: 14px;
                        background-color: #fff;
                    }
                    i{
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        z-index: 1;
                        width: 38px;
                        height: 100%;
                        text-align: center;
                        line-height: 38px;
                        color: #999;
                        font-size: 16px;
                    }
                    i:hover{
                        cursor: pointer;
                        color: #666;
                    }
                }
                .search-box {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 9;
                    width: 100%;
                    min-height: 100px;
                    max-height: 300px;
                    border: 1px solid #f0f0f0;
                    border-radius: 3px;
                    box-shadow: 0 0 10px #c3c3ed;
                    background-color: #fff;
                    font-size: 14px;

                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #f1f1f1;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #e7e7e7;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: #d5d4d4;
                    }
                    .search-li {
                        padding: 6px 20px 6px 30px;
                        cursor: pointer;
                        color: #616060;
                        font-size: 15px;
                        border-bottom: 1px solid #f3eeee;
                        position: relative;
                        &:hover {
                            color: rgba(var(--themecolor),1);
                        }
                        .eltxt{
                            margin-right: 20px;
                            border: 1px solid #ddd;
                            padding: 1px 8px;
                            font-size: 12px;
                            border-radius: 20px;
                        }
                        .e1{
                            color: #c24949;
                        }
                        .e2{
                            color: #0492d4;
                        }
                    }
                    .no-data {
                        padding-top: 40px;
                        text-align: center;
                        color: #999;
                    }
                }
                .s_btn{
                    width: 200px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    border-radius: 3px;
                    background-color: rgba(var(--themecolor),0.75);
                    float: right;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    i{
                        margin-right: 10px;
                    }
                }
                .s_btn:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .query_layer_box{
            position: fixed;
            left: 0;
            top: 0;
            z-index: 19;
            width: 100%;
            height: 100%;
            display: none;
            opacity: 0;
            background-color: rgba(0,0,0,0.5);
            .m_box{
                margin: 50px auto 0;
                max-width: 1420px;
                height: calc(100% - 100px);
                position: relative;
                .m_list{
                    border: 1px solid #ddd;
                    margin: 0 20px;
                    background-color: #ffffff;
                    box-shadow: 0px 3px 5px 0px #d4d2d2;
                    border-radius: 5px;
                    z-index: 2;
                    opacity: 0;
                    display: none;
                    height: 100%;
                    .sx_scrollbar{
                        width: 100%;
                        height: 100%;
                    }
                    .s_box{
                        margin: 0 20px 15px 20px;
                        border-bottom: 1px dashed #c4c2c2;
                        padding-bottom: 5px;
                        .tit{
                            color: #666;
                            font-size: 14px;
                            float: left;
                            width: 140px;
                            text-align: right;
                        }
                        .box{
                            width: calc(100% - 140px);
                            float: right;
                            .citem{
                                display: inline-block;
                                color: #666;
                                font-size: 12px;
                                padding: 2px 18px;
                                border: 1px solid #e2e2e2;
                                border-radius: 20px;
                                margin: 0px 8px 10px;
                                transition: all 0.2s;
                                -webkit-transition: all 0.2s;
                            }
                            .citem:hover{
                                border: 1px solid rgba(var(--themecolor),0.75);
                                cursor: pointer;
                                color: rgba(var(--themecolor),0.75);
                            }
                            .citem.on{
                                border: 1px solid rgba(var(--themecolor),0.75);
                                background-color: rgba(var(--themecolor),0.75);
                                color: #fff;
                            }
                        }
                    }
                    .s_box:nth-child(1){
                        margin-top: 20px;
                    }
                    .level {
                        .level-title {
                            padding-left: 60px;
                            color: #666;
                            font-size: 14px;
                            width: 80px;
                            text-align: right;
                        }
                        .level-box {
                            .level-item {
                                color: #666;
                                font-size: 12px;
                                padding: 2px 18px;
                                border: 1px solid #e2e2e2;
                                border-radius: 20px;
                                margin: 0px 8px 10px;
                                transition: all 0.2s;
                                -webkit-transition: all 0.2s;
                                position: relative;
                                &:hover {
                                    border: 1px solid rgba(var(--themecolor),0.75);
                                    cursor: pointer;
                                    color: rgba(var(--themecolor),0.75);
                                }
                            }
                            .level-item.on {
                                border: 1px solid rgba(var(--themecolor),0.75);
                                background-color: rgba(var(--themecolor),0.75);
                                color: #fff;
                            }
                        }
                    }
                    .btnbox{
                        width: 100%;
                        height: 60px;
                        background-color: #fff;
                        border-top: 1px solid #ddd;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: 2;
                        text-align: center;
                        .s_back{
                            width: 200px;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 3px;
                            background-color: #e0e0e0;
                            color: #666;
                            font-size: 15px;
                            letter-spacing: 2px;
                            text-align: center;
                            margin: 10px 20px;
                            display: inline-block;
                        }
                        .s_back:hover{
                            cursor: pointer;
                            background-color: #d6d6d6;
                        }
                    }
                }
                .m_list.layerboxshow {
                    animation: layerboxshow 0.3s ease 0s 1;
                    -webkit-animation: layerboxshow 0.3s ease 0s 1;
                    animation-fill-mode: forwards;
                    -webkit-animation-fill-mode: forwards;
                    @-webkit-keyframes layerboxshow {
                        from {
                            top: -100px;
                            opacity: 0;
                        }
                        to {
                            top: 39px;
                            opacity: 1;
                        }
                    }
                    @keyframes layerboxshow {
                        from {
                            top: -100px;
                            opacity: 0;
                        }
                        to {
                            top: 39px;
                            opacity: 1;
                        }
                    }
                }
                .m_list.layerboxhide {
                    animation: layerboxhide 0.3s ease 0s 1;
                    -webkit-animation: layerboxhide 0.3s ease 0s 1;
                    animation-fill-mode: forwards;
                    -webkit-animation-fill-mode: forwards;
                    @-webkit-keyframes layerboxhide {
                        from {
                            top: 39px;
                            opacity: 1;
                        }
                        to {
                            top: -100px;
                            opacity: 0;
                        }
                    }
                    @keyframes layerboxhide {
                        from {
                            top: 39px;
                            opacity: 1;
                        }
                        to {
                            top: -100px;
                            opacity: 0;
                        }
                    }
                }
            }
        }
        .query_layer_box.layerbackshow {
            animation: layerbackshow 0.3s ease 0s 1;
            -webkit-animation: layerbackshow 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes layerbackshow {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            @keyframes layerbackshow {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }
        .query_layer_box.layerbackhide {
            animation: layerbackhide 0.3s ease 0s 1;
            -webkit-animation: layerbackhide 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes layerbackhide {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }
            @keyframes layerbackhide {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }
        }
        .major_list{
            width: 100%;
            padding: 10px 0;
            min-height: 300px;
            .table_box{
                width: calc(100% - 342px);
                float:left;
                margin: 10px 0 10px 10px;
                padding: 0px 0 10px;
                min-height: 600px;
                .major_item{
                    margin: 0px 0px 10px;
                    border: 1px solid #e7e5e5;
                    box-shadow: 0px 0px 3px 1px #ebeaea;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: #fff;
                    padding: 10px 20px 0;
                    .name{
                        color: #666;
                        font-size: 16px;
                        margin-right: 20px;
                        font-weight: bold;
                        letter-spacing: 1px;
                    }
                    .info{
                        color: #888;
                        font-size: 14px;
                        height: 24px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
                .major_item:hover{
                    cursor: pointer;
                    border: 1px solid rgba(var(--themecolor),0.2);
                    box-shadow: 0px 0px 3px 1px rgba(var(--themecolor),0.3);
                    .name{
                        color: rgba(var(--themecolor),0.75);
                    }
                }
                .pages{
                    width: 100%;
                    min-height: 50px;
                    line-height: 50px;
                    color: #666;
                    font-size: 16px;
                    text-align: center;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                    user-select: none;
                    .iconfont{
                        font-size: 50px;
                        display: block;
                        margin-top: 40px;
                        color: #9b9b9b;
                    }
                }
            }
            .ad_con{
                width: 310px;
                float:right;
                margin-right:10px;
                margin-top:10px;
                .zntb_box{
                    user-select: none;
                    width: 100%;
                    height: 132px;
                    border-radius:5px;
                    overflow: hidden;
                    cursor: pointer;
                    position: relative;
                    .img{
                        width:100%;
                        height: 132px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        background:linear-gradient(to right, rgba(var(--themecolor),0.6), rgba(var(--themecolor),0.8));
                        transition: all 0.25s;
                        -webkit-transition: all 0.25s;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
                .zntb_box:hover .img{
                    transform: scale(1.08);
                    -webkit-transform: scale(1.08);
                }
                .rmyx_box{
                    border: 1px solid #ebebeb;
                    margin-top: 20px;
                    background-color: #fff;
                    overflow: hidden;
                    border-radius: 5px;
                    padding-bottom: 20px;
                    .rtit{
                        border-bottom: 1px solid #ebebeb;
                        height: 40px;
                        line-height: 40px;
                        i{
                            float: left;
                            margin: 11px 15px 0px 20px;
                            width: 6px;
                            height: 19px;
                            background-color: rgb(var(--themecolor));
                        }
                        span{
                            font-size: 16px;
                            color: #555;
                            font-weight: bold;
                        }
                    }
                    .rlist{
                        padding-top: 10px;
                        .ra{
                            margin-left: 14px;
                            height: 44px;
                            line-height: 44px;
                            .ico{
                                float: left;
                                width: 30px;
                                height: 100%;
                                position: relative;
                                margin-right: 12px;
                                i{
                                    font-size: 30px;
                                    color: rgb(var(--themecolor));
                                }
                                span{
                                    font-size: 14px;
                                    position: absolute;
                                    top: 0px;
                                    left: 11px;
                                    color: #666;
                                }
                            }
                            .txt{
                                color: #555;
                                font-size: 16px;
                                float: left;
                                width: calc(100% - 56px);
                                transition: color 0.3s;
                                -webkit-transition: color 0.3s;
                            }
                        }
                        .ra:hover{
                            cursor: pointer;
                        }
                        .ra:hover .txt{
                            color: rgb(var(--themecolor));
                        }
                        .ra:nth-child(-n+3) .ico span{
                            color: #fff;
                        }
                        .ra:nth-child(1) .ico i{
                            color: #eb312d;
                        }
                        .ra:nth-child(2) .ico i{
                            color: #fe7002;
                        }
                        .ra:nth-child(3) .ico i{
                            color: #f7aa4c;
                        }
                    }
                }
                .major_order{
                    margin-top: 20px;
                    background-color: #fff;
                    overflow: hidden;
                    border-radius: 5px;
                    height: 70px;
                    line-height: 70px;
                    padding-left: 40px;
                    background: linear-gradient(to right, rgba(var(--themecolor),0.6), rgba(var(--themecolor),0.8));
                    i{
                        font-size: 46px;
                        display: inline-block;
                        color: #f1e6b1;
                    }
                    .name{
                        color: #f1f0f0;
                        font-size: 24px;
                        letter-spacing: 2px;
                        display: inline-block;
                        margin-left: 30px;
                    }
                }
                .major_order:hover{
                    cursor: pointer;
                    background: linear-gradient(to right, rgba(var(--themecolor),0.7), rgba(var(--themecolor),0.9));
                }
            }
        }
    }
}
.el-select-dropdown__item.selected{
    color: rgba(var(--themecolor),1);
}
</style>
<style lang="less">
.major_box .major_con .searchbox .el-input.is-active .el-input__inner, .major_box .major_con .searchbox .el-input .el-input__inner:focus{
    border: 1px solid rgba(var(--themecolor),0.75);
}
.query_layer_box  .el-scrollbar__wrap{
    overflow-x: hidden;
}
</style>