<template>
    <div class="date-picker">
      <div class="picker-name">
        {{ pickerName }}：
      </div>
      <el-date-picker
        :style="{width: pickerWidth}"
        class="date-picker"
        v-model="birthday"
        @change="selectDate"
        :editable="false"
        type="date"
        placeholder="选择日期">
      </el-date-picker>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomDataPicker",
    data () {
      return {
        birthday: ''
      }
    },
    props:{
      pickerWidth:{
        type: String,
        default: '200px'
      },
      pickerName:{
        type: String,
        default: ''
      },
      value: '',
    },
    watch:{
      value(newValue){
        this.birthday = newValue
      }
    },
    mounted(){
      this.initialize()
    },
    methods:{
      // 选择日期
      selectDate(parameter){
        if(parameter){
          let date = `${parameter.getFullYear()}-${parameter.getMonth()+1}-${parameter.getDate()}`
          this.$emit('input', date)
        }else{
          this.$emit('input', parameter)
        }
      },
  
      // 初始化
      initialize(){
        this.birthday = this.value
      }
    }
  }
  </script>
  
  <style scoped lang="less">
  .date-picker {
    .picker-name {
      color: #666;
      margin: 0 0 8px 2px;
    }
    ::v-deep .el-input__inner:hover{
      cursor: pointer;
    }
  }
  
  </style>
  