<template>
  <div class="main-box">
    <div class="choose-province">
      <custom-frame title="新高考选科测评" :height="heightNumber"></custom-frame>
      <div class="main">
        <div class="title">
          选择省份
        </div>
        <div class="top main-item">
          <div class="name">3+3</div>
          <ul class="box">
            <li class="item" :class="activateSelect(item)" @click="selectProvinceItem(item)" v-for="(item, index) in mainData['3+3']" :key="index">
              {{ item.ProvinceName }}
            </li>
          </ul>
        </div>
        <div class="bottom main-item">
          <div class="name">3+1+2</div>
          <ul class="box">
            <li class="item" :class="activateSelect(item)" @click="selectProvinceItem(item)" v-for="(item, index) in mainData['3+1+2']" :key="index">
              {{ item.ProvinceName }}
            </li>
          </ul>
        </div>
        <div class="button" @click="goDown">下一步</div>
      </div>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  // import { SelSubjectCategoryOfProvinceList } from '@/api/test/test'
  import CustomFrame from '@/views/test/custom-components/CustomFrame'
  export default {
    components: {
      CustomFrame
    },
    name: "choose-province",
    data(){
      return{
        mainData: {},
        heightNumber:0,
        selectedProvinceID:''
      }
    },
    computed:{

    },
    mounted(){
      this.initialize()
      this.handleHeightNumber()
      this.getSelectedProvince()
    },
    methods:{
      //下一步
      goDown(){
        if(!this.selectedProvinceID){
          this.$messageTips('info', '请选择省份！')
          return
        }
        this.$router.push({
          path: '/exam-test-tips',
          query:{
            testType: 4,
            ID: this.selectedProvinceID
          }
        })
      },
      //选择省份
      selectProvinceItem(item){
        this.selectedProvinceID = item.ProvinceId
        this.$ls.set('test-newExamChoose', this.selectedProvinceID)
      },
      //选择省份状态激活
      activateSelect(item){
        return this.selectedProvinceID === item.ProvinceId?'activate-select':''
      },
      initialize(){
        API.Career.SelSubjectCategoryOfProvinceList().then(res => {
          res.data.forEach(item => {
            if(item.SelSubjectCategoryName === '3+3'){
              this.$set(this.mainData, '3+3', item.ProvinceList)
            }
            if(item.SelSubjectCategoryName === '3+1+2'){
              this.$set(this.mainData, '3+1+2', item.ProvinceList)
            }
          })
        })
      },
      //默认选择省份
      getSelectedProvince(){
        // const temp = this.$ls.get('test-newExamChoose')
        this.selectedProvinceID = this.$ls.get('test-newExamChoose')
      },
      handleHeightNumber(){
        const widthNumber = window.screen.width
        if(widthNumber <= 350){
          this.heightNumber = 800
        } else if(widthNumber <= 500 && widthNumber > 350){
          this.heightNumber = 850
        }else if(widthNumber > 500 && widthNumber < 1000){
          this.heightNumber = 1000
        }else{
          this.heightNumber = 1200
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .main-box{
    width: 100%;
    background: linear-gradient(to bottom, rgba(var(--themecolor),0.75), rgba(var(--themecolor),1));
  }
  .choose-province{
    position: relative;
    max-width: 750px;
    margin: 0 auto;
    border: 1px solid #eeefff;
    .main{
      box-sizing: border-box;
      width: 100%;
      padding: 40px;
      position: absolute;
      top: 100px;
      left: 0;
      text-align: center;
      &-item{
        margin-bottom: 20px;
        .name{
          color: rgba(var(--themecolor),1);
          margin: 10px 0;
          font-weight: 700;
          font-size: 20px;
        }
        .box{
          display: flex;
          flex-wrap: wrap;
          .item{
            padding: 10px 15px;
            margin: 0 10px 10px 0;
            background-color: #ffffff;
            border-radius: 20px;
            cursor: pointer;
          }
          .activate-select{
            background: linear-gradient(90deg, #FF823A 0%, #FF735D 100%);
            color:#fff;
          }
        }
      }
      .title{
        margin-bottom: 20px;
        font-weight: 700;
        color: #515357;
      }
      .top{}
      .bottom{}
      .button{
        width: 150px;
        padding: 10px;
        margin: 30px auto 20px;
        text-align: center;
        background-color: rgba(var(--themecolor),1);
        border-radius: 20px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 1024px){
    .choose-province{
      .main{
        &-item{
          .box{
            .item{
              padding: 15px 30px;
              margin: 0 30px 30px 0;
              border-radius: 30px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 750px) and (max-width: 1000px){
    .choose-province{
      .main{
        &-item{
          .box{
            .item{
              padding: 15px 30px;
              margin: 0 20px 20px 0;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
</style>
