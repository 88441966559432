<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <div class="report_home">
            <div class="reporthomebox">
                <!-- 筛选切换、信息显示 -->
                <div class="reporthometab">
                    <div class="tabs">
                        <div class="ta" :class="(TabActiveId=='1'?'on':'')" @click.prevent="ReportHomeTabBtn('1')"><i class="iconfont">&#xe62f;</i>院校优先</div>
                        <div class="ta" :class="(TabActiveId=='2'?'on':'')" @click.prevent="ReportHomeTabBtn('2')"><i class="iconfont">&#xe642;</i>专业优先</div>
                        <div class="clear"></div>
                    </div>
                    <div v-if="RecommendBatch" class="usermsg">
                        <span>{{ User.ProvinceName }}</span><span>成绩：{{ User.Score }}分</span>
                        <!-- <span v-if="User.SubjectList">选科：{{ User.SubjectList.join(',') }}</span> -->
                        <span v-if="User.SubjectType">
                            <span class="area">首选:{{User.SubjectType==1?'物理':'历史'}}</span>
                            <span v-if="User.SubjectList" class="area">再选:{{User.SubjectList.join(',')}}</span>
                        </span>
                        <span v-else>
                            <span v-if="User.SubjectList" class="area">{{User.SubjectList.join(',')}}</span>
                        </span>
                        <span v-if="RecommendBatch.Rank">{{ `位次：${RecommendBatch.Rank}` }}</span>
                        <!-- <span>批次：{{ SelectRecommendBatch.BatchName }}</span> -->
                        <el-popover ref="PopoverBatch" placement="bottom" trigger="hover" width="150" @show="PopoverEnter" @hide="PopoverLeave" :close-delay="100">
                            <div slot="reference">批次：{{ SelectRecommendBatch.BatchName }}<i class="el-icon-arrow-down" v-if="!PopoverShow"></i><i class="el-icon-arrow-up" v-if="PopoverShow"></i></div>

                            <div class="batch_item_popo" @click.prevent.stop="BatchOptionCheck(rIt)" v-for="(rIt, rI) in RecommendBatch.RecommendBatchList" :key="rIt.BatchId+rI">
                                <div :class="rIt.BatchScore>User.Score?'disabled':''">{{rIt.BatchName}}</div>
                            </div>

                            <!-- <div class="batch_item_popo" @click.prevent.stop="BatchOptionCheck(1)">本一批</div>
                            <div class="batch_item_popo" @click.prevent.stop="BatchOptionCheck(2)">本二批</div>
                            <div class="batch_item_popo" @click.prevent.stop="BatchOptionCheck(3)">专科批</div> -->
                        </el-popover>
                        <span class="text">
                            <span>等效分</span>
                            <span v-for="(yearItem, yearIndex) in tempScoreEqual" :key="String(yearItem+yearIndex)">{{ yearItem+'年：'+SelectRecommendBatch.ScoreEqual[yearItem]+'分' }}</span>
                        </span>
                    </div>
                    <div class="clear"></div>
                </div>
                <!-- 筛选按钮、院校搜索 -->
                <div class="i_box">
                    <div class="num" @click.prevent="OpenSearchLayer()">筛选条件<i class="el-icon-d-arrow-right"></i></div>
                    <div class="stxt" v-show="TabActiveId=='1'">
                        <el-input id="College_Input_ID" placeholder="搜索院校" v-model="CollegeSeachForm.CollegeName" @keyup.enter.native="searchCollege" @focus="ShowCollegeSearch=true" clearable @clear="clearCollegeSearch" style="width:calc(100% - 150px)"></el-input>
                        <div v-show="ShowCollegeSearch" id="College_Show_ID" class="search-box">
                            <div class="search-li" :class="SCItem.IsPlan?'':'DisableSelect'" v-for="SCItem in SearchCollegeList" :key="SCItem.CollegeId" @click="SelectSearchItem(SCItem)">
                                <i v-show="SelectSearchList.find(i => i.CollegeId === SCItem.CollegeId)" class="selected-icon iconfont">&#xe68a;</i>
                                {{ SCItem.CollegeName+(SCItem.IsPlan?'':'（该批次未招生或选考要求不匹配）') }}
                            </div>
                            <div class="search-sure" v-if="SearchCollegeList.length > 0" @click.prevent="SureScreen">
                                <span class="text">确 认</span>
                            </div>
                            <div class="no-data" v-if="SearchCollegeList.length <= 0">
                                <span>暂无数据</span>
                            </div>
                        </div>
                        <div id="Search_Button_ID" class="s_btn" @click="searchCollege"><i class="el-icon-search"></i>搜 索</div>
                        <!-- <div class="yx_back_close" v-if="ShowCollegeSearch" @click.prevent="ShowCollegeSearch=false"></div> -->
                    </div>
                    <div class="tj_tj" @click.prevent="openStat"><i class="iconfont">&#xe658;</i>数据说明</div>
                    <div class="clear"></div>
                </div>
                <!-- 院校优先/专业优先 已选条件 -->
                <div class="searchbox">
                    <div class="t_box" v-if="query.CollegeCityIdList.length!=0||query.CollegeCategoryIdList.length!=0||query.CollegeNatureIdList.length!=0||query.EducationLevel!=null||query.FeatureTagList.length!=0||query.StrengthTagList.length!=0||query.EnrollCategoryIdList.length!=0||query.MajorIdList.length!=0||BottomCollegeCardList.length!=0||query.IsNewCollege||query.IsNewMajor">
                        <div class="ttxt">已选条件 ：</div>
                        <div class="t_data">
                            <!-- 院校优先 -->
                            <div class="data" v-if="query.CollegeCityIdList.length!=0">
                                <span class="tit">院校地区：</span>
                                <span class="d" v-for="itemOne in CollegeCityCheckList" :key="itemOne.CityId">{{itemOne.CityName}}<i class="el-icon-circle-close" @click.prevent="ProvinceCityRemove(itemOne)"></i></span>
                                <div class="close" @click.prevent="ProvinceCityRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.CollegeCategoryIdList.length!=0">
                                <span class="tit">院校类别：</span>
                                <span class="d" v-for="itemTwo in query.CollegeCategoryIdList" :key="itemTwo">{{showName(itemTwo, 2)}}<i class="el-icon-circle-close" @click.prevent="CollegeCategoryRemove(itemTwo)"></i></span>
                                <div class="close" @click.prevent="CollegeCategoryRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.CollegeNatureIdList.length!=0">
                                <span class="tit">办学性质：</span>
                                <span class="d" v-for="itemThree in query.CollegeNatureIdList" :key="itemThree">{{showName(itemThree,3)}}<i class="el-icon-circle-close" @click.prevent="CollegeNatureRemove(itemThree)"></i></span>
                                <div class="close" @click.prevent="CollegeNatureRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="(query.EducationLevel==0||query.EducationLevel==1)">
                                <span class="tit">学历层次：</span>
                                <span class="d">{{query.EducationLevel==0?'专科':'本科'}}<i class="el-icon-circle-close" @click.prevent="CollegeLevelRemove"></i></span>
                                <div class="close" @click.prevent="CollegeLevelRemove"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.StrengthTagList.length!=0">
                                <span class="tit">实力标签：</span>
                                <span class="d" v-for="itemSix in query.StrengthTagList" :key="itemSix">{{showName(itemSix,6)}}<i class="el-icon-circle-close" @click.prevent="CollegeStrengthTagRemove(itemSix)"></i></span>
                                <div class="close" @click.prevent="CollegeStrengthTagRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.FeatureTagList.length!=0">
                                <span class="tit">特色标签：</span>
                                <span class="d" v-for="itemFive in query.FeatureTagList" :key="itemFive">{{showName(itemFive,5)}}<i class="el-icon-circle-close" @click.prevent="CollegeFeatureTagRemove(itemFive)"></i></span>
                                <div class="close" @click.prevent="CollegeFeatureTagRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.IsNewCollege">
                                <span class="tit">其他选项：</span>
                                <span class="d">新增院校<i class="el-icon-circle-close" @click.prevent="IsNewCollegeRemove()"></i></span>
                                <div class="close" @click.prevent="IsNewCollegeRemove()"><i class="el-icon-close"></i></div>
                            </div>
                            <!-- 专业优先 -->
                            <div class="data" v-if="MajorTypeCheckList.length!=0">
                                <span class="tit">专业类别：</span>
                                <span class="d" v-for="major in MajorTypeCheckList" :key="major.Id">{{major.Name}}<i class="el-icon-circle-close" @click.prevent="MajorTypeCheckRemove(major)"></i></span>
                                <div class="close" @click.prevent="MajorTypeCheckRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.EnrollCategoryIdList.length!=0">
                                <span class="tit">招生类别：</span>
                                <span class="d" v-for="itemFour in query.EnrollCategoryIdList" :key="itemFour">{{showName(itemFour,4)}}<i class="el-icon-circle-close" @click.prevent="CollegeEnrollCategoryRemove(itemFour)"></i></span>
                                <div class="close" @click.prevent="CollegeEnrollCategoryRemove('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="query.IsNewMajor">
                                <span class="tit">其他选项：</span>
                                <span class="d">新增专业<i class="el-icon-circle-close" @click.prevent="IsNewMajorRemove()"></i></span>
                                <div class="close" @click.prevent="IsNewMajorRemove()"><i class="el-icon-close"></i></div>
                            </div>
                            <!-- 院校优先、专业优先通用 -->
                            <div class="data" v-if="BottomCollegeCardList.length!=0">
                                <span class="tit">院校：</span>
                                <span class="d" v-for="(SItem,SIndex) in BottomCollegeCardList" :key="String(SItem.CollegeId)+SIndex">{{SItem.CollegeName}}<i class="el-icon-circle-close" @click.prevent="deleteSSList(SItem,SIndex)"></i></span>
                                <div class="close" @click.prevent="deleteSSListAll('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <!-- 院校+专业/专业+院校 ，冲稳保 ， 推荐范围 -->
                <div class="reporthead">
                    <div class="group">
                        <div v-if="SelectWishWayObj.WishWay==2" class="item" :class="(CollegeOrMajorGroupId==2?'on':'')" @click.prevent="CollegeOrMajorGroupChange(2)">按专业查看</div>
                        <div v-if="SelectWishWayObj.WishWay==2" class="item" :class="(CollegeOrMajorGroupId==1?'on':'')" @click.prevent="CollegeOrMajorGroupChange(1)">按院校查看</div>
                        <div class="clear"></div>
                    </div>
                    <div :style="(SelectWishWayObj.WishWay==2?'float:left;width:calc(100% - 264px);min-width:932px;':'')">
                        <div class="cwb_box">
                            <el-radio-group v-model="CWBGroupId" @change="CWBGroupChange" v-removeAriaHidden>
                                <el-radio :class="CWBClass(tItem.GroupShortName)" :label="tItem.Id" v-for="(tItem, tIndex) in ThreeStat" :key="String(tItem.Id)+tIndex">
                                    <span class="item" :class="CWBClass(tItem.GroupShortName)">{{tItem.GroupShortName}}<span class="n">({{tItem.Count}} 组)</span></span>
                                </el-radio>
                            </el-radio-group>
                        </div>
                        <div class="range">
                            <div class="t">推荐范围：</div>
                            <div class="slider">
                                <el-slider class="range_slider" @change="ReportRangeChange" @input="handleRangeShow" v-model="ReportRangeValue" range :show-tooltip="false" :min="ScoreLimitMin" :max="ScoreLimitMax"> </el-slider>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <!-- 填报数据表头 -->
        <div class="report_data_all">
            <div class="report_data_fixed" :class="TableHeaderFixed">
                <div class="report_databox">
                    <div class="reportdatatop">
                        <!-- 院校+专业 -->
                        <div class="ul yz" v-if="CollegeOrMajorGroupId==1">
                            <div class="li">
                                <span class="sort">{{ User.wishCategory===1?'预测分':'录取概率' }}
                                    <span class="ico">
                                        <i class="el-icon-caret-top" :class="SortType=='up'?'on':''" @click.prevent="handleSort('up')"></i>
                                        <i class="el-icon-caret-bottom" :class="SortType=='down'?'on':''" @click.prevent="handleSort('down')"></i>
                                    </span>
                                </span>
                            </div>
                            <div class="li">院校</div>
                            <div class="li">{{SelectRecommendBatch.PlanYear}}年招生计划</div>
                            <div v-if="SelectRecommendBatch.YearList" class="li">
                                <span class="year">
                                    <span class="ht">历年录取情况</span>
                                    <span class="y">
                                        <span>年份</span>
                                        <span>{{ SelectRecommendBatch.YearList[0] }}</span>
                                        <span>{{ SelectRecommendBatch.YearList[1] }}</span>
                                        <span>{{ SelectRecommendBatch.YearList[2] }}</span>
                                    </span>
                                </span>
                            </div>
                            <div class="li">填报</div>
                        </div>
                        <!-- 专业+院校 -->
                        <div class="ul zy" v-if="CollegeOrMajorGroupId==2">
                            <!-- <div class="li">预测分数</div> -->
                            <div class="li">
                                <span class="sort">{{ User.wishCategory===1?'预测分':'录取概率' }}
                                    <span class="ico">
                                        <i class="el-icon-caret-top" :class="SortType=='up'?'on':''" @click.prevent="handleSort('up')"></i>
                                        <i class="el-icon-caret-bottom" :class="SortType=='down'?'on':''" @click.prevent="handleSort('down')"></i>
                                    </span>
                                </span>
                            </div>
                            <div class="li">专业</div>
                            <div class="li">院校</div>
                            <div class="li">{{SelectRecommendBatch.PlanYear}}年招生计划</div>
                            <div v-if="SelectRecommendBatch.YearList" class="li">
                                <span class="year">
                                    <span class="ht">历年录取情况</span>
                                    <span class="y">
                                        <span>年份</span>
                                        <span>{{ SelectRecommendBatch.YearList[0] }}</span>
                                        <span>{{ SelectRecommendBatch.YearList[1] }}</span>
                                        <span>{{ SelectRecommendBatch.YearList[2] }}</span>
                                    </span>
                                </span>
                            </div>
                            <div class="li">填报</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 填报数据列表 -->
        <div class="report_data_table">
            <div class="report_tablebox">
                <div class="rtablebox">
                    <!-- 院校+专业 -->
                    <div class="rtable yz" v-if="CollegeOrMajorGroupId==1 && ReportData.length>0">
                        <div class="list" v-for="(item,index) in ReportData" :key="String(item.RecommendMajorId)+index">
                            
                            <div class="li">
                                <span class="new-add" v-if="item.IsNewCollege">新增</span>
                                <span v-else>
                                    <span v-if="User.wishCategory===1">
                                        <span v-if="item.PredictScore">
                                            <span>{{item.PredictScore}}</span>
                                            <span class="fen">分</span>
                                            <span class="tp" :class="CWBClass(item.RecommendGroupName)">
                                                {{item.RecommendGroupName}}
                                            </span>
                                        </span>
                                        <span v-else>-</span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.AdmissionProbability">
                                            <span>{{item.AdmissionProbability}}</span>
                                            <span class="fen">%</span>
                                            <span class="tp" :class="CWBClass(item.RecommendGroupName)">
                                                {{item.RecommendGroupName}}
                                            </span>
                                        </span>
                                        <span v-else>-</span>
                                    </span>
                                </span>
                            </div>
                            <div class="li">
                                <div class="smark">
                                    <span class="sname" @click.prevent="ToLink('/query/collegedetail?id='+item.CollegeId)">{{item.CollegeName}}</span>
                                    <span v-show="ProvinceObj.EnableRecommendEducationLevel" class="g">({{item.EducationLevel==1?'本科':'专科'}})</span>

                                    <span v-show="item.MajorAdmissionRule" class="tips-words">{{item.MajorAdmissionRule}}</span>
                                    <span v-show="item.CollegeRankNum" class="tips-words">{{item.CollegeRankNum+'['+item.CollegeRankCategoryName+']'}}</span>

                                    <span class="g">&nbsp;&nbsp;&nbsp;&nbsp;代码：{{item.CollegeCode}}</span>
                                    <div v-show="item.GroupCode" class="types">专业组代码：{{ item.GroupCode }}</div>
                                    <span class="types">{{handleNature(item)}}</span>
                                    <span class="tipss hide" :title="handleCollegeTags(item,' ')">
                                        <span v-html="handleCollegeTags(item,'&nbsp;')"></span>
                                        <span class="more"></span>
                                    </span>
                                </div>
                                <div class="tips-words">
                                    <span v-show="item.PostgraduateRate">读研率：{{ item.PostgraduateRate }}%</span>
                                    &nbsp;
                                    <span v-show="item.PostgraduateRecommendationRate">保研率：{{ item.PostgraduateRecommendationRate }}%</span>
                                </div>
                            </div>
                            <div class="li">

                                <div v-show="item.PlanPersons">
                                    {{item.PlanPersons}} 人
                                </div>
                                <div>
                                    <span v-show="item.LearningYears">{{item.LearningYears}}年</span>
                                    <span v-show="item.LearningYears && item.LearningCost"> · </span>
                                    <span v-show="item.LearningCost">￥{{item.LearningCost}}</span>
                                </div>
                                <div v-show="item.SubjectsCondition">{{item.SubjectsCondition}}</div>
                            </div>
                            <div class="li">
                                <table cellspacing="0">
                                    <tr>
                                        <td>最低分 / 位次</td>
                                        <td>{{item.ScoreLowOfYear1}} / {{item.RankLowOfYear1}}</td>
                                        <td>{{item.ScoreLowOfYear2}} / {{item.RankLowOfYear2}}</td>
                                        <td>{{item.ScoreLowOfYear3}} / {{item.RankLowOfYear3}}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>平均分 / 位次</td>
                                        <td>{{item.ScoreAvgOfYear1}} / {{item.RankAvgOfYear1}}</td>
                                        <td>{{item.ScoreAvgOfYear2}} / {{item.RankAvgOfYear2}}</td>
                                        <td>{{item.ScoreAvgOfYear3}} / {{item.RankAvgOfYear3}}</td>
                                    </tr> -->
                                    <tr>
                                        <td>录取人数</td>
                                        <td>{{item.PersonsOfYear1}}</td>
                                        <td>{{item.PersonsOfYear2}}</td>
                                        <td>{{item.PersonsOfYear3}}</td>
                                    </tr>
                                    <tr>
                                        <td>等效分差
                                            <span class="tips-text">
                                                <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                            </span>
                                        </td>
                                        <td>{{item.PersonalScoreDiffOfYear1}}</td>
                                        <td>{{item.PersonalScoreDiffOfYear2}}</td>
                                        <td>{{item.PersonalScoreDiffOfYear3}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="li">
                                <!-- <div class="btn" :class="index==0?'ck':''" @click.prevent="ReportMajorCheck(item)">专业 {{item.RecommendMajorCount}} <i class="el-icon-arrow-down"></i></div> -->
                                <div class="btn" :class="sortCollege(item)?'ck':''" @click.prevent="ReportMajorCheck(item)">专业 {{item.RecommendMajorCount}} <i class="el-icon-arrow-down"></i></div>
                                <div v-if="sortCollege(item)" v-show="SelectWishWayObj.WishWay!=2" class="b" :style="{'color':'#555','border':'none'}">已填 {{sortCollege(item)}}</div>
                                <!-- <div class="check" v-if="index==0">已填 2</div> -->
                            </div>
                        </div>
                    </div>
                    <!-- 专业+院校 -->
                    <div class="rtable zy" v-if="CollegeOrMajorGroupId==2 && ReportData.length>0">
                        <div class="list" v-for="(item,index) in ReportData" :key="String(item.RecommendMajorId)+index">
                            
                            <div class="li">
                                <span class="new-add" v-if="item.IsNewMajor">新增</span>
                                <span v-else>
                                    <span v-if="User.wishCategory===1">
                                        <span v-if="item.PredictScore">
                                            <span>{{item.PredictScore}}</span>
                                            <span class="fen">分</span>
                                            <span class="tp" :class="CWBClass(item.RecommendGroupName)">
                                                {{item.RecommendGroupName}}
                                            </span>
                                        </span>
                                        <span v-else>-</span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.AdmissionProbability">
                                            <span>{{item.AdmissionProbability}}</span>
                                            <span class="fen">%</span>
                                            <span class="tp" :class="CWBClass(item.RecommendGroupName)">
                                                {{item.RecommendGroupName}}
                                            </span>
                                        </span>
                                        <span v-else>-</span>
                                    </span>
                                </span>
                            </div>
                            <div class="li">
                                <span class="zname" @click.prevent="ToLink('/query/'+(item.IsMajorCategory?'majorlist':'majordetail')+'?id='+item.MajorOrCategoryId)">{{item.MajorName}}</span>
                                <div class="zcode">{{ item.MajorRemark }}</div>
                                <!-- <div class="tips-words">
                                    <span v-show="item.MajorRankNum">专业排名：{{item.MajorRankNum}}</span>
                                    <span v-show="item.LearnSubjectEvaluateLevel" class="evaluate">学科评估：{{item.LearnSubjectEvaluateLevel}}</span>
                                </div> -->
                                <span class="zcode">代码：{{item.MajorCode}}</span>
                                <div class="tips-words">
                                    <span v-show="item.PostgraduateRate">读研率：{{ item.PostgraduateRate }}%</span>
                                    <span v-show="item.PostgraduateRecommendationRate">保研率：{{ item.PostgraduateRecommendationRate }}%</span>
                                    <span v-show="item.IsPostgraduateMaster">硕士点：{{ item.IsPostgraduateMaster }}</span>
                                    <span v-show="item.IsPostgraduateDoctoral">博士点：{{ item.IsPostgraduateDoctoral }}</span>
                                    <span v-show="item.MajorRankNum">专业排名：{{item.MajorRankNum}}</span>
                                    <span v-show="item.LearnSubjectEvaluateLevel" class="evaluate">学科评估：{{item.LearnSubjectEvaluateLevel}}</span>
                                </div>
                            </div>
                            <div class="li">
                                <div class="smark">
                                    <span class="sname" @click.prevent="ToLink('/query/collegedetail?id='+item.CollegeId)">{{item.CollegeName}}</span>
                                    <span v-show="ProvinceObj.EnableRecommendEducationLevel" class="g">({{item.EducationLevel==1?'本科':'专科'}})</span>

                                    <span v-show="item.CollegeRankNum" class="tips-words">{{item.CollegeRankNum+'['+item.CollegeRankCategoryName+']'}}</span>
                                    
                                    <span class="g">&nbsp;&nbsp;&nbsp;&nbsp;代码：{{item.CollegeCode}}</span>
                                    
                                    <span class="types">{{handleNature(item)}}</span>
                                    <span class="tipss hide" :title="handleCollegeTags(item,' ')">
                                        <span v-html="handleCollegeTags(item,'&nbsp;')"></span>
                                        <span class="more"></span>
                                    </span>
                                </div>
                            </div>
                            <div class="li">

                                <div v-show="item.PlanPersons">
                                    {{item.PlanPersons}} 人
                                </div>
                                <div>
                                    <span v-show="item.LearningYears">{{item.LearningYears}}年</span>
                                    <span v-show="item.LearningYears && item.LearningCost"> · </span>
                                    <span v-show="item.LearningCost">￥{{item.LearningCost}}</span>
                                </div>
                                <div v-show="item.SubjectsCondition">{{item.SubjectsCondition}}</div>
                            </div>
                            <div class="li">
                                <table cellspacing="0">
                                    <tr>
                                        <td>最低分 / 位次</td>
                                        <td>{{item.ScoreLowOfYear1?item.ScoreLowOfYear1:''}} / {{item.RankLowOfYear1?item.RankLowOfYear1:''}}</td>
                                        <td>{{item.ScoreLowOfYear2?item.ScoreLowOfYear2:''}} / {{item.RankLowOfYear2?item.RankLowOfYear2:''}}</td>
                                        <td>{{item.ScoreLowOfYear3?item.ScoreLowOfYear3:''}} / {{item.RankLowOfYear3?item.RankLowOfYear3:''}}</td>
                                    </tr>
                                    <tr>
                                        <td>平均分 / 位次</td>
                                        <td>{{item.ScoreAvgOfYear1?item.ScoreAvgOfYear1:''}} / {{item.RankAvgOfYear1?item.RankAvgOfYear1:''}}</td>
                                        <td>{{item.ScoreAvgOfYear2?item.ScoreAvgOfYear2:''}} / {{item.RankAvgOfYear2?item.RankAvgOfYear2:''}}</td>
                                        <td>{{item.ScoreAvgOfYear3?item.ScoreAvgOfYear3:''}} / {{item.RankAvgOfYear3?item.RankAvgOfYear3:''}}</td>
                                    </tr>
                                    <tr>
                                        <td>录取人数</td>
                                        <td>{{item.PersonsOfYear1?item.PersonsOfYear1:''}}</td>
                                        <td>{{item.PersonsOfYear2?item.PersonsOfYear2:''}}</td>
                                        <td>{{item.PersonsOfYear3?item.PersonsOfYear3:''}}</td>
                                    </tr>
                                    <tr>
                                        <td>等效分差
                                            <span class="tips-text">
                                                <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                            </span>
                                        </td>
                                        <td>{{item.PersonalScoreDiffOfYear1?item.PersonalScoreDiffOfYear1:''}}</td>
                                        <td>{{item.PersonalScoreDiffOfYear2?item.PersonalScoreDiffOfYear2:''}}</td>
                                        <td>{{item.PersonalScoreDiffOfYear3?item.PersonalScoreDiffOfYear3:''}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="li">
                                <div v-if="ReportCheckData.find(f=>f.RecommendMajorId==item.RecommendMajorId)" class="btn ck" @click.prevent="ReportItemCheck(item)">已填</div>
                                <div v-else class="btn" @click.prevent="ReportItemCheck(item)">填报</div>
                                <div class="check" v-if="sortShow(item) > 0">已填 {{sortShow(item)}}</div>
                                <!-- <div class="btn ck" @click.prevent="DelStandbyItem(item.Id,item.RecommendMajorId)" v-if="IsJoinStandby(item.Id,item.RecommendMajorId)">移除备选</div>
                                <div class="btn" @click.prevent="AddStandbyItem(item.Id,item.RecommendMajorId)" v-else>加入备选</div> -->
                            </div>
                        </div>
                    </div>
                    <div v-if="authorization" class="pages" @click.prevent="pagesbtn()">
                        <i class="iconfont" v-if="ReportData.length==0&&pageObj.pageIndex==1">&#xe67c;</i>
                        <span>{{pagetxt}}</span>
                    </div>
                    <div v-else>
                        <member-limit :text_1="User.wishCategory==0?'开通':'升级'" :text_2="User.wishCategory==0?nameConfig.WCF:nameConfig.WDF"></member-limit>
                    </div>
                </div>
            </div>
        </div>
        <div class="clear" style="height:50px;"></div>

        <!-- 院校弹出专业 -->
        <div class="majorlayerback" id="majorlayerback" @click.prevent="MajorLayerBack()">
            <div class="majorlayerbox" id="majorlayerbox" @click.prevent.stop="">
                <div class="majorlayertable">
                    <div class="shead">
                        <div class="logo" :style="{'background':'url('+SelectCollege.CollegeLogoUrl+') no-repeat center center / cover'}"></div>
                        <span class="yname" @click.prevent="ToLink('/query/collegedetail?id='+SelectCollege.CollegeId)">{{SelectCollege.CollegeName}}</span>
                        <span class="dm">（ 代码：{{SelectCollege.CollegeCode}} ）</span>
                        <span v-show="SelectCollege.GroupCode" class="dm">（ 专业组代码：{{SelectCollege.GroupCode}} ）</span>
                        <i class="el-icon-close" @click.prevent="MajorLayerBack()"></i>
                        <div class="clear"></div>
                    </div>
                    <div class="ul">
                        <div class="li">
                            <span class="sort">{{ User.wishCategory===1?'预测分':'录取概率' }}
                                <span class="ico">
                                    <i class="el-icon-caret-top" :class="majorSortType=='up'?'on':''" @click.prevent="majorHandleSort('up')"></i>
                                    <i class="el-icon-caret-bottom" :class="majorSortType=='down'?'on':''" @click.prevent="majorHandleSort('down')"></i>
                                </span>
                            </span>
                        </div>
                        <div class="li">专业</div>
                        <div class="li">{{SelectRecommendBatch.PlanYear}}年招生计划</div>
                        <div v-if="SelectRecommendBatch.YearList" class="li">
                            <span class="year">
                                <span class="ht">历年录取情况</span>
                                <span class="y">
                                    <span>年份</span>
                                    <span>{{ SelectRecommendBatch.YearList[0] }}</span>
                                    <span>{{ SelectRecommendBatch.YearList[1] }}</span>
                                    <span>{{ SelectRecommendBatch.YearList[2] }}</span>
                                </span>
                            </span>
                        </div>
                        <div class="li">填报</div>
                    </div>
                    <div class="table">
                        <el-scrollbar wrap-class="scrollbar-wrapper" class="tablescroll">
                            <div class="list" v-for="(majorItem, majorIndex) in RecommendMajorList" :key="String(majorItem.RecommendMajorId)+majorIndex" >
                                <div class="item">
                                    
                                    <span class="new-add" v-if="majorItem.IsNewMajor">新增</span>
                                    <span v-else>
                                        <span v-if="User.wishCategory===1">
                                            <span v-if="majorItem.PredictScore">
                                                <span>{{majorItem.PredictScore}}</span>
                                                <span class="fen">分</span>
                                            </span>
                                            <span v-else>-</span>
                                        </span>
                                        <span v-else>
                                            <span v-if="majorItem.AdmissionProbability">
                                                <span>{{majorItem.AdmissionProbability}}</span>
                                                <span class="fen">%</span>
                                            </span>
                                            <span v-else>-</span>
                                        </span>
                                    </span>
                                </div>
                                <div class="item">
                                    <div class="smark">
                                        <span class="sname" @click.prevent="ToLink('/query/'+(majorItem.IsMajorCategory?'majorlist':'majordetail')+'?id='+majorItem.MajorOrCategoryId)">{{majorItem.MajorName}}</span>
                                        <span v-show="majorItem.MajorRemark" class="tipss"><span style="line-height:16px;">{{majorItem.MajorRemark}}</span></span>
                                        <!-- <div class="tips-words">
                                            <span v-show="majorItem.MajorRankNum">专业排名：{{majorItem.MajorRankNum}}</span>
                                            <span v-show="majorItem.LearnSubjectEvaluateLevel" class="evaluate">学科评估：{{majorItem.LearnSubjectEvaluateLevel}}</span>
                                        </div> -->
                                        <span class="g">代码：{{majorItem.MajorCode}}</span>
                                    </div>
                                    <div class="tips-words">
                                        <span v-show="majorItem.PostgraduateRate">读研率：{{ majorItem.PostgraduateRate }}%</span>
                                        &nbsp;
                                        <span v-show="majorItem.PostgraduateRecommendationRate">保研率：{{ majorItem.PostgraduateRecommendationRate }}%</span>
                                        <span v-show="majorItem.IsPostgraduateMaster">硕士点：{{ majorItem.IsPostgraduateMaster }}</span>
                                        <span v-show="majorItem.IsPostgraduateDoctoral">博士点：{{ majorItem.IsPostgraduateDoctoral }}</span>
                                        <span v-show="majorItem.MajorRankNum">专业排名：{{majorItem.MajorRankNum}}</span>
                                        <span v-show="majorItem.LearnSubjectEvaluateLevel" class="evaluate">学科评估：{{majorItem.LearnSubjectEvaluateLevel}}</span>
                                    </div>
                                </div>
                                <div class="item">
                                    <div v-show="majorItem.PlanPersons">{{majorItem.PlanPersons}} 人</div>
                                    
                                    <div>
                                        <span v-show="majorItem.LearningYears">{{majorItem.LearningYears}}年</span>
                                        <span v-show="majorItem.LearningYears && majorItem.LearningCost"> · </span>
                                        <span v-show="majorItem.LearningCost">￥{{majorItem.LearningCost}}</span>
                                    </div>
                                    
                                    <div v-show="majorItem.SubjectsCondition">{{majorItem.SubjectsCondition}}</div>
                                </div>
                                <div class="item">
                                    <table cellspacing="0">
                                        <tr>
                                            <td>最低分 / 位次</td>
                                            <td>{{majorItem.ScoreLowOfYear1?majorItem.ScoreLowOfYear1:''}} / {{majorItem.RankLowOfYear1?majorItem.RankLowOfYear1:''}}</td>
                                            <td>{{majorItem.ScoreLowOfYear2?majorItem.ScoreLowOfYear2:''}} / {{majorItem.RankLowOfYear2?majorItem.RankLowOfYear2:''}}</td>
                                            <td>{{majorItem.ScoreLowOfYear3?majorItem.ScoreLowOfYear3:''}} / {{majorItem.RankLowOfYear3?majorItem.RankLowOfYear3:''}}</td>
                                        </tr>
                                        <tr>
                                            <td>平均分 / 位次</td>
                                            <td>{{majorItem.ScoreAvgOfYear1?majorItem.ScoreAvgOfYear1:''}} / {{majorItem.RankAvgOfYear1?majorItem.RankAvgOfYear1:''}}</td>
                                            <td>{{majorItem.ScoreAvgOfYear2?majorItem.ScoreAvgOfYear2:''}} / {{majorItem.RankAvgOfYear2?majorItem.RankAvgOfYear2:''}}</td>
                                            <td>{{majorItem.ScoreAvgOfYear3?majorItem.ScoreAvgOfYear3:''}} / {{majorItem.RankAvgOfYear3?majorItem.RankAvgOfYear3:''}}</td>
                                        </tr>
                                        <tr>
                                            <td>录取人数</td>
                                            <td>{{majorItem.PersonsOfYear1?majorItem.PersonsOfYear1:''}}</td>
                                            <td>{{majorItem.PersonsOfYear2?majorItem.PersonsOfYear2:''}}</td>
                                            <td>{{majorItem.PersonsOfYear3?majorItem.PersonsOfYear3:''}}</td>
                                        </tr>
                                        <tr>
                                            <td>等效分差
                                                <span class="tips-text">
                                                    <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                    <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                                </span>
                                            </td>
                                            <td>{{majorItem.PersonalScoreDiffOfYear1?majorItem.PersonalScoreDiffOfYear1:''}}</td>
                                            <td>{{majorItem.PersonalScoreDiffOfYear2?majorItem.PersonalScoreDiffOfYear2:''}}</td>
                                            <td>{{majorItem.PersonalScoreDiffOfYear3?majorItem.PersonalScoreDiffOfYear3:''}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="item">
                                    <div v-if="!majorItem.CanFillIn" class="btn disabled-fill">填报</div>

                                    <div v-else>
                                        <div v-if="SelectWishWayObj.WishWay === 1">
                                            <div class="btn ck" @click.prevent="ReportItemCheck(majorItem, 'major')" v-if="fillStatus(majorItem)">已填</div>
                                            <div class="btn" @click.prevent="ReportItemCheck(majorItem, 'major')" v-else>填报</div>
                                            <div class="check" v-if="sortShow(majorItem) > 0">已填 {{sortShow(majorItem)}}</div>
                                        </div>
                                        <div v-else>
                                            <div class="btn ck" @click.prevent="ReportItemCheck(majorItem, 'major')" v-if="ReportCheckData.find(f=>f.RecommendMajorId==majorItem.RecommendMajorId)">已填</div>
                                            <div class="btn" @click.prevent="ReportItemCheck(majorItem, 'major')" v-else>填报</div>
                                            <div class="check" v-if="sortShow(majorItem) > 0">已填 {{sortShow(majorItem)}}</div>
                                        </div>
                                    </div>
                                    
                                    <!-- <div class="btn ck" @click.prevent="DelStandbyItem('1',majorItem.RecommendMajorId)" v-if="IsJoinStandby('1','179987898554')">移除备选</div>
                                    <div class="btn" @click.prevent="AddStandbyItem('1',majorItem.RecommendMajorId)" v-else>加入备选</div> -->
                                </div>
                            </div>
                            <!-- <div class="nodata">已经到底了！</div> -->
                        </el-scrollbar>
                        <div class="backbtn">
                            <div class="btn" @click.prevent="MajorLayerBack()">返 回</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 右侧填报浮层 -->
        <div class="report_check_box" :style="{'right':ReportFixed+'px'}" :class="AnimateReportClass">
            <div class="report_check">
                <div class="open" @click.prevent="OpenReportBtn">
                    <div class="ico">
                        <i class="el-icon-d-arrow-right" v-if="ReportFixed==0"></i>
                        <i class="el-icon-d-arrow-left" v-else></i>
                    </div>
                    <div class="tit">{{ReportFixed==0?'收起志愿表':'展开志愿表'}}</div>
                    <div class="clear"></div>
                    <div class="num" v-if="ReportCheckData.length>0">{{ReportCheckData.length}}</div>
                </div>
                <div class="sr" @click.prevent="StandbyReportShow">
                    <!-- <div class="sr_t"><span>备选志愿</span></div> -->
                    <div class="sr_n" v-if="GetStandbyTotal()!=''">{{GetStandbyTotal()}}</div>
                </div>
                <div class="save" v-if="ReportCheckData.length!=0" @click.prevent="CreateReportBtn">
                    <div class="st">保存志愿</div>
                </div>
                <div class="list">
                    <div class="dlist">
                        <el-scrollbar wrap-class="scrollbar-wrapper" class="comparescroll">
                            <div class="dl" v-for="(item,index) in ReportCheckData" :key="String(item.RecommendMajorId)+index">
                                <div class="ind">{{(index+1)}}</div>
                                <div class="sname">{{item.MajorName}}</div>
                                <i class="delete el-icon-delete" @click.prevent="RemoveReport(item)"></i>
                                <div class="clear"></div>
                                <div class="yxlist">{{item.CollegeName||item.FromCollege.CollegeName}}</div>
                                <span class="is-adjust" v-if="item.SelectMajor"><el-checkbox v-model="item.IsAdjust">服从调剂</el-checkbox></span>
                                <div v-if="item.SelectMajor" class="zylist">
                                    <div class="zy" v-for="(subItem,subIndex) in item.SelectMajor" :key="subItem.MajorName+subIndex">
                                        <div class="s">{{subIndex+1}}</div>
                                        <div class="n">{{subItem.MajorName}}</div>
                                        <i class="el-icon-delete" @click.prevent="removeMajor(item, subItem)"></i>
                                        <div class="clear"></div>
                                    </div>
                                </div>

                            </div>
                            <div v-if="ReportCheckData.length==0" style="text-align:center;color:#888;font-size:13px;line-height:60px;">请选择填报的专业</div>
                        </el-scrollbar>
                        <div class="btn" @click.prevent="CreateReportBtn">保存志愿表</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 备选志愿列表弹层 -->
        <!-- <div class="standby_report_layer" id="standbyreportlayer">
            <div class="standby_report_box" id="standbyreportbox">
                <div class="standby_tit"><span>备选志愿</span><i class="el-icon-close" @click.prevent="StandbyReportHide"></i><div class="clear"></div></div>
                <el-scrollbar wrap-class="scrollbar-wrapper" class="standbybox">
                    <div class="sritem" v-for="(item,index) in StandbyData" :key="index">
                        <div class="csbox">
                            <span class="n">{{item.CollegeName}}</span>
                            <span class="s">代码：10520</span>
                            <span class="m" @click.prevent="AddMajorBoxOpen(item.CollegeId)"><i class="el-icon-circle-plus-outline"></i>添加备选专业</span>
                            <span class="c" @click.prevent="DelStandbyItem(item.CollegeId,0)"><i class="el-icon-delete" title="删除备选院校及专业"></i></span>
                        </div>
                        <div class="mjitem">
                            <div class="mjbox" v-for="(obj,num) in item.MajorList" :key="num">
                                <span class="n">{{obj.MajorName}}</span>
                                <span class="m ch" @click.prevent="ReportItemCheck(obj.Id)" v-if="IsJoinReport(obj.Id)"><i class="el-icon-document-checked"></i>已填</span>
                                <span class="m" @click.prevent="ReportItemCheck(obj.Id)" v-else><i class="el-icon-document-add"></i>填报</span>
                                <span class="c" @click.prevent="DelStandbyItem(item.CollegeId,obj.Id)" title="删除备选专业"><i class="el-icon-delete"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="nomsg">{{(StandbyData.length!=0?'已经到底啦！':'暂无备选信息！')}}</div>
                </el-scrollbar>
                <div class="backbtn">
                    <div class="btn" @click.prevent="StandbyReportHide">返 回</div>
                </div>
                
                <div class="add_major_box" id="addmajorbox">
                    <div class="add_box">
                        <div class="add_tit"><span>添加备选专业</span><i class="el-icon-close" @click.prevent="AddMajorBoxClose"></i><div class="clear"></div></div>
                        <el-scrollbar wrap-class="scrollbar-wrapper" class="add_list">
                            <table cellspacing="0">
                                <tr>
                                    <td>计算机科学与技术</td>
                                    <td>代码：1052</td>
                                    <td>
                                        <span class="ch" @click.prevent="DelStandbyItem('1','179987898554')">已添加</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>计算机科学与技术</td>
                                    <td>代码：1052</td>
                                    <td>
                                        <span @click.prevent="AddStandbyItem('1','179987898554')">添加</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>计算机科学与技术</td>
                                    <td>代码：1052</td>
                                    <td><span>添加</span></td>
                                </tr>
                                <tr>
                                    <td>计算机科学与技术</td>
                                    <td>代码：1052</td>
                                    <td><span>已添加</span></td>
                                </tr>
                                <tr>
                                    <td>计算机科学与技术</td>
                                    <td>代码：1052</td>
                                    <td><span>添加</span></td>
                                </tr>
                            </table>
                            <div class="nomajor">已经到底啦！</div>
                        </el-scrollbar>
                        <div class="backbtn">
                            <div class="btn" @click.prevent="AddMajorBoxClose">返 回</div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- 筛选数据 显示/隐藏 -->
        <div class="search_layer_back" id="search_layer_back" @click.prevent="CloseSearchLayerOfBack()">
            <div class="search_layer_box" @click.prevent.stop="">
                <!-- 院校优先 -->
                <div class="m_list" v-if="TabActiveId=='1'" id="search_list_box">
                    <el-scrollbar wrap-class="scrollbar-wrapper" class="sx_scrollbar">
                        <div class="s_box">
                            <div class="tit">院校地区：</div>
                            <div class="box">
                                <el-popover v-for="province in CollegeScreen.CollegeAreaList" :key="province.ProvinceId" trigger="hover" placement="bottom" width="450" popper-class="search_city_popover">
                                    <div class="search_citybox">
                                        <div class="citem" :class="province.CheckNumber==province.CityList.length?'on':''" @click.prevent="ProvinceCityAllCheck(province)">全部</div>
                                        <div class="citem" :class="queryCopy.CollegeCityIdList.find(f=>f==item1.CityId)?'on':''" v-for="item1 in province.CityList" :key="item1.CityId" @click.prevent="ProvinceCityCheck(item1)">{{item1.CityName}}</div>
                                    </div>
                                    <el-button slot="reference">{{province.ProvinceName}}<i class="el-icon-arrow-down"></i><span class="num" v-if="province.CheckNumber">{{province.CheckNumber}}</span></el-button>
                                </el-popover>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">院校类别：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.CollegeCategoryIdList.length==0?'on':'')" @click.prevent="CollegeCategoryCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.CollegeCategoryIdList.find(f=>f==item2.Id)?'on':'')" v-for="item2 in CollegeScreen.CollegeCategoryList" :key="item2.Id" @click.prevent="CollegeCategoryCheck(item2)">{{item2.Name}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box level">
                            <div class="tit">办学性质：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.CollegeNatureIdList.length==0?'on':'')" @click.prevent="CollegeNatureCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.CollegeNatureIdList.find(f=>f==item3.Id)?'on':'')" v-for="item3 in CollegeScreen.CollegeNatureList" :key="item3.Id" @click.prevent="CollegeNatureCheck(item3)">{{item3.Name}}</span>
                                <span v-if="ProvinceObj.EnableRecommendEducationLevel" class="level">
                                    <span class="level-title">学历层次：</span>
                                    <span class="level-box">
                                        <span class="level-item" :class="queryCopy.EducationLevel==1?'on':''" @click="CollegeLevelCheck(1)">本科</span>
                                        <span class="level-item" :class="queryCopy.EducationLevel==0?'on':''" @click="CollegeLevelCheck(0)">专科</span>
                                    </span>
                                </span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">实力标签：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.StrengthTagList.length==0?'on':'')" @click.prevent="CollegeStrengthTagCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.StrengthTagList.find(f=>f==item6.Id)?'on':'')" v-for="item6 in CollegeScreen.StrengthTagList" :key="item6.Id" @click.prevent="CollegeStrengthTagCheck(item6)">{{item6.Name}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">特色标签：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.FeatureTagList.length==0?'on':'')" @click.prevent="CollegeFeatureTagCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.FeatureTagList.find(f=>f==item5.Id)?'on':'')" v-for="item5 in CollegeScreen.FeatureTagList" :key="item5.Id" @click.prevent="CollegeFeatureTagCheck(item5)">{{item5.Name}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">其他选项：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.IsNewCollege!=null?'on':'')" @click.prevent="IsNewCollegeCheck()">新增院校</span>
                                <span class="item" :class="(queryCopy.IsNewMajor?'on':'')" @click.prevent="IsNewMajorCheck()">新增专业</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear" style="height:80px"></div>
                        <div class="btnbox">
                            <div class="s_back" @click.prevent="CloseSearchLayerOfBack()"><i class="el-icon-arrow-left" style="color:#999"></i> 返 回</div>
                            <div class="s_submit" @click.prevent="SeachFormSubmit"><i class="el-icon-search"></i> 查 询</div>
                        </div>
                    </el-scrollbar>
                </div>
                <!-- 专业优先 -->
                <div class="m_list" v-if="TabActiveId=='2'" id="search_list_box">
                    <el-scrollbar wrap-class="scrollbar-wrapper" class="sx_scrollbar">
                        <div v-if="ProvinceObj.EnableRecommendEducationLevel" class="s_box">
                            <div class="tit">学历层次：</div>
                            <div class="box">
                                <span class="item" :class="(MajorScreenSelectLevel ===0?'on':'')" @click.prevent="selectMajorLevel(0)">专科</span>
                                <span class="item" :class="(MajorScreenSelectLevel ===1?'on':'')" @click.prevent="selectMajorLevel(1)">本科</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">专业类别：</div>
                            <div class="box">
                                <el-popover v-for="major in MajorTypeData" :key="major.Id" trigger="hover" placement="bottom" width="450" popper-class="search_city_popover">
                                    <div class="search_citybox">
                                        <div class="citem" :class="major.CheckNumber==major.SubList.length?'on':''" @click.prevent="MajorTypeAllCheck(major)">全部</div>
                                        <div class="citem" :class="queryCopy.MajorIdList.find(f=>f==item1.Id)?'on':''" v-for="item1 in major.SubList" :key="item1.Id" @click.prevent="MajorTypeCheck(item1)">{{item1.Name}}</div>
                                    </div>
                                    <el-button slot="reference">{{major.Name}}<i class="el-icon-arrow-down"></i><span class="num" v-if="major.CheckNumber">{{major.CheckNumber}}</span></el-button>
                                </el-popover>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">招生类别：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.EnrollCategoryIdList.length==0?'on':'')" @click.prevent="CollegeEnrollCategoryCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.EnrollCategoryIdList.find(f=>f==item4.Id)?'on':'')" v-for="item4 in CollegeScreen.EnrollCategoryList" :key="item4.Id" @click.prevent="CollegeEnrollCategoryCheck(item4)">{{item4.Name}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">其他选项：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.IsNewMajor?'on':'')" @click.prevent="IsNewMajorCheck()">新增专业</span>
                                <span class="item" :class="(queryCopy.IsNewCollege!=null?'on':'')" @click.prevent="IsNewCollegeCheck()">新增院校</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="btnbox">
                            <div class="s_back" @click.prevent="CloseSearchLayerOfBack()"><i class="el-icon-arrow-left" style="color:#999"></i> 返 回</div>
                            <div class="s_submit" @click.prevent="SeachFormSubmit"><i class="el-icon-search"></i> 查 询</div>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
        <!-- 推荐统计 -->
        <div class="stat_layer" v-if="StatLayerShow" @click.prevent="StatLayerShow=false">
            <div class="stat_box" @click.stop="">
                <el-scrollbar wrap-class="scrollbar-wrapper" class="stat_scroll">
                    <div class="tips">
                        <div class="tt"><i class="iconfont">&#xe687;</i>温馨提示</div>
                        <div class="bz" v-html="GetMark(ProvinceObj.Notice)"></div>
                    </div>
                    <div v-if="User.ProvinceName" class="info_box">
                        <div class="area">{{User.ProvinceName}}</div>
                        <div class="area">{{User.Score}}分</div>
                        <div>
                            <span v-if="User.SubjectType">
                                <span class="area">首选:{{User.SubjectType==1?'物理':'历史'}}</span>
                                <span v-if="User.SubjectList" class="area">再选:{{User.SubjectList.join(',')}}</span>
                            </span>
                            <span v-else>
                                <span v-if="User.SubjectList" class="area">{{User.SubjectList.join(',')}}</span>
                            </span>
                        </div>
                        <div class="area batch">
                            <el-popover ref="PopoverBatch" placement="bottom" trigger="hover" width="150" @show="PopoverEnter" @hide="PopoverLeave" :close-delay="100">
                                <div slot="reference">{{SelectRecommendBatch.BatchName}}<i class="el-icon-arrow-down" v-if="!PopoverShow"></i><i class="el-icon-arrow-up" v-if="PopoverShow"></i></div>
                                <div class="batch_item_popo" @click.prevent.stop="BatchOptionCheck(rIt)" v-for="rIt in RecommendBatch.RecommendBatchList" :key="rIt.BatchId">
                                    <div :class="rIt.BatchScore>User.Score?'disabled':''">{{rIt.BatchName}}</div>
                                </div>
                            </el-popover>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div v-if="ThreeStat.length>0" class="stat">
                        <div class="item">
                            <div class="ty c">
                                <div class="num show_animate"><div class="shu num_animate">{{(ThreeStat.find(f=>f.GroupCode=='chong')).Count}}</div></div>
                                <div class="border show_animate"></div>
                                <div class="t1 t_animate"><span class="bai"></span>{{(ThreeStat.find(f=>f.GroupCode=='chong')).GroupName}}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="ty w">
                                <div class="num show_animate"><div class="shu num_animate">{{(ThreeStat.find(f=>f.GroupCode=='wen')).Count}}</div></div>
                                <div class="border show_animate"></div>
                                <div class="t1 t_animate"><span class="bai"></span>{{(ThreeStat.find(f=>f.GroupCode=='wen')).GroupName}}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="ty b">
                                <div class="num show_animate"><div class="shu num_animate">{{(ThreeStat.find(f=>f.GroupCode=='bao')).Count}}</div></div>
                                <div class="border show_animate"></div>
                                <div class="t1 t_animate"><span class="bai"></span>{{(ThreeStat.find(f=>f.GroupCode=='bao')).GroupName}}</div>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="line"></div>
                    </div>
                    <div v-if="ThreeStat.length>0" class="type">
                        <div class="head">共有<span class="n">{{(ThreeStat.find(f=>f.GroupCode=='All')).Count}}</span>所适合的院校</div>
                        <div class="list">
                            <div class="li" v-for="it1 in NumberStat.StrengthTagStats" :key="it1.Name">
                                <div class="s">{{it1.Count}}</div>
                                <div class="t">{{it1.Name}}</div>
                            </div>
                            <div class="li" v-for="it1 in NumberStat.CollegeNatureStats" :key="it1.Name">
                                <div class="s">{{it1.Count}}</div>
                                <div class="t">{{it1.Name}}</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                    <div class="clear" style="height:100px;"></div>
                    <div class="gbtn">
                        <div class="btn" @click.prevent="StatLayerShow=false">立即填报</div>
                    </div>
                </el-scrollbar>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>

    </div>
</template>
<script>
import { mapGetters } from "vuex";
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import MemberLimit from '@/views/choose-wish/local-components/MemberLimit.vue';
import API from "@/api/config";
import { getStore, setStore, removeStore } from "@/utils/utils";
import { deepClone } from '@/utils/index'
export default {
    components:{
        PHeader,
        PFooter,
        MemberLimit
    },
    data() {
        return{
            loading:false,
            pageIndex:1,
            pagetxt:'未查询到数据',
            HasNext:false,
            BatchId:null,
            PopoverShow:false,
            StatLayerShow:true,

            ReportData:[],
            TabActiveId:'1',

            ProvinceName:'',
            CollegeSeachForm:{
                CollegeName:''
            },
            MajorSeachForm:{
                RecruitCategoryId:[],
            },
            CollegeOrMajorGroupId:1,
            CWBGroupId:'0',
            ScoreLimitMax: 0,
            ScoreLimitMin: 0,
            ReportRangeValue:[0,0],
            TableHeaderFixed:'',
            ReportCheckData:[],
            ReportFixed:-240,
            AnimateReportClass:'',
            StandbyData:[],

            CollegeScreen: {},
            MajorTypeData: [],
            MajorScreenSelectLevel: 0,

            User: {},
            ProvinceObj: {}, // 省份
            RecommendBatch: {}, // 批次
            SelectRecommendBatch: {},
            SelectWishWayObj: {},
            tempScoreEqual: [],
            ThreeStat: [],

            NumberStat: {},

            query: {
                // 推荐查询总参数
                RecommendKey: '',
                ScoreBegin: 0,
                ScoreEnd: 600,
                SubjectList: [],
                YearList: [],
                ScoreEqual: [],
                
                ScoreDiffBegin: null,
                ScoreDiffEnd: null,

                CollegeCityIdList: [],// 院校地区
                CollegeCategoryIdList: [],// 院校类别
                CollegeNatureIdList: [],// 办学性质
                EducationLevel: null, // 学历层次
                StrengthTagList: [],// 实力标签
                FeatureTagList: [], // 特色标签
                EnrollCategoryIdList: [],// 招生类别
                MajorIdList: [],// 专业类别
                CollegeIdList: [],// 院校
				IsNewCollege: null,
				IsNewMajor: null,

                WishWay: 0
            },
            CollegeCityCheckList:[],
            CollegeCityCheckListCopy:[],
            MajorTypeCheckList:[],
            MajorTypeCheckListCopy:[],
            queryCopy:{
                // 临时存放筛选数据
                CollegeCityIdList:[],// 院校地区
                CollegeCategoryIdList:[],// 院校类别
                CollegeNatureIdList:[],// 办学性质
                EducationLevel:null,// 学历层次
                StrengthTagList:[],// 实力标签
                FeatureTagList:[],// 特色标签
                EnrollCategoryIdList:[],// 招生类别
                MajorIdList: [],// 专业类别
                CollegeIdList: [],// 院校
				IsNewCollege: null,
				IsNewMajor: null
            },
            MajorQuery: {
                RecommendKey: '',
                RecommendCollegeId: '',
                SubjectList: [],
                YearList: [],
                ScoreEqual: [],
                WishWay: 0
            },

            pageObj: {
                pageIndex: 1,
                pageSize: 20
            },

            Main: {},

            SelectCollege: {},
            RecommendMajorList: [],

            ShowCollegeSearch: false, // 搜索面板
            SearchCollegeQuery: {
                RecommendKey: '',
                SubjectList: [],
                WishWay: 0,
                CollegeName: ''
            },
            SearchCollegeList: [],
            SelectSearchList: [], // 被选中的，搜索出来的院校
            BottomCollegeCardList: [],

            SortType: 'down',
            majorSortType: 'down',
            authorization: true,

            SaveQuery: {}, // 保存志愿表参数对象
            LoginInfo: null,
            
            modifyFlag: false,
        }
    },
    computed: {
        ...mapGetters(['nameConfig'])
    },
    mounted(){
        //监听滚动事件
        window.addEventListener('scroll', () => {
            let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
            if (ddeht > 394) {
                this.TableHeaderFixed='fixed';
            }
            else {
                this.TableHeaderFixed='';
            }
        }, true);

        let sliders = document.getElementsByClassName('el-slider__button-wrapper');
        let minScore = sliders[0].children[0];
        let maxScore = sliders[1].children[0];
        minScore.style.width="40px";
        maxScore.style.width="40px";
        minScore.style.height="18px";
        maxScore.style.height="18px";
        minScore.style.lineHeight="18px";
        maxScore.style.lineHeight="18px";
        minScore.style.borderRadius="40px";
        maxScore.style.borderRadius="40px";
        minScore.style.color="#666";
        maxScore.style.color="#666";
        minScore.style.fontSize="13px";
        maxScore.style.fontSize="13px";
        
        this.initial();

    },
    methods:{
        // 提示换行处理
        GetMark(con){
            if(con){
                return con.replace(/(\n)/g, '<br/>');
            }
            else{
                return '';
            }
        },
        // sort
        majorHandleSort(type) {
            if (type == this.majorSortType) return
            this.majorSortType = type
            // this.resetParams()
            if (type=='up') {
                
                if (this.User.wishCategory==0) {
                    this.MajorQuery.IsOrderAsc = false
                } else {
                    this.MajorQuery.IsOrderAsc = true
                }
            } else {
                if (this.User.wishCategory==0) {
                    this.MajorQuery.IsOrderAsc = true
                } else {
                    this.MajorQuery.IsOrderAsc = false
                }
            }
            this.getMajorList()
        },
        handleSort(type) {
            if (type == this.SortType) return
            this.SortType = type
            this.resetParams()
            if (type=='up') {
                
                if (this.User.wishCategory==0) {
                    this.query.IsOrderAsc = false
                } else {
                    this.query.IsOrderAsc = true
                }
            } else {
                if (this.User.wishCategory==0) {
                    this.query.IsOrderAsc = true
                } else {
                    this.query.IsOrderAsc = false
                }
            }

            this.GetReportList()
        },
        // 初始化数据
        initial() {
            setStore("SearchStoreData",'');//清除筛选数据
            this.getInfo()

            // 收起搜索面板
            setTimeout(() => {
                let Ele1 = document.getElementById('College_Input_ID')
                let Ele2 = document.getElementById('College_Show_ID')
                let Ele3 = document.getElementById('Search_Button_ID')
                
                const ClickHidden = (event) => {
                    
                    if (event.target !== Ele1 && event.target !== Ele2 && event.target !== Ele3 && !Ele2.contains(event.target)) {
                        this.ShowCollegeSearch = false
                    }
                }

                document.removeEventListener('click', ClickHidden)
                document.addEventListener('click', ClickHidden)

                // const enterEvent = (e) => {
                //     if (e.key === "Enter") {
                //         if (this.ShowCollegeSearch) {
                //             this.searchCollege()
                //         }
                //     }
                // }
                
                // document.removeEventListener('keyup', enterEvent)
                // document.addEventListener('keyup', enterEvent)
            }, 0)
        },
        // 获取用户相关数据
        getInfo() {
            // 获取个人信息
            let tempUser = getStore('studentReport')
            this.User = JSON.parse(tempUser)
            
            this.modifyFlag = getStore('modifyFlag')
            
            if (this.modifyFlag) {
                this.modifyFlag = JSON.parse(this.modifyFlag)
            } else {
                this.modifyFlag = false
            }

            // login info
            let login = getStore('LoginData')
            if (login) {
                this.LoginInfo = JSON.parse(login)
            }

            // 列表默认顺序
            if (this.User.wishCategory==0) {
                this.query.IsOrderAsc = false
                this.MajorQuery.IsOrderAsc = false
                this.SortType = 'up'
            } else {
                this.query.IsOrderAsc = false
                this.MajorQuery.IsOrderAsc = false
                this.SortType = 'down'
            }

            let P = ( this.User.wishCategory == 0?'WCFTB':'WDFTB')

            // 权限
            this.$store.dispatch('user/allowMember', [P]).then(flag => {
                this.authorization = flag
            })
            // 获取推荐批次
            let batch = getStore('RecommendBatch')
            this.RecommendBatch = JSON.parse(batch)
            
            let selectBatch = getStore('SelectRecommendBatch')
            this.SelectRecommendBatch = JSON.parse(selectBatch)
            this.SelectRecommendBatch.ScoreEqual
            this.tempScoreEqual = Object.keys(this.SelectRecommendBatch.ScoreEqual)
            
            this.tempScoreEqual.sort().reverse()
            
            // 获取选中省份
            let province = getStore('OrgProvince')
            if (!province) {
                this.$message.error('未获取到省份数据！')
                return false
            }
            this.ProvinceObj = JSON.parse(province)

            if (this.modifyFlag) {
                let modifyProvinceObj = getStore('modifyProvinceObj')
                this.ProvinceObj = JSON.parse(modifyProvinceObj)
            } else {

            }
            // 判断是院校专业组还是专业院校组
            this.ProvinceObj.WishWayDic[this.SelectRecommendBatch.BatchId]
            
            this.SelectWishWayObj = this.ProvinceObj.WishWayDic[this.SelectRecommendBatch.BatchId]
            
            this.CollegeOrMajorGroupId = this.SelectWishWayObj.WishWay
            
            this.query.WishWay = this.SelectWishWayObj.WishWay
            // this.query.EducationLevel = this.SelectWishWayObj.EducationLevel
            this.query.RecommendKey = this.SelectRecommendBatch.RecommendKey
            this.query.YearList = this.SelectRecommendBatch.YearList
            this.query.ScoreEqual = this.SelectRecommendBatch.ScoreEqual
            this.query.SubjectList = this.User.SubjectList
            this.query.Score = this.User.Score

            // this.RecommendBatch.Score
            // this.ScoreLimitMin = 0;
            // this.ScoreLimitMax = PLATFROM_CONFIG.ScoreLimitMax - PLATFROM_CONFIG.ScoreLimitMin;

            // this.ReportRangeValue = [0 - PLATFROM_CONFIG.ScoreLowerLimit - PLATFROM_CONFIG.ScoreLimitMin, this.ScoreLimitMax - PLATFROM_CONFIG.ScoreLimitMax - PLATFROM_CONFIG.ScoreUpperLimit];
            
            // 计算分数范围
            this.countScore()

            this.MajorQuery.WishWay = this.SelectWishWayObj.WishWay
            this.MajorQuery.RecommendKey = this.SelectRecommendBatch.RecommendKey
            this.MajorQuery.YearList = this.SelectRecommendBatch.YearList
            this.MajorQuery.ScoreEqual = this.SelectRecommendBatch.ScoreEqual
            this.MajorQuery.SubjectList = this.User.SubjectList


            this.SearchCollegeQuery.WishWay = this.SelectWishWayObj.WishWay
            this.SearchCollegeQuery.RecommendKey = this.SelectRecommendBatch.RecommendKey
            this.SearchCollegeQuery.SubjectList = this.User.SubjectList

            this.getThreeStat()

            this.getScreen()

            this.modifyWish()

            this.getStatData()
        },
        // 获取统计数据
        getStatData() {
            if (this.User.wishCategory==0) {
                API.Recommend.GetRecommendStat(this.query).then(res => {
                    this.NumberStat = res.data
                })
            } else {
                API.Recommend.GetRecommendStatByPredictScore(this.query).then(res => {
                    this.NumberStat = res.data
                })
            }
        },
        // 计算分数范围
        countScore() {
            if(this.User.wishCategory==1) {
                let v1 = this.User.Score + this.ProvinceObj.ScoreRangeUpMax
                let v2 = this.User.Score - this.ProvinceObj.ScoreRangeDownMax
                
                this.ScoreLimitMax = v1
                this.ScoreLimitMin = v2
                
                let d1 = this.User.Score + this.ProvinceObj.ScoreRangeUpDefault
                let d2 = this.User.Score - this.ProvinceObj.ScoreRangeDownDefault
                
                this.SetScoreLimit(d2,d1)
                this.ReportRangeValue = [d2,d1]

            } else {
                let t = Object.keys(this.SelectRecommendBatch.ScoreEqual)
                if (t.length>0) {
                    t.sort().reverse()
                    
                    let TScore = this.SelectRecommendBatch.ScoreEqual[t[0]]
                    
                    let v1 = TScore + this.ProvinceObj.ScoreRangeUpMax
                    let v2 = TScore - this.ProvinceObj.ScoreRangeDownMax
                    
                    this.ScoreLimitMax = v1
                    this.ScoreLimitMin = v2

                    let d1 = TScore + this.ProvinceObj.ScoreRangeUpDefault
                    let d2 = TScore - this.ProvinceObj.ScoreRangeDownDefault
                    
                    this.SetScoreLimit(d2,d1)
                    this.ReportRangeValue = [d2,d1]
                }
            }
        },
        
        // 缓存修改志愿
        modifyWish() {
            let temp = getStore('ReportCheckDataObj')

            if (temp) {
                this.ReportCheckData = JSON.parse(temp)[this.SelectWishWayObj.WishWay]||[]
            }
        },
        // 筛选数据
        selectMajorLevel(type) {
            this.MajorScreenSelectLevel = type
            this.GetMajorTypeData();// 获取专业类别数据
        },
        // 获取筛选数据
        getScreen() {
            // 院校优先
            API.Web.GetCollegeQueryParameter().then(res => {
                this.CollegeScreen = res.Data;
                // 院校地区参数处理
                this.CollegeScreen.CollegeAreaList = this.CollegeScreen.CollegeAreaList||[];
            });

            if(this.SelectWishWayObj.EducationLevel!==0 && this.SelectWishWayObj.EducationLevel!==1) {
                this.MajorScreenSelectLevel = 0;
            } else {
                this.MajorScreenSelectLevel = this.SelectWishWayObj.EducationLevel;
            }
            this.GetMajorTypeData();//获取专业类别数据
        },
        // 获取专业类别数据
        GetMajorTypeData() {
            // 专业优先
            API.Web.GetMajorCategoryTreeList({EducationLevel:this.MajorScreenSelectLevel}).then(res => {
                this.MajorTypeData = res.data;
                this.MajorCheckNumber();
            })
        },
        // 删除院校操作
        deleteSSListAll() {
            this.BottomCollegeCardList = []
            this.SelectSearchList = []
            this.query.CollegeIdList = []

            this.getThreeStat()
        },
        deleteSSList(it, index) {
            
            this.BottomCollegeCardList = this.BottomCollegeCardList.filter(t => t.CollegeId !== it.CollegeId)
            this.SelectSearchList = deepClone(this.BottomCollegeCardList)
            this.query.CollegeIdList = this.SelectSearchList.map(t => t.CollegeId)
            this.getThreeStat()
        },
        // 确认勾选
        SureScreen() {
            this.BottomCollegeCardList = deepClone(this.SelectSearchList)
            this.query.CollegeIdList = this.SelectSearchList.map(i => i.CollegeId)
            this.getThreeStat()
            this.ShowCollegeSearch = false
        },
        SelectSearchItem(item) {
            if (!item.IsPlan) return

            let tempIndex = this.SelectSearchList.findIndex(i => i.CollegeId === item.CollegeId)
            if (tempIndex !== -1) {
                this.SelectSearchList.splice(tempIndex, 1)
            } else {
                this.SelectSearchList.push(item)
            }
        },
        // 搜索院校
        searchCollege() {
            this.SearchCollegeQuery.CollegeName = this.CollegeSeachForm.CollegeName
            if (!this.SearchCollegeQuery.CollegeName){
                this.$message.info('请输入院校');
                return;
            }
            let P = ( this.User.wishCategory === 0?'WCFTB':'WDFTB')
        
            this.$store.dispatch('user/allowMember', [P]).then(flag => {
                if (flag) {
                    this.Showloading()
                    API.Recommend.SearchRecommendCollege(this.SearchCollegeQuery).then(res => {

                        if (res.code==0) {

                            let arr1 = res.data.filter(item => {
                                return item.IsPlan
                            })
                            let arr2 = res.data.filter(item => {
                                return !item.IsPlan
                            })
                            this.SearchCollegeList = arr1.concat(arr2)
                            this.ShowCollegeSearch = true

                            this.loading.close()
                        } else {
                            this.$message.error(res.message)
                            this.loading.close()
                        }
                    })
                } else {
                    let tempText = this.User.wishCategory === 0?('此功能需要开通'+this.nameConfig.WCF):('此功能需要升级'+this.nameConfig.WDF)

                    this.$confirm(tempText, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    }).then(() => {

                    let temp = this.User.wishCategory === 0?'WCF':'WDF'
                    this.$router.push('/openvip?productCode='+temp)
                    }).catch(() => { });
                }
            })
        },
        // 冲稳保样式
        CWBClass(name) {
            return {"冲": 'c', "稳": 'w', "保": 'b'}[name];
        },
        sortCollege(it) {
            let tempIndex = this.ReportCheckData.findIndex(f=>f.RecommendCollegeId===it.RecommendCollegeId)
            return tempIndex+1
        },
        // 选中专业位置
        // sortShow(item) {
        //     let temp = this.ReportCheckData.findIndex(f=>f.RecommendMajorId==item.RecommendMajorId)
        //     return temp + 1
        // },
        sortShow(it) {
            if (this.SelectWishWayObj.WishWay==2) {
				let temp = this.ReportCheckData.findIndex(f=>f.RecommendMajorId==it.RecommendMajorId)
				return temp + 1
			} else {
				let num = 0
				let obj = this.ReportCheckData.find(f=>f.RecommendCollegeId==this.SelectCollege.RecommendCollegeId)
				if (obj) {
					let temp = obj.SelectMajor.findIndex(f=>f.RecommendMajorId==it.RecommendMajorId)
					num = temp+1
				} else {
					num = 0
				}
				return num
			}
        },
        // 填报状态
        fillStatus(majorItem) {
            let status = false
            let temp1 = this.ReportCheckData.find(f=>f.RecommendCollegeId==this.SelectCollege.RecommendCollegeId)
            if (temp1) {
                if (temp1.SelectMajor) {
                    let flag = temp1.SelectMajor.find(m => m.RecommendMajorId === majorItem.RecommendMajorId)
                    flag?(status = true):(status = false)
                } else {
                    status = false
                }
            } else {
                status = false
            }

            return status
        },
        // 实力标签+特色标签
        handleCollegeTags(item,obj) {
            let str='';
            let sl = (item.CollegeStrengthTags ? item.CollegeStrengthTags.split(','):[]);
            let ts = (item.CollegeFeatureTags ? item.CollegeFeatureTags.split(','):[]);
            for(let s=0;s<sl.length;s++){
                str+=(s==0?sl[s]:(obj+'|'+obj+sl[s]));
            }
            for(let t=0;t<ts.length;t++){
                str+=(sl.length==0?ts[t]:(obj+'|'+obj+ts[t]));
            }
            return str;
        },
        handleNature(item) {
            let data = `${item.CollegeProvinceName||''} · ${item.CollegeCityName||''} · ${item.CollegeCategoryName||''} · ${item.CollegeNatureName||''}`
            return data
        },
        // 获取统计
        getThreeStat() {
            this.Showloading();
            this.ResetOrClearSearchStoreData();// 重置 筛选信息
            if (this.CollegeOrMajorGroupId === 1) {
                if (this.User.wishCategory === 1) { // 位点
                    API.Recommend.GetRecommendCollegeGroupStatByPredictScore(this.query).then(res => {
                        if(res){
                            if(res.code==0) {
                                this.ThreeStat = res.data
                                this.resetParams()
                                this.GetReportList()
                            }
                            else {
                                this.$message.error(res.message)
                            }
                        }
                        this.loading.close()
                    })
                }
                else {
                    API.Recommend.GetRecommendCollegeGroupStat(this.query).then(res => {
                        if(res){
                            if(res.code==0) {
                                this.ThreeStat = res.data
                                this.resetParams()
                                this.GetReportList()

                            }
                            else {
                                this.$message.error(res.message)
                            }
                        }
                        this.loading.close()
                    })
                }
            }
            if (this.CollegeOrMajorGroupId === 2) {

                if (this.User.wishCategory === 1) { // 位点
                    API.Recommend.GetRecommendMajorAndCollegeGroupStatByPredictScore(this.query).then(res => {
                        if(res){
                            if(res.code==0) {
                                this.ThreeStat = res.data
                                this.resetParams()
                                this.GetReportList()
                            }
                            else {
                                this.$message.error(res.message)
                            }
                        }
                        this.loading.close()
                    })
                }
                else {
                    API.Recommend.GetRecommendMajorAndCollegeGroupStat(this.query).then(res => {
                        if(res){
                            if(res.code==0) {
                                this.ThreeStat = res.data
                                this.resetParams()
                                this.GetReportList()
                            }
                            else {
                                this.$message.error(res.message)
                            }
                        }
                        this.loading.close()
                    })
                }
            }
        },

        // 重置页面参数
        resetParams() {
            this.pageObj.pageIndex = 1
            this.Main = {}
            this.ReportData = []
        },

        // 院校/专业 优先 切换
        ReportHomeTabBtn(t){
            this.TabActiveId = t;
        },
        // 城市选择
        ProvinceCityCheck(item){
            let temp = this.queryCopy.CollegeCityIdList.find(f => f === item.CityId);// 判断是否存在当前城市
            if(temp){
                this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!=item.CityId);// 移除查询条件的当前城市
                this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.CityId!=item.CityId);// 移除dom选中的当前城市
            }
            else{
                this.queryCopy.CollegeCityIdList.push(item.CityId);// 添加查询条件的当前城市
                this.CollegeCityCheckListCopy.push({CityId: item.CityId,CityName: item.CityName});// 添加dom选中的当前城市
            }
            this.CityCheckNumber();//更新已选城市数量
        },
        // 城市选择/全选
        ProvinceCityAllCheck(item){
            item.CityList.forEach(obj=>{
                this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!=obj.CityId);// 移除查询条件的当前城市
                this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.CityId!=obj.CityId);// 移除dom选中的当前城市
            });
            if(item.CheckNumber!=item.CityList.length){
                item.CityList.forEach(obj=>{
                    this.queryCopy.CollegeCityIdList.push(obj.CityId);// 添加查询条件的当前城市
                    this.CollegeCityCheckListCopy.push({CityId: obj.CityId,CityName: obj.CityName});// 添加dom选中的当前城市
                });
            }
            this.CityCheckNumber();//更新已选城市数量
        },
        // 城市移除
        ProvinceCityRemove(item){
            if(item!=''){
                this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!=item.CityId);// 移除查询条件的当前城市
                this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.CityId!=item.CityId);// 移除dom选中的当前城市
            }
            else{
                this.queryCopy.CollegeCityIdList = [];
                this.CollegeCityCheckListCopy = [];
            }
            this.getThreeStat();
        },
        // 绑定已选城市数量
        CityCheckNumber(){
            this.CollegeScreen.CollegeAreaList.forEach(item=>{
                let num=0;
                item.CityList.forEach(c=>{
                    if(this.queryCopy.CollegeCityIdList.indexOf(c.CityId)>-1){
                        num+=1;
                    }
                });
                item.CheckNumber = num;
            });
        },
        // 绑定已选城市
        CityCheckBind(){
            this.CollegeCityCheckListCopy = [];
            this.CollegeScreen.CollegeAreaList.forEach(item=>{
                item.CityList.forEach(c=>{
                    if(this.queryCopy.CollegeCityIdList.indexOf(c.CityId)>-1){
                        this.CollegeCityCheckListCopy.push({CityId: c.CityId,CityName: c.CityName});
                    }
                });
            });
        },
        // 院校类别选择
        CollegeCategoryCheck(item){
            if(item!=''){
                let flag = this.queryCopy.CollegeCategoryIdList.includes(item.Id)
                if(flag){
                    this.queryCopy.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.CollegeCategoryIdList.push(item.Id);
                }
            }
            else{
                this.queryCopy.CollegeCategoryIdList = [];
            }
        },
        // 院校类别移除
        CollegeCategoryRemove(id){
            if(id!=''){
                this.queryCopy.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.CollegeCategoryIdList = [];
            }
            this.getThreeStat();
        },
        // 办学性质选择
        CollegeNatureCheck(item){
            if(item!=''){
                let flag = this.queryCopy.CollegeNatureIdList.includes(item.Id)
                if(flag){
                    this.queryCopy.CollegeNatureIdList = this.queryCopy.CollegeNatureIdList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.CollegeNatureIdList.push(item.Id);
                }
            }
            else{
                this.queryCopy.CollegeNatureIdList = [];
            }
        },
        // 办学性质移除
        CollegeNatureRemove(id){
            if(id!=''){
                this.queryCopy.CollegeNatureIdList = this.queryCopy.CollegeNatureIdList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.CollegeNatureIdList = [];
            }
            this.getThreeStat();
        },
        // 学历层次选择
        CollegeLevelCheck(value) {
            if (this.queryCopy.EducationLevel === value) {
                this.queryCopy.EducationLevel = null;
            }
            else {
                this.queryCopy.EducationLevel = value;
            }
        },
        // 学历层次移除
        CollegeLevelRemove() {
            this.queryCopy.EducationLevel = null;
            this.getThreeStat()
        },
        // 院校实力标签选择
        CollegeStrengthTagCheck(item){
            if(item!=''){
                let flag = this.queryCopy.StrengthTagList.includes(item.Id)
                if(flag){
                    this.queryCopy.StrengthTagList = this.queryCopy.StrengthTagList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.StrengthTagList.push(item.Id);
                }
            }
            else{
                this.queryCopy.StrengthTagList = [];
            }
        },
        // 院校实力标签移除
        CollegeStrengthTagRemove(id){
            if(id!=''){
                this.queryCopy.StrengthTagList = this.queryCopy.StrengthTagList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.StrengthTagList = [];
            }
            this.getThreeStat();
        },
        // 院校特色标签选择
        CollegeFeatureTagCheck(item){
            if(item!=''){
                let flag = this.queryCopy.FeatureTagList.includes(item.Id)
                if(flag){
                    this.queryCopy.FeatureTagList = this.queryCopy.FeatureTagList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.FeatureTagList.push(item.Id);
                }
            }
            else{
                this.queryCopy.FeatureTagList = [];
            }
        },
        // 院校特色标签移除
        CollegeFeatureTagRemove(id){
            if(id!=''){
                this.queryCopy.FeatureTagList = this.queryCopy.FeatureTagList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.FeatureTagList = [];
            }
            this.getThreeStat();
        },
        // 新增院校选择
        IsNewCollegeCheck(){
            if(this.queryCopy.IsNewCollege){
                this.queryCopy.IsNewCollege = null;
            }
            else{
                this.queryCopy.IsNewCollege = true;
            }
        },
        // 新增院校移除
        IsNewCollegeRemove(){
            this.queryCopy.IsNewCollege = null;
            this.getThreeStat();
        },
        // 新增专业选择
        IsNewMajorCheck(){
            if(this.queryCopy.IsNewMajor){
                this.queryCopy.IsNewMajor = null;
            }
            else{
                this.queryCopy.IsNewMajor = true;
            }
        },
        // 新增专业移除
        IsNewMajorRemove(){
            this.queryCopy.IsNewMajor = null;
            this.getThreeStat();
        },
        // 招生类别选择
        CollegeEnrollCategoryCheck(item){
            if(item!=''){
                let flag = this.queryCopy.EnrollCategoryIdList.includes(item.Id)
                if(flag){
                    this.queryCopy.EnrollCategoryIdList = this.queryCopy.EnrollCategoryIdList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.EnrollCategoryIdList.push(item.Id);
                }
            }
            else{
                this.queryCopy.EnrollCategoryIdList = [];
            }
        },
        // 招生类别移除
        CollegeEnrollCategoryRemove(id){
            if(id!=''){
                this.queryCopy.EnrollCategoryIdList = this.queryCopy.EnrollCategoryIdList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.EnrollCategoryIdList = [];
            }
            this.getThreeStat();
        },
        // 专业类别选择
        MajorTypeCheck(item){
            let temp = this.queryCopy.MajorIdList.find(f => f === item.Id);// 判断是否存在当前专业类
            if(temp){
                this.queryCopy.MajorIdList = this.queryCopy.MajorIdList.filter(f=>f!=item.Id);// 移除查询条件的当前专业类
                this.MajorTypeCheckListCopy = this.MajorTypeCheckListCopy.filter(f=>f.Id!=item.Id);// 移除dom选中的当前专业类
            }
            else{
                this.queryCopy.MajorIdList.push(item.Id);// 添加查询条件的当前专业类
                this.MajorTypeCheckListCopy.push({Id: item.Id,Name: item.Name});// 添加dom选中的当前专业类
            }
            this.MajorCheckNumber();//更新已选专业数量
        },
        // 专业类别选择/全部
        MajorTypeAllCheck(item){
            item.SubList.forEach(obj=>{
                this.queryCopy.MajorIdList = this.queryCopy.MajorIdList.filter(f=>f!=obj.Id);// 移除查询条件的当前专业类
                this.MajorTypeCheckListCopy = this.MajorTypeCheckListCopy.filter(f=>f.Id!=obj.Id);// 移除dom选中的当前专业类
            });
            if(item.CheckNumber!=item.SubList.length){
                item.SubList.forEach(obj=>{
                    this.queryCopy.MajorIdList.push(obj.Id);// 添加查询条件的当前专业类
                    this.MajorTypeCheckListCopy.push({Id: obj.Id,Name: obj.Name});// 添加dom选中的当前专业类
                });
            }
            this.MajorCheckNumber();//更新已选专业数量
        },
        // 专业类别移除
        MajorTypeCheckRemove(item){
            if(item!=''){
                this.queryCopy.MajorIdList = this.queryCopy.MajorIdList.filter(f=>f!=item.Id);// 移除查询条件的当前专业
                this.MajorTypeCheckListCopy = this.MajorTypeCheckListCopy.filter(f=>f.Id!=item.Id);// 移除dom选中的当前专业
            }
            else{
                this.queryCopy.MajorIdList = [];
                this.MajorTypeCheckListCopy = [];
            }
            this.getThreeStat();
        },
        // 绑定已选专业数量
        MajorCheckNumber(){
            this.MajorTypeData.forEach(item=>{
                let num=0;
                item.SubList.forEach(m=>{
                    if(this.queryCopy.MajorIdList.indexOf(m.Id)>-1){
                        num+=1;
                    }
                });
                item.CheckNumber = num;
            });
        },
        // 绑定已选专业
        MajorCheckBind(){
            this.MajorTypeCheckListCopy = [];
            this.MajorTypeData.forEach(item=>{
                item.SubList.forEach(m=>{
                    if(this.queryCopy.MajorIdList.indexOf(m.Id)>-1){
                        this.MajorTypeCheckListCopy.push({Id: m.Id,Name: m.Name});
                    }
                });
            });
        },
        // 缓存、重置 筛选信息
        ResetOrClearSearchStoreData(){
            setStore("SearchStoreData",this.queryCopy);
            // 院校优先
            this.query.CollegeCityIdList = this.queryCopy.CollegeCityIdList;
            this.query.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList;
            this.query.CollegeNatureIdList = this.queryCopy.CollegeNatureIdList;
            this.query.EducationLevel = this.queryCopy.EducationLevel;
            this.query.StrengthTagList = this.queryCopy.StrengthTagList;
            this.query.FeatureTagList = this.queryCopy.FeatureTagList;
            this.query.EnrollCategoryIdList = this.queryCopy.EnrollCategoryIdList;
            this.query.IsNewCollege = this.queryCopy.IsNewCollege;
            this.CollegeCityCheckList = this.CollegeCityCheckListCopy;// 操作dom
            // 专业优先
            this.query.MajorIdList = this.queryCopy.MajorIdList;
            this.query.IsNewMajor = this.queryCopy.IsNewMajor;
            this.MajorTypeCheckList = this.MajorTypeCheckListCopy;// 操作dom
        },
        // 招生类别选择
        RecruitCategoryCheck(item){
            if(item!==''){
                let obj = this.MajorSeachForm.RecruitCategoryId.find(f=>f.Id==item.Id);
                if(obj){
                    this.MajorSeachForm.RecruitCategoryId = this.MajorSeachForm.RecruitCategoryId.filter(f=>f.Id!==item.Id);
                }
                else{
                    this.MajorSeachForm.RecruitCategoryId.push(item);
                }
            }
            else{
                this.MajorSeachForm.RecruitCategoryId = [];
            }
        },
        MSName(key, it) {
            let temp1 = this.MajorTypeData.find(f => f.Id === key)
            let temp2 = temp1.SubList.find(f => f.Id === it)
            return temp2.Name
        },
        // 显示选中标签名称
        showName(id, type) {
            let name = ''
            switch (type) {
                // case 1:
                // this.ShowNameCity.some(it => {
                //     if (it.CityId === id) {
                //         name = it.CityName
                //     }
                // })
                // break;
                case 2:
                this.CollegeScreen.CollegeCategoryList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 3:
                this.CollegeScreen.CollegeNatureList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 4:
                this.CollegeScreen.EnrollCategoryList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 5:
                this.CollegeScreen.FeatureTagList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 6:
                this.CollegeScreen.StrengthTagList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
            }

            return name
        },
        clearCollegeSearch() {
            this.SearchCollegeList = []

            setTimeout(() => {
                this.ShowCollegeSearch = true
            },100)
        },
        // 筛选数据 打开
        OpenSearchLayer(){

            document.getElementById('search_layer_back').style.display='block';
            document.getElementById('search_layer_back').classList.add("layerbackshow");
            document.getElementById('search_layer_back').classList.remove("layerbackhide");
            let obj = document.getElementById("search_list_box");
            obj.style.display='block';
            obj.classList.remove("layerboxhide");
            obj.classList.add("layerboxshow");
            
            //绑定筛选信息
            let data = getStore("SearchStoreData",this.queryCopy);
            if(data){
                data = JSON.parse(data);
                // 院校优先
                this.queryCopy.CollegeCityIdList = data.CollegeCityIdList;
                this.queryCopy.CollegeCategoryIdList = data.CollegeCategoryIdList;
                this.queryCopy.CollegeNatureIdList = data.CollegeNatureIdList;
                this.queryCopy.EducationLevel = data.EducationLevel;
                this.queryCopy.StrengthTagList = data.StrengthTagList;
                this.queryCopy.FeatureTagList = data.FeatureTagList;
                this.queryCopy.EnrollCategoryIdList = data.EnrollCategoryIdList;
                this.queryCopy.IsNewCollege = data.IsNewCollege;
                this.CityCheckNumber();// 绑定已选城市数量
                this.CityCheckBind();// 绑定已选城市
                // 专业优先
                this.queryCopy.MajorIdList = data.MajorIdList;
                this.queryCopy.IsNewMajor = data.IsNewMajor;
                this.MajorCheckNumber();// 绑定已选专业数量
                this.MajorCheckBind();// 绑定已选专业
                // 院校优先与专业优先通用
                this.queryCopy.CollegeIdList = data.CollegeIdList;
            }
        },
        // 关闭筛选数据dom
        CloseSearchLayer(){
            document.getElementById('search_layer_back').classList.add("layerbackhide");
            document.getElementById('search_layer_back').classList.remove("layerbackshow");
            let obj = document.getElementById("search_list_box");
            obj.classList.remove("layerboxshow");
            obj.classList.add("layerboxhide");
            let st = setTimeout(()=>{
                document.getElementById('search_layer_back').style.display='none';
                obj.style.display='none';
                clearTimeout(st);
            },300);
        },
        // 筛选数据关闭、回滚
        CloseSearchLayerOfBack(){
            // 院校优先
            this.queryCopy.CollegeCityIdList = this.query.CollegeCityIdList;
            this.queryCopy.CollegeCategoryIdList = this.query.CollegeCategoryIdList;
            this.queryCopy.CollegeNatureIdList = this.query.CollegeNatureIdList;
            this.queryCopy.EducationLevel = this.query.EducationLevel;
            this.queryCopy.StrengthTagList = this.query.StrengthTagList;
            this.queryCopy.FeatureTagList = this.query.FeatureTagList;
            this.queryCopy.EnrollCategoryIdList = this.query.EnrollCategoryIdList;
            this.queryCopy.IsNewCollege = this.query.IsNewCollege;
            
            // 专业优先
            this.queryCopy.MajorIdList = this.query.MajorIdList;
            this.queryCopy.IsNewMajor = this.query.IsNewMajor;
            // 院校优先与专业优先通用
            this.queryCopy.CollegeIdList = this.query.CollegeIdList;
            this.CloseSearchLayer();//关闭筛选数据dom
        },
        // 筛选提交
        SeachFormSubmit(){
            let P = ( this.User.wishCategory === 0?'WCFTB':'WDFTB')
            this.$store.dispatch('user/allowMember', [P]).then(flag => {
                if (flag) {
                    this.getThreeStat();
                } else {
                    let tempText = this.User.wishCategory === 0?('此功能需要开通'+this.nameConfig.WCF):('此功能需要升级'+this.nameConfig.WDF)
                    this.$confirm(tempText, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    }).then(() => {

                    let temp = this.User.wishCategory === 0?'WCF':'WDF'
                    this.$router.push('/openvip?productCode='+temp)
                    }).catch(() => { });
                }
            })
            this.CloseSearchLayer();//关闭筛选数据dom
        },
        // 院校+专业 / 专业+院校 切换
        CollegeOrMajorGroupChange(val){
            this.CollegeOrMajorGroupId = val;
            this.CWBGroupId = '0';
            // this.query.WishWay = this.CollegeOrMajorGroupId
            this.query.ScoreDiffBegin = null
            this.query.ScoreDiffEnd = null

            this.getThreeStat()
        },
        // 冲稳保 切换
        CWBGroupChange(val){

            
            let P = ( this.User.wishCategory === 0?'WCFTB':'WDFTB')
        
                this.$store.dispatch('user/allowMember', [P]).then(flag => {
                if (flag) {


                    this.CWBGroupId = val;
                    this.ThreeStat.some(item => {
                        if (item.Id === val) {
                            this.query.ScoreDiffBegin = item.ScoreDiffBegin
                            this.query.ScoreDiffEnd = item.ScoreDiffEnd
                            return true
                        }
                    })
                    this.resetParams()
                    this.GetReportList()


                } else {
                    this.CWBGroupId = '0'

                    let tempText = this.User.wishCategory == 0?('此功能需要开通'+this.nameConfig.WCF):('此功能需要升级'+this.nameConfig.WDF)
                    this.$confirm(tempText, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    }).then(() => {

                    let temp = this.User.wishCategory === 0?'WCF':'WDF'
                    this.$router.push('/openvip?productCode='+temp)
                    }).catch(() => { });
                }
            })
        },
        // 推荐范围 拖动改变
        handleRangeShow(val) {

            this.SetScoreLimit(val[0], val[1])
            // let sliders = document.getElementsByClassName('el-slider__button-wrapper');
            // sliders[0].children[0].innerText = val[0];
            // sliders[1].children[0].innerText = val[1];
            // this.query.ScoreBegin = val[0];
            // this.query.ScoreEnd = val[1];
        },
        ReportRangeChange(val){

            let P = ( this.User.wishCategory === 0?'WCFTB':'WDFTB')
            this.$store.dispatch('user/allowMember', [P]).then(flag => {
                if (flag) {

                    this.SetScoreLimit(val[0],val[1]);
                    this.query.ScoreBegin = this.ReportRangeValue[0]
                    this.query.ScoreEnd = this.ReportRangeValue[1]

                    this.getThreeStat()

                } else {

                    let tempText = this.User.wishCategory === 0?('此功能需要开通'+this.nameConfig.WCF):('此功能需要升级'+this.nameConfig.WDF)

                    this.$confirm(tempText, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    }).then(() => {

                    let temp = this.User.wishCategory === 0?'WCF':'WDF'
                    this.$router.push('/openvip?productCode='+temp)
                    }).catch(() => { });
                }
            })
        },
        // 推荐范围 设置dom分数、查询
        SetScoreLimit(min,max){
            let sliders = document.getElementsByClassName('el-slider__button-wrapper');
            sliders[0].children[0].innerText = min;
            sliders[1].children[0].innerText = max;
            this.query.ScoreBegin = min;
            this.query.ScoreEnd = max;
            
        },
        // 获取填报数据
        GetReportList(){
            this.Showloading()
            if(this.CollegeOrMajorGroupId==1){ // 院校

                if (this.User.wishCategory === 1) { // 位点

                    API.Recommend.GetRecommendCollegeListByPredictScore(this.query, this.pageObj).then(res => {
                        if (res.code == 0) {

                            this.Main = res.data

                            this.ReportData = this.ReportData.concat(res.data.Data)

                            if(this.ReportData.length>0){
                                
                                if(this.Main.Total>this.ReportData.length){
                                    this.pagetxt='点击加载更多';
                                }
                                else{
                                    this.pagetxt='已经到底啦！';
                                }
                            }
                            else{
                                this.pagetxt='未查询到数据';
                            }
                            this.loading.close()

                        } else {
                            this.$message.error(res.message)
                            this.loading.close()
                        }
                    })

                } else {

                    API.Recommend.GetRecommendCollegeList(this.query, this.pageObj).then(res => {
                        if (res.code == 0) {

                            this.Main = res.data

                            this.ReportData = this.ReportData.concat(res.data.Data)

                            if(this.ReportData.length>0){
                                
                                if(this.Main.Total>this.ReportData.length){
                                    this.pagetxt='点击加载更多';
                                }
                                else{
                                    this.pagetxt='已经到底啦！';
                                }
                            }
                            else{
                                this.pagetxt='未查询到数据';
                            }
                            this.loading.close()

                        } else {
                            this.$message.error(res.message)
                            this.loading.close()
                        }
                    })
                }
                // this.ReportData=[
                //     {Id:'1',CollegeName:'哈尔滨医科大学',PlanNumber:230,Score:585,TypeStr:'c'},
                //     {Id:'2',CollegeName:'中国矿业大学',PlanNumber:152,Score:572,TypeStr:'w'},
                //     {Id:'3',CollegeName:'成都航空职业技术学院',PlanNumber:56,Score:564,TypeStr:'b'}
                // ];
            }else{ // 专业

                if (this.User.wishCategory === 1) { // 位点

                    API.Recommend.GetRecommendMajorAndCollegeListByPredictScore(this.query, this.pageObj).then(res => {
                        if(res.code==0) {
                            this.Main = res.data

                            this.ReportData = this.ReportData.concat(res.data.Data)

                            if(this.ReportData.length>0){
                                
                                if(this.Main.Total>this.ReportData.length){
                                    this.pagetxt='点击加载更多';
                                }
                                else{
                                    this.pagetxt='已经到底啦！';
                                }
                            }
                            else{
                                this.pagetxt='未查询到数据';
                            }
                            this.loading.close()

                        } else {
                            this.$message.error(res.message)
                            this.loading.close()
                        }
                    })
                } else {
                    API.Recommend.GetRecommendMajorAndCollegeList(this.query, this.pageObj).then(res => {
                        if(res.code==0) {
                            this.Main = res.data

                            this.ReportData = this.ReportData.concat(res.data.Data)

                            if(this.ReportData.length>0){
                                
                                if(this.Main.Total>this.ReportData.length){
                                    this.pagetxt='点击加载更多';
                                }
                                else{
                                    this.pagetxt='已经到底啦！';
                                }
                            }
                            else{
                                this.pagetxt='未查询到数据';
                            }
                            this.loading.close()

                        } else {
                            this.$message.error(res.message)
                            this.loading.close()
                        }
                    })
                }
            }
        },
        // 冲稳保转换
        GetGroupTypeStr(str){
            if(str=='c'){
                return '冲';
            }
            else if(str=='w'){
                return '稳';
            }
            else{
                return '保';
            }
        },
        //加载更多
        pagesbtn(){
            
            if(this.pagetxt=='点击加载更多'){
                this.pageObj.pageIndex++;
                this.GetReportList();// 获取填报数据
            }else {
                if(this.ReportData.length>=this.Main.Total){
                    this.pagetxt='已经到底啦！';
                }
            }
            // if(this.HasNext){
            //     if(this.pagetxt=='点击加载更多'){
            //         this.pageObj.pageIndex++;
            //         this.GetReportList();// 获取填报数据
            //     }
            // }else{
            //     if(this.ReportData.length!=0){
            //         this.pagetxt='已经到底啦！';
            //     }
            // }
        },
        // 专业选择弹层显示
        ReportMajorCheck(item){
            let P = ( this.User.wishCategory === 0?'WCFTB':'WDFTB')
            this.$store.dispatch('user/allowMember', [P]).then(flag => {
                if (flag) {
                    
                    document.getElementById('majorlayerback').style.display='block';
                    document.getElementById('majorlayerback').classList.add("majorbackshow");
                    document.getElementById('majorlayerback').classList.remove("majorbackhide");
                    let obj = document.getElementById("majorlayerbox");
                    obj.style.display='block';
                    obj.classList.remove("majorboxhide");
                    obj.classList.add("majorboxshow");
                    // document.body.style.overflowY='hidden';

                    this.SelectCollege = item
                    this.MajorQuery.RecommendCollegeId = item.RecommendCollegeId
                    
                    this.getMajorList()

                } else {
                    let tempText = this.User.wishCategory === 0?('此功能需要开通'+this.nameConfig.WCF):('此功能需要升级'+this.nameConfig.WDF)
                    this.$confirm(tempText, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    }).then(() => {

                    let temp = this.User.wishCategory === 0?'WCF':'WDF'
                    this.$router.push('/openvip?productCode='+temp)
                    }).catch(() => { });
                }
            })
        },

        // 获取专业列表
        getMajorList() {
            this.Showloading()
            if (this.User.wishCategory === 1) { // 位点

                API.Recommend.GetRecommendMajorListByPredictScore(this.MajorQuery).then(res => {
                    if(res.code==0) {
                        this.RecommendMajorList = res.data

                        this.loading.close()
                    } else {
                        this.$message.error(res.message)
                        this.loading.close()
                    }
                })

            } else {

                API.Recommend.GetRecommendMajorList(this.MajorQuery).then(res => {
                    if(res.code==0) {
                        this.RecommendMajorList = res.data

                        this.loading.close()
                    } else {
                        this.$message.error(res.message)
                        this.loading.close()
                    }
                })
            }
        },

        // 专业选择弹层隐藏
        MajorLayerBack(){
            document.getElementById('majorlayerback').classList.add("majorbackhide");
            document.getElementById('majorlayerback').classList.remove("majorbackshow");
            let obj = document.getElementById("majorlayerbox");
            obj.classList.remove("majorboxshow");
            obj.classList.add("majorboxhide");
            // document.body.style.overflowY='scroll';
            let st = setTimeout(()=>{
                document.getElementById('majorlayerback').style.display='none';
                obj.style.display='none';
                clearTimeout(st);
            },300);
        },
        // 填报 选择/取消
        ReportItemCheck(row, type){

            if (this.SelectWishWayObj.WishWay === 1) {

                let index = this.ReportCheckData.findIndex(f => f.RecommendCollegeId === this.SelectCollege.RecommendCollegeId)
                
                if (index !== -1) {
                    
                    let index2 = this.ReportCheckData[index].SelectMajor.findIndex(f => f.RecommendMajorId === row.RecommendMajorId)

                    if (index2 !== -1) {
                        this.ReportCheckData[index].SelectMajor.splice(index2, 1)
                    } else {
                        this.ReportCheckData[index].SelectMajor.push(row)
                    }

                } else {
                    this.SelectCollege.IsAdjust = true;
                    this.ReportCheckData.push({
                        ...this.SelectCollege,
                        SelectMajor: [
                            {...row}
                        ]
                    })
                }
            } else {

                if(this.ReportCheckData.find(f=>f.RecommendMajorId===row.RecommendMajorId)){
                    this.ReportCheckData = this.ReportCheckData.filter(f=>f.RecommendMajorId!==row.RecommendMajorId);
                }
                else{
                    if (type === 'major') {
                        this.ReportCheckData.push({...row, FromCollege: this.SelectCollege});
                    } else {
                        this.ReportCheckData.push(row);
                    }
                }
            }
        },
        // 填报右侧浮层 展开/收起
        OpenReportBtn(){
            if(this.ReportFixed==0){
                this.AnimateReportClass = 'animate_close';
                this.ReportFixed=-240;
            }
            else{
                this.AnimateReportClass = 'animate_open';
                this.ReportFixed=0;
            }
        },
        // 填报右侧浮层 移除专业
        RemoveReport(item){
            if (this.SelectWishWayObj.WishWay === 1) {
                this.ReportCheckData = this.ReportCheckData.filter(f=>f.RecommendCollegeId!==item.RecommendCollegeId);

            } else {
                this.ReportCheckData = this.ReportCheckData.filter(f=>f.RecommendMajorId!==item.RecommendMajorId);
            }
        },
        removeMajor(item, majorItem) {
            item.SelectMajor = item.SelectMajor.filter(f => f.RecommendMajorId!==majorItem.RecommendMajorId)
            if (item.SelectMajor.length === 0) {
                this.ReportCheckData = this.ReportCheckData.filter(f=>f.RecommendCollegeId!==item.RecommendCollegeId);
            }
        },
        // 填报右侧浮层 是否存在
        IsJoinReport(id){
            let obj = this.ReportCheckData.find(f=>f.RecommendMajorId===id);
            if(obj){
                return true;
            }
            else{
                return false;
            }
        },
        // 填报右侧浮层 生成志愿表
        CreateReportBtn(){
            if(this.ReportCheckData.length==0){
                this.$message({ type: 'error', message: '您还没有选择填报的专业!' });return false;
            }
            this.$confirm('您已填报了 '+this.ReportCheckData.length+' 个专业，是否确定要保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.WishSave()
            }).catch(() => {});
        },

        // 处理保存志愿表
        // 保存
        WishSave(){
            if (!this.LoginInfo) {
                this.$message.error('未获取到登录信息！')
                return false
            }
            this.SaveQuery.UserId = this.LoginInfo.UserId
            this.SaveQuery.ExamProvinceId = this.User.ProvinceId
            this.SaveQuery.ExamProvinceName = this.User.ProvinceName || null
            this.SaveQuery.ExamYear = this.User.Year
            this.SaveQuery.SubjectType = this.User.SubjectType
            this.SaveQuery.Subjects = this.User.SubjectList?(this.User.SubjectList.join()):null
            this.SaveQuery.BatchId = this.SelectRecommendBatch.BatchId
            this.SaveQuery.BatchName = this.SelectRecommendBatch.BatchName || null
            this.SaveQuery.ZYWay = this.SelectWishWayObj.WishWay
            this.SaveQuery.ZYType = this.User.wishCategory
            this.SaveQuery.ZYName = null
            this.SaveQuery.Score = this.User.Score
            this.SaveQuery.RankHigh = this.RecommendBatch.RankHigh
            this.SaveQuery.RankLow = this.RecommendBatch.RankLow
            this.SaveQuery.Rank = this.RecommendBatch.Rank || null
            this.SaveQuery.ScoreDiff = this.User.Score - this.SelectRecommendBatch.BatchScore
            this.SaveQuery.PlanYear = this.SelectRecommendBatch.PlanYear
            this.SaveQuery.RecommendVersion = this.SelectRecommendBatch.RecommendVersion
            this.SaveQuery.RecommendKey = this.SelectRecommendBatch.RecommendKey

            this.SaveQuery.YearList = this.SelectRecommendBatch.YearList

            this.SaveQuery.ScoreEqualDic = this.SelectRecommendBatch.ScoreEqual || null
            this.SaveQuery.RecordList = []

            if (this.$route.query.WishId) {
                this.SaveQuery.Id = this.$route.query.WishId
            } else {
                delete this.SaveQuery.Id
            }

            this.handleParams()
        },

        handleParams() {
            // 1
            if (this.SelectWishWayObj.WishWay != 2) {
                
                this.ReportCheckData.map((item, index) => {
                    let data = []
                    
                    item.SelectMajor.map((sItem, sIndex) => {

                        delete item.MajorPredictScoreMin
                        delete item.MajorPredictScoreMax

                        data.push({MajorNum: sIndex+1, ...sItem})
                    })
                    this.SaveQuery.RecordList.push({...item, MajorList:data, ZYNum: index+1})
                })

                API.Recommend.SaveZY(this.SaveQuery).then(res => {

                    if (res.code==0) {
                        // removeStore('ReportCheckDataObj')
                        this.$message.success('志愿保存成功')

                        let obj = {}
                        if (this.SelectWishWayObj.WishWay != 2) {
                            obj = {
                                1: this.ReportCheckData
                            }
                        }
                        if (this.SelectWishWayObj.WishWay == 2) {
                            obj = {
                                2: this.ReportCheckData
                            }
                        }

                        setStore('ReportCheckDataObj', obj)

                        let p = this.$route.query.WishId
                        if (p) {
                            this.$router.push("/wishtable?WishId="+p);
                        } else {
                            this.$router.push("/wishtable?WishId="+res.data);
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                })

                // this.$prompt('请输入志愿名称', '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                // }).then(({ value }) => {
                //     this.SaveQuery.ZYName = value
                    
                //     API.Recommend.SaveZY(this.SaveQuery).then(res => {

                //         if (res.code==0) {
                //             removeStore('ReportCheckDataObj')
                //             this.$message.success('志愿保存成功')
                //         } else {
                //             this.$message.error(res.message)
                //         }
                //     })
                // })

                // API.Recommend.SaveZY
            } else {

                this.ReportCheckData.map((item, index) => {
                    if (item.FromCollege) {
                        item = {...item.FromCollege, ...item}
                    }
                    // delete item.FromCollege

                    this.SaveQuery.RecordList.push({...item, ZYNum: index+1})
                })

                // this.SaveQuery.ZYName = value

                API.Recommend.SaveZYMajorAndCollege(this.SaveQuery).then(res => {
                    if (res.code==0) {
                        // removeStore('ReportCheckDataObj')
                        this.$message.success('志愿保存成功')


                        let obj = {}
                        if (this.SelectWishWayObj.WishWay != 2) {
                            obj = {
                                1: this.ReportCheckData
                            }
                        }
                        if (this.SelectWishWayObj.WishWay == 2) {
                            obj = {
                                2: this.ReportCheckData
                            }
                        }

                        setStore('ReportCheckDataObj', obj)

                        let p = this.$route.query.WishId
                        if (p) {
                            this.$router.push("/wishtable?WishId="+p);
                        } else {
                            this.$router.push("/wishtable?WishId="+res.data);
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                })

                // this.$prompt('请输入志愿名称', '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                // }).then(({ value }) => {
                //     this.SaveQuery.ZYName = value
                    
                //     API.Recommend.SaveZYMajorAndCollege(this.SaveQuery).then(res => {
                //         if (res.code==0) {
                //             removeStore('ReportCheckDataObj')
                //             this.$message.success('志愿保存成功')
                //         } else {
                //             this.$message.error(res.message)
                //         }
                //     })
                // })
                // API.Recommend.SaveZYMajorAndCollege
            }
        },
        // 备选志愿显示
        StandbyReportShow(){
            document.getElementById('standbyreportlayer').style.display='block';
            document.getElementById('standbyreportlayer').classList.add("standbybackshow");
            document.getElementById('standbyreportlayer').classList.remove("standbybackhide");
            let obj = document.getElementById("standbyreportbox");
            obj.style.display='block';
            obj.classList.remove("standbyboxhide");
            obj.classList.add("standbyboxshow");
            // document.body.style.overflowY='hidden';
        },
        // 备选志愿关闭
        StandbyReportHide(){
            document.getElementById('standbyreportlayer').classList.add("standbybackhide");
            document.getElementById('standbyreportlayer').classList.remove("standbybackshow");
            let obj = document.getElementById("majorlayerbox");
            obj.classList.remove("standbyboxshow");
            obj.classList.add("standbyboxhide");
            // document.body.style.overflowY='scroll';
            let st = setTimeout(()=>{
                document.getElementById('standbyreportlayer').style.display='none';
                obj.style.display='none';
                clearTimeout(st);
            },300);
        },
        // 判断是否加入了备选志愿
        IsJoinStandby(cId,id){
            let obj = this.StandbyData.find(f=>f.CollegeId===cId);
            if(obj){
                if(obj.MajorList.find(f=>f.Id===id)){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return false;
            }
        },
        // 加入备选志愿
        AddStandbyItem(cId,id){
            let obj = this.StandbyData.find(f=>f.CollegeId===cId);
            if(obj){
                obj.MajorList.push({Id:id,MajorName:'化学工程与工艺（'+id+'）'});
            }
            else{
                this.StandbyData.push({CollegeId:cId,CollegeName:'成都航空职业技术学院（'+cId+'）',MajorList:[{Id:id,MajorName:'化学工程与工艺（'+id+'）'}]});
            }
        },
        // 移除备选志愿
        DelStandbyItem(cId,id){
            let obj = this.StandbyData.find(f=>f.CollegeId===cId);
            if(obj){
                obj.MajorList = obj.MajorList.filter(f=>f.Id!==id);// 移除专业备选
                if(obj.MajorList.length==0 || id==0){
                    this.StandbyData = this.StandbyData.filter(f=>f.CollegeId!==cId);// 移除院校及专业备选
                }
            }
        },
        // 获取备选专业总数
        GetStandbyTotal(){
            let num = 0;
            this.StandbyData.forEach(item => {
                num+=item.MajorList.length;
            });
            return num==0?'':num;
        },
        // 备选弹层 添加专业 显示
        AddMajorBoxOpen(cId){
            // cId 院校Id
            document.getElementById('addmajorbox').style.display='block';
            document.getElementById('addmajorbox').classList.add("addmajorboxshow");
            document.getElementById('addmajorbox').classList.remove("addmajorboxhide");
        },
        // 备选弹层 添加专业 关闭
        AddMajorBoxClose(){
            document.getElementById('addmajorbox').classList.add("addmajorboxhide");
            document.getElementById('addmajorbox').classList.remove("addmajorboxshow");
            let st = setTimeout(()=>{
                document.getElementById('addmajorbox').style.display='none';
                clearTimeout(st);
            },300);
        },
        openStat() {
            this.StatLayerShow=true

            this.getStatData()
        },
        // 批次 选项选中
        BatchOptionCheck(val){
            if (val.BatchScore>this.User.Score) {
                return
            }
            this.BatchId = val;
            this.SelectRecommendBatch = val
            this.CWBGroupId = '0'

            setStore('SelectRecommendBatch', this.SelectRecommendBatch)

            if (this.$refs.PopoverBatch) {
                this.$refs.PopoverBatch.showPopper = false;//关闭批次弹出框
            }

            this.query.CollegeIdList = []
            this.SearchCollegeList = []
            this.BottomCollegeCardList = []
            this.CollegeSeachForm.CollegeName = ''

            this.getInfo()
        },
        // 批次显示
        PopoverEnter(){
            this.PopoverShow=true;
        },
        // 批次隐藏
        PopoverLeave(){
            this.PopoverShow=false;
        },
        // 加载
        Showloading() {
            this.loading = this.$loading({
                lock: false,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#000'
            })
        },
        ToLink(url){
            window.open(this.$router.resolve({ path: url }).href, "_blank");
        }
    }
}
</script>
<style scoped lang="less">
.report_home{
    max-width: 1420px;
    margin: 20px auto 0px;
    user-select: none;
    .reporthomebox{
        margin: 0px 20px;
        border: 1px solid #ddd;
        border-bottom: none;
        .reporthometab{
            width: 100%;
            height: 45px;
            position: relative;
            border-bottom: 1px solid #ddd;
            background-color: #fff;
            .tabs{
                float: left;
                .ta{
                    float: left;
                    height: 45px;
                    line-height: 44px;
                    border: 1px solid #ddd;
                    border-top: none;
                    border-left: 0px;
                    background-color: #f5ecec;
                    color: #666666;
                    font-size: 16px;
                    letter-spacing: 2px;
                    padding: 0px 40px;
                    text-align: center;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    i{
                        margin-right: 5px;
                        color: #9e9e9e;
                    }
                }
                .ta.on{
                    background-color: #fcfcfc;
                    color: #555;
                    border-bottom: 1px solid #fcfcfc;
                    i{
                        color: #999;
                    }
                }
                .ta:hover{
                    cursor: pointer;
                    color: rgba(var(--themecolor),1);
                    background-color: #fcfcfc;
                    i{
                        color: rgba(var(--themecolor),0.6);
                    }
                }
            }
            .usermsg{
                float: right;
                height: 45px;
                line-height: 45px;
                margin-right: 20px;
                span{
                    margin-left: 15px;
                    color: #666;
                    font-size: 13px;
                    display: inline-block;
                    i{
                        margin-left: 5px;
                    }
                }
                .text{
                    margin-left: 45px;
                }
            }
        }
        
        .searchbox{
            background-color: #fff;
            border-bottom: 1px solid #ebe9e9;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .t_box{
                padding: 15px 20px 5px;
                .ttxt{
                    width: 90px;
                    float: left;
                    color: #666;
                    font-size: 15px;
                    line-height: 38px;
                }
                .t_data{
                    width: calc(100% - 90px);
                    float: right;
                    .data{
                        float: left;
                        border: 1px solid rgba(var(--themecolor),0.75);
                        border-radius: 3px;
                        position: relative;
                        overflow: hidden;
                        margin: 0px 10px 10px 0px;
                        padding: 2px 41px 2px 10px;
                        .tit{
                            color: #666;
                            font-size: 14px;
                        }
                        .d{
                            display: inline-block;
                            color: #666;
                            font-size: 12px;
                            border: 1px solid #ddd;
                            border-radius: 20px;
                            background-color: #fff;
                            height: 20px;
                            line-height: 20px;
                            padding: 0px 5px 0px 12px;
                            margin: 4px 4px;
                            i{
                                margin-left: 6px;
                                color: #999;
                                font-size: 14px;
                            }
                            i:hover{
                                color: rgba(var(--themecolor),1);
                                cursor: pointer;
                            }
                        }
                        .close{
                            width: 36px;
                            text-align: center;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top:0;
                            background-color: rgba(var(--themecolor),0.75);
                            i{
                                display: block;
                                color: #f0ecec;
                                font-size:18px;
                                height: 20px;
                                line-height: 20px;
                                width: 100%;
                                position: relative;
                                top: calc(50% - 10px);
                                transition: all 0.2s;
                                -webkit-transition: all 0.2s;
                            }
                        }
                        .close:hover{
                            cursor: pointer;
                        }
                        .close:hover i{
                            transform: rotate(180deg);
                            -webkit-transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        .i_box{
            padding: 15px 20px;
            background-color: #fcfcfc;
            .num{
                float: left;
                width: 100px;
                line-height: 38px;
                color: #666;
                font-size: 15px;
                position: relative;
                z-index: 1;
                border: 1px solid #ddd;
                border-radius:5px;
                text-align:center;
                i{
                    margin-left: 2px;
                    color: rgba(var(--themecolor), 0.8);
                }
            }
            .num:hover{
                cursor: pointer;
                color: rgba(var(--themecolor), 1);
                border: 1px solid rgba(var(--themecolor), 0.5);
                i{
                    color: rgba(var(--themecolor), 1);
                }
            }
            .stxt{
                position: relative;
                float: left;
                width: calc(100% - 222px);
                height: 40px;
                z-index: 3;
                margin-left:20px;
                input{
                    display: block;
                    width: calc(100% - 150px);
                    height: 100%;
                    line-height: 38px;
                    line-height: 1;
                    text-indent: 20px;
                    color: #666;
                    font-size: 14px;
                    background-color: #fff;
                    float: left;
                }
                .search-box {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 9;
                    width: 100%;
                    min-height: 100px;
                    max-height: 300px;
                    border: 1px solid #f0f0f0;
                    border-radius: 3px;
                    box-shadow: 0 0 10px #c3c3ed;
                    background-color: #fff;
                    font-size: 14px;

                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #f1f1f1;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #e7e7e7;
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: #d5d4d4;
                    }

                    .search-li {
                        padding: 5px 20px 5px 50px;
                        cursor: pointer;
                        color: #616060;
                        font-size: 15px;
                        border-bottom: 1px solid #f3eeee;
                        position: relative;
                        &:hover {
                            color: rgba(var(--themecolor),1);
                        }
                        .selected-icon {
                            position: absolute;
                            left: 20px;
                            color: rgba(var(--themecolor),1);
                        }
                    }
                    .no-data {
                        padding-top: 40px;
                        text-align: center;
                        color: #999;
                    }
                    .DisableSelect {
                        color: #999;
                        &:hover {
                            cursor: not-allowed;
                            color: #999;
                        }
                    }

                    .search-sure {
                        padding: 6px 20px;
                        margin: 0 10px;
                        border: 1px solid rgba(var(--themecolor), 1);
                        border-radius: 5px;
                        text-align: center;
                        background-color: rgba(var(--themecolor), 1);
                        color: #fff;
                        font-size: 15px;
                        cursor: pointer;

                        position: sticky;
                        bottom: 0;
                        left: 0;
                        z-index: 3;
                        &:hover {
                            .text {
                                color: #efe9e9;
                            }
                            .icon {
                                color: #efe9e9;
                            }
                        }

                        .icon {
                            position: inherit;
                            color: #fff;
                        }
                    }
                }
                .s_btn{
                    width: 150px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    background-color: rgba(var(--themecolor),0.75);
                    float: right;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    i{
                        margin-right: 10px;
                    }
                }
                .s_btn:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
                // .yx_back_close{
                //     position: fixed;
                //     left: 0;
                //     top: 0;
                //     width: 100%;
                //     height: 100%;
                //     background-color: rgba(0,0,0,0.5);
                //     z-index: 1;
                // }
            }
            .tj_tj{
                width: 100px;
                height: 40px;
                line-height: 40px;
                color: #666;
                font-size: 14px;
                float: right;
                text-align: right;
                i{
                    font-size: 12px;
                    color: rgba(var(--themecolor), 0.8);
                    margin-right: 3px;
                }
            }
            .tj_tj:hover{
                cursor: pointer;
                color: rgba(var(--themecolor), 1);
                i{
                    color: rgba(var(--themecolor), 1);
                }
            }
        }
        .reporthead{
            min-height: 60px;
            background-color: #fff;
            .group{
                float: left;
                height: 100%;
                .item{
                    padding: 0px 20px;
                    color: #777;
                    font-size: 17px;
                    letter-spacing: 1px;
                    float: left;
                    height: 44px;
                    line-height: 44px;
                    border-right: 1px solid #e6e6e6;
                    border-bottom: 1px solid #e6e6e6;
                    font-weight: bold;
                    cursor: pointer;
                    background-color: #f5ecec;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }
                .item.on{
                    background-color: #fff;
                    border-bottom: 1px solid #fff;
                }
            }
            .cwb_box{
                float: left;
                border-right: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                height: 44px;
                padding-right: 15px;
                .el-radio{
                    margin-left: 15px;
                    margin-right: 0;
                }
                .item{
                    font-size: 15px;
                    height: 100%;
                    line-height: 44px;
                    cursor: pointer;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    color: #666;
                    .n{
                        font-size: 12px;
                        font-weight: normal;
                        color: #777;
                        margin-left: 5px;
                    }
                }
                .item.c{
                    color: #d63434;
                }
                .item.w{
                    color: #ec8a20;
                }
                .item.b{
                    color: #1ebb72;
                }
            }
            .range{
                float: right;
                margin-right: 20px;
                margin-top: 10px;
                .t{
                    color: #666;
                    font-size: 15px;
                    float: left;
                    margin-top: 7px;
                    margin-right: 5px;
                    .num{
                        font-size: 22px;
                        color: rgba(var(--themecolor), 1);
                    }
                }
                .slider{
                    width: 360px;
                    float: right;
                    position: relative;
                    .range_slider{
                        width: 360px;
                        margin: auto;
                    }
                }
            }
        }
    }
}
.report_data_all{
    width: 100%;
    height: 50px;
    .report_data_fixed.fixed{
        position: fixed;
        top: 40px;
        left: 0px;
        z-index: 2;
    }
    .report_data_fixed{
        width: 100%;
        height: 50px;
        .report_databox{
            max-width: 1420px;
            margin: auto;
            height: 100%;
            .reportdatatop{
                height: 100%;
                margin: 0px 20px;
                background-color: #fff;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                .ul{
                    height: 48px;
                    margin: 0px 10px;
                    background-color: #f8f4ee;
                    border: 1px solid #ddd;
                    display: block;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    .li{
                        display: inline-block;
                        color: #666;
                        font-size: 14px;
                        text-align: center;
                        line-height: 50px;
                        .sort{
                            position: relative;
                            .ico{
                                position: absolute;
                                left: -30px;
                                top: -8px;
                                z-index: 1;
                                width: 30px;
                                height: 36px;
                                display: block;
                                i{
                                    color: #b3b3b3;
                                    font-size: 18px;
                                    display: block;
                                    width: 30px;
                                    height: 18px;
                                    line-height: 18px;
                                    text-align: center;
                                }
                                i:hover{
                                    cursor: pointer;
                                    color: rgba(var(--themecolor), 1);
                                }
                                i.on{
                                    color: rgba(var(--themecolor), 0.8);
                                }
                            }
                        }
                        .year{
                            line-height: normal;
                            display: inline-block;
                            width: 360px;
                            position: relative;
                            height: 30px;
                            .ht{
                                display: inline-block;
                                line-height: 24px;
                                height: 24px;
                                width: 360px;
                                position: absolute;
                                left: 0;
                                top:0;
                            }
                            .y{
                                display: inline-block;
                                line-height: 24px;
                                width: 358px;
                                position: absolute;
                                left: 0;
                                top:24px;
                                height: 24px;
                                border: 1px solid #e0e0e0;
                                border-bottom: none;
                                border-top-left-radius: 5px;
                                border-top-right-radius: 5px;
                                span{
                                    width: 89px;
                                    display: inline-block;
                                }
                                span:nth-child(1){
                                    width: 90px;
                                }
                            }
                        }
                    }
                }
                .ul.yz{
                    .li:nth-child(1){
                        width: 150px;
                    }
                    .li:nth-child(2){
                        width: calc(100% - 850px);
                        text-align: left;
                    }
                    .li:nth-child(3){
                        width: 160px;
                    }
                    .li:nth-child(4){
                        width: 360px;
                    }
                    .li:nth-child(5){
                        width: 180px;
                    }
                }
                .ul.zy{
                    .li:nth-child(1){
                        width: 150px;
                    }
                    .li:nth-child(2){
                        width: 280px;
                    }
                    .li:nth-child(3){
                        width: calc(100% - 1130px);
                        text-align: left;
                    }
                    .li:nth-child(4){
                        width: 160px;
                    }
                    .li:nth-child(5){
                        width: 360px;
                    }
                    .li:nth-child(6){
                        width: 180px;
                    }
                }
            }
        }
    }
}
.report_data_table{
    width: 100%;
    .report_tablebox{
        max-width: 1420px;
        margin: auto;
        .rtablebox{
            margin: 0px 20px;
            border: 1px solid #ddd;
            border-top: none;
            background-color: #fff;
            .rtable{
                margin: 0px 10px 30px;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                .list{
                    background-color: #fff;
                    border-bottom: 1px solid #ddd;
                    .li{
                        display: inline-block;
                        text-align: center;
                        color: #666;
                        font-size: 14px;
                        vertical-align:middle;
                        position: relative;
                        .fen{
                            font-size: 13px;
                            color: #999;
                            margin-left: 5px;
                        }
                        .tp{
                            position: absolute;
                            left: 5px;
                            top: -15px;
                            width: 40px;
                            height: 40px;
                            display: block;
                            font-size: 14px;
                        }
                        .tp.c{
                            color: #d63434;
                        }
                        .tp.w{
                            color: #ec8a20;
                        }
                        .tp.b{
                            color: #1ebb72;
                        }
                        .smark{
                            margin-top: 10px;
                            // margin-bottom: 10px;
                            .sname{
                                font-size: 18px;
                                color: #555;
                                letter-spacing: 1px;
                                font-weight: bold;
                                margin-right: 5px;
                                margin-bottom: 5px;
                                display: inline-block;
                            }
                            .sname:hover{
                                cursor: pointer;
                                color: rgba(var(--themecolor), 0.9);
                            }
                            .g{
                                font-size: 13px;
                                display: inline-block;
                            }
                            .types{
                                font-size: 12px;
                                display: block;
                                line-height: 24px;
                            }
                            .tipss{
                                font-size: 12px;
                                color: #777;
                                margin: 0 0 5px;
                                display: block;
                                position: relative;
                                span{
                                    padding: 0 5px;
                                    border-left: 1px solid #ddd;
                                    display: inline-block;
                                    height: 12px;
                                    line-height: 12px;
                                }
                                span:first-child{
                                    border-left:none;
                                    padding-left: 0;
                                }
                                .more{
                                    display: block;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    width: 80px;
                                    height: 100%;
                                    background: linear-gradient(90deg,rgba(255,255,255,0.2),rgba(255,255,255,1));
                                    border-left: none;
                                }
                            }
                            .hide{
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }
                        table{
                            display: inline-block;
                            margin-top: 10px;
                            table-layout: fixed;
                            border-top: 1px solid #e7e7e7;
                            border-right: 1px solid #e7e7e7;
                            width: 100%;
                            margin-bottom: 6px;
                            tr{
                                border-bottom: 1px solid #e7e7e7;
                                display: block;
                                td{
                                    border-left: 1px solid #e7e7e7;
                                    color: #777;
                                    font-size: 12px;
                                    padding: 4px 5px;

                                    .tips-text {
                                        position: relative;
                                        
                                        .icon {
                                            height: 14px;
                                        }
                                        
                                        &:hover {
                                            .show-text {
                                                display: block;
                                                cursor: default;
                                                text-align: left;
                                            }
                                        }
                                        .show-text {
                                            display: none;
                                            width: 200px;
                                            padding: 8px;
                                            border-radius: 5px;
                                            background-color: rgba(0,0,0,0.8);
                                            color: #fff;
                                            position: absolute;
                                            top: -98px;
                                            left: -100px;
                                            z-index: 2;
                                            line-height: 20px;
                                        }
                                    }
                                    .tips-text:hover{
                                        cursor: pointer;
                                    }
                                }
                                td:nth-child(1){
                                    width: 90px;
                                    padding: 4px 0px;
                                }
                                td:nth-child(2){
                                    width: 80px;
                                }
                                td:nth-child(3){
                                    width: 80px;
                                }
                                td:nth-child(4){
                                    width: 80px;
                                }
                            }
                        }
                        .btn{
                            width: 100px;
                            height: 30px;
                            line-height: 30px;
                            background-color: #faf3f3;
                            border: 1px solid rgba(var(--themecolor), 0.4);
                            border-radius: 3px;
                            display: inline-block;
                            cursor: pointer;
                            margin: 5px 0;
                        }
                        
                        .btn:hover{
                            color: rgba(var(--themecolor), 1);
                        }
                        .btn.ck{
                            color: #fff;
                            background-color: rgba(var(--themecolor), 0.75);
                            border: 1px solid rgba(var(--themecolor), 0.75);
                        }
                        .btn.ck:hover{
                            background-color: rgba(var(--themecolor), 1);
                            border: 1px solid rgba(var(--themecolor), 1);
                        }
                        .check{
                            margin-top: 10px;
                            font-size: 14px;
                        }
                        .icon {
                            color: #999;
                            // font-size: 20px;
                            position: relative;
                            &:hover {
                                .textTips {
                                    display: inline-block;

                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 999;
                                }
                            }
                        }
                    }
                    
                }
                .list:last-child{
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
            .rtable.yz{
                .li:nth-child(1){
                    width: 150px;
                    font-size: 24px;
                }
                .li:nth-child(2){
                    width: calc(100% - 850px);
                    text-align: left;
                    padding-bottom: 5px;
                }
                .li:nth-child(3){
                    width: 160px;
                }
                .li:nth-child(4){
                    width: 360px;
                }
                .li:nth-child(5){
                    width: 180px;
                    user-select:none;
                }
            }
            .rtable.zy{
                .li:nth-child(1){
                    width: 150px;
                    font-size: 24px;
                    .new-add {
                        font-size: 20px;
                    }
                }
                .li:nth-child(2){
                    width: 280px;
                    padding-right: 10px;
                    box-sizing: border-box;
                    .zname{
                        font-size: 16px;
                        line-height: 24px;
                        color: #555;
                        font-weight: bold;
                        display: block;
                        margin: 0 20px;
                    }
                    .zname:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor), 0.9);
                    }
                    .zcode{
                        font-size: 13px;
                        color: #666;
                        // display: inline-block;
                        margin-top: 10px;
                    }
                }
                .li:nth-child(3){
                    width: calc(100% - 1130px);
                    text-align: left;
                }
                .li:nth-child(4){
                    width: 160px;
                }
                .li:nth-child(5){
                    width: 360px;
                }
                .li:nth-child(6){
                    width: 180px;
                    user-select:none;
                }
            }
            .pages{
                width: 100%;
                min-height: 100px;
                line-height: 50px;
                color: #666;
                font-size: 16px;
                text-align: center;
                letter-spacing: 1px;
                user-select: none;
                .iconfont{
                    font-size: 50px;
                    display: block;
                    padding-top: 40px;
                    color: #9b9b9b;
                }
            }
            .pages:hover{
                color: rgb(var(--themecolor));
                cursor: pointer;
            }
        }
    }
}
.majorlayerback{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    display: none;
    opacity: 0;
    .majorlayerbox.majorboxshow {
        animation: majorboxshow 0.3s ease 0s 1;
        -webkit-animation: majorboxshow 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        @-webkit-keyframes majorboxshow {
            from {
                margin-top: -100px;
                opacity: 0;
            }
            to {
                margin-top: 30px;
                opacity: 1;
            }
        }
        @keyframes majorboxshow {
            from {
                margin-top: -100px;
                opacity: 0;
            }
            to {
                margin-top: 30px;
                opacity: 1;
            }
        }
    }
    .majorlayerbox.majorboxhide {
        animation: majorboxhide 0.3s ease 0s 1;
        -webkit-animation: majorboxhide 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        @-webkit-keyframes majorboxhide {
            from {
                margin-top: 30px;
                opacity: 1;
            }
            to {
                margin-top: -100px;
                opacity: 0;
            }
        }
        @keyframes majorboxhide {
            from {
                margin-top: 30px;
                opacity: 1;
            }
            to {
                margin-top: -100px;
                opacity: 0;
            }
        }
    }
    .majorlayerbox{
        max-width: 1420px;
        margin: auto;
        height: calc(100% - 60px);
        margin-top: 30px;
        opacity: 0;
        display: none;
        .majorlayertable{
            background-color: #fff;
            margin: 0px 20px;
            height: 100%;
            border-radius: 5px;
            overflow: hidden;
            .shead{
                width: 100%;
                height: 50px;
                line-height: 50px;
                color: #555;
                font-size: 18px;
                background-color: #f7f7f7;
                font-weight: bold;
                position: relative;
                .yname:hover{
                    cursor: pointer;
                    color: rgba(var(--themecolor), 0.9);
                }
                .logo{
                    width: 30px;
                    height: 30px;
                    margin: 10px 10px 0 15px;
                    float: left;
                }
                .dm{
                    font-size: 14px;
                    font-weight: normal;
                    margin-left: 10px;
                }
                i{
                    width: 50px;
                    height: 100%;
                    line-height: 50px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 1;
                    text-align: center;
                    font-size: 20px;
                    color: #888;
                    cursor: pointer;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }
                i:hover{
                    color: rgba(var(--themecolor),1);
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                }
            }
            .ul{
                height: 48px;
                background-color: #f8f4ee;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                display: block;
            }
            .li{
                display: inline-block;
                color: #666;
                font-size: 14px;
                text-align: center;
                line-height: 50px;
                .sort{
                    position: relative;
                    .ico{
                        position: absolute;
                        left: -30px;
                        top: -8px;
                        z-index: 1;
                        width: 30px;
                        height: 36px;
                        display: block;
                        i{
                            color: #b3b3b3;
                            font-size: 18px;
                            display: block;
                            width: 30px;
                            height: 18px;
                            line-height: 18px;
                            text-align: center;
                        }
                        i:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor), 1);
                        }
                        i.on{
                            color: rgba(var(--themecolor), 0.8);
                        }
                    }
                }
                .year{
                    line-height: normal;
                    display: inline-block;
                    width: 360px;
                    position: relative;
                    height: 30px;
                    .ht{
                        display: inline-block;
                        line-height: 24px;
                        height: 24px;
                        width: 360px;
                        position: absolute;
                        left: 0;
                        top:0;
                    }
                    .y{
                        display: inline-block;
                        line-height: 24px;
                        width: 358px;
                        position: absolute;
                        left: 0;
                        top:24px;
                        height: 24px;
                        border: 1px solid #e0e0e0;
                        border-bottom: none;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        span{
                            width: 89px;
                            display: inline-block;
                        }
                        span:nth-child(1){
                            width: 90px;
                        }
                    }
                }
            }
            .li:nth-child(1){
                width: 150px;
            }
            .li:nth-child(2){
                width: calc(100% - 890px);
            }
            .li:nth-child(3){
                width: 200px;
            }
            .li:nth-child(4){
                width: 360px;
            }
            .li:nth-child(5){
                width: 180px;
            }
            .table{
                height: calc(100% - 48px);
                .tablescroll{
                    height: calc(100% - 112px);
                    .list{
                        background-color: #fff;
                        border-bottom: 1px dashed #b8b6b6;
                        .item{
                            display: inline-block;
                            text-align: center;
                            color: #666;
                            font-size: 14px;
                            vertical-align:middle;
                            position: relative;
                            .fen{
                                font-size: 13px;
                                color: #999;
                                margin-left: 5px;
                            }
                            .tp{
                                position: absolute;
                                left: 5px;
                                top: -15px;
                                width: 40px;
                                height: 40px;
                                display: block;
                                font-size: 14px;
                            }
                            .tp.c{
                                color: #d63434;
                            }
                            .tp.w{
                                color: #ec8a20;
                            }
                            .tp.b{
                                color: #1ebb72;
                            }
                            .smark{
                                margin-top: 10px;
                                // margin-bottom: 10px;
                                .sname{
                                    font-size: 18px;
                                    color: #555;
                                    letter-spacing: 1px;
                                    font-weight: bold;
                                    margin-right: 15px;
                                    margin-bottom: 5px;
                                    display: inline-block;
                                }
                                .sname:hover{
                                    cursor: pointer;
                                    color: rgba(var(--themecolor), 0.9);
                                }
                                .g{
                                    font-size: 13px;
                                    // display: inline-block;
                                }
                                .types{
                                    font-size: 12px;
                                    display: block;
                                    line-height: 24px;
                                }
                                .tipss{
                                    font-size: 12px;
                                    color: #777;
                                    margin: 0 0 5px;
                                    display: block;
                                    span{
                                        padding: 0 5px;
                                        border-left: 1px solid #ddd;
                                        display: inline-block;
                                        height: 12px;
                                        line-height: 12px;
                                    }
                                    span:first-child{
                                        border-left:none;
                                        padding-left: 0;
                                    }
                                }
                            }
                            table{
                                display: inline-block;
                                margin-top: 10px;
                                table-layout: fixed;
                                border-top: 1px solid #e7e7e7;
                                border-right: 1px solid #e7e7e7;
                                width: 100%;
                                margin-bottom: 6px;
                                tr{
                                    border-bottom: 1px solid #e7e7e7;
                                    display: block;
                                    td{
                                        border-left: 1px solid #e7e7e7;
                                        color: #777;
                                        font-size: 12px;
                                        padding: 4px 5px;

                                        .tips-text {
                                            position: relative;
                                            
                                            .icon {
                                                height: 13px;
                                            }

                                            &:hover {
                                                .show-text {
                                                    display: block;
                                                }
                                            }
                                            .show-text {
                                                display: none;
                                                width: 200px;
                                                padding: 8px;
                                                border-radius: 5px;
                                                background-color: rgba(0,0,0,0.8);
                                                color: #fff;
                                                position: absolute;
                                                top: -98px;
                                                left: -100px;
                                                z-index: 2;
                                                line-height: 20px;
                                            }
                                        }
                                        .tips-text:hover{
                                            cursor: pointer;
                                        }
                                    }
                                    td:nth-child(1){
                                        width: 90px;
                                        padding: 4px 0px;
                                    }
                                    td:nth-child(2){
                                        width: 80px;
                                    }
                                    td:nth-child(3){
                                        width: 80px;
                                    }
                                    td:nth-child(4){
                                        width: 80px;
                                    }
                                }
                            }
                            .btn{
                                width: 100px;
                                height: 30px;
                                line-height: 30px;
                                background-color: #faf3f3;
                                border: 1px solid rgba(var(--themecolor), 0.4);
                                border-radius: 3px;
                                display: inline-block;
                                cursor: pointer;
                                margin: 5px 0;
                            }
                            .btn:hover{
                                color: rgba(var(--themecolor), 1);
                            }
                            .btn.ck{
                                color: #fff;
                                background-color: rgba(var(--themecolor), 0.75);
                                border: 1px solid rgba(var(--themecolor), 0.75);
                            }
                            .btn.ck:hover{
                                background-color: rgba(var(--themecolor), 1);
                                border: 1px solid rgba(var(--themecolor), 1);
                            }
                            .check{
                                margin-top: 10px;
                                font-size: 14px;
                            }
                            .disabled-fill {
                                background-color: #ebebeb;
                                border: 1px solid #e2e1e1;
                                color: #cfc9c9;
                                cursor: default;
                                &:hover {
                                    color: #cfc9c9;
                                }
                            }
                        }
                        .item:nth-child(1){
                            width: 150px;
                            font-size: 24px;
                        }
                        .item:nth-child(2){
                            width: calc(100% - 890px);
                        }
                        .item:nth-child(3){
                            width: 200px;
                        }
                        .item:nth-child(4){
                            width: 360px;
                        }
                        .item:nth-child(5){
                            width: 180px;
                            user-select:none;
                        }
                    }
                    .nodata{
                        line-height: 60px;
                        text-align: center;
                        color: #888;
                        font-size: 14px;
                    }
                }
                .backbtn{
                    width: 100%;
                    height: 60px;
                    border-top: 1px solid #ddd;
                    background-color: #fff;
                    .btn{
                        width: 150px;
                        height: 40px;
                        letter-spacing: 1px;
                        line-height: 40px;
                        margin: auto;
                        margin-top: 10px;
                        background-color: rgba(var(--themecolor), 0.75);
                        color: #fff;
                        font-size: 14px;
                        text-align: center;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    .btn:hover{
                        background-color: rgba(var(--themecolor), 1);
                    }
                }
            }
        }
    }
}
.majorlayerback.majorbackshow{
    animation: majorbackshow 0.3s ease 0s 1;
    -webkit-animation: majorbackshow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes majorbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes majorbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.majorlayerback.majorbackhide {
    animation: majorbackhide 0.3s ease 0s 1;
    -webkit-animation: majorbackhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes majorbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes majorbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
.search_layer_back{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 19;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
    background-color: rgba(0,0,0,0.5);
    user-select: none;
    .search_layer_box{
        margin: 50px auto 0;
        max-width: 1420px;
        height: calc(100% - 100px);
        position: relative;
    }
    .m_list{
        border: 1px solid #ddd;
        margin: 0 20px;
        background-color: rgba(255,255,255,9);
        box-shadow: 0px 3px 5px 0px #d4d2d2;
        border-radius: 5px;
        position: absolute;
        z-index: 2;
        opacity: 0;
        display: none;
        height: 100%;
        .sx_scrollbar{
            width: 100%;
            height: 100%;
        }
        .s_box{
            margin: 0 20px 15px 20px;
            border-bottom: 1px dashed #c4c2c2;
            padding-bottom: 5px;
            .tit{
                color: #666;
                font-size: 14px;
                float: left;
                width: 80px;
                text-align: right;
            }
            .box{
                width: calc(100% - 100px);
                float: right;
                .item{
                    display: inline-block;
                    color: #666;
                    font-size: 12px;
                    padding: 2px 18px;
                    border: 1px solid #e2e2e2;
                    border-radius: 20px;
                    margin: 0px 8px 10px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    position: relative;
                    i{
                        margin-left: 5px;
                        color: #999;
                    }
                }
                .item:hover{
                    border: 1px solid rgba(var(--themecolor),0.75);
                    cursor: pointer;
                    color: rgba(var(--themecolor),0.75);
                }
                .item.on{
                    border: 1px solid rgba(var(--themecolor),0.75);
                    background-color: rgba(var(--themecolor),0.75);
                    color: #fff;
                }
            }
        }
        .s_box:nth-child(1){
            margin-top: 20px;
        }
        .level {
            
            .level-title {
                padding-left: 60px;
                color: #666;
                font-size: 14px;
                width: 80px;
                text-align: right;
            }
            .level-box {
                .level-item {
                    color: #666;
                    font-size: 12px;
                    padding: 2px 18px;
                    border: 1px solid #e2e2e2;
                    border-radius: 20px;
                    margin: 0px 8px 10px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    position: relative;
                    &:hover {
                        border: 1px solid rgba(var(--themecolor),0.75);
                        cursor: pointer;
                        color: rgba(var(--themecolor),0.75);
                    }
                }
                .level-item.on {
                    border: 1px solid rgba(var(--themecolor),0.75);
                    background-color: rgba(var(--themecolor),0.75);
                    color: #fff;
                }
            }
        }
        .btnbox{
            width: 100%;
            height: 60px;
            background-color: #fff;
            border-top: 1px solid #ddd;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 2;
            text-align: center;
            .s_back{
                width: 200px;
                height: 40px;
                line-height: 40px;
                border-radius: 3px;
                background-color: #e0e0e0;
                color: #666;
                font-size: 15px;
                letter-spacing: 2px;
                text-align: center;
                margin: 10px 20px;
                display: inline-block;
            }
            .s_back:hover{
                cursor: pointer;
                background-color: #d6d6d6;
            }
            .s_submit{
                width: 200px;
                height: 40px;
                line-height: 40px;
                border-radius: 3px;
                background-color: rgba(var(--themecolor),0.75);
                color: #fff;
                font-size: 15px;
                letter-spacing: 2px;
                text-align: center;
                margin: 10px 20px;
                display: inline-block;
            }
            .s_submit:hover{
                cursor: pointer;
                background-color: rgba(var(--themecolor),1);
            }
        }
    }
    .m_list.layerboxshow {
        animation: layerboxshow 0.3s ease 0s 1;
        -webkit-animation: layerboxshow 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        @-webkit-keyframes layerboxshow {
            from {
                top: -100px;
                opacity: 0;
            }
            to {
                top: 0px;
                opacity: 1;
            }
        }
        @keyframes layerboxshow {
            from {
                top: -100px;
                opacity: 0;
            }
            to {
                top: 0px;
                opacity: 1;
            }
        }
    }
    .m_list.layerboxhide {
        animation: layerboxhide 0.3s ease 0s 1;
        -webkit-animation: layerboxhide 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        @-webkit-keyframes layerboxhide {
            from {
                top: 0px;
                opacity: 1;
            }
            to {
                top: -100px;
                opacity: 0;
            }
        }
        @keyframes layerboxhide {
            from {
                top: 0px;
                opacity: 1;
            }
            to {
                top: -100px;
                opacity: 0;
            }
        }
    }
}
.search_citybox{
    overflow: hidden;
    z-index: 2;
    padding: 10px 0 10px;
    .citem{
        display: inline-block;
        color: #666;
        font-size: 12px;
        padding: 2px 18px;
        border: 1px solid #e2e2e2;
        border-radius: 20px;
        margin: 0px 8px 10px;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
    }
    .citem:hover{
        border: 1px solid rgba(var(--themecolor),0.75);
        cursor: pointer;
        color: rgba(var(--themecolor),0.75);
    }
    .citem.on{
        border: 1px solid rgba(var(--themecolor),0.75);
        background-color: rgba(var(--themecolor),0.75);
        color: #fff;
    }
}
.search_layer_back.layerbackshow {
    animation: layerbackshow 0.3s ease 0s 1;
    -webkit-animation: layerbackshow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.search_layer_back.layerbackhide {
    animation: layerbackhide 0.3s ease 0s 1;
    -webkit-animation: layerbackhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
.report_check_box{
    position: fixed;
    width: 240px;
    height: 476px;
    top: 181px;
    z-index: 21;
    .report_check{
        position: relative;
        width: 100%;
        height: 100%;
        .sr{
            position: absolute;
            left: -48px;
            top: 40px;
            width: 48px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: rgba(var(--themecolor),1);
            text-align: center;
            overflow: hidden;
            cursor: pointer;
            .sr_t{
                padding: 10px 0;
                span{
                    display: inline-block;
                    width: 32px;
                    color: #fff;
                }
            }
            .sr_n{
                border: 1px solid rgba(var(--themecolor),1);
                background-color: #fff;
                border-bottom-left-radius: 5px;
                color: rgba(var(--themecolor),1);
                font-weight: bold;
                width: 46px;
                height: 30px;
                line-height: 30px;
            }
        }
        .open{
            position: absolute;
            left: -48px;
            top: 150px;
            width: 48px;
            border-right: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: rgba(var(--themecolor),1);
            cursor: pointer;
            .ico{
                width: 18px;
                height: 180px;
                line-height: 180px;
                text-align: center;
                color: #e9e8e8;
                font-size: 13px;
                float: left;
                transition: all 0.3s;
                -webkit-transition: all 0.3s;
            }
            .tit{
                width: 14px;
                height: 108px;
                color: #e9e8e8;
                font-size: 16px;
                line-height: 22px;
                margin-left: 2px;
                margin-top: 35px;
                float: left;
            }
            .num{
                width: 46px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-bottom-left-radius: 5px;
                background-color: #fff;
                color: rgba(var(--themecolor),1);
                font-weight: bold;
                overflow: hidden;
                border: 1px solid rgba(var(--themecolor),1);
            }
        }
        .open:hover .ico{
            animation: animate_ico 1s ease 0s infinite;
            -webkit-animation: animate_ico 1s ease 0s infinite;
            @-webkit-keyframes animate_ico {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0.3;
                }
                100% {
                    opacity: 1;
                }
            }
            @keyframes animate_ico {
                0% {
                    opacity: 1;
                    font-size: 13px;
                }
                50% {
                    opacity: 0.3;
                }
                100% {
                    opacity: 1;
                }
            }
        }
        .save{
            position: absolute;
            left: -48px;
            top: 378px;
            width: 48px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: rgba(var(--themecolor),1);
            text-align: center;
            overflow: hidden;
            cursor: pointer;
            .st{
                color: #fff;
                display: inline-block;
                width: 32px;
                color: #fff;
                padding: 10px 0;
            }
        }
        .list{
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #cecece;
            .dlist{
                margin: 10px 10px 0;
                height: 400px;
                .comparescroll{
                    height: 100%;
                    width: 222px;
                }
                .dl:last-child{
                    border-bottom: none;
                }
                .dl{
                    width: 100%;
                    border-bottom: 1px solid #dddada;
                    position: relative;
                    .ind{
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        background-color: rgba(var(--themecolor),0.8);
                        color: #fff;
                        overflow: hidden;
                        border-radius: 20px;
                        font-size: 12px;
                        text-align: center;
                        position: absolute;
                        top:9px;
                        left: 0px;
                        z-index: 1;
                    }
                    .sname{
                        width: calc(100% - 60px);
                        color: #666;
                        font-size: 13px;
                        line-height: 18px;
                        margin-left: 27px;
                        padding-top: 8px;
                    }
                    .delete{
                        color: #999;
                        font-size: 16px;
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        position: absolute;
                        top:2px;
                        right: 0px;
                        z-index: 1;
                    }
                    .delete:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor),1);
                    }
                    .yxlist{
                        margin: 6px 40px 10px 26px;
                        color: #999;
                        font-size: 12px;
                    }
                    .is-adjust{
                        display: block;
                        margin-left: 26px;
                    }
                    .zylist{
                        margin-left: 26px;
                        margin-top: 6px;
                        .zy{
                            margin: 5px auto;
                            .s{
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                                overflow: hidden;
                                float: left;
                                color: #999;
                                font-size: 12px;
                            }
                            .n{
                                float: left;
                                width: calc(100% - 46px);
                                color: #888;
                                font-size: 12px;
                                line-height: 14px;
                                padding-top: 3px;
                            }
                            i{
                                display: inline-block;
                                width: 20px;
                                height: 20px;
                                text-align: center;
                                line-height: 20px;
                                color: #a0a0a0;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .btn{
                    width: 160px;
                    height: 38px;
                    text-align: center;
                    line-height: 38px;
                    color: #fff;
                    font-size: 14px;
                    background-color: rgba(var(--themecolor),0.75);
                    cursor: pointer;
                    letter-spacing: 1px;
                    margin: auto;
                    margin-top: 15px;
                    border-radius: 5px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }
                .btn:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
    }
}
.report_check_box.animate_open{
    animation: animate_open 0.3s ease 0s 1;
    -webkit-animation: animate_open 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes animate_open {
        from {
            right: -240px;
        }
        to {
            right: 0px;
        }
    }
    @keyframes animate_open {
        from {
            right: -240px;
        }
        to {
            right: 0px;
        }
    }
}
.report_check_box.animate_close{
    animation: animate_close 0.3s ease 0s 1;
    -webkit-animation: animate_close 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes animate_close {
        from {
            right: 0px;
        }
        to {
            right: -240px;
        }
    }
    @keyframes animate_close {
        from {
            right: 0px;
        }
        to {
            right: -240px;
        }
    }
}
.standby_report_layer{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    display: none;
    opacity: 0;
    .standby_report_box.standbyboxshow {
        animation: standbyboxshow 0.3s ease 0s 1;
        -webkit-animation: standbyboxshow 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        @-webkit-keyframes standbyboxshow {
            from {
                margin-top: -100px;
                opacity: 0;
            }
            to {
                margin-top: 50px;
                opacity: 1;
            }
        }
        @keyframes standbyboxshow {
            from {
                margin-top: -100px;
                opacity: 0;
            }
            to {
                margin-top: 50px;
                opacity: 1;
            }
        }
    }
    .standby_report_box.standbyboxhide {
        animation: standbyboxhide 0.3s ease 0s 1;
        -webkit-animation: standbyboxhide 0.3s ease 0s 1;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        @-webkit-keyframes standbyboxhide {
            from {
                margin-top: 50px;
                opacity: 1;
            }
            to {
                margin-top: -100px;
                opacity: 0;
            }
        }
        @keyframes standbyboxhide {
            from {
                margin-top: 50px;
                opacity: 1;
            }
            to {
                margin-top: -100px;
                opacity: 0;
            }
        }
    }
    .standby_report_box{
        width: 700px;
        margin: auto;
        height: calc(100% - 100px);
        margin-top: 50px;
        opacity: 0;
        display: none;
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        .standby_tit{
            width: 100%;
            height: 40px;
            border-bottom: 1px solid #ddd;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            background-color: #fff;
            span{
                margin-left: 20px;
                color: #555;
                font-size: 16px;
                line-height: 40px;
            }
            i{
                width: 40px;
                height: 100%;
                line-height: 40px;
                float: right;
                text-align: center;
                font-size: 20px;
                color: #888;
                cursor: pointer;
                transition: all 0.2s;
                -webkit-transition: all 0.2s;
            }
            i:hover{
                color: rgba(var(--themecolor),1);
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
            }
        }
        .standbybox{
            width: 100%;
            height:calc(100% - 102px);
            margin-top: 41px;
            .sritem{
                margin: 20px 20px;
                border: 1px solid #ddd;
                border-radius:5px;
                overflow:hidden;
                .csbox{
                    width: 100%;
                    border-bottom: 1px solid #ddd;
                    height: 50px;
                    line-height: 50px;
                    position: relative;
                    background-color: rgba(var(--themecolor), 0.15);
                    .n{
                        color: #555;
                        font-size: 18px;
                        margin-left: 20px;
                    }
                    .s{
                        color: #666;
                        font-size: 14px;
                        margin-left: 20px;
                    }
                    .m{
                        font-size: 12px;
                        color: #666;
                        position: absolute;
                        right: 40px;
                        top: 0px;
                        i{
                            margin-right: 3px;
                            color: #999;
                        }
                    }
                    .m:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor), 1);
                        i{
                            color: rgba(var(--themecolor), 1);
                        }
                    }
                    .c{
                        display: inline-block;
                        color: #999;
                        font-size: 16px;
                        position: absolute;
                        right: 0px;
                        top:0px;
                        height:50px;
                        width: 30px;
                        line-height:50px;
                        text-align: center;
                    }
                    .c:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor), 1);
                    }
                }
                .mjitem{
                    padding: 10px 0;
                    .mjbox{
                        background-color: rgba(var(--themecolor), 0.1);
                        margin:8px 15px;
                        padding: 8px 10px;
                        border-radius:5px;
                        position: relative;
                        .n{
                            color: #666;
                            font-size: 14px;
                            line-height: 18px;
                            display: inline-block;
                            width:calc(100% - 200px);
                        }
                        .m{
                            font-size: 12px;
                            color: #fff;
                            position: absolute;
                            right: 40px;
                            top: 8px;
                            cursor: pointer;
                            background-color: #faf3f3;
                            border: 1px solid rgba(var(--themecolor), 0.4);
                            border-radius: 20px;
                            padding: 2px 10px;
                            color: #666;
                            i{
                                margin-right: 3px;
                            }
                        }
                        .m:hover{
                            color: rgba(var(--themecolor), 1);
                        }
                        .ch{
                            color: #fff;
                            background-color: rgba(var(--themecolor), 0.8);
                        }
                        .ch:hover{
                            color: #fff;
                            background-color: rgba(var(--themecolor), 1);
                        }
                        .c{
                            display: inline-block;
                            color: #888;
                            font-size: 14px;
                            width: 36px;
                            height: 36px;
                            line-height: 36px;
                            text-align: center;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                        .c:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor), 1);
                        }
                    }
                }
            }
            .nomsg{
                text-align: center;
                line-height: 40px;
                color: #888;
                font-size: 14px;
            }
        }
        .backbtn{
            width: 100%;
            height: 60px;
            border-top: 1px solid #ddd;
            background-color: #fff;
            .btn{
                width: 150px;
                height: 40px;
                letter-spacing: 1px;
                line-height: 40px;
                margin: auto;
                margin-top: auto;
                margin-top: 10px;
                background-color: rgba(var(--themecolor), 0.75);
                color: #fff;
                font-size: 14px;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
            }
            .btn:hover{
                background-color: rgba(var(--themecolor), 1);
            }
        }
        .add_major_box{
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,0.6);
            opacity: 0;
            display: none;
            .add_box{
                width: 640px;
                height: calc(100% - 60px);
                margin: 30px;
                background-color: #fff;
                border-radius: 5px;
                overflow: hidden;
                position: relative;
                .add_tit{
                    width: 100%;
                    height: 40px;
                    border-bottom: 1px solid #ddd;
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    background-color: #fff;
                    span{
                        margin-left: 20px;
                        color: #555;
                        font-size: 16px;
                        line-height: 40px;
                    }
                    i{
                        width: 40px;
                        height: 100%;
                        line-height: 40px;
                        float: right;
                        text-align: center;
                        font-size: 20px;
                        color: #888;
                        cursor: pointer;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                    }
                    i:hover{
                        color: rgba(var(--themecolor),1);
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                    }
                }
                .add_list{
                    width: 100%;
                    height:calc(100% - 102px);
                    margin-top: 41px;
                    table{
                        width: 100%;
                        background-color: #f3f3f3;
                        tr{
                            td{
                                border-bottom: 1px solid #ddd;
                            }
                            td:nth-child(1){
                                color: #333;
                                font-size: 16px;
                                line-height: 24px;
                                padding: 15px 15px;
                            }
                            td:nth-child(2){
                                color: #666;
                                font-size: 14px;
                                width: 150px;
                            }
                            td:nth-child(3){
                                width: 80px;
                                span{
                                    display: inline-block;
                                    width: 60px;
                                    height: 26px;
                                    line-height: 26px;
                                    color: #666;
                                    font-size: 12px;
                                    letter-spacing: 1px;
                                    text-align: center;
                                    border-radius: 3px;
                                    border: 1px solid rgba(var(--themecolor), 0.4);
                                    cursor: pointer;
                                }
                                span:hover{
                                    border: 1px solid rgba(var(--themecolor), 0.8);
                                }
                                span.ch{
                                    border: 1px solid rgba(var(--themecolor), 0.8);
                                    background-color: rgba(var(--themecolor), 0.8);
                                    color: #fff;
                                }
                                span.ch:hover{
                                    border: 1px solid rgba(var(--themecolor), 0.65);
                                    background-color: rgba(var(--themecolor), 0.65);
                                }
                            }
                        }
                    }
                    .nomajor{
                        line-height: 40px;
                        color: #999;
                        font-size: 14px;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
        .add_major_box.addmajorboxshow{
            animation: addmajorboxshow 0.3s ease 0s 1;
            -webkit-animation: addmajorboxshow 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes addmajorboxshow {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            @keyframes addmajorboxshow {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }
        .add_major_box.addmajorboxhide{
            animation: addmajorboxhide 0.3s ease 0s 1;
            -webkit-animation: addmajorboxhide 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes addmajorboxhide {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }
            @keyframes addmajorboxhide {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }
        }
    }
}
.standby_report_layer.standbybackshow{
    animation: standbybackshow 0.3s ease 0s 1;
    -webkit-animation: standbybackshow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes standbybackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes standbybackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.standby_report_layer.standbybackhide {
    animation: standbybackhide 0.3s ease 0s 1;
    -webkit-animation: standbybackhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes standbybackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes standbybackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
.stat_layer{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: rgba(0,0,0,0.5);
    .stat_box{
        width: 800px;
        height: calc(100% - 100px);
        background-color: #fff;
        border-radius: 5px;
        margin: auto;
        margin-top: 50px;
        overflow: hidden;
        .stat_scroll{
            width: 100%;
            height: 100%;
            .info_box{
                color: #666;
                font-size: 14px;
                margin: 0px 30px;
                line-height: 20px;
                overflow: hidden;
                padding-top: 25px;
                .area{
                    float: left;
                    margin-right: 15px;
                    i{
                        font-size: 12px;
                        margin-left: 4px;
                        color: #999;
                    }
                }
                .batch:hover{
                    cursor: pointer;
                    color: rgba(var(--themecolor),1);
                    i{
                        color: rgba(var(--themecolor),1);
                    }
                }
            }
            .stat{
                margin-top: 40px;
                height: 250px;
                position: relative;
                padding: 0 50px;
                background: url(../../assets/images/statback.png) no-repeat center bottom / 100%;
                .item{
                    width: calc(100% / 3);
                    position: relative;
                    float: left;
                    z-index: 3;
                    .ty{
                        margin: 0 auto;
                        width: 140px;
                        height: 140px;
                        background-color: #fff;
                        border-radius: 100px;
                        text-align: center;
                        position: relative;
                        .num{
                            border-radius: 100px;
                            width: 100px;
                            height: 100px;
                            .shu{
                                color: #000;
                                font-size: 32px;
                                font-weight: bold;
                                overflow: hidden;
                                line-height: 100px;
                            }
                        }
                        .border{
                            width: 1px;
                            height: 30px;
                            background-color: #d1d0d0;
                            position: absolute;
                            left: 70px;
                            bottom: -30px;
                            z-index: 1;
                        }
                        .t1{
                            width: 120px;
                            height: 30px;
                            line-height: 30px;
                            border: 1px solid #d1d0d0;
                            position: absolute;
                            bottom: -62px;
                            left: 9px;
                            font-size: 15px;
                            text-align: center;
                            border-radius: 5px;
                            background-color: #fff;
                            .bai{
                                color: #666;
                                margin-right: 5px;
                            }
                        }
                        .num_animate{
                            animation: num_animate 2.2s ease 0s 1;
                            -webkit-animation: num_animate 2.2s ease 0s 1;
                            animation-fill-mode: forwards;
                            -webkit-animation-fill-mode: forwards;
                            @-webkit-keyframes num_animate {
                                from {
                                    color: #ddd;
                                }
                                to {
                                    color: #000;
                                }
                            }
                            @keyframes num_animate {
                                from {
                                    color: #ddd;
                                }
                                to {
                                    color: #000;
                                }
                            }
                        }
                        .show_animate{
                            animation: show_animate 1.8s ease 0s 1;
                            -webkit-animation: show_animate 1.8s ease 0s 1;
                            animation-fill-mode: forwards;
                            -webkit-animation-fill-mode: forwards;
                            @-webkit-keyframes show_animate {
                                from {
                                    opacity: 0;
                                }
                                to {
                                    opacity: 1;
                                }
                            }
                            @keyframes show_animate {
                                from {
                                    opacity: 0;
                                }
                                to {
                                    opacity: 1;
                                }
                            }
                        }
                        .t_animate{
                            animation: t_animate 1s ease 0s 1;
                            -webkit-animation: t_animate 1s ease 0s 1;
                            animation-fill-mode: forwards;
                            -webkit-animation-fill-mode: forwards;
                            @-webkit-keyframes t_animate {
                                from {
                                    opacity: 0;
                                    bottom: -200px;
                                }
                                to {
                                    opacity: 1;
                                    bottom: -62px;
                                }
                            }
                            @keyframes t_animate {
                                from {
                                    opacity: 0;
                                    bottom: -200px;
                                }
                                to {
                                    opacity: 1;
                                    bottom: -62px;
                                }
                            }
                        }
                    }
                    .ty.c .num{
                        border: 20px solid #d63434;
                    }
                    .ty.c .t1{
                        color: #d63434;
                    }
                    .ty.w .num{
                        border: 20px solid #ec8a20;
                    }
                    .ty.w .t1{
                        color: #ec8a20;
                    }
                    .ty.b .num{
                        border: 20px solid #1ebb72;
                    }
                    .ty.b .t1{
                        color: #1ebb72;
                    }
                }
                .line{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                    width: 100%;
                    height: 40px;
                    background: linear-gradient(to top, #fff, transparent);
                }
            }
            .type{
                width: 100%;
                background-color: #fff;
                .head{
                    color: #666;
                    font-size: 18px;
                    text-align: center;
                    .n{
                        font-size: 26px;
                        margin: 0 14px;
                        color: rgba(var(--themecolor),1);
                    }
                }
                .list{
                    padding: 30px 30px 0;
                    background-color: #fff;
                    .li{
                        width: calc(100% / 3);
                        float: left;
                        height: 100px;
                        text-align: center;
                        .s{
                            font-size: 26px;
                            margin-top: 10px;
                            color: #444;
                            font-weight: bold;
                            letter-spacing: 2px;
                        }
                        .t{
                            font-size: 14px;
                            color: #888;
                            margin-top: 10px;
                        }
                    }
                }
            }
            .tips{
                margin: 20px 30px 0;
                background-color: #f8f7f7;
                border: 1px dashed rgba(var(--themecolor), 1);
                border-radius: 5px;
                padding-bottom: 5px;
                .tt{
                    color: #ee6d04;
                    font-size: 16px;
                    padding: 10px 0 0px;
                    letter-spacing: 2px;
                    margin: 0 20px;
                    i{
                        font-size: 19px;
                        margin-right: 6px;
                    }
                }
                .bz{
                    color: #777;
                    font-size: 14px;
                    line-height: 22px;
                    margin: 10px 46px;
                }
            }
            .gbtn{
                width: 100%;
                height: 80px;
                background-color: #fff;
                border-top: 1px solid #eee;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                .btn{
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: #fff;
                    margin: 15px;
                    border-radius: 5px;
                    letter-spacing: 2px;
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),0.8);
                    transition: all 0.3s;
                    -webkit-transition: all 0.3s;
                }
                .btn:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
    }
}
.batch_item_popo{
    color: #666;
    font-size: 15px;
    font-weight: bold;
    line-height: 36px;
    text-align: center;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    border-radius: 5px;
    cursor: pointer;

    .disabled {
        color: #aeaeae;
        cursor: default;
        &:hover {
            background-color: #fff;
        }
    }
}
.batch_item_popo:hover{
    color: #444;
    background-color: rgba(var(--themecolor),0.15);
}
</style>
<style lang="less">
.report_home .reporthomebox .el-input__inner{
    border: 1px solid #ddd;
    border-right:0;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.report_home .reporthomebox .el-input.is-active .el-input__inner, .report_home .reporthomebox .el-input .el-input__inner:focus{
    border: 1px solid rgba(var(--themecolor),0.75);
    border-right:0;
}
.report_home .reporthomebox .reporthometab .el-tabs__header{
    margin: 0;
}
.report_home .reporthomebox .reporthometab .el-tabs__nav{
    z-index: 0;
    margin-left: 1px;
}
.report_home .reporthomebox .reporthometab .el-tabs__item{
    color: #eee;
    background-color: rgba(var(--themecolor),0.75);
    padding: 0 30px;
}
.report_home .reporthomebox .reporthometab .el-tabs__item:hover{
    color: #fff;
    background-color: rgba(var(--themecolor),0.55);
}
.report_home .reporthomebox .reporthometab .el-tabs--card > .el-tabs__header{
    border-bottom: none;
}
.report_home .reporthomebox .reporthometab .el-tabs--card > .el-tabs__header .el-tabs__nav{
    border: none;
    overflow: hidden;
}
.report_home .reporthomebox .reporthometab .el-tabs--card > .el-tabs__header .el-tabs__item:first-child{
    border-left: none;
}
.report_home .reporthomebox .reporthometab .el-tabs--card > .el-tabs__header .el-tabs__item{
    border-left: 1px solid rgba(var(--themecolor),0.55);
}
.report_home .reporthomebox .reporthometab .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
    color: #666;
    background-color: #fff;
}
.report_home .reporthomebox .reporthometab .el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-focus {
  box-shadow: none;
  border-radius: 0;
}
.reporthead .el-slider__bar{
    background-color: rgba(var(--themecolor),0.5);
}
.reporthead .el-slider__button{
  border: 2px solid rgba(var(--themecolor),0.7);
}
.reporthead .el-slider__button-wrapper{
    z-index: 1;
}
.reporthead .el-input__inner:checked,.reporthead .el-input__inner:focus{
  border: 1px solid rgba(var(--themecolor),0.7);
}
.reporthead .el-range-editor.is-active, .reporthead .el-range-editor.is-active:hover, .reporthead .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.7);
}
.cwb_box .el-radio .el-radio__input.is-checked .el-radio__inner{
    border-color: #999;
    background: #999;
}
.cwb_box .el-radio.c .el-radio__input.is-checked .el-radio__inner{
    border-color: #d63434;
    background: #d63434;
}
.cwb_box .el-radio.w .el-radio__input.is-checked .el-radio__inner{
    border-color: #ec8a20;
    background: #ec8a20;
}
.cwb_box .el-radio.b .el-radio__input.is-checked .el-radio__inner{
    border-color: #1ebb72;
    background: #1ebb72;
}
.cwb_box .el-radio .el-radio__inner:hover,.cwb_box .el-radio:hover .el-radio__inner{
    border-color: #acadad;
}
.cwb_box .el-radio .el-radio__label{
    padding-left: 5px;
}
.majorlayerback .majorlayerbox .majorlayertable .el-scrollbar__wrap{
    overflow-x: hidden;
}
.report_check_box .report_check .el-scrollbar__wrap{
    overflow-x: hidden;
}
.standby_report_layer .standby_report_box .el-scrollbar__wrap{
    overflow-x: hidden;
}
.search_layer_back .m_list .el-scrollbar__wrap{
    overflow-x: hidden;
}
.search_layer_back .search_layer_box .m_list .s_box .box .el-button{
    padding:3px 10px 3px 18px;
    margin: 0px 8px 10px;
    border-radius: 20px;
    color: #666;
    font-size: 12px;
    position: relative;
    i{
        color: #999;
        font-size: 12px;
        margin-left: 3px;
    }
    .num{
        position: absolute;
        right: -7px;
        top: -8px;
        z-index: 2;
        font-size: 8.5px;
        background-color: rgba(var(--themecolor), 0.75);
        color: #fff;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        line-height: 12px;
        text-align: center;
        overflow: hidden;
    }
}
.tips-words {
    margin-right: 5px;
    font-size: 12px;
}
.evaluate {
    margin-left: 8px;
}
.search_layer_back .search_layer_box .m_list .s_box .box .el-button:hover{
    border-color: rgba(var(--themecolor),0.8);
    background-color: #fff;
    color: rgba(var(--themecolor),0.8);
}
.search_layer_back .search_layer_box .m_list .s_box .box .el-button:focus{
    border: 1px solid #DCDFE6;
}
.search_city_popover.el-popover{
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.3) !important;
}
.el-message-box__wrapper .el-message-box .el-button:focus, .el-message-box__wrapper .el-message-box .el-button:hover{
    color: rgba(var(--themecolor),0.8);
    border-color: rgba(var(--themecolor),0.5);
}
.el-message-box__wrapper .el-message-box .el-button--primary{
    background-color: rgba(var(--themecolor),0.8);
    border-color: rgba(var(--themecolor),0.8);
}
.el-message-box__wrapper .el-message-box .el-button--primary:focus{
    color: #fff;
    background: rgba(var(--themecolor),0.8);
    border-color: rgba(var(--themecolor),0.8);
}
.el-message-box__wrapper .el-message-box .el-button--primary:hover{
    color: #fff;
    background: rgba(var(--themecolor),1);
    border-color: rgba(var(--themecolor),1);
}
.el-message-box__wrapper .el-message-box .el-message-box__headerbtn:focus .el-message-box__close, .el-message-box__wrapper .el-message-box .el-message-box__headerbtn:hover .el-message-box__close{
    color: rgba(var(--themecolor),1);
}
.stat_layer .stat_box .el-scrollbar__wrap{
    overflow-x: hidden;
}
.is-adjust .el-checkbox__inner{
    width: 12px;
    height: 12px;
}
.is-adjust .el-checkbox__label{
    font-size: 12px;
    padding-left: 5px;
    color: #888;
}
.is-adjust .el-checkbox__inner:hover{
    border-color:rgba(var(--themecolor), 0.8);
}
.is-adjust .el-checkbox__input.is-focus .el-checkbox__inner{
    border-color:#DCDFE6;
}
.is-adjust .el-checkbox__input.is-checked .el-checkbox__inner, .is-adjust .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: rgba(var(--themecolor), 0.8);
    border-color: rgba(var(--themecolor), 0.8);
}
.is-adjust .el-checkbox__input.is-checked + .el-checkbox__label{
    color: #888;
}
</style>