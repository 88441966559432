<template>
  <div class="planBody">
    <div class="head">
      <div class="headerTitle">{{$route.query.CategoryName}}</div>
    </div>
    <div class="planBodyMain">
      <div
        class="video-item"
        v-for="(item, index) in mainData"
        :key="index"
        :class="{ listitemNone: (index + 1) % 4 === 0 }"
        @click="playCourse(item)"
      >
        <img
          class="list-img"
          :src="item.CoverImageFileUrl	 ? item.CoverImageFileUrl	 : imagesURLComplete"
        />
        <div class="list-title">{{ item.VideoName }}</div>
      </div>
      <div class="pages" @click.prevent="pagesbtn()">
        <i class="iconfont" v-if="mainData.length==0&&pageIndex==1">&#xe67c;</i>
        <span>{{pagetxt}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api/config'
export default {
  data () {
    return {
      //图片地址
      imagesBaseURL: this.$store.state.imagesBaseURL,
      imagesURLComplete: this.$store.state.imagesURL + "/images/shushekaoqing.png",
      imagesURL: this.$store.state.imagesURL,
      mainData: [],
      pageIndex: 1,
      pageSize: 12,
      pagetxt:'',
    };
  },
  mounted () {
    this.initialize();
  },
  methods: {
    // 播放
    playCourse (params) {
      this.$router.push({
        name: 'video-detail',
        query: {
          CoverImageUrl: params.CoverImageFileUrl,
          Url: params.VideoFileUrl,
          CoursewareName: params.VideoName,
          Description: params.VideoDesc
        }
      })
    },
    handleCurrentChange (val) {
      this.pageIndex = val;
      this.initialize();
    },
    // 初始化获取数据
    initialize () {
      API.CareerPlan.GetVideoList(this.pageIndex,this.pageSize,{
        CategoryId: this.$route.query.CategoryId,
      }).then((res) => {
        if(res.Data.Data.length>0){
          res.Data.Data.forEach(f => {
            this.mainData.push(f);
          });
          this.HasNext=res.Data.HasNext;
          if(this.HasNext){
            this.pagetxt='点击加载更多';
          }
          else{
            this.pagetxt='已经到底啦！';
          }
        }
        else{
          this.pagetxt='未找到信息！';
        }
      });
    },
    // 翻页
    pagesbtn(){
      if(this.HasNext){
        if(this.pagetxt=='点击加载更多'){
          this.pageIndex+=1;
          this.initialize();// 获取数据列表
        }
      }else{
        if(this.mainData.length!=0){
          this.pagetxt='已经到底啦！';
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .headerTitle {
    font-size: 34px;
    line-height: 48px;
    color: #31415e;
  }
}

.planBody {
  padding: 40px 64px;
  background: #eef2f6;
  border-radius: 20px;
  width: 1200px;
  margin: 40px auto;

  .planBodyMain {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .video-item {
      margin-right: 38px;
      width: calc((100% - 114px) / 4);
      background: #fff;
      border-radius: 20px;
      margin-bottom: 32px;
      text-align: center;
      cursor: pointer;

      .list-img {
        width: 100%;
        height: 160px;
        box-shadow: 0px 4px 10px rgba(49, 65, 94, 0.1);
        border-radius: 20px 20px 0px 0px;
        vertical-align: top;
      }

      .list-title {
        padding: 0 16px;
        line-height: 40px;
        font-weight: bold;
        color: #31415e;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .listitemNone {
      margin-right: 0;
    }
  }

  .footerText {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 22px;
    color: #ffffff;
    margin: 40px 0;
  }
  .pages{
    width: 100%;
    min-height: 50px;
    line-height: 50px;
    color: #666;
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 10px;
    user-select: none;
    .iconfont{
      font-size: 50px;
      display: block;
      margin-top: 40px;
      color: #9b9b9b;
    }
  }
  .pages:hover{
    color: rgb(var(--themecolor));
    cursor: pointer;
  }
}
</style>
