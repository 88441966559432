<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 院校排名 -->
        <div class="college_fixed_box">
            <div class="college_rank_fixed" id="college_rank_fixed">
                <div class="fixed_box">
                    <div class="area_box">
                        <div class="area_list" :style="{'height':AearHeight}">
                            <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                                <div class="aitem" :class="query.CollegeProvinceId==null?'on':''" @click.prevent="AreaTreeCheck(null)">全部</div>
                                <div class="aitem" :class="query.CollegeProvinceId==item.Id?'on':''" v-for="(item,index) in AreaTreeData" :key="index" @click.prevent="AreaTreeCheck(item.Id)">{{item.ShortName}}</div>
                                <div class="clear" style="height:20px;"></div>
                            </el-scrollbar>
                        </div>
                    </div>
                    <div class="type_box">
                        <div class="type_list">
                            <div class="titem" :class="query.RankCategoryId==item.Id?'on':''" v-for="(item,index) in CollegeRankCategoryData" :key="index" @click.prevent="CollegeRankCategoryCheck(item.Id)">{{item.Name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="college_rank_box">
            <div class="college_rank_list">
                <div class="table_box">
                    <div class="table">
                        <div class="head">
                            <div class="item">排名</div>
                            <div class="item">院校Logo</div>
                            <div class="item">院校名称</div>
                            <div class="item">类型</div>
                            <div class="item">所在地</div>
                        </div>
                        <div class="list" v-for="(item,index) in CollegeRankData" :key="index" @click.prevent="CollegeRankCheck(item.CollegeId)">
                            <div class="item">{{item.RankNum}}</div>
                            <div class="item"><div class="logo" :style="{'background':'url('+item.CollegeLogoUrl+') no-repeat center center / cover'}"></div></div>
                            <div class="item">{{item.CollegeName}}</div>
                            <div class="item">{{item.CollegeCategoryName}}</div>
                            <div class="item">{{item.CollegeProvinceName}}</div>
                            <div class="clear"></div>
                        </div>
                        <div class="pages" @click.prevent="pagesbtn()">
                            <i class="iconfont" v-if="CollegeRankData.length==0&&pageIndex==1">&#xe67c;</i>
                            <span>{{pagetxt}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
export default {
    components:{
        PHeader,
        PFooter
    },
    data() {
        return{
            loading:false,
            pageIndex:1,
            pageSize:10,
            pagetxt:'未查询到院校排名信息',
            HasNext:false,
            AreaTreeData:[],
            CollegeRankCategoryData:[],
            query:{
                RankCategoryId:null,
                CollegeProvinceId:null
            },
            CollegeRankData:[],
            AearHeight:'auto'
        }
    },
    mounted(){
        this.GetAreaTreeList();// 获取地区树列表
        this.GetCollegeRankCategoryList();// 获取院校排名类别列表
        //监听滚动事件
        window.addEventListener('scroll', () => {
            let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
            let cln=document.getElementById('college_rank_fixed');
            if(cln){
                if (ddeht > 120) {
                    cln.style.position='fixed';
                    cln.style.top='40px';
                }
                else {
                    cln.style.position='initial';
                }
            }
            if((document.documentElement.offsetHeight-ddeht)<=988){
                this.AearHeight = document.documentElement.offsetHeight-ddeht-412+'px';
            }
            else{
                this.AearHeight="auto";
            }
        }, true);
    },
    methods:{
        // 获取地区树列表
        GetAreaTreeList(){
            API.Query.GetAreaTreeList({Level:1}).then(res => {
                if(res.Code==0){
                    if(res.Data){
                        this.AreaTreeData = res.Data;
                    }
                }
            });
        },
        // 地区切换
        AreaTreeCheck(id){
            this.query.CollegeProvinceId=id;
            this.pageIndex=1;
            this.CollegeRankData=[];
            this.GetCollegeRankList();//获取院校排名列表
        },
        // 获取院校排名类别列表
        GetCollegeRankCategoryList(){
            API.Query.GetCollegeRankCategoryList().then(res => {
                if(res.Code==0){
                    if(res.Data){
                        this.CollegeRankCategoryData = res.Data;
                        if(this.CollegeRankCategoryData.length>0){
                            this.query.RankCategoryId = this.CollegeRankCategoryData[0].Id;
                            this.GetCollegeRankList();//获取院校排名列表
                        }
                    }
                }
            });
        },
        // 排名类别切换
        CollegeRankCategoryCheck(id){
            this.query.RankCategoryId=id;
            this.pageIndex=1;
            this.CollegeRankData=[];
            this.GetCollegeRankList();//获取院校排名列表
        },
        //获取院校排名列表
        GetCollegeRankList(){
            API.Query.GetCollegeRankList(this.pageIndex,this.pageSize,this.query).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.CollegeRankData.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                    this.AearHeight="auto";
                }
                else{
                    this.pagetxt='未找到院校排名信息！';
                }
            });
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetCollegeRankList();
                }
            }else{
                if(this.CollegeRankData.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        },
        //点击院校
        CollegeRankCheck(id){
            this.$router.push('/query/collegedetail?id='+id);
        }
    }
}
</script>
<style scoped lang="less">
.college_fixed_box{
    width: 100%;
    height: 50px;
    .college_rank_fixed{
        position: initial;
        width: 100%;
        height: auto;
        background-color: #f5f5f5;
        .fixed_box{
            max-width: 1420px;
            margin: auto;
            user-select: none;
            position: relative;
            height: 50px;
            .area_box{
                width: 250px;
                position: absolute;
                left: 20px;
                top: 0;
                z-index: 10;
                .area_list{
                    margin: 20px 0 0px;
                    border: 1px solid #ddd;
                    background-color: #fff;
                    .scrollbox{
                        height: calc(100% - 10px);
                        margin-left: 4px;
                        margin-top: 10px;
                    }
                    .aitem{
                        padding: 0px 18px;
                        color: #666;
                        font-size: 14px;
                        height: 30px;
                        line-height: 30px;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        margin: 5px 5px 10px;
                        display: inline-block;
                    }
                    .aitem:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor),0.8);
                        border: 1px solid rgba(var(--themecolor),0.6);
                    }
                    .aitem.on{
                        cursor: pointer;
                        color: rgba(var(--themecolor),1);
                        border: 1px solid rgba(var(--themecolor),8);
                    }
                }
            }
            .type_box{
                width: calc(100% - 310px);
                height: 84px;
                position: absolute;
                right: 20px;
                top: 0;
                z-index: 10;
                .type_list{
                    border: 1px solid #ddd;
                    margin: 20px 0 0;
                    height: 62px;
                    background-color: #fff;
                    padding: 0 10px;
                    .titem{
                        display: inline-block;
                        padding: 0 25px;
                        height: 30px;
                        line-height: 30px;
                        color: #666;
                        font-size: 14px;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        margin: 15px 5px 0px;
                        display: inline-block;
                    }
                    .titem:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor),0.8);
                        border: 1px solid rgba(var(--themecolor),0.6);
                    }
                    .titem.on{
                        cursor: pointer;
                        color: rgba(var(--themecolor),1);
                        border: 1px solid rgba(var(--themecolor),8);
                    }
                }
            }
        }
    }
    
}
.college_rank_box{
    max-width: 1420px;
    margin: auto;
    user-select: none;
    .college_rank_list{
        width: calc(100% - 312px);
        min-height: 750px;
        float: right;
        margin-right: 22px;
        .table_box{
            padding-top: 56px;
            .table{
                width: 100%;
                min-height: 400px;
                background-color: #fff;
                border: 1px solid #ddd;
                margin-bottom: 30px;
                .head{
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    border-bottom: 1px solid #ddd;
                    .item{
                        width: calc(100% / 5);
                        display: inline-block;
                        color: #666;
                        font-size: 14px;
                        text-align: center;
                    }
                }
                .list{
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    border-bottom: 1px solid #ddd;
                    .item{
                        width: calc(100% / 5);
                        color: #666;
                        font-size: 14px;
                        text-align: center;
                        float: left;
                        .logo{
                            width: 40px;
                            height: 40px;
                            border-radius: 40px;
                            overflow: hidden;
                            margin: 15px auto 0;
                        }
                    }
                }
                .list:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),0.08);
                }
                .pages{
                    width: 100%;
                    min-height: 50px;
                    line-height: 50px;
                    color: #666;
                    font-size: 16px;
                    text-align: center;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                    user-select: none;
                    .iconfont{
                        font-size: 50px;
                        display: block;
                        margin-top: 40px;
                        color: #9b9b9b;
                    }
                }
                .pages:hover{
                    color: rgb(var(--themecolor));
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
<style>
.college_fixed_box .college_rank_fixed .el-scrollbar__wrap{
    overflow-x: hidden;
}
</style>