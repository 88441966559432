<template>
  <div class="self-analysis">
    <div class="commonBox">
      <div class="titleBox">
        家庭环境分析（如经济状况、家人期望、家庭文化等以及对本人的影响）：
      </div>
      <div class="textBox">
        <el-input
          type="textarea"
          class="textarea"
          v-model="pageData.FamilyAnalysis"
          autosize
        ></el-input>
      </div>
    </div>
    <div class="commonBox">
      <div class="titleBox">
        社会环境分析（如就业形势、就业政策、竞争对手、宏观经济的影响等）：
      </div>
      <div class="textBox">
        <el-input
          type="textarea"
          class="textarea"
          v-model="pageData.SociatyAnalysis"
          autosize
        ></el-input>
      </div>
    </div>
    <div class="commonBox">
      <div class="titleBox">
        行业分析（如行业现状及发展趋势、人业匹配分析）：
      </div>
      <div class="textBox">
        <el-input
          type="textarea"
          class="textarea"
          v-model="pageData.ProfessionAnalysis"
          autosize
        ></el-input>
      </div>
    </div>
    <div class="commonBox">
      <div class="titleBox">
        职业分析（如职业的工作内容、工作要求、发展前景分析）：
      </div>
      <div class="textBox">
        <el-input
          type="textarea"
          class="textarea"
          v-model="pageData.OccupationAnalysis"
          autosize
        ></el-input>
      </div>
    </div>
    <div class="commonBox">
      <div class="titleBox">环境分析总结：</div>
      <div class="textBox">
        <el-input
          type="textarea"
          class="textarea"
          v-model="pageData.EnvironmentAnalysisSummary"
          autosize
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      //图片地址
      imagesBaseURL: this.$store.state.imagesBaseURL,
      pageData: {
        FamilyAnalysis: "",
        SociatyAnalysis: "",
        ProfessionAnalysis: "",
        OccupationAnalysis: "",
        EnvironmentAnalysisSummary: "",
      },
    };
  },
  watch: {
    detailData(val) {
      this.pageData = this.detailData;
    },
  },
  mounted() {
    this.inte();
  },
  methods: {
    inte() {
      if (this.detailData) {
        this.pageData = {
          FamilyAnalysis: this.detailData.FamilyAnalysis,
          SociatyAnalysis: this.detailData.SociatyAnalysis,
          ProfessionAnalysis: this.detailData.ProfessionAnalysis,
          OccupationAnalysis: this.detailData.OccupationAnalysis,
          EnvironmentAnalysisSummary:
            this.detailData.EnvironmentAnalysisSummary,
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.self-analysis {
  color: #fff;
}

.commonBox {
  margin-bottom: 8px;
  .titleBox {
     line-height: 28px;
  color: #666;
  font-weight: bold;
  margin: 8px 0px;
  }

  .titleBoxTwo {
    line-height: 20px;
  }
  .textBox {
    width: 100%;
    line-height: 1.5;
    min-height: 48px;
    .textarea {
      background: #eef2f6;
      width: 100%;
    }
  }
}
</style>
