<template>
  <div class="learning-plan">
    <div class="learning-main">
      <div class="left">
        <div class="left-head">
          <div class="name">学习计划</div>
          <div class="add-button" @click="addEvent">添加计划</div>
        </div>
        <div class="left-main">
          <el-scrollbar wrap-class="scrollbar-wrapper" class="operatescroll">
            <div class="event-item" v-for="(item, index) in showMainData" :key="index">
              <div class="operate">
                <div class="operate-item" @click="deleteItem(item)" title="删除">
                  <i class="el-icon-delete"></i>
                </div>
                <div class="operate-item" @click="editEvent(item)" title="修改">
                  <i class="el-icon-edit"></i>
                </div>
                <div class="operate-item" @click="commentEvent(item)" title="完成" v-if="!IsFinish(item)">
                  <i class="el-icon-finished"></i>
                </div>
              </div>
              <div class="event-box" :class="IsFinish(item)?'on':'no'">
                <i class="iconfont on">&#xe695;</i>
                <i class="iconfont no">&#xe6eb;</i>
                <div class="event-text">{{ item.TaskName }}</div>
              </div>
              <div class="comment" v-if="IsFinish(item)">
                <div class="info" v-for="(info,index2) in item.TaskRecordList" :key="index2" v-show="info.FinishDate.substring(0,10)==selectedDate">
                  <div class="txt">{{ info.Comment }}</div>
                  <div class="level">
                    <div class="ibox" v-if="info.ScoreLevel">
                      <img :src="GetScoreLevel(info.ScoreLevel)" alt="" />
                      <span>{{GetLevelStr(info.ScoreLevel)}}</span>
                    </div>
                    <span>{{info.FinishDate?info.FinishDate.substring(0,10):''}}</span>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="left-bottom"></div>
      </div>
      <div class="right">
        <custom-calendar
          :calendarData="showMainData"
          @selectDate="handleSelectDate"
          @changeMonth="changeMonth"
        ></custom-calendar>
      </div>
    </div>
    <div>
      <custom-popup
        :title="popupTitle"
        v-model="visiblePopup"
        :content-text="operateItem.text"
        :progress-item="operateProgressItem"
        :type="operateFlag"
        @change="handleChange"
      ></custom-popup>
    </div>
  </div>
</template>

<script>
import CustomCalendar from './my-components/CustomCalendar'
import CustomPopup from './my-components/CustomPopup'
import API from "@/api/config";
import { getStore} from "@/utils/utils";
export default {
  name: "learning-plan",
  components: {
    CustomCalendar,
    CustomPopup,
  },
  data () {
    return {

      showMainData: [], // 展示的事件列表
      selectedDate: '',
      queryNew: {},
      queryShow: {},

      visiblePopup: false, // 弹窗开关,
      popupTitle: '添加计划', // 弹窗标题
      operateItem: { // 操作事件项(选中日期的月份对应的事件项)
        text: ''
      },
      operateProgressItem: {}, // 操作事件项下具体某天的进度项

      operateFlag: '',
      UserId:null
    }
  },
  mounted () {
    let user = getStore("LoginData");
    if(user){
      user=JSON.parse(user);
      this.UserId = user.UserId;
      this.getShowList();// 初始列表数据
    }
    else{
      this.$message({ type: 'info', message: '请先登录!' });
      this.$router.push('/login');
    }
  },
  methods: {
    // 是否完成
    IsFinish(item){
      if(item.TaskRecordList){
        let obj = item.TaskRecordList.find(f=>f.FinishDate.substring(0,10)==this.selectedDate);
        if(obj){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        return false;
      }
    },
    // 初始列表数据
    getShowList () {
      // 当前月份参数
      let tempYear = new Date().getFullYear(), tempMonth = new Date().getMonth() + 1
      let temp2 = new Date(tempYear, tempMonth, 0)
      this.queryNew = {
        UserId:this.UserId,
        DateBegin: `${tempYear}-${tempMonth}-1`,
        DateEnd: `${tempYear}-${tempMonth}-${temp2.getDate()}`
      }
      this.getData(this.queryNew);
    },
    
    // 获取计划数据
    async getData(query) {
      await API.CareerPlan.GetDailyTaskList(query).then(res=>{
        if(res.Code==0){
          this.showMainData = res.Data;
        }
      });
    },
    // 添加计划
    addEvent () {
      this.popupTitle = '添加计划'
      this.$set(this.operateItem, 'text', ' ') // 这里的值有一个空格
      setTimeout(() => {
        this.operateItem.text = ''// 置空内容，实现弹框输入框的行高动态改变（rowConfig）
      }, 0)
      this.operateFlag = 'add'
      this.visiblePopup = true
    },
    // 修改计划
    editEvent (item) {
      this.operateItem = item
      this.popupTitle = '修改计划'

      this.$set(this.operateItem, 'text', '') // 这样动态显示绑定内容
      setTimeout(() => {
        this.operateItem.text = item.TaskName // 显示内容
      }, 0)

      this.operateFlag = 'edit'
      this.visiblePopup = true
    },
    // 删除操作
    deleteItem(item){
      this.$confirm('确定删除该计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        API.CareerPlan.DelDailyTask({Id: item.Id}).then(_ => {
          this.$message({ type: 'success', message: '删除成功!' });
          this.getData(this.queryShow);
        })
      }).catch(() => {
                  
      });
    },
    // 完成计划 弹出
    commentEvent (item) {
      this.popupTitle = '完成计划'
      this.operateItem = item
      this.$set(this.operateItem, 'text', ' ') // 这样动态显示绑定内容
      this.operateFlag = 'comment'
      this.visiblePopup = true
    },
    // 弹出类型
    handleChange (text, callback) {
      switch (this.operateFlag) {
        case 'add':
          this.addEventResult(text).then(_ => {
            callback('success')
          })
          break;
        case 'edit':
          this.editEventResult(text).then(_ => {
            callback('success')
          })
          break;
        case 'comment':
          this.commentResult(text).then(_ => {
            callback('success')
          })
      }
    },
    // 完成计划
    commentResult (text) {
      return new Promise(resolve => {
        let parameter = {}
        parameter.Comment = text.Comment || ''
        parameter.ScoreLevel = text.ScoreLevel || 0
        parameter.UserId = this.UserId
        parameter.FinishDate = this.selectedDate
        parameter.DailyTaskId = this.operateItem.Id
        parameter.Id = this.operateProgressItem.Id || ''
        this.handleProgressApi(parameter).then(_ => {
          resolve()
        })
      })
    },
    // 完成计划
    handleProgressApi (parameter) {
      return new Promise(resolve => {
        API.CareerPlan.ComplateDailyTask(parameter).then(_ => {
          this.getData(this.queryShow).then(_ => {
            resolve()
          })
        })
      })
    },
    // 添加计划 结果处理
    addEventResult (text) {
      return new Promise(resolve => {
        let parameter = {
          UserId:this.UserId,
          TaskName: text
        }
        this.showMainData.length <= 0 ? (parameter.Sort = 1) : (parameter.Sort = this.showMainData[this.showMainData.length - 1].Sort + 1)
        this.AddDailyTask(parameter).then(_ => {
          resolve()
        });
      })
    },
    // 添加
    AddDailyTask (parameter) {
      return new Promise(resolve => {
        API.CareerPlan.AddDailyTask(parameter).then(_ => {
          this.getData(this.queryShow).then(_ => {
            resolve()
          })
        })
      })
    },
    // 修改计划 结果处理
    editEventResult (text) {
      return new Promise(resolve => {
        let parameter = {
          TaskName: text,
          Id: this.operateItem.Id,
          Sort: this.operateItem.Sort
        }
        this.UpdateDailyTask(parameter).then(_ => {
          resolve()
        })
      })
    },
    // 修改
    UpdateDailyTask (parameter) {
      return new Promise(resolve => {
        API.CareerPlan.UpdateDailyTask(parameter).then(_ => {
          this.getData(this.queryShow).then(_ => {
            resolve()
          })
        })
      })
    },
    // 笑脸表情状态
    GetScoreLevel (level) {
      if(level){
        return require('@/assets/careerPlan/learning-level' + String(level) + '.png')
      }
      else{
        return '';
      }
    },
    // 状态对应文字
    GetLevelStr(level){
      if(level==1){
        return '优秀';
      }
      else if(level==2){
        return '良好';
      }
      else{
        return '一般';
      }
    },
    // 选中计划样式
    selectedEvent (item, direction) {
      if (item.Id === item.Id) {
        return direction === 'text' ? 'activate-text' : true
      } else return ''
    },
    // 选择日期
    handleSelectDate (date, parameter) {
      this.queryShow = {
        UserId:this.UserId,
        DateBegin: parameter.firstDate,
        DateEnd: parameter.endDate
      }
      this.selectedDate = date
      this.getData(this.queryShow);
    },
    // 改变月份时，获取月份参数
    changeMonth (parameter, callback) {
      this.queryNew = {
        UserId:this.UserId,
        DateBegin: parameter.firstDate,
        DateEnd: parameter.endDate
      }
      this.getData(this.queryNew);
      callback('change month success!')
    },
  }
}
</script>


<style scoped lang="less">
.learning-main {
  width: 1300px;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;

  .left {
    width: 50%;
    height: 78vh;
    min-height: 600px;
    border-radius: 20px;
    background-color: rgba(238, 242, 246, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    &-head {
      width: calc(100% - 40px);
      padding-bottom: 32px;
      margin: 30px auto 0;
      display: flex;
      justify-content: space-between;

      .name {
        color: rgba(49, 65, 94, 1);
        font-size: 34px;
        font-weight: 700;
      }

      .add-button {
        width: 112px;
        height: 48px;
        line-height: 48px;
        background: rgba(var(--themecolor),0.8);
        box-shadow: 0 10px 20px rgba(var(--themecolor),0.2);
        border-radius: 5px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }

    &-main {
      height: calc(100% - 150px);
      background-color: rgba(49, 65, 94, .04);
      .operatescroll{
        height: 100%;
      }
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #dcdada;
        box-shadow: 0 0 5px #e2dcdc;
      }
      .event-item:last-child{
        margin-bottom: 30px;
      }
      .event-item {
        margin: 60px 20px 0;
        position: relative;
        .operate {
          border-radius: 8px 8px 0 0;
          background-color: #fff;
          box-shadow: 0 4px 5px rgba(var(--themecolor),0.1);
          display: flex;
          position: absolute;
          top: -6px;
          right: 0;
          transform: translateY(-40px);
          z-index: 1;
          padding: 0 5px;
          .operate-item {
            cursor: pointer;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background-color: #f8eaea;
            margin: 7px 5px;
            border-radius: 5px;
            color: #888;
            font-size: 16px;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
          }
          .operate-item:hover{
            color: #fff;
            background-color: rgba(var(--themecolor),0.7);
            font-size: 18px;
          }
        }
        .event-box.on{
          .event-text{
            border-radius: 8px 0px 0px 0px;
          }
          i.on{
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: -35px;
            left: 20px;
            z-index: 5;
            font-size: 40px;
            color: rgba(var(--themecolor),1);
          }
        }
        .event-box.no{
          .event-text{
            border-radius: 8px 0px 8px 8px;
          }
          i.no{
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: -32px;
            left: 20px;
            z-index: 5;
            font-size: 34px;
            color: #b1b1b1;
          }
        }
        .event-box{
          position: relative;
          z-index: 2;
          i{
            display: none;
          }
          .event-text {
            min-height: 108px;
            line-height: 1.5;
            padding: 16px;
            font-size: 14px;
            background-color: #fff;
            box-shadow: 0 4px 10px rgba(49, 65, 94, 0.1);
            position: relative;
            z-index: 2;
          }
        }
        .comment {
          padding:15px 20px;
          font-size: 14px;
          box-shadow: 0 4px 10px rgba(49, 49, 49, 0.1);
          border-radius: 0 0 8px 8px;
          background: #f7efef;
          position: relative;
          top: 0px;
          .info{
            .txt{
              width: calc(100% - 200px);
              color: #666;
              font-size: 14px;
              line-height: 20px;
              float: left;
            }
            .level{
              width: 200px;
              float: right;
              text-align: right;
              color: #666;
              font-size: 13px;
              .ibox{
                display: inline-block;
                margin-right: 50px;
                img{
                  display: inline-block;
                  width: 26px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
    &-bottom {
      height: 0px;
    }
  }
}

</style>
<style>
.el-button:focus, .el-button:hover{
  color: rgba(var(--themecolor),0.8);
  border-color: #ddd;
}
.el-button--primary:focus{
  background: rgba(var(--themecolor),0.8);
  border-color: rgba(var(--themecolor),0.8);
  color: #fff;
}
.el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
  color: #fff;
}
.el-button--primary{
  background: rgba(var(--themecolor),0.8);
  border-color: rgba(var(--themecolor),0.8);
}
.el-message-box__headerbtn:focus .el-message-box__close, .el-message-box__headerbtn:hover .el-message-box__close{
  color: rgba(var(--themecolor),1);
}
.learning-plan .learning-main .left-main .el-scrollbar__wrap{
    overflow-x: hidden;
}
</style>