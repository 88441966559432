<template>
	<div class="custom-table">
		<div class="table-name">{{ tableName }}：</div>
		<div class="table-content">
			<div class="table-title">
				<div class="table-title-item" :style="{width: item.width}" v-for="(item, index) in tableOption" :key="index">
					{{ item.name }}
				</div>
			</div>
      <div class="add-button" @click="addTable">{{ addButtonText }}</div>
      <div class="table-main">
        <div v-if="mainList.length > 0" class="table-box">
          <div class="list" v-for="(item, index) in mainList" :key="index">
            <div class="item" :style="{width: subItem.width}" v-for="(subItem, subIndex) in tableOption" :key="subItem.value">

              <!-- 时间选择 -->
              <div v-if="cellTypeShow(subIndex, 'date')" class="date-picker">
                <el-date-picker
                  class="picker"
                  v-model="mainList[index][subItem.value]"
                  @change="handleSelectDate($event, index, subIndex)"
                  :editable="false"
                  type="date"
                  placeholder="日期">
                </el-date-picker>
              </div>
              <!-- 上传 -->
              <div v-else-if="cellTypeShow(subIndex, 'upload')" class="upload">
                <el-upload
                  :action="uploadUrl"
                  :headers="{
                    Token: token
                  }"
                  :show-file-list="false"
                  :before-upload="handleUpload"
                  :on-success="successUpload"
                >
                  <el-button @click="onUpload(index, subIndex)" type="primary" size="mini">{{ uploadButton(index, subIndex) }}</el-button>
                </el-upload>
              </div>
              <!-- 删除 -->
              <div v-else-if="cellTypeShow(subIndex, 'delete')" class="delete-row">
                <el-button @click="deleteRow(index)" type="text">删除</el-button>
              </div>
              <!-- 文本框 -->
              <div v-else-if="cellTypeShow(subIndex, 'textarea')" class="textarea">
                <el-input class="text-area" v-model="mainList[index][subItem.value]" @input="textareaInput" autosize type="textarea" ></el-input>
              </div>
              <!-- 输入框 -->
              <div v-else class="text-input">
                <el-input v-model="mainList[index][subItem.value]" @input="textareaInput" type="text"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="have-no-data">{{ emptyTips }}</div>
      </div>

<!--			<div :class="tableBodyStyle">
				<div class="table-main-list" v-for="(item, index) in mainList" :key="index">
					<div class="table-main-list-item" :style="{width: subItem.width}" v-for="(subItem, subIndex) in tableOption" :key="subItem.name">
						<div v-if="cellTypeShow(subIndex,'date')"  class="table-item-innder table-picker">
              <picker mode="date" :value="inputValue(index, subIndex)" @change="handleSelectDate($event,index, subIndex)">
								<div class="table-picker-inner">
									<div class="table-picker-inner-text">{{ item.ContestDate }}</div>
								</div>
							</picker>
						</div>
						<div v-else-if="cellTypeShow(subIndex,'upload')" class="table-item-innder table-upload">
							<div class="upload" @tap="handleUpload(index, subIndex)">
								<div class="upload-text">{{ uploadStatus(index, subIndex) }}</div>
							</div>
						</div>
						<div v-else-if="cellTypeShow(subIndex, 'delete')" class="table-item-innder table-delete">
              删除
							<image class="delete-icon" @tap="deleteRow(index, subIndex)" :src="imagesBaseURL+'/CareerH5App/icons/tableDel.png'"></image>
						</div>
						<div v-else class="table-textarea">
							<textarea
							class="textarea"
							@input="textareaInput($event, index, subIndex)"
							:value="inputValue(index, subIndex)"
							auto-height
							:maxlength="-1" />
						</div>

					</div>
				</div>
			</div>

			<div :class="tableAddStyle">
				<div class="table-add-button" @tap="addTable">
					<image class="add-icon" :src="imagesBaseURL+'/CareerH5App/images/tableAdd.png'"></image>
					{{ addButtonText }}
				</div>
			</div>-->


		</div>
    <!--		<l-file ref="lFile" @up-success="onSuccess"></l-file>-->
	</div>
</template>

<script>
	// import { BASE_URL } from '@/static/config'
  import { mapGetters } from 'vuex'
	export default {
		data(){
			return{
				imagesBaseURL: this.$store.state.imagesBaseURL,
				mainList: [],
				rowIndex: 0,
				columnIndex: 0,

				selectDate: '',

        textareaValue: '',
        uploadUrl: ''
			}
		},
		props:{

			tableType: {
				type: Array,
				default: () => []
			},
			tableName: {
				type: String,
				default: ''
			},
			tableOption: {
				type: Array,
				default: () => []
			},
			tableMain: {
				type: Array,
				default: () => []
			},
			addButtonText: {
				type: String,
				default: '添加'
			},
      emptyTips: {
			  type: String,
        default: '暂无数据'
      }
		},
		computed:{

			uploadImage(){
				// const temp = this.uploadStatus(this.rowIndex, this.columnIndex) === '上传证明'?'.png':'-over.png'
				return this.imagesBaseURL+'/CareerH5App/images/private-info-upload.png'
			},
			tableAddStyle(){
				return this.mainList.length > 0?'close-padding table-add':'table-add'
			},
			tableBodyStyle(){
				return this.mainList.length > 0?'show-main table-main':'table-main'
			},
      ...mapGetters(['token'])
		},
		watch:{
			tableMain(){
				this.mainList = this.tableMain
			}
		},
		mounted(){
			this.initialize()
		},
		methods:{
		  // 上传成功
      successUpload(response, file, fileList){
        console.log(response,1)
        console.log(file,2)
        console.log(fileList,3)
        console.log(this.rowIndex,'r')
        console.log(this.columnIndex,'c')
        this.mainList[this.rowIndex][this.tableOption[this.columnIndex].value] = response.data[0]
        this.$messageTips('success', '上传成功！')
      },
			// 上传
			handleUpload(){
			  if(!this.token){
			    this.$messageTips('error', '请先登录！')
          return Boolean(this.token)
        }

				/*let baseUrl = process.env.NODE_ENV === 'development'?BASE_URL.common.dev:BASE_URL.common.pro
				this.tableType.some(item => {
					if(item.type === 'upload'){

					}
				})
				this.$refs.lFile.upload({
					// #ifdef APP-PLUS
					currentWebdiv: this.$mp.page.$getAppWebdiv(),
					// #endif
					url: baseUrl+this.uploadApi,
					name: 'AuthFile',
					header: {'token':this.token},
					maxSize: 1
				})*/
			},
      onUpload(index, subIndex){
        this.rowIndex = index
        this.columnIndex = subIndex

      },
			// 上传成功的回调
			onSuccess(res){
				this.mainList[this.rowIndex][this.tableOption[this.columnIndex].value] = res.data[0]
				this.$emit('change', this.mainList)
			},
      // 上传按钮
      uploadButton(index, subIndex){
        return this.mainList[index][this.tableOption[subIndex].value]?'已上传':'上传'
      },
			// 文本输入
			textareaInput(event, rowIndex, columnIndex){
			  // console.log(this.mainList,'main list')
				// this.mainList[rowIndex][this.tableOption[columnIndex].value] = event.detail.value
				this.$emit('change', this.mainList)
			},
			// 选择日期
			handleSelectDate(event, index, subIndex){
        if(event){
          this.mainList[index][this.tableOption[subIndex].value] = `${event.getFullYear()}-${event.getMonth()+1}-${event.getDate()}`
        }
        console.log(this.mainList,'main l')
				this.$emit('change', this.mainList)
			},

			// 删除行
			deleteRow(index){
        this.$confirmTips('确定删除该项吗？', '注意！', 'warning').then(_=> {
          this.mainList.splice(index, 1)
          this.$emit('change', this.mainList)
        })
			},

			// 添加行
			addTable(){
				let temp = {}
				this.tableOption.map(item => {
				  item.value&&(temp[item.value] = '')
        })
				this.mainList.push(temp)
			},

			// 上传状态
			uploadStatus(rowIndex, columnIndex){
				let temp = this.mainList[rowIndex][this.tableOption[columnIndex].value]
				return temp?'重新上传':'上传证明'
			},

			// 单元格value值绑定
			inputValue(rowIndex, columnIndex){
				return this.mainList[rowIndex][this.tableOption[columnIndex].value]
			},

			// 单元格展示类型
			cellTypeShow(index, type){
				return this.tableType.some(item => {
					if(item.order === (index+1)){
						if(item.type === type) return true
					}
				})
			},

			// 初始化数据处理
			initialize(){
			  console.log(this.tableMain, 't main')
			  console.log(this.tableOption, 't option')
				// 赋值已有数据
				this.mainList = this.tableMain

				// 上传api
				this.tableType.some(item => {
					if(item.type === 'upload'){
					  this.uploadUrl = PLATFROM_CONFIG.baseUrl+item.api
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.custom-table{
		.table-name{
      		line-height: 40px;
			margin-left: 2px;
			font-size: 16px;
			color: #666;
		}
		.table-content{
			//overflow: hidden;
      		position: relative;
			.table-title{
				height: 48px;
				padding: 10px 0;
				background-color: rgba(var(--themecolor), 0.8);
				opacity: 1;

				border-radius: 5px 5px 0px 0px;
						display: flex;
				position: relative;
				z-index: 1;

				&-item{
          line-height: 1.8;
          text-align: center;
          color: #EEF2F6;
					border-right: 1px solid rgba(238, 242, 246, .1);
					&:last-child{
						border-right: none;
					}
				}
			}
      .add-button{
        width: 120px;
        height: 35px;
        padding-top: 15px;
        text-align: center;
        background: #eee;
        border-radius: 5px;
        color: rgba(var(--themecolor),0.8);
		border: 1px solid #ddd;
        cursor: pointer;
        position: absolute;
        top: -55px;
        right: 0;
        z-index: 0;
      }
      .table-main{
        .table-box{
          border: 1px solid #ddd;
          .list{
            display: flex;
            align-items: center;
            .item{
              .date-picker{
                width: 100%;
                .picker{
                  width: 100%;
                  ::v-deep .el-input__inner:hover{
                    cursor: pointer;
                  }
                }
              }
              .upload{
                //border: 1px solid #000;
                text-align: center;
              }
              .delete-row{
                text-align: center;
                position: relative;
                ::after{
                  content: '';
                  width: 100%;
                  height: 1px;
                  background-color: #eee;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                }
              }
              .text-area{}
              .text-input{
                ::v-deep .el-input__inner{
                  border-radius: 0;
                }
                ::v-deep .el-textarea__inner{
                  min-height: 33px !important;
                  height: 33px !important;
                  border-radius: 0;
                }
              }
            }



          }
        }


        .have-no-data{
          height: 64px;
          line-height: 64px;
          text-align: center;
          background: #ddd;
          border-radius: 0px 0px 5px 5px;
          color: #666;

        }
      }
			/*.table-main{
				background-color: rgba(4, 36, 49, 1);
				&-list{
					display: flex;
					border-bottom: 1rpx solid rgba(28, 80, 203, 0.4);
					&-item{
						min-height: 56px;
						padding: 10px;
						border-right: 1rpx solid rgba(28, 80, 203, 0.4);
						color: rgba(168, 194, 255, 1);
						&:last-child{
							border-right: none;
						}
						.table-item-innder{
							height: 100%;
							line-height: 2.3;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.table-picker{
							justify-content: flex-end;
							&-inner{
								min-width: 100px;
								display: flex;
								justify-content: flex-end;
								align-items: center;
								&-text{
									height: 30px;
									line-height: 30px;
								}
								.select-arrow{
									width: 20rpx;
									height: 15rpx;
									margin-left: 20rpx;
								}
							}

						}
						.table-upload{
							.upload{
								display: flex;
								align-items: center;
								justify-content: center;
								&-image{
									width: 20px;
									height: 20px;
									margin-right: 10px;
								}
								&-text{}
							}
						}
						.table-textarea{
							height: 100%;
							.textarea{
								width: 100%;
							}
						}
						.table-delete{
							.delete-icon{
								width: 35px;
								height: 35px;
							}
						}
					}
					&:last-child{
						border-bottom: none;
					}
				}
			}
			.show-main{
				padding-top: 20px;
				border: 1px solid rgba(28, 80, 203, 0.4);
			}
			.table-add{
				height: 74px;
				padding-top: 20px;
				text-align: center;
				color: rgba(168, 194, 255, 1);
				background-color: rgba(28, 80, 203, 0.4);
				display: flex;
				justify-content: center;
				align-items: center;
				.table-add-button{
					display: flex;
					align-items: center;
					.add-icon{
						width: 27px;
						height: 27px;
						margin-right: 14px;
					}
				}
			}
			.close-padding{
				height: 54px;
				padding-top: 0;
			}*/
		}
	}
</style>
