<template>
  <div class="download-box" :class="{'border-status': !border}" :style="{width: width,'padding-bottom': downloadButton?'80px':'0'}">
    <div class="head">
      <div v-if="title" class="title" :class="{'align-center': titleCenter}">{{ title }}</div>
      <slot name="title"></slot>
    </div>
    <div v-if="textList.length !== 0" class="text">
      <p v-for="(item, index) in textList" :key="index">{{ item }}</p>
    </div>
    <div v-if="downloadButton" class="download-button" @click="downloadFile">
      <img class="download-icon" src="@/assets/careerPlan/career-download-icon.png" />
      <span class="download-button-text">下载表格</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomDownloadText",
  data(){
    return{}
  },
  props: {
    downloadUrl: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    textList: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: '100%'
    },
    titleCenter: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    downloadButton: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    // 点击下载
    downloadFile(){
      this.downloadUrl&&window.open(this.downloadUrl)
    }
  }
}
</script>

<style scoped lang="less">
.download-box{
  padding-bottom: 80px;
  box-shadow: 0 4px 10px rgba(49, 65, 94, 0.1);
  border-radius: 20px;
  background-color: rgba(238, 242, 246, 1);
  overflow: hidden;
  position: relative;
  .head{
    .title{
      padding: 9px 24px;
      background: rgba(var(--themecolor),0.1);
      color: rgba(var(--themecolor),1);
    }
    .align-center{
      text-align: center;
    }
  }
  .text{
    line-height: 1.8;
    padding: 24px 32px 40px;
    color: rgba(49, 65, 94, 1);
    font-size: 16px;
  }
  .download-button{
    width: 136px;
    height: 48px;
    color: #ffffff;
    background: rgba(var(--themecolor),0.8);
    box-shadow: 0 10px 20px rgba(50, 113, 225, 0.4);
    border-radius: 8px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);

    .download-icon{
      width: 16px;
      height: 16px;
    }
  }
}
.border-status{
  box-shadow: none;
  overflow: visible;
  border-radius: 0;
  .head{
    .title{
      border-radius: 8px;
    }
  }
  .text{
    padding: 24px 0 40px;
  }
}

</style>
