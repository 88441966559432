export default {
  text1: '每个人都会经历不同的人生阶段，分为：儿童期、青少年期、青年期、中年期以及老年期，这个连续的过程就是一个人的生涯。生涯也是一生中不同时期所扮演的各种不同角色的组合。生涯发展阶段的角色有：子女、学生、休闲者、公民、工作者、配偶、持家者、父母等。你是否了解了你的生涯角色和角色里的梦想？',
  text2_1: '你长大以后想干什么？这个问题背后隐含着另一个需要思考的问题——我有哪些能力？如何储备新能力？让我们通过过往成就事件聊一聊。' +
    '通过过去自己成就的总结，你能发现自己拥有取得这些成就所擅长的技能，例如写作、绘画、组织领导等等。这些技能是未来你无论学业还是职业成功最有可能的突破点，同时也能发现你欠缺的技能，这对生涯规划也是十分重要的。',
  text2_2: '通过过去自己成就的总结，你能发现自己拥有取得这些成就所擅长的技能，例如写作、绘画、组织领导等等。这些技能是未来你无论学业还是职业成功最有可能的突破点，同时也能发现你欠缺的技能，这对生涯规划也是十分重要的。',
  text3_1: '上天赋予我们每个人独特的能力，我们只需要发现自己的能力并将它发挥到极致，就一定会取得满意的成绩。在学业和职业发展道路上，都需要每个人找出自己最擅长的点，然后把它发展起来，这样更容易成功。',
  text3_2: '你能否明确属于你的优势智能？',
  text4_1: '人们往往在分析他们获取成功的因素时，会先总结评价他是一个怎么样的人；在工作应聘时，除了具体的技能之外，也总是会要求应聘者自我评价是怎么样的一个人。一个人的特质没有好坏之分，但是生活、学习、交友、理想等方面又有着很不同的风格烙印，这就是罗曼·罗兰说的：每个人都有他的隐藏的精华，和任何别人的精华不同，它使人具有自己的气味。大千世界，因为个性不同而丰富多彩！',
  text4_2: '在性格这面哈哈镜面前，你看到几重的自己？',
  text5_1: '未见意趣，必不乐学！一个人如果能根据自己的爱好去选择学习和工作，他的主动性将会得到充分发挥。即使十分疲倦和辛劳，也总是兴致勃勃、心情愉快；兴趣就是我们心甘情愿地做某些事情的愿望和动力。培养我们的兴趣，让自我因兴趣而充实吧！',
  text5_2: '你知道自己的兴趣在哪里吗？',
  text6_1: '价值观指个人对于人、事、物的看法，凡是你觉得重要的、想追求的，就代表你的价值观。它的面貌常常是“我在乎…..”、“我认为…..”、“我不喜欢….. ”等。它能帮助了解自己的生活目标和意义，使人在面对决定时有较明确的选择。它就像一艘船的锚，为人生之船定位，也决定着我们生涯的方向。',
  text6_2: '你知道价值观都有哪些吗？你清楚自己的价值观吗？',
  text7: '“三百六十行，行行出状元。只要功夫深，铁杵磨成针。”每种职业都有它的用武之处，每个行业只要下好功夫，也就会成为一行专家。你可以从自己已了解的职业所属的分类入手，从而认识更大的职业体系。',
  text8: '“知己知彼，百战不殆”！在了解自我的基础上，动手搜集生涯资讯是必须的！因为不同的行业、职业有不同的工作内容和工作方式。未来哪些行业、职业是适合自己的？答案就在资讯里！',
  text9: '与你所读的学校条件相似，从事某项职业也会有其特定的工作条件，例如是否在安静的环境下、是整天坐在室内或者到处奔走、是否天天做固定不变的事情等等。你理想中的工作环境是什么？',
  text10: '一般情况下，上大学能让我们接受更进一步的教育，是为从事一些对文凭、知识体系水平等有要求的职业做准备，是提升职业竞争力的途径之一。如果你的职业目标需要你上大学，那么你需要对大学的基本情况有所了解，主要有院校和专业两大块内容。',
  text11: '“知己知彼，百战不殆”！在了解自我的基础上，动手搜集生涯资讯是必须的！因为不同的高中有不同的办学模式和学习氛围。对于你喜欢的高中，你真正了解多少呢？打开嘴，迈开腿！来趟调查呗！',
  text12: '这是个终身学习的年代，学业是我们通向未来的桥梁！为了实现更好的“自己”，让我们放眼未来，立足初中，科学规划我们的学业，一步一个脚印踏实前进！',
  text13: '你长大后想要做什么？这个问题相信你常常会被问起。 当然，现在问答这个问题并不是意味着你未来的目标现在就确定下来了。在你今后的学习和探索过程中，你会更多地认识自我以及认识其他的目标，你可能还会需要不断地进行调整 。',
  text14: '哈佛大学有一个非常著名的关于人生规划影响的跟踪调查，报告显示属于社会的精英人士有个共同的特点：有清晰且长期的目标，并能把目标分解执行，过程中不断地评估、修正、完善。同学们，奏起行动进行曲吧！',
}
