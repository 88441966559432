<template>
  <div class="self-analysis">
    <div class="commonBox">
      <div class="titleBox">目标设定:</div>
      <div class="tableBox">
        <div class="table-head">
          <div class="headTextBox">
            <div class="headtext headtext1">目标类型</div>
            <div class="headtext headtext2">具体学业理想描述</div>
            <div class="headtext">开始日期</div>
            <div class="headtext">结束日期</div>
            <div class="headtext headtext4">操作</div>
          </div>
          <div class="addbtn">
            <div @click="EvaluationListAdd" class="addbtnText">+添加</div>
          </div>
        </div>
        <div class="table-body">
          <div
            class="bodyTr"
            :class="{
              bodyTrBot: TargetPlanList.length > 1,
            }"
            v-for="(item, index) in TargetPlanList"
            :key="index"
          >
            <div class="bodyTd  bodyTd1">
              <el-select v-model="item.PlanType" placeholder="请选择">
                <el-option
                  v-for="item in PlanTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="bodyTd bodyTd2">
              <el-input
                type="textarea"
                class="textarea"
                v-model="item.PlanInfo"
                autosize
              ></el-input>
            </div>
            <div class="bodyTd">
              <el-date-picker
                v-model="item.StartDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                class="uni-input"
              >
              </el-date-picker>
            </div>
            <div class="bodyTd">
              <el-date-picker
                v-model="item.EndDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                class="uni-input"
              >
              </el-date-picker>
            </div>
            <div class="bodyTd bodyTd4">
              <el-button type="text" @click="handDelete(index)">删除</el-button>
            </div>
          </div>
          <template v-if="!TargetPlanList || TargetPlanList.length === 0">
            <div class="notDataDiv">暂无目标设定</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import local_json from "../my-components/private_info_json.js";
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      //图片地址
      imagesBaseURL: this.$store.state.imagesBaseURL,
      TargetPlanList: [],
      PlanTypeList: local_json.PlanTypeList,
      selectIndex: 0,
    };
  },
  watch: {
    detailData(val) {
      let temp = this.detailData.TargetPlanList;
      if (temp && temp.length !== 0) {
        temp.forEach((item) => {
          item.EndDate = item.EndDateStr;
          item.StartDate = item.StartDateStr;
        });
      }
      this.TargetPlanList = temp;
    },
  },
  mounted() {
    // this.inte()
  },
  methods: {
    inte() {
      if (this.detailData) {
        this.TargetPlanList = this.detailData.TargetPlanList;
      }
    },
    EvaluationListAdd() {
      this.TargetPlanList.push({
        PlanType: 3,
        StartDate: "",
        EndDate: "",
        PlanInfo: "",
        Sort: 0,
      });
    },
    handleSelect(val) {
      this.TargetPlanList[this.selectIndex].PlanType =
        this.PlanTypeList[val.detail.value].value;
    },
    selectStartData(val) {
      this.TargetPlanList[this.selectIndex].StartDate = val.detail.value;
    },
    selectStartEndDate(val) {
      this.TargetPlanList[this.selectIndex].EndDate = val.detail.value;
    },
    handDelete(index) {
      this.$confirm("确定删除该项吗？", "注意！", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.TargetPlanList.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.self-analysis {
  color: #fff;
}

.commonBox {
  .titleBox {
    line-height: 28px;
    color: #666;
    font-weight: bold;
    margin: 8px 0px;
  }

  .tableBox {
    width: 100%;
    color: #a8c2ff;
    font-size: 14px;
    position: relative;
    z-index: 1;
    .addbtn {
      position: absolute;
      top: -52px;
      right: 0;
      width: 120px;
      color: rgba(var(--themecolor),0.8);
      height: 100px;
      text-align: center;
      border-radius: 5px 5px 0px 0px;
      background: #ddd;
      font-weight: bold;
      z-index: -1;
      cursor: pointer;
      .addbtnText {
        line-height: 52px;
        height: 52px;
      }
    }

    .table-head {
      width: 100%;
      height: 48px;
      background: rgba(var(--themecolor),0.8);
      opacity: 1;
      border-radius: 5px 5px 0px 0px;
      padding: 12px 0px;

      .headTextBox {
        width: 100%;
        display: flex;
      }
    }

    .headtext {
      width: 16%;
      font-size: 16px;
      height: 24px;
      line-height: 24px;
      color: #eef2f6;
      text-align: center;
      border-left: #ddd 1px solid;
    }

    .headtext1 {
      width: 12%;
      border-left: none;
    }

    .headtext2 {
      width: 50%;
    }

    .headtext4 {
      width: 6%;
    }

    .table-body {
      width: 100%;
      background: #ddd;
      border-radius: 0px 0px 5px 5px;

      .bodyTr {
        display: flex;
        .bodyTd {
          width: 16%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          padding: 17px 14px;
          min-height: 54px;
          border-left: #ddd 1px solid;
        }
        .bodyTd1 {
          width: 12%;
          border-left: none;
        }

        .bodyTd2 {
          width: 50%;
          text-align: left;
          .textarea {
            width: 100%;
          }
        }

        .bodyTd4 {
          width: 6%;
        }
      }
      .bodyTrBot {
        border-bottom: #ddd 1px solid;
      }
    }
  }
}
.notDataDiv {
  height: 48px;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  color: #31415e;
  opacity: 0.4;
}
</style>
