<template>
    <div class="app">
        <Header></Header>
        <div class="app-main">
            <router-view></router-view>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
    components: {
        Header,
        Footer
    }
}
</script>
<style lang="less" scoped>
.app-main {
    max-width: 1420px;
    margin: auto;
}
</style>