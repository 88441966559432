import {service} from '@/api/config'
import qs from 'qs'
import axios from 'axios';
export default {
    CareerPlan: {
        //获取院校列表
        GetRecommendBookList(){
            return axios.post('/api/Career/GetRecommendBookList');
        },
        //获取生涯课件列表
        GetCoursewareList(pageIndex,pageSize,data){
            return axios.post('/api/Career/GetCoursewareList?pageIndex='+pageIndex+'&pageSize='+pageSize,data);
        },
        //获取生涯课件详情
        GetCoursewareDetail(data){
            return axios.post('/api/Career/GetCoursewareDetail',data);
        },
        //获取视频类别列表
        GetVideoCategoryList(){
            return axios.post('/api/Career/GetVideoCategoryList');
        },
        //获取视频课程列表
        GetVideoList(pageIndex,pageSize,data){
            return axios.post('/api/Career/GetVideoList?pageIndex='+pageIndex+'&pageSize='+pageSize,data);
        },
        //获取每日任务列表
        GetDailyTaskList(data){
            return axios.post('/api/Career/GetDailyTaskList',data);
        },
        //添加每日任务
        AddDailyTask(data){
            return axios.post('/api/Career/AddDailyTask',data);
        },
        //删除每日任务
        DelDailyTask(data){
            return axios.post('/api/Career/DelDailyTask',data);
        },
        //修改每日任务
        UpdateDailyTask(data){
            return axios.post('/api/Career/UpdateDailyTask',data);
        },
        //完成每日任务
        ComplateDailyTask(data){
            return axios.post('/api/Career/ComplateDailyTask',data);
        },
        //设置目标
        SetTarget(data){
            return axios.post('/api/Career/SetTarget',data);
        },
        //获取目标详情
        GetTargetDetail(data){
            return axios.post('/api/Career/GetTargetDetail',data);
        },
        
    }
}