<template>
  <div class="main-content">
    <div class="content-inner">
      <video
        class="videobox"
        :poster="mainData.CoverImageUrl"
        :src="mainData.CoursewareFileUrl"
        controls="controls"
        controlsList="nodownload"
      ></video>
      <div class="title-name">
        {{ mainData.CoursewareName ? mainData.CoursewareName : "暂无详情" }}
      </div>
      <div class="content">
        {{ mainData.Description }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoDetail",
  props: {
    mainData: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="less" scoped>
.main-content {
  width: 1200px;
  margin: 40px auto;
  .content-inner {
    width: 100%;
    min-height: 400px;
  }
}
.videobox {
  height: 675px;
  background: rgba(0, 0, 0, 0);
  border-radius: 20px;
  width: 100%;
  object-fit: fill;
}
.title-name {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #31415e;
  margin: 24px 0;
}
.content {
  font-size: 16px;
  line-height: 32px;
  color: #31415e;
}
</style>
