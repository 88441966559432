<template>
    <div class="custom-input" :style="{'width': inputWidth}">
      <div class="input-name">
        {{ inputName }}：
      </div>
      <div class="input-box">
        <el-input class="input" type="text" :value="value" @input="handleInput"></el-input>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {}
    },
    props: {
      // 输入框名字
      inputName: {
        type: String,
        default: ''
      },
      // 输入框宽度
      inputWidth: {
        type: String,
        default: '200px'
      },
      value: {
        type: String,
        default: ''
      }
    },
    mounted () {},
    methods: {
      handleInput (event) {
        this.$emit('input', event)
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .custom-input {
    display: inline-block;
    font-size: 16px;
  
    .input-name {
      color: #666;
      margin: 0 0 8px 2px;
    }
  
    .input-box {
  
    }
  }
  </style>
  