<template>
  <div class="self-analysis">
    <div class="commonBox">
      <div class="self-assessment">
        <div class="assessment-Title">个人职业价值观</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.CareerValues"
            autosize
          ></el-input>
        </div>
      </div>
      <div class="self-assessment">
        <div class="assessment-Title">MBTI模型测试</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.MBTITest"
            autosize
          ></el-input>
        </div>
      </div>
      <div class="self-assessment">
        <div class="assessment-Title">中学生人格测试</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.Enneagram"
            autosize
          ></el-input>
        </div>
      </div>
      <div class="self-assessment">
        <div class="assessment-Title">多元智能发展测评</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.MultiIntelligenceTest"
            autosize
          ></el-input>
        </div>
      </div>
      <div class="self-assessment">
        <div class="assessment-Title">爱好及特长</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.Hobbys"
            autosize
          ></el-input>
        </div>
      </div>
      <div class="self-assessment">
        <div class="assessment-Title">意志力状况</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.MentalityStatus"
            autosize
          ></el-input>
        </div>
      </div>
      <div class="self-assessment">
        <div class="assessment-Title">个人优点</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.Merit"
            autosize
          ></el-input>
        </div>
      </div>
      <div class="self-assessment">
        <div class="assessment-Title">个人缺点</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.Demerit"
            autosize
          ></el-input>
        </div>
      </div>
      <div class="self-assessment">
        <div class="assessment-Title">人际关系状况</div>
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.RelationshipStatus"
            autosize
          ></el-input>
        </div>
      </div>
    </div>
    <div class="commonBox">
      <div class="assessment-Title">社会中的自我评估：</div>
      <div class="tableBox">
        <div class="table-head">
          <div class="headTextBox">
            <div class="headtext">关系</div>
            <div class="headtext">姓名</div>
            <div class="headtext headtext3">
              对你的看法与期望/对你人生发展产生的影响
            </div>
            <div class="headtext headtext4">操作</div>
          </div>
          <div class="addbtn">
            <div @click="EvaluationListAdd" class="addbtnText">+添加</div>
          </div>
        </div>
        <div class="table-body">
          <template v-if="pageData.EvaluationList">
            <div
              class="bodyTr"
              :class="{
                bodyTrBot: pageData.EvaluationList.length > 1,
              }"
              v-for="(item, index) in pageData.EvaluationList"
              :key="index"
            >
              <div class="bodyTd">
                <el-select v-model="item.Relationship" placeholder="请选择">
                  <el-option
                    v-for="item in RelationshipList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="bodyTd">
                <el-input type="text" v-model="item.Name" />
              </div>
              <div class="bodyTd bodyTd3">
                <el-input
                  type="textarea"
                  v-model="item.EvaluationInfo"
                  autosize
                ></el-input>
              </div>
              <div class="bodyTd bodyTd4">
                <el-button type="text" @click="handDelete(index)"
                  >删除</el-button
                >
              </div>
            </div>
          </template>
          <template
            v-if="
              !pageData.EvaluationList || pageData.EvaluationList.length === 0
            "
          >
            <div class="notDataDiv">暂无社会中的自我评估</div>
          </template>
        </div>
      </div>
    </div>
    <div class="commonBox">
      <div class="assessment-Title">自我分析总结：</div>
      <div class="self-assessment self-sumup">
        <div class="textBox">
          <el-input
            type="textarea"
            class="textarea"
            v-model="pageData.SelfAnalysisSummary"
            autosize
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import local_json from "../my-components/private_info_json.js";
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      //图片地址
      imagesBaseURL: this.$store.state.imagesBaseURL,
      pageData: {
        CareerValues: "",
        MBTITest: "",
        Enneagram: "",
        MultiIntelligenceTest: "",
        Hobbys: "",
        MentalityStatus: "",
        Merit: "",
        Demerit: "",
        RelationshipStatus: "",
        EvaluationList: [],
        SelfAnalysisSummary: "",
      },
      // 父亲:1;母亲:2;亲戚:3;朋友:4;老师:5;同学:6;领导:7;其他:8;
      RelationshipList: local_json.RelationshipList,
      selectIndex: 0,
    };
  },
  watch: {
    detailData(val) {
      this.pageData = this.detailData;
    },
  },
  mounted() {
    // this.inte()
  },
  methods: {
    inte() {
      if (this.detailData) {
        this.pageData = {
          CareerValues: this.detailData.CareerValues,
          MBTITest: this.detailData.MBTITest,
          Enneagram: this.detailData.Enneagram,
          MultiIntelligenceTest: this.detailData.MultiIntelligenceTest,
          Hobbys: this.detailData.Hobbys,
          MentalityStatus: this.detailData.MentalityStatus,
          Merit: this.detailData.Merit,
          Demerit: this.detailData.Demerit,
          RelationshipStatus: this.detailData.RelationshipStatus,
          EvaluationList: this.detailData.EvaluationList,
          SelfAnalysisSummary: this.detailData.SelfAnalysisSummary,
        };
      }
    },
    EvaluationListAdd() {
      this.pageData.EvaluationList.push({
        Relationship: "",
        Name: "",
        EvaluationInfo: "",
        Sort: 0,
      });
    },
    handDelete(index) {
      this.$confirm("确定删除该项吗？", "注意！", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.pageData.EvaluationList.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.self-analysis {
  color: #fff;
}

.commonBox {
  .titleBox {
    line-height: 40px;
    color: #a8c2ff;
    opacity: 0.4;
  }

  .titleBoxTwo {
    line-height: 20px;
  }
}
.assessment-Title {
  line-height: 28px;
  color: #666;
  font-weight: bold;
  margin: 8px 0px;
}
.self-assessment {
  width: 100%;
  margin-bottom: 10px;
  line-height: 40px;
  font-size: 16px;
  .textBox {
    width: 100%;
    line-height: 1.5;
    min-height: 48px;
    .textarea {
      background: #eef2f6;
      width: 100%;
    }
  }
}

.self-sumup {
  .textBox {
    width: 100%;
  }
}

.tableBox {
  width: 100%;
  color: #a8c2ff;
  font-size: 14px;
  position: relative;
  z-index: 1;

  .addbtn {
    position: absolute;
    top: -52px;
    right: 0;
    width: 120px;
    color: rgba(var(--themecolor),0.8);
    height: 100px;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    background: #ddd;
    font-weight: bold;
    z-index: -1;
    cursor: pointer;
    .addbtnText {
      line-height: 52px;
      height: 52px;
    }
  }
  .table-head {
    width: 100%;
    height: 48px;
    background: rgba(var(--themecolor),0.8);
    opacity: 1;
    border-radius: 5px 5px 0px 0px;
    padding: 12px 0px;

    .headTextBox {
      width: 100%;
      display: flex;    

      .headtext {
        font-size: 16px;
        width: 14%;
        height: 24px;
        line-height: 24px;
        color: #eef2f6;
        text-align: center;
        border-right: #ddd 1px solid;
      }

      .headtext3 {
        width: 66%;
      }
      .headtext4 {
        width: 6%;
        border-right: none;
      }
    }
  }

  .table-body {
    width: 100%;
    background: #fff;
    border-radius: 0px 0px 5px 5px;

    .bodyTr {
      display: flex;
      .bodyTd {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14%;
        padding: 17px 14px;
        line-height: 20px;
        min-height: 54px;
        text-align: center;
        border-right: #ddd 1px solid;
      }

      .bodyTd3 {
        width: 66%;
        text-align: left;
        .textarea {
          width: 100%;
        }
      }
      .bodyTd4 {
        width: 6%;
        border-right: none;
        padding: 0;
        .image {
          width: 34px;
          height: 34px;
        }
      }
    }
    .bodyTrBot {
      border-bottom: #ddd 1px solid;
    }

    .table-add {
      height: 74px;
      background: #ddd;
      border-radius: 0px 0px 15px 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > image {
        width: 27px;
        height: 27px;
        margin-right: 14px;
      }
    }
  }
  .notDataDiv {
    height: 48px;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    color: #31415e;
    opacity: 0.4;
  }
}
</style>
